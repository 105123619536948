import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Configuration } from '../../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { StateModule } from '../state.module';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetStateList = (body: string, page: number, size: number): Observable<StateModule[]> => {
    const _url: string = this.configuration.Server + 'admin/all/states/' + page + '/' + size;
    return this.http.post<StateModule[]>(_url, body);
  };

  public GetStateDropdown = (body: string, page: number, size: number): Observable<StateModule[]> => {
    const _url: string = this.configuration.Server + 'admin/states/' + page + '/' + size;
    return this.http.post<StateModule[]>(_url, body);
  };

  public GetSingle = (body: StateModule): Observable<StateModule> => {
    const _url: string = this.configuration.Server + 'admin/state/details';
    return this.http.post<StateModule>(_url, body);
  };

  public Add = (addState: StateModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/state';
    return this.http.post<ResponseMessage>(_url, addState);
  };

  public Update = (updateState: StateModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/state';
    return this.http.put<ResponseMessage>(_url, updateState);
  };
}
