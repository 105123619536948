import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { BuildingModule } from '../../venuemanagement/building/building.module';
import { VenueModule } from '../../venuemanagement/venue/venue.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule],

  declarations: [],
})
export class AssetModule extends GenericEntity {
  public Level: number;
  public AssetId: number;
  public AssetCPId: string;
  public AssetName: string;
  public AssetDescription: string;
  public LocationId: number;
  public Longitude: any;
  public Latitude: any;
  public LocationName: string;
  public Building: BuildingModule;
  public ImageName: string;
  public Venue: VenueModule;
}
