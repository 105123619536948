<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-4 col-md-6 col-sm-4 col-xs-12 form-heading">
        <i class="fa fa-upload heading-icon"></i>
        <span class="list-heading">IMPORT DATA</span>
      </div>

      <div class="col-lg-8 col-md-6 col-sm-8 col-xs-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="transactions">
          <ngx-atlas-select
            (model)="pageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-3 col-md-3 col-sm-8 col-xs-12 input-group pull-right add-section"
          *ngIf="canAccess('IMPORT_DATA_FILE_DOWNLOAD', 'view')"
        >
          <input
            type="button"
            value="Download Template File"
            class="btn btn-add col-lg-12 col-md-12 col-xs-12"
            (click)="exportToExcel()"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="canAccess('IMPORT_DATA_ALLOW', 'view')">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg-3 col-md-4 col-sm-4 input-group data-import">
        <label class="col-md-12 col-sm-12 col-lg-10" for="choosefile">Choose Excel File To Import</label>
      </div>

      <div class="col-sm-6 col-md-4 col-lg-4 uploadfile">
        <input
          #fileInput
          type="file"
          #ImportTransactionFile
          id="chooseFile"
          class="btn btn-default form-control"
          value="Choose Files"
          (change)="fileChange($event)"
          accept=".xls,.xlsx,.xlsm"
          required
        />
      </div>

      <div class="col-md-4" *ngIf="!isFileSelected">
        <input type="button" value="Remove" class="btn btn-remove" (click)="fileReset()" />
        <input
          type="button"
          value="Save"
          [disabled]="isFileSelected"
          class="btn btn-remove remove"
          (click)="AddFile(VenueForm)"
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 10px">
      <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!hasData()">
        <h3 class="listerror">Sorry no data found!</h3>
      </div>

      <div class="box-body table-responsive">
        <table class="table table-hover table-responsive" *ngIf="hasData()">
          <thead>
            <tr>
              <th>Import Date</th>
              <th>Import Status</th>
              <th>Imported By</th>
              <th>Download Log</th>
              <th>
                <a
                  (click)="getAllData('', page, size)"
                  class="fa fa-refresh"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Refresh"
                ></a>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="
                let transaction of transactions | paginate: { itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <td>{{ transaction.ModifiedOn | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
              <td>{{ transaction.Message }}</td>
              <td>{{ transaction.User.FirstName }} {{ transaction.User.LastName }}</td>
              <td *ngIf="transaction.LogFiles != null && transaction.LogFiles.length > 0">
                <a
                  (click)="downloadTransactionFiles(transaction.Id)"
                  class="fa fa-download"
                  style="color: black; cursor: pointer"
                  download
                ></a>
              </td>
              <td *ngIf="transaction.LogFiles == null">-</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls
        class="pagination pull-right"
        (pageChange)="pageChanged($event)"
        *ngIf="hasData() && count > 10"
      ></pagination-controls>
    </div>
  </div>
</section>
