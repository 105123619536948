import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthState } from 'src/app/store/auth.state';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { VenueService } from '../../../venuemanagement/venue/components/venue.http.service';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { GatewayModule } from '../getway.module';
import { GatewayService } from './getway.http.service';

@Component({
  selector: 'getway.component',
  templateUrl: 'getway.component.html',
  providers: [GatewayService, VenueService, UtilitiesService],
})
export class GatewayComponent implements OnInit {
  currentUser: User;

  public gateways: GatewayModule[] = [];
  public gateway: GatewayModule = new GatewayModule();

  public venues: VenueModule[] = [];

  page = 1;
  size = 10;
  count = 0;
  searchString = '';

  isNewState = true;
  formList = true;
  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;
  pageSizes: any[];

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  venueBind: number;
  public pageState = [{ pageName: 'gatewayForm', page: this.page, size: this.size, viewType: this.viewType }];

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private gatewayService: GatewayService,
    private venueService: VenueService,
    private loaderService: LoaderService,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.gateway = new GatewayModule();
    this.gateway.Venue = new VenueModule();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
    this.venueBind = null;
    this.venueBind = this.authState.getStoredContextVenueId();
  }

  public canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.viewType = this.pageState[0].viewType;
      this.getAllGateways(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllGateways(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public GateWayPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllGateways(this.searchString, this.page, this.size, this.viewType);
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllGateways(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllGateways(this.searchString, this.page, this.size, this.viewType);
  }

  public gateWaySearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllGateways(searchString, 1, this.size, this.viewType);
  }

  public setUpdateForm() {
    this.gateways = null;
    this.count = 0;
    this.searchString = '';
    this.getAllVenues(this.searchString, -1, -1);
  }

  public setAddForm() {
    this.searchString = '';
    this.gateways = null;
    this.count = 0;
    this.gateway = new GatewayModule();
    this.gateway.Venue = new VenueModule();

    this.getAllVenues(this.searchString, -1, -1);
  }

  public hasData(): boolean {
    return this.gateways != null && this.gateways.length > 0;
  }

  public clearFormData(getwayForm: NgForm) {
    getwayForm.reset();
    this.searchString = '';
    this.gateway = new GatewayModule();
    this.gateway.Venue = new VenueModule();
    this.getAllGateways(this.searchString, this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.getAllGateways(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllGateways(this.searchString, this.page, this.size, this.viewType);
  }

  public addGateway(getwayForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (getwayForm.status == 'INVALID' && getwayForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Gateway', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    const _getwayDetails: GatewayModule = new GatewayModule();

    _getwayDetails.GatewayName = this.utilitiesService.removeInnerSpaces(this.gateway.GatewayName);
    if (_getwayDetails.GatewayName == null || _getwayDetails.GatewayName == '') {
      this.gateway.GatewayName = _getwayDetails.GatewayName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _getwayDetails.GatewayIdentifier = this.utilitiesService.removeInnerSpaces(this.gateway.GatewayIdentifier);
    if (_getwayDetails.GatewayIdentifier == null || _getwayDetails.GatewayIdentifier == '') {
      this.gateway.GatewayIdentifier = _getwayDetails.GatewayIdentifier;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _getwayDetails.Range = this.gateway.Range;
    _getwayDetails.Venue = new VenueModule();
    _getwayDetails.Venue.VenueId = this.gateway.Venue.VenueId;
    _getwayDetails.AddedBy = this.currentUser.UserId;

    this.gatewayService.Add(this.utilitiesService.stripScript(_getwayDetails)).subscribe(
      () => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Gateway', 'Gateway added successfully!');
        this.clearFormData(getwayForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'gatewayService addGateway Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public updateGateway(getwayForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (getwayForm.status == 'INVALID' && getwayForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Gateway', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    const _getwayDetails: GatewayModule = new GatewayModule();

    _getwayDetails.GatewayName = this.utilitiesService.removeInnerSpaces(this.gateway.GatewayName);
    if (_getwayDetails.GatewayName == null || _getwayDetails.GatewayName == '') {
      this.gateway.GatewayName = _getwayDetails.GatewayName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _getwayDetails.GatewayIdentifier = this.utilitiesService.removeInnerSpaces(this.gateway.GatewayIdentifier);
    if (_getwayDetails.GatewayIdentifier == null || _getwayDetails.GatewayIdentifier == '') {
      this.gateway.GatewayIdentifier = _getwayDetails.GatewayIdentifier;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _getwayDetails.Range = this.gateway.Range;
    _getwayDetails.GatewayId = this.gateway.GatewayId;
    _getwayDetails.Venue = new VenueModule();
    _getwayDetails.Venue.VenueId = this.gateway.Venue.VenueId;
    _getwayDetails.IsActive = this.gateway.IsActive ? 1 : 0;
    _getwayDetails.ModifiedBy = this.currentUser.UserId;

    this.gatewayService.Update(this.utilitiesService.stripScript(_getwayDetails)).subscribe(
      () => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Gateway', 'Gateway updated successfully!');
        this.clearFormData(getwayForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'gatewayService updateGateway Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllGateways(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.gatewayService.GetGatewayList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.gateways = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'gatewayService getAllGateways Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.gateways[0] ? this.gateways[0].Count : 0),
    );
  }

  public getGatewayById(getwayId: number) {
    if (this.canAccess('GATEWAY_VIEW', 'view')) {
      this.loaderService.display(true);
      const CPgateway = new GatewayModule();
      CPgateway.GatewayCPId = this.authState.AESEncryptText(getwayId);

      this.gatewayService.GetSingle(CPgateway).subscribe(
        (data) => {
          this.gateway = data;
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'gatewayService getGatewayById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Gateway', 'You do not have permission to view gateway details.');
    }
  }

  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;

        if (!this.gateways && !this.gateway.GatewayId) {
          const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
          if (obj[0]) {
            this.gateway.Venue.VenueId = obj[0].VenueId;
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService getAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  keyPressCheck($event: any) {
    if ($event.key == 'e' || $event.key == 'E' || $event.key == '-') {
      $event.preventDefault();
      return false;
    }

    if ($event.target.value.length > 10) {
      $event.preventDefault();
      return false;
    }
  }
}
