import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthState } from 'src/app/store/auth.state';
import { Configuration } from '../../../app.constants';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { ElementModule } from '../../element/element.module';
import { VenueElement } from '../venueelementconfiguration.module';
import { VenueElementService } from './venueelementconfiguration.http.service';

@Component({
  selector: 'venueelementconfiguration.component',
  templateUrl: 'venueelementconfiguration.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./venueelementconfiguration.scss'],
  providers: [VenueElementService, UtilitiesService],
})
export class VenueElementConfigurationComponent implements OnInit {
  currentUser: User;

  public masterElementList: ElementModule[] = [];
  public VenueelementList: VenueElement[] = [];
  public Venueelements: VenueElement[] = [];
  public payload: VenueElement[] = [];
  public instructions: VenueElement[] = [];
  public VenueElement: VenueElement = new VenueElement();

  public Venue: VenueModule = new VenueModule();
  public venues: VenueModule[] = [];
  isReqComplete = false;

  rowid: number;
  index = -1;
  sensorImg = '';

  @ViewChild('fileInput') fileInputVariable: any;
  @ViewChild('addNewElementFileInput') addNewElementFileInput: any;
  file: File = null;
  noImage = 'assets/no-image.jpg';
  profileImgSrc: any = null;
  elementImgPath = this.configuration.Server_Element_Image_Folder;

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    private configuration: Configuration,
    private venueElementService: VenueElementService,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.Venue = new VenueModule();
    this.VenueElement = new VenueElement();
    this.VenueElement.VenueId = this.authState.getStoredContextVenueId();
    this.VenueElement.VenueName = this.authState.getStoredVenueName();
  }

  ngOnInit() {
    this.getAllVenueElements();
  }

  public canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public hasElementData(): boolean {
    if (this.canAccess('VENUE_ELEMENT_VIEW', 'view')) {
      return this.masterElementList != null && this.masterElementList.length > 0;
    } else {
      return false;
    }
  }

  public clearFormData(VenueElementForm: NgForm) {
    this.masterElementList = [];
    this.instructions = [];
    this.Venueelements = [];
    VenueElementForm.reset();

    this.getAllVenueElements();
  }

  public openElementImage(imageName: string) {
    this.sensorImg = '';
    this.sensorImg = imageName;
  }

  public getAllVenueElements() {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: '', GenericSearch: 1, IsActive: 1 };

    this.venueElementService.GetVenueElementList(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.Venue.VenueElementList = [];
        if (data != null) {
          this.Venue = data;
          const that = this;

          if (this.Venue.VenueElementList != null && this.Venue.VenueElementList.length > 0) {
            let obj = this.Venue.VenueElementList.filter((element) => element.IsMasterElement == 1);
            if (obj != null) {
              obj.forEach(function (t) {
                const element = new ElementModule();
                if (that.canAccess('VENUE_ELEMENT_EDIT', 'view')) {
                  element.isAllowToEdit = false;
                } else {
                  element.isAllowToEdit = true;
                }

                element.ElementId = t.MasterElementId;
                element.ElementName = t.VenueElementName;

                element.ImageType = t.ImageType;
                element.ImageTypeName = t.ImageTypeName;
                element.showElementImage = t.AttachmentURL != null && t.AttachmentURL != '' ? t.AttachmentURL : '';

                element.Sequence = t.Sequence;
                element.IsActive = t.IsActive;
                element.MasterElementId = t.MasterElementId;
                element.IsMasterElement = t.IsMasterElement;
                that.masterElementList.push(element);
              });
            }
            for (let i = 0; i < this.Venue.VenueElementList.length; i++) {
              const id = new VenueElement();
              if (this.canAccess('VENUE_ELEMENT_EDIT', 'view')) {
                id.isAllowToEdit = false;
              } else {
                id.isAllowToEdit = true;
              }

              id.VenueElementId = this.Venue.VenueElementList[i].VenueElementId;
              id.VenueElementName = this.Venue.VenueElementList[i].VenueElementName;

              id.ImageType = this.Venue.VenueElementList[i].ImageType;
              id.ImageTypeName = this.Venue.VenueElementList[i].ImageTypeName;

              id.VenueElementImageType = this.Venue.VenueElementList[i].VenueElementImageType;
              id.VenueElementImageTypeName = this.Venue.VenueElementList[i].VenueElementImageTypeName;
              id.showElementImage =
                this.Venue.VenueElementList[i].AttachmentURL != null &&
                  this.Venue.VenueElementList[i].AttachmentURL != ''
                  ? this.Venue.VenueElementList[i].AttachmentURL
                  : '';

              id.Sequence = this.Venue.VenueElementList[i].Sequence;
              id.IsActive = this.Venue.VenueElementList[i].IsActive;
              id.MasterElementId = this.Venue.VenueElementList[i].MasterElementId;
              id.IsMasterElement = this.Venue.VenueElementList[i].IsMasterElement;
              that.instructions.push(id);
            }
          }

          if (this.masterElementList && this.masterElementList.length > 0) {
            for (let s = 0; s < this.instructions.length; s++) {
              for (let j = 0; j < this.masterElementList.length; j++) {
                if (this.instructions[s].MasterElementId == this.masterElementList[j].ElementId) {
                  this.masterElementList[j].IsActive = this.instructions[s].IsActive;
                  this.masterElementList[j].Sequence = this.instructions[s].Sequence;
                }
              }
            }
          }

          if (this.instructions && this.instructions.length > 0) {
            for (let t = 0; t < this.instructions.length; t++) {
              if (this.instructions[t].MasterElementId != null) {
                const element = new VenueElement();
                if (this.canAccess('VENUE_ELEMENT_EDIT', 'view')) {
                  element.isAllowToEdit = false;
                } else {
                  element.isAllowToEdit = true;
                }
                element.VenueElementId = this.instructions[t].VenueElementId;
                element.VenueElementName = this.instructions[t].VenueElementName;

                element.ImageType = this.instructions[t].ImageType;
                element.ImageTypeName = this.instructions[t].ImageTypeName;

                element.VenueElementImageType = this.instructions[t].VenueElementImageType;
                element.VenueElementImageTypeName = this.instructions[t].VenueElementImageTypeName;
                element.showElementImage =
                  this.instructions[t].AttachmentURL != null && this.instructions[t].AttachmentURL != ''
                    ? this.instructions[t].AttachmentURL
                    : '';

                element.Sequence = this.instructions[t].Sequence;
                element.IsActive = this.instructions[t].IsActive;
                element.IsMasterElement = this.instructions[t].IsMasterElement;
                element.MasterElementId = this.instructions[t].MasterElementId;
                this.Venueelements.push(element);

                this.instructions.splice(t, 1);
                t = t - 1;
              }
            }
          }
        }

        let elementIndex = 0;
        for (let h = 0; h < this.instructions.length; h++) {
          elementIndex = h + 1;
          this.instructions[h].VenueElementRowID = elementIndex;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueElementService getAllVenueElements Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public updateVenueElement(VenueElementForm: NgForm) {
    if (this.canAccess('VENUE_ELEMENT_EDIT', 'view') || this.canAccess('VENUE_ELEMENT_ADD', 'view')) {
      this.loaderService.display(true);
      this.isReqComplete = true;

      if (VenueElementForm.status == 'INVALID' && VenueElementForm.disabled == false) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal(
          'Venue Element',
          'You cannot proceed ahead until all the fields are filled properly.',
        );
        return;
      }

      if (this.instructions.length == 0 && this.Venueelements.length == 0) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal('Venue Element', 'Please add atleast one element.');
        return;
      }

      if (this.instructions && this.instructions.length > 0) {
        for (let i = 0; i < this.instructions.length; i++) {
          if (
            this.instructions[i].IsActive &&
            (this.instructions[i].Sequence == null ||
              this.instructions[i].VenueElementName == '' ||
              this.instructions[i].VenueElementName.trim().length <= 0)
          ) {
            this.utilitiesService.smmodal('Venue Element', 'Element row should not be blank.');
            this.loaderService.display(false);
            this.isReqComplete = false;
            return;
          }
        }
      }

      if (
        this.masterElementList != null &&
        this.masterElementList.length > 0 &&
        this.Venueelements != null &&
        this.Venueelements.length > 0
      ) {
        for (let inv = 0; inv < this.masterElementList.length; inv++) {
          for (let vi = 0; vi < this.Venueelements.length; vi++) {
            if (this.masterElementList[inv].ElementId == this.Venueelements[vi].ElementId) {
              this.Venueelements[vi].Sequence = this.masterElementList[inv].Sequence;
            }
          }
        }
      }

      if (
        this.masterElementList &&
        this.masterElementList.length > 0 &&
        this.Venueelements &&
        this.Venueelements.length > 0
      ) {
        for (let master = 0; master < this.masterElementList.length; master++) {
          for (let main = 0; main < this.Venueelements.length; main++) {
            if (this.masterElementList[master].MasterElementId == this.Venueelements[master].MasterElementId) {
              this.Venueelements[master].ImageBase64String = this.masterElementList[master].ImageBase64String;
              this.Venueelements[master].ImageType = this.masterElementList[master].ImageType;
              this.Venueelements[master].ImageTypeName = this.masterElementList[master].ImageTypeName;
              this.Venueelements[master].IsImageRemoved = this.masterElementList[master].IsImageRemoved;
            }
          }
        }
      }

      this.payload = this.Venueelements.concat(this.instructions);
      let that = this;
      this.payload.forEach(function (item) {
        item.VenueId = that.VenueElement.VenueId;
        item.ModifiedBy = that.currentUser.UserId;
        item.isAllowToEdit = item.isAllowToEdit ? 1 : 0;
      });

      this.Venue = new VenueModule();
      this.Venue.VenueId = this.VenueElement.VenueId;
      this.Venue.VenueElementList = this.payload;

      this.venueElementService.Update(this.utilitiesService.stripScript(this.Venue)).subscribe(
        (data) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          this.utilitiesService.smmodal('Venue Element', data.Message);
          this.clearFormData(VenueElementForm);
        },
        (error) => {
          this.isReqComplete = false;
          this.loaderService.display(false);
          console.log(
            'venueElementService updateVenueElement Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    } else {
      this.utilitiesService.smmodal('Venue Element', 'You do not have permission to edit venue element details.');
    }
  }

  public selectedVenueElement(elementId: number, elementName: string, sequence: number, isActive: any, base64: string) {
    let venueElementObj = this.Venueelements.filter((element) => element.MasterElementId == elementId);
    if (venueElementObj && venueElementObj.length > 0) {
      venueElementObj[0].MasterElementId = elementId;
      venueElementObj[0].VenueElementName = elementName;
      venueElementObj[0].Sequence = sequence;
      venueElementObj[0].IsActive = isActive ? 1 : 0;
      if (isActive === false) {
        venueElementObj[0].ImageBase64String = '';
      } else {
        venueElementObj[0].ImageBase64String = base64;
      }
    } else if (elementName != null && isActive == true) {
      const elements = new VenueElement();
      elements.MasterElementId = elementId;
      elements.VenueElementName = elementName;
      elements.Sequence = sequence;
      elements.IsActive = 1;
      elements.IsMasterElement = 1;
      this.Venueelements.push(elements);
    }

    if (elementName && isActive == false) {
      for (let i = 0; i < this.Venueelements.length; i++) {
        if (this.Venueelements[i].VenueElementName == elementName) {
          this.Venueelements[i].IsActive = 0;
          return;
        }
      }
    }
  }

  public addElement() {
    if (this.instructions && this.instructions.length > 0) {
      for (let i = 0; i < this.instructions.length; i++) {
        if (this.instructions[i].VenueElementName == '' || this.instructions[i].VenueElementName.trim().length <= 0) {
          window.scrollTo(0, document.body.scrollHeight);
          this.utilitiesService.smmodal('Venue Element', 'Element row should not be blank.');
          return;
        }
      }
    }

    this.rowid = this.instructions.length;
    this.rowid = this.rowid + 1;

    const newElement = new VenueElement();
    newElement.Sequence = this.VenueElement.Sequence;
    newElement.VenueElementName = '';
    newElement.VenueElementRowID = this.rowid;
    newElement.IsActive = 1;

    newElement.ImageBase64String = '';
    newElement.VenueElementImageType = '';
    newElement.VenueElementImageTypeName = '';
    this.instructions.push(newElement);
    window.scrollTo(0, document.body.scrollHeight);
  }

  public removeElement(venueElementRowId: number) {
    for (let i = 0; i < this.instructions.length; i++) {
      if (this.instructions[i].VenueElementRowID == venueElementRowId) {
        this.instructions[i].IsActive = 0;
        this.index = i;
        if (this.instructions[i].VenueElementId == null) {
          this.instructions.splice(this.index, 1);
          i = i - 1;
        }
      }
    }
  }

  public fileChange(event: any, venueElementRowID: number, isMasterElement: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal('Venue Element', this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        return;
      }

      const extention = event.target.files[0].name.split('.');
      const ext = extention[extention.length - 1].toLowerCase();
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png') {
        if (event.target.files && event.target.files[0]) {
          const filesize = event.target.files[0].size / Math.pow(1024, 2);
          if (filesize > 5) {
            this.utilitiesService.mdmodal('Venue Element', 'Picture should not be more than ' + 5 + ' MB.');
            event.target.value = '';
            return;
          } else {
            const fileList: FileList = event.target.files;
            if (fileList.length > 0) {
              this.file = fileList[0];
              const reader = new FileReader();
              reader.onload = this.handleReaderLoaded.bind(this, venueElementRowID, isMasterElement);
              reader.readAsBinaryString(this.file);
            }
          }
        }
      } else {
        this.utilitiesService.smmodal('Venue Element', 'Please upload image of type .jpg, .jpeg, .png.');
        event.target.value = '';
      }
    }
  }

  handleReaderLoaded(venueElementRowID: number, isMasterElement: any, readerEvt: any) {
    const binaryString = readerEvt.target.result;
    if (isMasterElement) {
      for (let img = 0; img < this.masterElementList.length; img++) {
        if (this.masterElementList[img].ElementId === venueElementRowID) {
          this.masterElementList[img].IsImageRemoved = 0;
          this.masterElementList[img].ImageBase64String = btoa(binaryString);
          this.masterElementList[img].showElementImage = 'data:image/jpeg;base64,' + btoa(binaryString);
        }
      }
    } else {
      for (let img = 0; img < this.instructions.length; img++) {
        if (this.instructions[img].VenueElementRowID === venueElementRowID) {
          this.instructions[img].IsImageRemoved = 0;
          this.instructions[img].ImageBase64String = btoa(binaryString);
          this.instructions[img].showElementImage = 'data:image/jpeg;base64,' + btoa(binaryString);
        }
      }
    }
  }

  public fileReset(venueElementRowID: number, isMasterElement: any) {
    if (isMasterElement) {
      for (let i = 0; i < this.masterElementList.length; i++) {
        if (this.masterElementList[i].ElementId == venueElementRowID) {
          // if (this.masterElementList[i].ImageTypeName != '') {
          this.masterElementList[i].IsImageRemoved = 1;
          this.masterElementList[i].ImageBase64String = '';
          this.masterElementList[i].showElementImage = '';
          // this.elementList[i].showElementImage = this.elementList[i].ImageTypeName != '' ?
          //     this.elementImgPath + this.elementList[i].ImageTypeName : '';
          // }
          if (this.fileInputVariable) {
            this.fileInputVariable.nativeElement.value = '';
            this.file = null;
          }
        }
      }
    } else {
      for (let i = 0; i < this.instructions.length; i++) {
        if (this.instructions[i].VenueElementRowID == venueElementRowID) {
          // if (this.instructions[i].VenueElementImageTypeName != null) {
          this.instructions[i].IsImageRemoved = 1;
          this.instructions[i].ImageBase64String = '';
          this.instructions[i].showElementImage = '';

          if (this.addNewElementFileInput) {
            this.addNewElementFileInput.nativeElement.value = '';
            this.file = null;
          }
          // }
        }
      }
    }
  }
}
