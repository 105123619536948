<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-8 col-sm-12 form-heading" *ngIf="badgeList">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading">BADGES</span>
      </div>
      <div class="col-lg-6 col-md-8 col-sm-12 form-heading" *ngIf="!badgeList">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="!badgeList && !badge.BadgeId">ADD BADGE</span>
        <span class="list-heading" *ngIf="!badgeList && badge.BadgeId">EDIT BADGE</span>
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12">
        <div
          id="page-size"
          class="col-lg-1 col-sm-3 col-md-4 col-xs-12 pull-right list-heading pageSize"
          *ngIf="badgeList"
        >
          <ngx-atlas-select
            (model)="BadgePageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="badgeList && !badge.BadgeId && canAccess('BADGE_ADD', 'view')"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Badge
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="badgeList">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-5 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="badgeList">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="badgeSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="badgeList">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="list-view-error">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('BadgeNumber', columnSortOrder)">
                  Badge Number
                  <span *ngIf="selectedColumnName == 'BadgeNumber' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BadgeNumber' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header">Venue</th>
                <th class="table-sort-header">User</th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>
            <tbody
              *ngFor="
                let badge of badgeList
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getBadgeById(badge.BadgeId)">
                <td>{{ badge.BadgeNumber }}</td>
                <td>{{ badge.Venue.VenueName }}</td>
                <td>{{ badge.UserNames }}</td>
                <td *ngIf="badge.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!badge.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls
            class="pagination pull-right"
            (pageChange)="pageChanged($event)"
            *ngIf="hasData() && count > 10"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!badgeList">
    <form #BadgeForm="ngForm" name="badge" role="form" class="form-horizontal">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div>
          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <label for="BadgeName"><span id="red-circle">&#x25CF;</span> Badge Name</label>
            <input
              id="BadgeName"
              name="BadgeName"
              #BadgeName="ngModel"
              type="text"
              class="form-control"
              placeholder="Badge Name"
              [(ngModel)]="badge.BadgeName"
              required
              maxlength="59"
              autocomplete="off"
              [pattern]="utilitiesService.alphanumericRegex"
            />
            <div *ngIf="BadgeName.errors && (BadgeName.dirty || BadgeName.touched)" class="alert alert-danger">
              <div [hidden]="!BadgeName.errors.required" class="text-danger">Badge Name is required.</div>
              <div [hidden]="!BadgeName.errors.pattern" class="text-danger">
                {{ utilitiesService.alphanumericRegexMessage }}
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <label for="badgeNumber"><span id="red-circle">&#x25CF;</span> Badge Number</label>
            <input
              id="badgeNumber"
              type="text"
              name="badgeNumber"
              class="form-control"
              pattern="^[a-zA-Z0-9\s.\-_&():']+$"
              [(ngModel)]="badge.BadgeNumber"
              required
              maxlength="50"
              #badgeNumber="ngModel"
              placeholder="Badge Number"
              autocomplete="off"
            />
            <div *ngIf="badgeNumber.errors && (badgeNumber.dirty || badgeNumber.touched)" class="alert alert-danger">
              <div [hidden]="!badgeNumber.errors.required" class="text-danger">Badge number is required.</div>
              <div [hidden]="!badgeNumber.errors.pattern" class="text-danger">
                {{ utilitiesService.alphanumericRegexMessage }}
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <label for="Venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <ngx-atlas-select
              (model)="badge.Venue.VenueId = $event"
              [list]="venues"
              [idField]="'VenueId'"
              [textField]="'VenueName'"
              [placeholder]="'Select Venue'"
              [allowClear]="true"
              [multiple]="false"
              [required]="true"
              [selectedValue]="badge.Venue.VenueId"
            >
            </ngx-atlas-select>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group" *ngIf="badge.BadgeId">
              <div>
                <div>
                  <mat-checkbox [(ngModel)]="badge.IsActive" name="isActive">Is Active</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type pull-right btn-cancel"
            (click)="clearFormData(BadgeForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!badge.BadgeId"
            class="btn btn-type btn-save"
            (click)="addBadge(BadgeForm)"
            [disabled]="!BadgeForm.form.valid || !badge.Venue.VenueId || isReqComplete"
          />
          <input
            type="button"
            value="Save"
            *ngIf="badge.BadgeId"
            class="btn btn-type btn-save"
            (click)="updateBadge(BadgeForm)"
            [disabled]="
              !BadgeForm.form.valid || !badge.Venue.VenueId || isReqComplete || !canAccess('BADGE_EDIT', 'view')
            "
          />
        </div>
      </div>
    </form>
  </div>
</section>
