import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { WorkorderTaskModule } from './workordertask.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule],

  declarations: [],
})
export class WorkorderTaskCommentModule extends GenericEntity {
  public WorkorderTaskCommentId: number;
  // public workorderId: number;
  public Comment: string;
  public TaskDetails: WorkorderTaskModule[];
  public WorkorderInspections: WorkorderInspectionsModule[];
}

export class WorkorderInspectionsModule extends GenericEntity {
  // public workorderId: number;
  public InspectionTask: string;
}
