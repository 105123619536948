<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="Buildings">BUILDINGS</span>
        <span class="list-heading" *ngIf="!Buildings && !building.BuildingId">ADD BUILDING</span>
        <span class="list-heading" *ngIf="!Buildings && building.BuildingId">EDIT BUILDING</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="Buildings">
          <ngx-atlas-select
            (model)="BuildingPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="Buildings && !building.BuildingId && canAccess('BUILDING_ADD', 'view')"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Building
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="Buildings">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="Buildings">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="buildingsSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="Buildings">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('BuildingName', columnSortOrder)">
                  Building Name
                  <span *ngIf="selectedColumnName == 'BuildingName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BuildingName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('BuildingDisplayName', columnSortOrder)">
                  Building Display Name
                  <span *ngIf="selectedColumnName == 'BuildingDisplayName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BuildingDisplayName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                  Venue Name
                  <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>
            <tbody
              *ngFor="
                let building of Buildings
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getBuildingById(building.BuildingId)">
                <td>{{ building.BuildingName }}</td>
                <td>{{ building.BuildingDisplayName }}</td>
                <td>{{ building.VenueName }}</td>
                <td *ngIf="building.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!building.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!Buildings">
    <form #BuildingForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="BuildingName"><span id="red-circle">&#x25CF;</span> Building Name</label>
            <div>
              <input
                id="buildingName"
                name="BuildingName"
                #buildingName="ngModel"
                type="text"
                class="form-control"
                placeholder="Building Name"
                [(ngModel)]="building.BuildingName"
                required
                maxlength="49"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div
                *ngIf="buildingName.errors && (buildingName.dirty || buildingName.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!buildingName.errors.required" class="text-danger">Building name is required.</div>
                <!--<div [hidden]="!buildingName.errors.pattern" class="text-danger">
                                    Building name must start with alphabet, accepts only alphanumeric value & single space between words.
                                </div>-->
                <div [hidden]="!buildingName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="BuildingDisplayName" class=""><span id="red-circle">&#x25CF;</span> Display Name</label>
            <div>
              <input
                id="BuildingDisplayName"
                name="Buildingdisplayname"
                #buildingDisplayName="ngModel"
                type="text"
                class="form-control"
                placeholder="Display Name"
                [(ngModel)]="building.BuildingDisplayName"
                required
                maxlength="49"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div
                *ngIf="buildingDisplayName.errors && (buildingDisplayName.dirty || buildingDisplayName.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!buildingDisplayName.errors.required" class="text-danger">Display name is required.</div>
                <div [hidden]="!buildingDisplayName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-xs-12 form-group">
            <label for="venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div>
              <ngx-atlas-select
                (model)="building.Venue.VenueId = $event"
                [list]="venues"
                [idField]="'VenueId'"
                [textField]="'VenueName'"
                [placeholder]="'Select Venue'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="building.Venue.VenueId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group chkbox1" *ngIf="building.BuildingId">
              <mat-checkbox [(ngModel)]="building.IsActive" name="isActive">Is Active</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type1 pull-right btn-cancel"
            (click)="clearFormData(BuildingForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!building.BuildingId"
            class="btn btn-type btn-save"
            [disabled]="!BuildingForm.form.valid || !building.Venue.VenueId || isReqComplete"
            (click)="addBuilding(BuildingForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="building.BuildingId"
            class="btn btn-type btn-save"
            [disabled]="
              !BuildingForm.form.valid ||
              !building.Venue.VenueId ||
              isReqComplete ||
              !canAccess('BUILDING_EDIT', 'view')
            "
            (click)="updateBuilding(BuildingForm)"
          />
        </div>
      </div>
    </form>
  </div>
</section>
