import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';
import { GenericEntity } from '../../shared/genericentity/genericentity';

import { SensorComponent } from './components/sensor.component';
import { SensorRoutes } from './sensor.routes';

import { CoreModule } from 'src/app/core/core.module';
import { AssetLevelModule } from '../../assetmanagement/asset/asset.module';
import { UserModule } from '../../usermanagement/user/user.module';
import { VenueModule } from '../../venuemanagement/venue/venue.module';
import { SensorManufacturerModule } from '../sensormanufacturer/sensormanufacturer.module';
import { SensorTypeModule } from '../sensortype/sensortype.module';

@NgModule({
  imports: [CommonModule, CoreModule, SensorRoutes, FormsModule, NgxPaginationModule, HomeModule],

  declarations: [SensorComponent],
})
export class SensorModule extends GenericEntity {
  public SensorId: number;
  public SensorCPId: string;
  public SensorName: string;
  public SensorIdentifier: string;
  public SensorRule: string;
  public Threshold: number;
  public WarnPercentage: number;
  public SensorImageType: number;
  public SensorImageName: number;
  public SensorManufacturer: SensorManufacturerModule;
  public SensorType: SensorTypeModule;
  public Venue: VenueModule;
  public User: UserModule;
  public RelLocationSensor: LocationSensor;
  public LastUpdatedDate: any;
  public AirQualitySensorConfiguration: AirQualitySensorConfiguration;
  public SensorColor: string;
  public SensorStatusColor: string;

  public Longitude: any;
  public Latitude: any;
  public Altitude: any;

  public FilledDistanceInMillimeters: any;
  public EmptyDistanceInMillimeters: any;
  public InternalSensorId: string;
}

export class LocationSensor extends GenericEntity {
  public LocationSensorId: number;
  public Sensor: SensorLite;
  public Threshold: number;
  public WarnPercentage: number;
  public Latitude: any;
  public Longitude: any;
}

export class AirQualitySensorConfiguration extends GenericEntity {
  public AirQualitySensorId: number;
  public AirQualitySensorTempratureFId: string;
  public AirQualitySensorTempratureCId: string;
  public SensorAirQualityId: string;
  public AirQualitySensorHumidityId: string;
  public AirQualitySensorCO2Id: string;
  public AirQualitySensorCH2OId: string;
  public AirQualitySensorPressureId: string;
  public AirQualitySensorStatusId: string;
}

export class AirQualitySensorTransactionModule extends GenericEntity {
  public SensorTransactionId: number;
  public Sensor: SensorModule;
  public SensorStatus: string;
  public LocationId: number;
  public TransactionIdentifier: string;
  public Measurements: any;
  public AirQualitySensorTempratureF: any;
  public AirQualitySensorTempratureFTimestamp: any;
  public AirQualitySensorTempratureC: any;
  public AirQualitySensorTempratureCTimestamp: any;
  public SensorAirQuality: any;
  public SensorAirQualityTimestamp: any;
  public AirQualitySensorHumidity: any;
  public AirQualitySensorHumidityTimestamp: any;
  public AirQualitySensorCO2: any;
  public AirQualitySensorCO2Timestamp: any;
  public AirQualitySensorCH2O: any;
  public AirQualitySensorCH2OTimestamp: any;
  public AirQualitySensorPressure: any;
  public AirQualitySensorPressureTimestamp: any;
  public AirQualitySensorStatus: any;
  public AirQualitySensorStatusTimestamp: any;
  public StatusColor: string;
  public AirQualitySensorLght: any;
}

export class SensorTransactionModule extends GenericEntity {
  public SensorTransactionId: number;
  public CurrentCount: number;
  public TotalCount: number;
  public LastUpdated: any;
  public LastReset: any;
  public LastNotify: any;
  public LastWarnNotify: any;
  public LastMaxNotify: any;
  public LastCheckIn: any;
  public SensorStatus: string;
  public LocationId: number;
  public Sensor: SensorModule;
}

export class SensorLite extends GenericEntity {
  public SensorId: number;
  public SensorCPId: string;
  public SensorName: string;
  public SensorIdentifier: string;
  public InternalSensorId: string;
  public SensorRule: string;
  public Threshold: number;
  public WarnPercentage: number;
  public SensorImageType: string;
  public SensorImageName: string;
  public SensorStatus: string;
  public SensorColor: string;
  public SensorStatusSince: string;
  public CurrentCount: number;
  public IsOnline: any;
  public LastUpdatedDate: any;
  public SensorType: SensorTypeLite;
  public SensorStatusColor: string;

  public Longitude: any;
  public Latitude: any;
  public Altitude: any;
  public TotalCount: any;

  public SensorManufacturerId: number;
  public SensorManufacturerName: string;
  public SensorManufacturerCode: string;
  public SensorCount: number;
}

export class SensorTypeLite extends GenericEntity {
  public SensorTypeId: number;
  public SensorTypeCPId: string;
  public SensorTypeName: string;
  public SensorTypeCode: string;
  public SensorTypeImageType: string;
  public SensorTypeImageName: string;
}

export class SensorLiteMap extends GenericEntity {
  public SensorId: number;
  public SensorCPId: string;
  public SensorName: string;
  public SensorIdentifier: string;
  public SensorRule: string;
  public Threshold: number;
  public WarnPercentage: number;
  public SensorImageType: string;
  public SensorImageName: string;
  public SensorStatus: string;
  public SensorColor: string;
  public SensorStatusSince: string;
  public CurrentCount: number;
  public IsOnline: any;
  public LastUpdatedDate: any;
  public SensorType: SensorTypeLiteMap;
  public SensorStatusColor: string;

  public Longitude: any;
  public Latitude: any;
  public Altitude: any;
  public TotalCount: number;
  public AssetVisible: boolean;

  public SensorManufacturerId: number;
  public SensorManufacturerName: string;
  public SensorManufacturerCode: string;
  public AssetLevel: AssetLevelModule;
}

export class SensorTypeLiteMap extends GenericEntity {
  public SensorTypeId: number;
  public SensorTypeCPId: string;
  public SensorTypeName: string;
  public SensorTypeCode: string;
  public SensorTypeImageType: string;
  public SensorTypeImageName: string;

  public EmptyOrFullCountGreen: any;
  public EmptyOrFullCountRed: any;

  public FillingPercentage: any;
  public SensorColor: string;
  public SensorManufacturerCode: any;
  public SensorStatusCount: any;
}
