import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { CorporationModule, CorporationIntegrationKey } from '../corporation.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { Module } from '../../../serviceprovider/serviceprovider.module';

@Injectable({
  providedIn: 'root',
})
export class CorporationService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (body: CorporationModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'corporation/corporation';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: CorporationModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'corporation/corporation';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetCorporationList = (body: string, page: number, size: number): Observable<CorporationModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/all/corporations/' + page + '/' + size;
    return this.http.post<CorporationModule[]>(_url, body);
  };

  public GetCorporationListForDropdown = (
    body: string,
    page: number,
    size: number,
  ): Observable<CorporationModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/corporations/' + page + '/' + size;
    return this.http.post<CorporationModule[]>(_url, body);
  };

  public GetSingle = (body: CorporationModule): Observable<CorporationModule> => {
    const _url: string = this.configuration.Server + 'corporation/details';
    return this.http.post<CorporationModule>(_url, body);
  };

  public GetCorporationListinHeader = (): Observable<CorporationModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/user/corporations';
    return this.http.get<CorporationModule[]>(_url);
  };

  public GetVenueByCorporation = (body: CorporationModule): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/venues';
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetModueleList = (body: string, page: number, size: number): Observable<Module[]> => {
    const _url: string = this.configuration.Server + 'corporation/modules/' + page + '/' + size;
    return this.http.post<Module[]>(_url, body);
  };

  AddCoporationLogo(filedata: File, corporationId: number): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'corporation/logo/corporation/' + corporationId;
      xhr.open('PUT', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }

  public RemoveCoporationLogo = (corporationId: number): Observable<ResponseMessage> => {
    const toUpdate = JSON.stringify({});
    const _url: string = this.configuration.Server + 'corporation/remove/logo/corporation/' + corporationId;
    return this.http.put<ResponseMessage>(_url, toUpdate);
  };

  public GetCorporationIntegrationKey = (): Observable<CorporationIntegrationKey> => {
    const _url: string = this.configuration.Server + 'venue/generate/integration/key';
    return this.http.get<CorporationIntegrationKey>(_url);
  };
}
