<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="areas">AREAS</span>
        <span class="list-heading" *ngIf="!areas && !area.AreaId">ADD AREA</span>
        <span class="list-heading" *ngIf="!areas && area.AreaId">EDIT AREA</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="areas">
          <ngx-atlas-select
            (model)="areaPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="areas && !area.AreaId && canAccess('AREA_ADD', 'view')"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Areas
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="areas">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="areas">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>

              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="areaSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="areas">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>

        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('AreaName', columnSortOrder)">
                  Area Name
                  <span *ngIf="selectedColumnName == 'AreaName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'AreaName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                  Venue Name
                  <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let area of areas
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getAreaById(area.AreaId)">
                <td>{{ area.AreaName }}</td>
                <td>{{ area.VenueName }}</td>
                <td *ngIf="area.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!area.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!areas">
    <form #AreaForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="areaname"><span id="red-circle">&#x25CF;</span> Area Name</label>
            <div>
              <input
                id="areaName"
                type="text"
                class="form-control"
                name="areaname"
                placeholder="Area Name"
                [(ngModel)]="area.AreaName"
                required
                #areaname="ngModel"
                maxlength="250"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div *ngIf="areaname.errors && (areaname.dirty || areaname.touched)" class="alert alert-danger">
                <div [hidden]="!areaname.errors.required" class="text-danger">Area name is required.</div>
                <div [hidden]="!areaname.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 form-group">
            <label for="sequence"> Sequence</label>
            <div>
              <input
                type="number"
                class="form-control"
                placeholder="Sequence"
                name="Sequence"
                [(ngModel)]="area.Sequence"
                #Sequence="ngModel"
                pattern="[0-9]+"
                min="1"
                autocomplete="off"
                (keypress)="keyPress($event)"
              />
              <div *ngIf="Sequence.errors && (Sequence.dirty || Sequence.touched)" class="alert alert-danger">
                <div [hidden]="!Sequence.errors.pattern" class="text-danger">Sequence accept only numeric value.</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div>
              <ngx-atlas-select
                (model)="area.VenueId = $event"
                [list]="venues"
                [idField]="'VenueId'"
                [textField]="'VenueName'"
                [placeholder]="'Select Venue'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="area.VenueId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6">
            <div class="form-group" *ngIf="area.AreaId" style="margin-top: 25px !important">
              <div>
                <div>
                  <mat-checkbox [(ngModel)]="area.IsActive" name="isActive">Is Active</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type1 pull-right btn-cancel"
            (click)="clearFormData(AreaForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!area.AreaId"
            class="btn btn-type btn-save"
            [disabled]="!AreaForm.form.valid || !area.VenueId || isReqComplete"
            (click)="addArea(AreaForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="area.AreaId"
            class="btn btn-type btn-save"
            [disabled]="!AreaForm.form.valid || !area.VenueId || isReqComplete || !canAccess('AREA_EDIT', 'view')"
            (click)="updateArea(AreaForm)"
          />
        </div>
      </div>
    </form>
  </div>
</section>
