import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthState } from 'src/app/store/auth.state';
import { Configuration } from '../../../app.constants';
import { CalModule, RecurringPattern, ScheduleTypeModule, ScheduleUser } from '../../../calendar/calendar.module';
import { CalendarService } from '../../../calendar/component/calendar.http.service';
import { CorporationService } from '../../../corporationmanagement/corporation/components/corporation.http.service';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { LocationModule } from '../../../staffscheduling/location/location.module';
import { User } from '../../../usermanagement/user/user.model';
import { BuildingModule } from '../../../venuemanagement/building/building.module';
import { VenueService } from '../../../venuemanagement/venue/components/venue.http.service';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { AssetModule } from '../../workorder/asset.module';
import { WorkorderService } from '../../workorder/components/workorder.http.service';
import { MaintenanceWorkorderModule } from '../../workorder/maintenanceworkorder.module';
import { WorkorderAndQCLogAndHistoryDetails, WorkorderModule } from '../../workorder/workorder.module';
import {
  QCInventoryRefill,
  QCMaster,
  QCTaskComment,
  QCTaskFeedback,
  QCTransaction,
  WorkorderInspection,
  WorkorderTaskComment,
  WorkorderTransaction,
} from '../qcworkorder.module';
import { QCWorkOrderService } from './qcworkorder.http.service';

@Component({
  selector: 'qcworkorder-component',
  templateUrl: 'qcworkorder.component.html',
  styleUrls: ['./qcworkorder.scss'],
  providers: [
    QCWorkOrderService,
    WorkorderService,
    UtilitiesService,
    CalendarService,
    CorporationService,
    VenueService,
    DatePipe,
  ],
})
export class QCWorkOrderComponent implements OnInit {
  @ViewChild('scheduleDialog', { static: true }) scheduleOpenDialog: TemplateRef<any>;
  public confirmInspectionScreen = false;
  public scheduleDialogTitle: string;
  public calendar: CalModule = new CalModule();
  public IsAdHoc: number;
  public selectedTabIndex = 0;
  public rangeOfRecurrenceRadioControl: any;
  public isrbDailyDays = 0;
  public scheduleForm: FormGroup;
  public IsVisible = false;
  public WeekOfMonthMonthly = 0;
  public IsEntireSeriesModifiedControl: any;
  public activeDayIsOpen = false;
  public IsRecurringDisabled = false;
  public IsEntireSeries = 0;

  public StartTimeDisabled = false;
  public EndTimeDisabled = false;

  // Daily
  public SeperationCountDaily = 1;

  // Monthly
  public SeperationCountMonthly1 = 1;
  public SeperationCountMonthly2 = 1;
  public DayOfMonthMonthly1 = 1;
  public isrbMonthlyDays1 = 0;
  public DayOfMonthMonthly2 = 0;

  // Yearly
  public SeperationCountYearly = 1;
  public DayOfMonthYearly = 1;
  public isrbYearlyMonths = 0;
  public MonthOfYearYearly = 0;
  public YearlyWeekOfMonth = 0;
  public YearlyDayOfMonth = 0;
  public YearlyMonthOfYear = 0;
  public isrbYearlyDayOfMonth = 0;

  public IsrbRecurrenceNoEndDateCheked = true;
  public IsrbRecurrenceEndAfterChecked = false;
  public IsrbRecurrenceEndbyChecked = false;
  public IsrbDailyDaysChecked = true;
  public rbDailyEveryWeekdays = false;
  public rbMonthlyDays = true;
  public rbMonthlyMonths = false;
  public rbYearlyMonths = true;
  public rbYearlyMonthWeekDay = false;

  public IsrbJustThisOneChecked = true;
  public IsrbTheEntireSeriesChecked = false;
  public FullDayStartDate: any;
  public FullDayEndDate: any;
  public FullDayStartTime: any;
  public FullDayEndTime: any;
  public IsRecurringVisible = true;
  public IsFullDayVisible = true;
  public IsFullDayDatePanalVisible = true;
  public IsrbJustThisOneVisible = true;
  public isrbMonthlyDays2 = 0;

  // for first and last  date
  meridian = true;
  TodayDate = new Date();
  // year = this.TodayDate.getFullYear();
  // month = this.TodayDate.getMonth();
  // firstDay: any = new Date(this.year, this.month, 1);
  // lastDay: any = new Date(this.year, this.month + 1, 0);

  public refresh: Subject<any> = new Subject();
  public eventIsRecurring = false;
  public eventTypeDisabled = true;
  public corporationDisabled = true;
  public venueDisabled = true;
  public isCASchedulingShow = false;
  public isCA_ASSIGNMENT = false;

  public currentStartTime = '';
  public currentEndTime = '';
  public venueServerTime: any;
  public venueTimeZoneText: any;

  public locations: LocationModule[] = [];
  public locationDisabled = true;

  public supervisors: User[] = [];
  public workers: User[] = [];
  public inspectors: User[] = [];
  public ScheduleTypes: ScheduleTypeModule[] = [];
  public corporations: CorporationModule[] = [];
  public Venues: VenueModule[] = [];

  @ViewChild('fileInput') fileInputVariable: any;
  currentUser: User;

  public workorders: WorkorderModule[] = [];
  public workorder: WorkorderModule = new WorkorderModule();
  public qcWorkorder: WorkorderTaskComment = new WorkorderTaskComment();
  public qcMaster: QCMaster = new QCMaster();
  public QCMasters: QCMaster[] = [];

  public workorderInspections: WorkorderInspection[] = [];
  public workorderInspection: WorkorderInspection = new WorkorderInspection();

  public workorderTransaction: WorkorderTransaction = new WorkorderTransaction();
  public inspectionTasks: WorkorderTaskComment = new WorkorderTaskComment();

  public qcTransaction: QCTransaction = new QCTransaction();

  page = 1;
  size = 10;
  count = 0;
  searchString = '';

  isNewState = true;
  formList = true;
  Isdataloaded = false;
  isReqComplete = false;
  isReadOnlyField = false;

  isTasks = false;
  isemptystep = false;
  rowid: number;
  index = -1;
  checkIsActive: any;
  pageSizes: any[] = [];

  multiFiles: any[] = [];
  file: File = null;
  qcImgSrc: any = null;

  isHistoryShow = false;
  public workorderHistoryDetails: WorkorderAndQCLogAndHistoryDetails[] = [];
  public maintenanceWOHistoryDetails: MaintenanceWorkorderModule[] = [];
  qcAttachmentImg = '';

  // isMaintenanceSupervisor = false;
  // isSupervisor = false;

  feedbackDefaultImage = 'assets/no-image.jpg';
  baseUrl = this.configuration.Server + 'TaskIcons/';
  woAttachmentURL = this.configuration.Server_Closure_Workorder_Attachment;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;

  qcAssignedModal = false;
  cleaningAlertHistoryModal = false;
  woMaintananceHistoryModal = false;
  QCRejectconfirmationModal = false;
  confirmationModal = false;
  cleaningAlertAcceptQCModal = false;
  cleaningAlertRejectQCModal = false;
  public pageState = [{ pageName: 'qcWorkorderForm', page: undefined, size: undefined }];

  public retriveModules: any[] = [];

  public isQC_FeedbackShow = false;
  public isQC_InventoryShow = false;
  public isQC_CommentShow = false;
  public isQC_PictureShow = false;
  public isQC_VideoShow = false;
  public isQC_CreationShow = false;
  public isQC_OverallRationShow = false;
  public isQC_RatingShow = false;
  public isQC_HistoryShow = false;

  activeNavigationUrls: any;
  activeNavigationName: string;

  routeTitle: string;
  pageTitle: string;

  constructor(
    private qcWOrkorderService: QCWorkOrderService,
    private workorderService: WorkorderService,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    private configuration: Configuration,
    private _route: ActivatedRoute,
    private datePipe: DatePipe,
    private calendarservice: CalendarService,
    private scheduleDialog: MatDialog,
    private fb: FormBuilder,
    private corporationService: CorporationService,
    private venueService: VenueService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    // this.isMaintenanceSupervisor = true;
    // this.isSupervisor = false;

    this.qcMaster = new QCMaster();
    this.qcMaster.QCTransaction = new QCTransaction();
    this.qcMaster.QCTaskComment = new QCTaskComment();

    this.workorder = new WorkorderModule();

    this.scheduleForm = this.fb.group({
      VenueControl: ['', [Validators.required]],
      titleControl: ['', [Validators.required]],
      descriptionControl: ['', []],
      supervisorControl: ['', []],
      workerControl: ['', []],
      isRecurringControl: ['', []],
      durationControl: ['', []],
      StartTime: ['', [Validators.required]],
      EndTime: ['', []],
      dailyRadioControl: ['', []],
      dayControl: ['', []],
      everyWeekRadioControl: ['', []],

      weekControl: ['', []],
      sundaycheckboxControl: ['', []],
      mondaycheckboxControl: ['', []],
      thuesdaycheckboxControl: ['', []],
      wednesdaycheckboxControl: ['', []],
      thursdaycheckboxControl: ['', []],
      fridaycheckboxControl: ['', []],
      saturdaycheckboxControl: ['', []],

      dayRadioControl: ['', []],
      everyDayControl: ['', []],
      everyMonthControl: ['', []],
      WeekDaysControl: ['', []],
      monthlyDaysControl: ['', []],
      everyMonthsControl: ['', []],

      yearsControl: ['', []],
      monthsSelectControl: ['', []],
      monthsDayControl: ['', []],
      yearlyWeekRadioControl: ['', []],
      weekofControl: ['', []],
      WeeksSelectControl: ['', []],
      weeksOfMonthsControl: ['', []],

      picker1: ['', []],
      picker2: ['', []],
      occurencesControl: ['', []],
      rangeOfRecurrenceRadioControl: ['', []],

      isFullDayEventControler: ['', []],
      IsEntireSeriesModifiedControl: ['', []],
      timeZoneControl: ['', []],
    });
  }

  ngOnInit() {
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
    this.getModulesData();

    this.routeTitle = this._route.root.firstChild.snapshot.url.toString().toLocaleLowerCase();
    this.activeNavigationUrls = document.getElementsByClassName('treeview');
    for (let i = 0; i < this.activeNavigationUrls.length; i++) {
      this.activeNavigationName = this.utilitiesService.removeInnerSpaces(this.activeNavigationUrls[i].textContent);

      if (this.activeNavigationName.toLowerCase() != 'qc - cleaning alerts' && this.routeTitle == 'qc-cleaning-alert') {
        this.activeNavigationUrls[i].classList.remove('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'none';
        this.activeNavigationUrls[i].classList.remove('active');
      }

      if (this.activeNavigationName.toLowerCase() != 'qc - work orders' && this.routeTitle == 'qc-workorder') {
        this.activeNavigationUrls[i].classList.remove('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'none';
        this.activeNavigationUrls[i].classList.remove('active');
      }

      if (this.activeNavigationName.toLowerCase() == 'qc - work orders' && this.routeTitle == 'qc-workorder') {
        this.activeNavigationUrls[i].classList.add('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'block';
        this.activeNavigationUrls[i].classList.add('active');
        this.pageTitle = 'QUALITY CHECK - WORK ORDERS';
      }

      // if (this.activeNavigationName.toLowerCase() == 'qc - cleaning alerts' && this.routeTitle == 'qc-cleaning-alert') {
      //     this.activeNavigationUrls[i].classList.add('menu-open');
      //     this.activeNavigationUrls[i].lastElementChild.style.display = 'block';
      //     this.activeNavigationUrls[i].classList.add('active');
      //     this.pageTitle = 'QUALITY CHECK - CLEANING ALERTS';
      // }
    }
  }

  public canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      // if (this.isMaintenanceSupervisor) {
      this.getAllMaintenanceWorkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
      // } else if (this.isSupervisor) {
      //     // this.getAllCustodialWorkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
      //     this.getAllQCWOrkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
      // }
    } else {
      // if (this.isMaintenanceSupervisor) {
      this.getAllMaintenanceWorkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
      // } else if (this.isSupervisor) {
      //     // this.getAllCustodialWorkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
      //     this.getAllQCWOrkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
      // }
    }
  }

  /// this funcation used to get result as per page size change
  public QCWorkOrderPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.authState.storePageState(this.pageState);
    // if (this.isMaintenanceSupervisor) {
    this.getAllMaintenanceWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
    // } else if (this.isSupervisor) {
    //     // this.getAllCustodialWorkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
    //     this.getAllQCWOrkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
    // }
  }

  public setAddForm() {
    this.isNewState = true;
    this.formList = false;
    this.searchString = '';
    this.workorderInspections = [];
  }

  public setUpdateForm() {
    this.isNewState = false;
    this.formList = false;
    this.searchString = '';
  }

  public clearFormData() {
    this.formList = true;
    this.isNewState = true;

    this.isHistoryShow = false;
    this.workorderHistoryDetails = [];
    this.maintenanceWOHistoryDetails = [];

    this.searchString = '';
    this.multiFiles = [];

    // if (this.isMaintenanceSupervisor) {
    this.getAllMaintenanceWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
    // } else if (this.isSupervisor) {
    //     // this.getAllCustodialWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
    //     this.getAllQCWOrkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
    // }
    this.workorder = new WorkorderModule();
    this.qcMaster = new QCMaster();
    this.workorderInspections = [];
  }

  public fileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      const fileCount =
        (this.qcWorkorder.WorkorderQCImages !== null ? this.qcWorkorder.WorkorderQCImages.length : 0) +
        (this.multiFiles.length !== null ? this.multiFiles.length : 0) +
        event.target.files.length;
      if (fileCount > 5) {
        this.fileInputVariable.nativeElement.value = '';
        this.file = null;
        this.utilitiesService.smmodal('File Upload', 'You can only upload upto 5 files.');
        return;
      } else {
        for (let i = 0; i < event.target.files.length; i++) {
          const filename = event.target.files[i].name;
          let name = filename.split('');
          for (let n = 0; n < name.length; n++) {
            if (
              name[n] == '<' ||
              name[n] == '>' ||
              name[n] == '%' ||
              name[n] == ':' ||
              name[n] == '?' ||
              name[n] == '/' ||
              name[n] == '*' ||
              name[n] == '"' ||
              name[n] == '|' ||
              name[n] == '#' ||
              name[n] == '{' ||
              name[n] == '}' ||
              name[n] == '&' ||
              name[n] == '='
            ) {
              this.utilitiesService.smmodal('File Upload', this.utilitiesService.fileUploadErrorMessage);
              event.target.value = '';
              return;
            }
          }
          this.multiFiles.push(event.target.files[i]);
        }
      }
    }
  }

  _handleReaderLoaded(_readerEvt: any) {}

  fileReset() {
    this.file = null;
    this.qcImgSrc = null;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.authState.storePageState(this.pageState);
    // if (this.isMaintenanceSupervisor) {
    this.getAllMaintenanceWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
    // } else if (this.isSupervisor) {
    //     // this.getAllCustodialWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
    //     this.getAllQCWOrkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
    // }
  }

  public hasData(): boolean {
    // if (this.isSupervisor) {
    return this.workorders != null && this.workorders.length > 0;
    // } else {
    //     return (this.workers != null && this.workers.length > 0);
    // }
  }

  // maintainance qc code below - (Maintenance Supervisor) ------------------------------------------------

  public getAllMaintenanceWorkorders(searchTerm: string, defaultVenueId: number, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    // const searchfilter = {
    //    'SearchQuery': searchTerm ? searchTerm.trim() : '',
    //    'GenericSearch': 1,
    //    'SortColumn': this.columnName ? this.columnName : null,
    //    'SortOrder': this.sortOrder ? this.sortOrder : null
    // };
    const CPMaintenanceWO = new VenueModule();
    CPMaintenanceWO.VenueCPId = this.authState.AESEncryptText(defaultVenueId);
    CPMaintenanceWO.SearchQuery = searchTerm ? searchTerm.trim() : '';
    CPMaintenanceWO.GenericSearch = 0;
    CPMaintenanceWO.SortColumn = this.columnName ? this.columnName : null;
    CPMaintenanceWO.SortOrder = this.sortOrder ? this.sortOrder : null;
    this.qcWOrkorderService.GetAllMaintenanceWorkorders(CPMaintenanceWO, pageIndex, pageSize).subscribe(
      (data) => {
        this.workorders = data;
        if (this.workorders.length < 0 && this.workorders[0].Count / 10 < this.page) {
          this.page = 1;
        }
        this.Isdataloaded = true;
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'qcWOrkorderService GetAllMaintenanceWorkorders Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.workorders[0] ? this.workorders[0].Count : 0),
    );
  }

  public getMaintenanceWorkorderQCById(workorderId: number) {
    if (
      this.canAccess('QC_WORK_ORDER_VIEW_MY_RECORDS', 'view') ||
      this.canAccess('QC_WORK_ORDER_VIEW_ALL_RECORDS', 'view')
    ) {
      this.loaderService.display(true);
      const CPworkorder = new WorkorderModule();
      CPworkorder.WorkorderCPId = this.authState.AESEncryptText(workorderId);

      this.workorderService.GetSingleMaintenanceWO(CPworkorder).subscribe(
        (data) => {
          this.isReadOnlyField = true;
          this.workorder = data;

          if (this.workorder) {
            if (this.workorder.ParentWorkorderId !== null) {
              this.isHistoryShow = true;
            }
            this.getMaintenanceTaskByWOId(this.workorder.AssetId, this.workorder.WorkorderId);
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'qcWOrkorderService GetMaintenanceWorkorderQCById Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Quality Check', 'You do not have permission to view quality check details.');
    }
  }

  public getMaintenanceTaskByWOId(assetId: number, workorderId: number) {
    const temperaryAssetId = assetId ? assetId : 0;
    const CPworkorder = new WorkorderModule();
    CPworkorder.Asset = new AssetModule();
    CPworkorder.WorkorderCPId = this.authState.AESEncryptText(workorderId);
    CPworkorder.Asset.AssetCPId = this.authState.AESEncryptText(temperaryAssetId);

    this.qcWOrkorderService.GetMaintenanceTaskByWOId(CPworkorder).subscribe(
      (data) => {
        this.qcWorkorder = data;
        if (
          this.qcWorkorder &&
          this.qcWorkorder.WorkorderInspections &&
          this.qcWorkorder.WorkorderInspections.length > 0
        ) {
          this.workorderInspections = this.qcWorkorder.WorkorderInspections;
        }
        // console.log(this.qcWorkorder);
      },
      (error) => {
        console.log(
          'qcWOrkorderService GetMaintenanceTaskByWOId Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
      },
    );
  }

  public hasworkorderQCImages(): boolean {
    return this.qcWorkorder.WorkorderQCImages !== null && this.qcWorkorder.WorkorderQCImages.length > 0;
  }

  public addTask() {
    // if (this.workorderInspections && this.workorderInspections.length > 0) {
    //     for (let i = 0; i < this.workorderInspections.length; i++) {
    //         if (this.workorderInspections[i].inspectionTask == '' ||
    //             this.workorderInspections[i].inspectionTask.trim().length <= 0 ||
    //             this.workorderInspections[i].qcInput == '' ||
    //             this.workorderInspections[i].qcInput.trim().length <= 0) {
    //             this.utilitiesService.smmodal('Quality Control Check', 'Task row should not be blank.');
    //             return;
    //         }
    //     }
    // }

    this.isTasks = true;
    this.rowid = this.workorderInspections.length;
    this.rowid = -(this.rowid + 1);
    const newInspection = new WorkorderInspection();
    newInspection.InspectionTask = '';
    newInspection.QcInput = '';

    newInspection.Sequence = this.rowid;
    newInspection.WorkorderId = this.workorder.WorkorderId;
    // newInspection.workorderInspectionId = this.workorder.workorderInspections[0].workorderInspectionId;
    // newInspection.IsActive = 1;
    this.workorderInspections.push(newInspection);
  }

  public removeTasks(rowid: number) {
    this.isTasks = true;
    for (let i = 0; i < this.workorderInspections.length; i++) {
      if (this.workorderInspections[i].Sequence == rowid) {
        this.workorderInspections[i].IsActive = 0;
        this.index = i;
        if (this.workorderInspections[i].WorkorderInspectionId == null) {
          this.workorderInspections.splice(this.index, 1);
          i = i - 1;
        }
      }
    }
  }

  // Accept/Reject maintenance workorder
  public maintenanceAcceptRejectQC(status: any) {
    this.isReqComplete = true;
    this.loaderService.display(true);
    this.isemptystep = false;

    for (let i = 0; i < this.workorderInspections.length; i++) {
      if (
        this.workorderInspections[i].InspectionTask == null ||
        this.workorderInspections[i].InspectionTask.trim().length <= 0
      ) {
        this.utilitiesService.smmodal(
          'Quality Control Check',
          'Task from ' + this.workorderInspections[i].Sequence + ' row should not be blank.',
        );
        this.isemptystep = true;
        this.isReqComplete = false;
        this.loaderService.display(false);
        return;
      }
      this.workorderInspections[i].WorkorderId = this.workorder.WorkorderId;
      this.workorderInspection.WorkorderInspections = this.workorderInspections;
    }

    if (this.isemptystep) {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    this.workorderInspection.WorkorderTaskComment.QcComment = this.qcWorkorder.QcComment;
    this.workorderInspection.WorkorderId = this.workorder.WorkorderId;

    this.workorderInspection.WorkorderTransaction.StatusId = status;
    this.workorderInspection.WorkorderTransaction.Comments = this.qcWorkorder.QcComment;
    this.workorderInspection.WorkorderTransaction.WorkorderId = this.workorder.WorkorderId;

    if (status == 'yes') {
      this.workorderInspection.WorkorderTransaction.StatusId = 5;
      this.workorderInspection.WorkorderTransaction.GenerateNewWorkOrder = 1;
    } else if (status == 'no') {
      this.workorderInspection.WorkorderTransaction.StatusId = 5;
      this.workorderInspection.WorkorderTransaction.GenerateNewWorkOrder = 0;
    }
    // console.log(this.workorderInspection);

    this.qcWOrkorderService.saveQCInput(this.utilitiesService.stripScript(this.workorderInspection)).subscribe(
      (data) => {
        if (data !== null) {
          if (this.multiFiles) {
            this.qcWOrkorderService.QCAttachmentFile(
              this.multiFiles,
              this.currentUser.UserId,
              this.workorder.WorkorderId,
              // data.msg
            );
          }
          // qcWorkOrderForm.reset();
          this.clearFormData();
          this.confirmationModal = false;
          this.QCRejectconfirmationModal = false;
          this.utilitiesService.smmodal('Quality Control Check', data.Message);
          this.isReqComplete = false;
          this.loaderService.display(false);
        }
      },
      (error) => {
        console.log(
          'qcWOrkorderService saveQCInput Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.isReqComplete = false;
        this.loaderService.display(false);
      },
    );
  }

  // cleaning alert qc code below - (Custodial Supervisor) ------------------------------------------------

  public getAllQCWOrkorders(searchTerm: string, defaultVenueId: number, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    // const searchfilter = {
    //    'SearchQuery': searchTerm ? searchTerm.trim() : '', 'GenericSearch': 1,
    //    'SortColumn': this.columnName ? this.columnName : null,
    //    'SortOrder': this.sortOrder ? this.sortOrder : null,
    //    //'QCid:': '',
    //    //'WorkorderId': this.qcWorkorder.workorderId,
    // };
    const CPWorkorder = new WorkorderModule();
    CPWorkorder.Building = new BuildingModule();
    CPWorkorder.Building.Venue = new VenueModule();
    CPWorkorder.Building.Venue.VenueCPId = this.authState.AESEncryptText(defaultVenueId);
    CPWorkorder.SearchQuery = searchTerm ? searchTerm.trim() : '';
    CPWorkorder.GenericSearch = 0;
    CPWorkorder.SortColumn = this.columnName ? this.columnName : null;
    CPWorkorder.SortOrder = this.sortOrder ? this.sortOrder : null;

    this.qcWOrkorderService.GetAllQCWOrkorders(CPWorkorder, pageIndex, pageSize).subscribe(
      (data) => {
        this.QCMasters = data;

        if (this.QCMasters.length < 0 && this.QCMasters[0].Count / 10 < this.page) {
          this.page = 1;
        }
        this.Isdataloaded = true;
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'qcWOrkorderService GetAllCustodialWorkorders Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.QCMasters[0] ? this.QCMasters[0].Count : 0),
    );
  }

  // Get details of custodial workorder
  public openPopupCustodialWOQCById(workorderId: number) {
    this.loaderService.display(true);
    const CPQcMaster = new QCMaster();
    CPQcMaster.QcCPId = this.authState.AESEncryptText(workorderId);
    this.qcWOrkorderService.GetQCWorkorderById(CPQcMaster).subscribe(
      (data) => {
        // this.workorder = data;
        this.qcMaster = data;
        this.loaderService.display(false);
        if (this.isQC_CreationShow) {
          this.qcAssignedModal = true;
        }
      },
      (error) => {
        console.log(
          'qcWOrkorderService GetQCWorkorderById Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public checkStatusRecord(QcId: number, status: string) {
    if (status == 'QC Assigned') {
      this.openPopupCustodialWOQCById(QcId);
    } else if (status == 'QC In Progress') {
      this.getCustodialWOQCById(QcId);
    }
  }

  // Get details of custodial workorder
  public getCustodialWOQCById(workorderId: number) {
    this.getQCTaskByWOId(workorderId);
    this.loaderService.display(true);
    const CPQcMaster = new QCMaster();
    CPQcMaster.QcCPId = this.authState.AESEncryptText(workorderId);
    this.qcWOrkorderService.GetQCWorkorderById(CPQcMaster).subscribe(
      (data) => {
        this.isReadOnlyField = true;
        // this.workorder = data;
        this.qcMaster = data;

        if (this.qcMaster.ParentWorkorderId != null) {
          this.isHistoryShow = true;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'qcWOrkorderService GetQCWorkorderById Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
      () => this.setUpdateForm(),
    );
  }

  public getQCTaskByWOId(workorderId: number) {
    this.loaderService.display(true);
    const CPworkorder = new WorkorderModule();
    CPworkorder.WorkorderCPId = this.authState.AESEncryptText(workorderId);
    this.qcWOrkorderService.GetQCTaskWOById(CPworkorder).subscribe(
      (data) => {
        this.qcWorkorder = data;

        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'qcWOrkorderService GetQCWorkorderById Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public closeQCAssignedModal() {
    this.qcAssignedModal = false;
  }

  // Self assigned a custodial workorder
  public acceptRejectCleaningQC(statusId: number, workorderId: number, qcId: number) {
    this.loaderService.display(true);

    this.qcTransaction.WorkorderId = workorderId;
    this.qcTransaction.StatusId = statusId;
    this.qcTransaction.QcId = qcId;
    // this.qcTransaction.Comments = comment;

    // this.workorderTransaction.workorderId = workorderId;
    // this.workorderTransaction.StatusId = statusId;

    this.qcWOrkorderService.actionOnCleaningWOQC(this.qcTransaction).subscribe(
      (_data) => {
        // this.getAllQCWOrkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
        // console.log(_data);
        // this.qcWorkorder = _data;
        // if (this.isMaintenanceSupervisor) {
        //    this.getAllMaintenanceWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
        // } else if (this.isSupervisor) {
        //    //this.getAllCustodialWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
        //     this.getAllQCWOrkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
        // }

        this.closeQCAssignedModal();
        this.clearAcceptRejectQCData();
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'qcWOrkorderService actionOnCleaningWOQC Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public clearAcceptRejectQCData() {
    this.workorder = new WorkorderModule();
  }

  // Accept/Reject custodial workorder
  public cleaningAlertAcceptRejectQC(status: any, isFromSchedule: boolean) {
    this.confirmInspectionScreen = false;
    this.loaderService.display(true);
    this.isReqComplete = true;
    this.isemptystep = false;
    const _inspectionTask: QCMaster = new QCMaster();

    // Tasks
    _inspectionTask.QCTaskFeedbacks = [];
    if (this.qcMaster.QCTaskFeedbacks !== null && this.qcMaster.QCTaskFeedbacks.length > 0) {
      for (let i = 0; i < this.qcMaster.QCTaskFeedbacks.length; i++) {
        const _taskDetail: QCTaskFeedback = new QCTaskFeedback();
        _taskDetail.QcId = this.qcMaster.QcId;
        _taskDetail.QcInput = this.qcMaster.QCTaskFeedbacks[i].QcInput;
        _taskDetail.QcTaskId = this.qcMaster.QCTaskFeedbacks[i].QcTaskId;
        _taskDetail.QcTaskCommentId = this.qcMaster.QCTaskFeedbacks[i].QcTaskCommentId;
        _taskDetail.TaskId = this.qcMaster.QCTaskFeedbacks[i].TaskId;
        _taskDetail.IsActive = this.qcMaster.QCTaskFeedbacks[i].IsActive;

        _inspectionTask.QCTaskFeedbacks.push(_taskDetail);
      }
    }

    // Inventories
    _inspectionTask.QCInventoryRefills = [];
    if (this.qcMaster.QCInventoryRefills !== null && this.qcMaster.QCInventoryRefills.length > 0) {
      for (let i = 0; i < this.qcMaster.QCInventoryRefills.length; i++) {
        const _inventoryDetail: QCInventoryRefill = new QCInventoryRefill();
        _inventoryDetail.QcId = this.qcMaster.QcId;
        _inventoryDetail.InventoryId = this.qcMaster.QCInventoryRefills[i].InventoryId;
        _inventoryDetail.QcInventoryRefillId = this.qcMaster.QCInventoryRefills[i].QcInventoryRefillId;
        _inventoryDetail.Quantity = this.qcMaster.QCInventoryRefills[i].Quantity;
        _inventoryDetail.IsActive = this.qcMaster.QCInventoryRefills[i].IsActive;

        _inspectionTask.QCInventoryRefills.push(_inventoryDetail);
      }
    }

    _inspectionTask.PreQCFeedback = this.qcMaster.PreQCFeedback;

    _inspectionTask.QCTaskComment = new QCTaskComment();
    _inspectionTask.QCTaskComment.QcId = this.qcMaster.QcId;
    _inspectionTask.QCTaskComment.QcTaskCommentId = this.qcMaster.QCTaskComment.QcTaskCommentId;
    _inspectionTask.QCTaskComment.QcComment = this.qcMaster.QCTaskComment.QcComment;

    _inspectionTask.QcId = this.qcMaster.QcId;
    _inspectionTask.QCTransaction = new QCTransaction();
    _inspectionTask.QCTransaction.StatusId = status;
    _inspectionTask.QCTransaction.QcId = this.qcMaster.QcId;
    _inspectionTask.QCTransaction.WorkorderId = this.qcMaster.WorkorderId;

    if (status == 'yes') {
      _inspectionTask.QCTransaction.StatusId = 5;
      _inspectionTask.QCTransaction.GenerateNewWorkorder = 1;
    } else if (status == 'no') {
      _inspectionTask.QCTransaction.StatusId = 5;
      _inspectionTask.QCTransaction.GenerateNewWorkorder = 0;
    }
    // console.log(_inspectionTask);

    this.qcWOrkorderService.CleaningAlertAcceptRejectQC(this.utilitiesService.stripScript(_inspectionTask)).subscribe(
      (data) => {
        if (data !== null) {
          if (this.multiFiles) {
            this.qcWOrkorderService.QCAttachmentFile(this.multiFiles, this.currentUser.UserId, this.qcMaster.QcId);
          }
          // cleaningQCWOForm.reset();
          this.clearFormData();
          this.closecleaningAlertAcceptQCModal();
          this.closecleaningAlertRejectQCModal();
          if (!isFromSchedule) {
            this.utilitiesService.smmodal('Quality Control Check', data.Message);
          }
          this.isReqComplete = false;
          this.loaderService.display(false);
        }
      },
      (error) => {
        console.log(
          'qcWOrkorderService saveQCInput Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.isReqComplete = false;
        this.loaderService.display(false);
      },
    );
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;

    // if (this.isSupervisor) {
    //     // this.getAllCustodialWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
    //     this.getAllQCWOrkorders('', this.authState.getStoredContextVenueId(), this.page, this.size);
    // }
    // if (this.isMaintenanceSupervisor) {
    this.getAllMaintenanceWorkorders(this.searchString, this.authState.getStoredContextVenueId(), this.page, this.size);
    // }
  }

  public searchWorkorder(searchString: string) {
    // if (this.isMaintenanceSupervisor) {
    this.getAllMaintenanceWorkorders(searchString, this.authState.getStoredContextVenueId(), 1, this.size);
    // } else if (this.isSupervisor) {
    //     // this.getAllCustodialWorkorders(searchString, this.authState.getStoredContextVenueId(), page, size);
    //     this.getAllQCWOrkorders(searchString, this.authState.getStoredContextVenueId(), 1, this.size);
    // }
  }

  public clearSearch() {
    this.searchString = '';
    // if (this.isMaintenanceSupervisor) {
    this.getAllMaintenanceWorkorders(this.searchString, this.authState.getStoredContextVenueId(), 1, this.size);
    // } else if (this.isSupervisor) {
    //     this.getAllQCWOrkorders(this.searchString, this.authState.getStoredContextVenueId(), 1, this.size);
    // }
  }

  public getWorkorderHistory(qcId: number) {
    this.loaderService.display(true);
    const CPQcMaster = new QCMaster();
    CPQcMaster.QcCPId = this.authState.AESEncryptText(qcId);
    this.workorderService.GetQCWorkorderHistory(CPQcMaster).subscribe(
      (data) => {
        this.workorderHistoryDetails = data;
        this.cleaningAlertHistoryModal = true;

        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'WOService GetQCWorkorderHistory Call Failed.Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public getMaintenanceWorkorderHistory(workorderId: number) {
    this.loaderService.display(true);
    const CPworkorder = new WorkorderModule();
    CPworkorder.WorkorderCPId = this.authState.AESEncryptText(workorderId);
    this.workorderService.GetMaintenanceWorkorderHistory(CPworkorder).subscribe(
      (data) => {
        this.maintenanceWOHistoryDetails = data;
        // console.log(this.maintenanceWOHistoryDetails);
        this.woMaintananceHistoryModal = true;
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'WOService GetMaintenanceWorkorderHistory Call Failed.Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public openQCAttachment(imageName: string) {
    this.qcAttachmentImg = '';
    this.qcAttachmentImg = this.woAttachmentURL + imageName;
  }

  public openconfirmationModal() {
    this.confirmationModal = true;
  }

  public closeconfirmationModal() {
    this.confirmationModal = false;
  }

  public openQCRejectconfirmationModal() {
    this.QCRejectconfirmationModal = true;
  }

  public closeQCRejectconfirmationModal() {
    this.QCRejectconfirmationModal = false;
  }

  public opencleaningAlertAcceptQCModal() {
    this.cleaningAlertAcceptQCModal = true;
  }

  public closecleaningAlertAcceptQCModal() {
    this.cleaningAlertAcceptQCModal = false;
  }

  public opencleaningAlertRejectQCModal() {
    this.cleaningAlertRejectQCModal = true;
  }

  public closecleaningAlertRejectQCModal() {
    this.cleaningAlertRejectQCModal = false;
  }

  public removeBrowsFile(filename: any) {
    const index = this.multiFiles.findIndex((file) => file.name == filename);
    this.multiFiles.splice(index, 1);
    this.fileInputVariable.nativeElement.value = '';
    this.file = null;
  }

  public closeCleaningAlertHistoryModal() {
    this.cleaningAlertHistoryModal = false;
  }

  public CloseWoMaintananceHistoryModal() {
    this.woMaintananceHistoryModal = false;
  }

  public closeQcAssignedModal() {
    this.qcAssignedModal = false;
  }

  public getModulesData() {
    if (
      this.utilitiesService.checkVenueFeatureConfiguration('QC_COMPLETION_CRITERIA_FEEDBACK_FOR_MARK_TASKS') &&
      this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_TASK')
    ) {
      this.isQC_FeedbackShow = true;
    }

    if (
      this.utilitiesService.checkVenueFeatureConfiguration('QC_COMPLETION_CRITERIA_DISPLAY_INVENTORIES') &&
      this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_INVENTORIES')
    ) {
      this.isQC_InventoryShow = true;
    }

    this.isQC_CommentShow = this.utilitiesService.checkVenueFeatureConfiguration('QC_COMPLETION_CRITERIA_ADD_COMMENTS');
    this.isQC_PictureShow = this.utilitiesService.checkVenueFeatureConfiguration('QC_COMPLETION_CRITERIA_ADD_PICTURES');
    this.isQC_VideoShow = this.utilitiesService.checkVenueFeatureConfiguration('QC_COMPLETION_CRITERIA_ADD_VIDEOS');
    this.isQC_CreationShow = this.utilitiesService.checkVenueFeatureConfiguration(
      'QC_COMPLETION_CRITERIA_MANUAL_FROM_WEB',
    );
    this.isQC_OverallRationShow = this.utilitiesService.checkVenueFeatureConfiguration(
      'QC_COMPLETION_CRITERIA_PROVIDE_OVERALL_RATING',
    );
    this.isQC_RatingShow = this.utilitiesService.checkVenueFeatureConfiguration('QC_TRIGGER_ON_RATING');
    this.isQC_HistoryShow = this.utilitiesService.checkVenueFeatureConfiguration(
      'QC_COMPLETION_CRITERIA_SHOW_HISTORY_ON_QC',
    );
    this.isCASchedulingShow = this.utilitiesService.checkVenueFeatureConfiguration('CA_CALENDAR_SCHEDULING');

    this.isCA_ASSIGNMENT = this.utilitiesService.checkVenueFeatureConfiguration('CA_ASSIGNMENT_CALENDAR_SCHEDULING')
      ? false
      : true;
  }

  public changeRating(emojiRating: string) {
    this.qcMaster.PreQCFeedback = emojiRating;
  }

  public downloadWOImage(AttachmentName: string) {
    const download = this.configuration.Server_Closure_Workorder_Attachment + AttachmentName;
    const a = document.createElement('a');
    a.href = download;
    a.target = '_blank';
    a.download = 'download';
    a.click();
  }

  //// schedule code (start) ------------------------------------
  public openInspectNowOrLaterModal() {
    this.cleaningAlertRejectQCModal = false;
    this.confirmInspectionScreen = true;
  }

  public closeConfirmInspectionScreen() {
    this.cleaningAlertRejectQCModal = false;
    this.confirmInspectionScreen = false;
  }

  public MonthlyonFocusWeek($event) {
    this.isrbMonthlyDays1 = 0;
    this.isrbMonthlyDays2 = 1;
    this.rbMonthlyDays = false;
    this.rbMonthlyMonths = true;
    this.WeekOfMonthMonthly = $event;
  }

  public MonthlyonFocusDays($event) {
    this.isrbMonthlyDays1 = 0;
    this.isrbMonthlyDays2 = 1;
    this.rbMonthlyDays = false;
    this.rbMonthlyMonths = true;
    this.DayOfMonthMonthly2 = $event;
  }

  public YearlyonFocusOnMonth1($event) {
    this.isrbYearlyMonths = 1;
    this.isrbYearlyDayOfMonth = 0;
    this.rbYearlyMonthWeekDay = false;
    this.rbYearlyMonths = true;
    this.MonthOfYearYearly = $event;
  }

  public YearlyonFocusonWeek2($event) {
    this.isrbYearlyMonths = 0;
    this.isrbYearlyDayOfMonth = 1;
    this.rbYearlyMonths = false;
    this.rbYearlyMonthWeekDay = true;
    this.YearlyWeekOfMonth = $event;
  }

  public YearlyonFocusonDay2($event) {
    this.isrbYearlyMonths = 0;
    this.isrbYearlyDayOfMonth = 1;
    this.rbYearlyMonths = false;
    this.rbYearlyMonthWeekDay = true;
    this.YearlyDayOfMonth = $event;
  }

  public YearlyonFocusonMonth2($event) {
    this.isrbYearlyMonths = 0;
    this.isrbYearlyDayOfMonth = 1;
    this.rbYearlyMonths = false;
    this.rbYearlyMonthWeekDay = true;
    this.YearlyMonthOfYear = $event;
  }

  public IsFullDayEvent(event: any) {
    this.IsFullDayDatePanalVisible = true;
    this.calendar.IsRecurring = 0;
    if (event.checked) {
      this.FullDayStartTime = this.calendar.StartTime;
      this.FullDayEndTime = this.calendar.EndTime;
      this.calendar.StartTime = '12:00 am';
      this.calendar.EndTime = '12:00 am';
      this.FullDayStartDate = this.calendar.StartDate;
      this.FullDayEndDate = this.calendar.EndDate;

      this.IsRecurringVisible = false;
      this.IsVisible = false;
      this.StartTimeDisabled = true;
      this.EndTimeDisabled = true;
    } else {
      if (!this.isNewState && this.FullDayEndDate === undefined && this.FullDayEndDate === undefined) {
        this.getCurrentTime();
        this.FullDayStartTime = this.calendar.StartTime;
        this.FullDayEndTime = this.calendar.EndTime;
        this.FullDayStartDate = this.calendar.StartDate;
        this.FullDayEndDate = this.calendar.EndDate;
        this.StartTimeDisabled = false;
        this.EndTimeDisabled = false;
      } else {
        this.calendar.StartDate = this.FullDayStartDate;
        this.calendar.EndDate = this.FullDayEndDate;
        this.calendar.StartTime = this.FullDayStartTime;
        this.calendar.EndTime = this.FullDayEndTime;
        this.IsRecurringVisible = true;
        this.StartTimeDisabled = false;
        this.EndTimeDisabled = false;
      }
    }
    if (!this.isNewState) {
      this.IsRecurringVisible = false;
    }
  }

  public IsRecurring(event: any) {
    if (event.checked === true) {
      this.IsVisible = true;
      this.calendar.IsRecurring = 1;
      this.IsFullDayDatePanalVisible = false;
    } else {
      this.calendar.IsRecurring = 0;
      this.IsVisible = false;
      this.IsFullDayDatePanalVisible = true;
    }
  }

  public rbDailyChange(event: MatRadioChange) {
    const Daily: MatRadioButton = event.source;
    if (Daily.id === 'rbDailyDays' && Daily.checked) {
      this.isrbDailyDays = 1;
      this.calendar.RecurringPattern.SeperationCount = 1;
      this.IsrbDailyDaysChecked = true;
      this.rbDailyEveryWeekdays = false;
    }

    if (Daily.id === 'rbDailyEveryWeekdays' && Daily.checked) {
      this.isrbDailyDays = 0;
      this.calendar.RecurringPattern.SeperationCount = 0;
      this.IsrbDailyDaysChecked = false;
      this.rbDailyEveryWeekdays = true;
    }
  }

  public rbMonthlyChange(event: MatRadioChange) {
    const Monthly: MatRadioButton = event.source;
    if (Monthly.id === 'rbMonthlyDays' && Monthly.checked) {
      this.isrbMonthlyDays1 = 1;
      this.isrbMonthlyDays2 = 0;
      this.rbMonthlyDays = true;
      this.rbMonthlyMonths = false;
    }

    if (Monthly.id === 'rbMonthlyMonths' && Monthly.checked) {
      this.isrbMonthlyDays1 = 0;
      this.isrbMonthlyDays2 = 1;
      this.rbMonthlyDays = false;
      this.rbMonthlyMonths = true;
    }
  }

  public rbYearlyChange(event: MatRadioChange) {
    const Yearly: MatRadioButton = event.source;
    if (Yearly.id === 'rbYearlyMonths' && Yearly.checked) {
      this.isrbYearlyMonths = 1;
      this.isrbYearlyDayOfMonth = 0;
      this.rbYearlyMonths = true;
      this.rbYearlyMonthWeekDay = false;
    }

    if (Yearly.id === 'rbYearlyMonthWeekDay' && Yearly.checked) {
      this.isrbYearlyMonths = 0;
      this.isrbYearlyDayOfMonth = 1;
      this.rbYearlyMonthWeekDay = true;
      this.rbYearlyMonths = false;
    }
  }

  public rangeOfRecurrenceFocus(ControlName: string) {
    switch (ControlName) {
      case 'rbRecurrenceNoEndDate':
        this.rangeOfRecurrenceRadioControl = '1';
        this.calendar.RecurringPattern.Occurences = 0;
        this.calendar.EndDate = '';
        this.IsrbRecurrenceNoEndDateCheked = true;
        this.IsrbRecurrenceEndAfterChecked = false;
        this.IsrbRecurrenceEndbyChecked = false;
        break;
      case 'rbRecurrenceEndAfter':
        this.rangeOfRecurrenceRadioControl = '2';
        this.calendar.RecurringPattern.Occurences = 10;
        this.calendar.EndDate = '';
        this.IsrbRecurrenceNoEndDateCheked = false;
        this.IsrbRecurrenceEndAfterChecked = true;
        this.IsrbRecurrenceEndbyChecked = false;
        break;
      case 'rbRecurrenceEndby':
        this.rangeOfRecurrenceRadioControl = '3';
        this.calendar.RecurringPattern.Occurences = 0;
        if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
          this.calendar.EndDate = this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd');
        }
        this.IsrbRecurrenceNoEndDateCheked = false;
        this.IsrbRecurrenceEndAfterChecked = false;
        this.IsrbRecurrenceEndbyChecked = true;
        break;
    }
  }

  public rangeOfRecurrenceRadioclick(event: MatRadioChange) {
    const rangeOfRecurrence: MatRadioButton = event.source;

    if (rangeOfRecurrence.id === 'rbRecurrenceNoEndDate' && rangeOfRecurrence.checked) {
      this.calendar.RecurringPattern.Occurences = 0;
      this.calendar.EndDate = '';
      this.IsrbRecurrenceNoEndDateCheked = true;
      this.IsrbRecurrenceEndAfterChecked = false;
      this.IsrbRecurrenceEndbyChecked = false;
    }

    if (rangeOfRecurrence.id === 'rbRecurrenceEndAfter' && rangeOfRecurrence.checked) {
      this.calendar.RecurringPattern.Occurences = 10;
      this.calendar.EndDate = '';
      this.IsrbRecurrenceNoEndDateCheked = false;
      this.IsrbRecurrenceEndAfterChecked = true;
      this.IsrbRecurrenceEndbyChecked = false;
    }

    if (rangeOfRecurrence.id === 'rbRecurrenceEndby' && rangeOfRecurrence.checked) {
      this.calendar.RecurringPattern.Occurences = 0;

      if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
        this.calendar.EndDate = this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd');
      }
      this.IsrbRecurrenceNoEndDateCheked = false;
      this.IsrbRecurrenceEndAfterChecked = false;
      this.IsrbRecurrenceEndbyChecked = true;
    }
  }

  public onChangerangeOfRecurrenceRadioControl(mrChange: MatRadioChange) {
    this.rangeOfRecurrenceRadioControl = mrChange.value;
    this.BindAllSupervisors();
  }

  public checkValidation() {
    if (this.calendar.CorporationId === 0 || this.calendar.CorporationId === null) {
      this.utilitiesService.openWarningModal('Calendar', 'Corporation is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.Venue.VenueId === 0 || this.calendar.Venue.VenueId === null) {
      this.utilitiesService.openWarningModal('Calendar', 'Venue is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.ScheduleTitle === null || this.calendar.ScheduleTitle === '') {
      this.utilitiesService.openWarningModal('Calendar', 'Title is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.RecurringPattern.Occurences < 0) {
      this.utilitiesService.openWarningModal('Calendar', 'Recurrence type is not valid.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.StartTime === null || this.calendar.StartTime === '') {
      this.utilitiesService.openWarningModal('Calendar', 'Start Time is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.EndTime === null || this.calendar.EndTime === '') {
      this.utilitiesService.openWarningModal('Calendar', 'End Time is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.StartDate === null || this.calendar.StartDate === '') {
      this.utilitiesService.openWarningModal('Calendar', 'Start date is required.');
      this.loaderService.display(false);
      return false;
    }

    if (this.rangeOfRecurrenceRadioControl === '3' || this.rangeOfRecurrenceRadioControl === 3) {
      if (this.calendar.EndDate === null || this.calendar.EndDate === '') {
        this.utilitiesService.openWarningModal('Calendar', 'End date is required.');
        this.loaderService.display(false);
        return false;
      }
    } else if (
      this.calendar.IsRecurring === 1 &&
      this.isNewState === true &&
      (this.rangeOfRecurrenceRadioControl === '1' || this.rangeOfRecurrenceRadioControl === '2')
    ) {
      this.calendar.EndDate = '';
    }

    if (this.calendar.IsFullDayEvent !== 1 && this.calendar.StartTime === this.calendar.EndTime) {
      this.utilitiesService.openWarningModal('Calendar', 'Start Time and End Time should not be equal.');
      this.loaderService.display(false);
      return false;
    } else if (
      this.calendar.IsFullDayEvent === 1 &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd')
    ) {
      this.utilitiesService.openWarningModal(
        'Calendar',
        'Sorry, you cannot create a full day event schedule for current day.',
      );
      this.loaderService.display(false);
      return false;
    } else if (
      this.utilitiesService.convert12To24Hr(this.calendar.StartTime) >
      this.utilitiesService.convert12To24Hr(this.calendar.EndTime)
    ) {
      this.utilitiesService.openWarningModal('Calendar', 'Schedule start time should not be greater than end time.');
      this.loaderService.display(false);
      return false;
    }

    if (
      this.calendar.StartDate !== null ||
      (this.calendar.StartDate !== '' && this.calendar.EndDate !== null) ||
      this.calendar.EndDate !== ''
    ) {
      const sDate = Date.parse(this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd'));
      const eDate = Date.parse(this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd'));
      if (sDate > eDate) {
        this.utilitiesService.openWarningModal('Calendar', 'Schedule end date should not be smaller than start date.');
        this.loaderService.display(false);
        return false;
      }
    }

    if (
      this.calendar.IsRecurring === 0 &&
      this.calendar.StartDate !== null &&
      this.calendar.StartDate !== '' &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
      this.utilitiesService.dateParseFromTime(this.calendar.StartTime) <=
        this.utilitiesService.dateParseFromTime(this.venueServerTime)
    ) {
      this.utilitiesService.openWarningModal('Calendar', 'Schedule start time should not be less than current time.');
      this.loaderService.display(false);
      return false;
    }

    if (this.calendar.IsRecurring === 1 && (this.IsEntireSeries !== 0 || this.isNewState === true)) {
      switch (this.selectedTabIndex) {
        case 0:
          if (this.isrbDailyDays === 1) {
            if (this.SeperationCountDaily === 0 || this.SeperationCountDaily === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a day.');
              this.loaderService.display(false);
              return false;
            }
          }
          break;
        case 1:
          if (
            this.calendar.RecurringPattern.SeperationCount === 0 ||
            this.calendar.RecurringPattern.SeperationCount === null
          ) {
            this.utilitiesService.openWarningModal('Calendar', 'Please select week.');
            this.loaderService.display(false);
            return false;
          } else {
            this.calendar.RecurringPattern.Sunday = this.calendar.RecurringPattern.Sunday ? 1 : 0;
            this.calendar.RecurringPattern.Monday = this.calendar.RecurringPattern.Monday ? 1 : 0;
            this.calendar.RecurringPattern.Tuesday = this.calendar.RecurringPattern.Tuesday ? 1 : 0;
            this.calendar.RecurringPattern.Wednesday = this.calendar.RecurringPattern.Wednesday ? 1 : 0;
            this.calendar.RecurringPattern.Thusday = this.calendar.RecurringPattern.Thusday ? 1 : 0;
            this.calendar.RecurringPattern.Friday = this.calendar.RecurringPattern.Friday ? 1 : 0;
            this.calendar.RecurringPattern.Saturday = this.calendar.RecurringPattern.Saturday ? 1 : 0;

            if (
              this.calendar.RecurringPattern.Sunday !== 1 &&
              this.calendar.RecurringPattern.Monday !== 1 &&
              this.calendar.RecurringPattern.Tuesday !== 1 &&
              this.calendar.RecurringPattern.Wednesday !== 1 &&
              this.calendar.RecurringPattern.Thusday !== 1 &&
              this.calendar.RecurringPattern.Friday !== 1 &&
              this.calendar.RecurringPattern.Saturday !== 1
            ) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week day.');
              this.loaderService.display(false);
              return false;
            }
          }
          break;

        case 2: {
          if (this.isrbMonthlyDays1 === 1) {
            if (this.DayOfMonthMonthly1 === 0 || this.DayOfMonthMonthly1 === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a day.');
              this.loaderService.display(false);
              return false;
            } else if (this.SeperationCountMonthly1 === 0 || this.SeperationCountMonthly1 === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a month.');
              this.loaderService.display(false);
              return false;
            } else if (this.DayOfMonthMonthly1 > 31) {
              this.utilitiesService.openWarningModal('Calendar', 'Recurrence type is not valid.');
              this.loaderService.display(false);
              return false;
            }
          }

          if (this.isrbMonthlyDays2 === 1) {
            if (this.WeekOfMonthMonthly === 0 || this.WeekOfMonthMonthly === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week.');
              this.loaderService.display(false);
              return false;
            } else if (this.DayOfMonthMonthly2 === 0 || this.DayOfMonthMonthly2 === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week day.');
              this.loaderService.display(false);
              return false;
            } else if (this.SeperationCountMonthly2 === 0 || this.SeperationCountMonthly2 === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a month.');
              this.loaderService.display(false);
              return false;
            }
          }
          break;
        }

        case 3: {
          if (this.SeperationCountYearly === 0 || this.SeperationCountYearly === null) {
            this.utilitiesService.openWarningModal('Calendar', 'Please select recur year(s).');
            this.loaderService.display(false);
            return false;
          }

          if (this.isrbYearlyMonths === 1) {
            if (this.MonthOfYearYearly === 0 || this.MonthOfYearYearly === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a month.');
              this.loaderService.display(false);
              return false;
            } else if (this.DayOfMonthYearly === 0 || this.DayOfMonthYearly === null) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a day.');
              this.loaderService.display(false);
              return false;
            } else if (this.DayOfMonthYearly !== 0 || this.DayOfMonthYearly !== null) {
              let maxdays = 0;
              switch (this.MonthOfYearYearly) {
                case 1:
                case 3:
                case 5:
                case 7:
                case 8:
                case 10:
                case 12:
                  maxdays = 31;
                  break;

                case 2:
                  maxdays = this.utilitiesService.isLeapYear(new Date(this.calendar.StartDate).getFullYear()) ? 29 : 28;
                  break;

                default:
                  maxdays = 30;
              }

              if (this.DayOfMonthYearly > maxdays) {
                this.utilitiesService.openWarningModal('Calendar', 'Recurrence type is not valid.');
                this.loaderService.display(false);
                return false;
              }
            }
          }

          if (this.isrbYearlyDayOfMonth === 1) {
            if (this.isrbYearlyDayOfMonth === 1 && (this.YearlyWeekOfMonth === 0 || this.YearlyWeekOfMonth === null)) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week.');
              this.loaderService.display(false);
              return false;
            } else if (
              this.isrbYearlyDayOfMonth === 1 &&
              (this.YearlyDayOfMonth === 0 || this.YearlyDayOfMonth === null)
            ) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a week day.');
              this.loaderService.display(false);
              return false;
            } else if (
              this.isrbYearlyDayOfMonth === 1 &&
              (this.YearlyMonthOfYear === 0 || this.YearlyMonthOfYear === null)
            ) {
              this.utilitiesService.openWarningModal('Calendar', 'Please select a month.');
              this.loaderService.display(false);
              return false;
            }
          }
          break;
        }
      }

      if (this.rangeOfRecurrenceRadioControl === '2') {
        if (this.calendar.RecurringPattern.Occurences === null || this.calendar.RecurringPattern.Occurences === 0) {
          this.utilitiesService.openWarningModal('Calendar', 'Occurences is required.');
          this.loaderService.display(false);
          return false;
        }
      }
    } else {
      if (this.calendar.StartDate !== this.calendar.EndDate) {
        this.utilitiesService.openWarningModal(
          'Calendar',
          'Your schedule is not recurring so start date and end date should be same.',
        );
        this.loaderService.display(false);
        return false;
      }
    }

    if (
      this.calendar.IsRecurring === 1 &&
      this.isNewState === true &&
      this.calendar.StartDate !== null &&
      this.calendar.StartDate !== '' &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
      this.utilitiesService.dateParseFromTime(this.calendar.StartTime) <=
        this.utilitiesService.dateParseFromTime(this.venueServerTime)
    ) {
      if (this.rangeOfRecurrenceRadioControl === '1' || this.rangeOfRecurrenceRadioControl === '2') {
        this.calendar.EndDate = '';
      }

      this.utilitiesService.openWarningModal(
        'Calendar',
        'Sorry, you cannot create schedule for current day as start time has already passed.',
      );
      this.loaderService.display(false);
      return false;
    }
  }

  public getValueFromSelectedTab(selectedTabIndex: number) {
    this.calendar.RecurringPattern.MonthOfYear = 0;
    this.calendar.RecurringPattern.WeekOfMonth = 0;
    this.calendar.RecurringPattern.DayOfMonth = 0;

    switch (selectedTabIndex) {
      case 0: {
        this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
        if (this.isrbDailyDays === 1) {
          this.calendar.RecurringPattern.SeperationCount = this.SeperationCountDaily;
          this.calendar.RecurringPattern.Sunday = 0;
          this.calendar.RecurringPattern.Monday = 0;
          this.calendar.RecurringPattern.Tuesday = 0;
          this.calendar.RecurringPattern.Wednesday = 0;
          this.calendar.RecurringPattern.Thusday = 0;
          this.calendar.RecurringPattern.Friday = 0;
          this.calendar.RecurringPattern.Saturday = 0;
        } else {
          this.calendar.RecurringPattern.SeperationCount = 0;
          this.calendar.RecurringPattern.Sunday = 0;
          this.calendar.RecurringPattern.Monday = 1;
          this.calendar.RecurringPattern.Tuesday = 1;
          this.calendar.RecurringPattern.Wednesday = 1;
          this.calendar.RecurringPattern.Thusday = 1;
          this.calendar.RecurringPattern.Friday = 1;
          this.calendar.RecurringPattern.Saturday = 0;
        }
        break;
      }
      case 1: {
        this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
        this.calendar.RecurringPattern.Sunday = this.calendar.RecurringPattern.Sunday ? 1 : 0;
        this.calendar.RecurringPattern.Monday = this.calendar.RecurringPattern.Monday ? 1 : 0;
        this.calendar.RecurringPattern.Tuesday = this.calendar.RecurringPattern.Tuesday ? 1 : 0;
        this.calendar.RecurringPattern.Wednesday = this.calendar.RecurringPattern.Wednesday ? 1 : 0;
        this.calendar.RecurringPattern.Thusday = this.calendar.RecurringPattern.Thusday ? 1 : 0;
        this.calendar.RecurringPattern.Friday = this.calendar.RecurringPattern.Friday ? 1 : 0;
        this.calendar.RecurringPattern.Saturday = this.calendar.RecurringPattern.Saturday ? 1 : 0;
        break;
      }
      case 2: {
        this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
        this.calendar.RecurringPattern.Sunday = 0;
        this.calendar.RecurringPattern.Monday = 0;
        this.calendar.RecurringPattern.Tuesday = 0;
        this.calendar.RecurringPattern.Wednesday = 0;
        this.calendar.RecurringPattern.Thusday = 0;
        this.calendar.RecurringPattern.Friday = 0;
        this.calendar.RecurringPattern.Saturday = 0;

        if (this.isrbMonthlyDays1 === 1) {
          this.calendar.RecurringPattern.SeperationCount = this.SeperationCountMonthly1;
          this.calendar.RecurringPattern.DayOfMonth = this.DayOfMonthMonthly1;
        } else if (this.isrbMonthlyDays2 === 1) {
          this.calendar.RecurringPattern.SeperationCount = this.SeperationCountMonthly2;
          this.calendar.RecurringPattern.WeekOfMonth = this.WeekOfMonthMonthly;
          this.calendar.RecurringPattern.Sunday = 0;
          this.calendar.RecurringPattern.Monday = 0;
          this.calendar.RecurringPattern.Tuesday = 0;
          this.calendar.RecurringPattern.Wednesday = 0;
          this.calendar.RecurringPattern.Thusday = 0;
          this.calendar.RecurringPattern.Friday = 0;
          this.calendar.RecurringPattern.Saturday = 0;
          switch (this.DayOfMonthMonthly2) {
            case 1: {
              this.calendar.RecurringPattern.Sunday = 1;
              break;
            }
            case 2: {
              this.calendar.RecurringPattern.Monday = 1;
              break;
            }
            case 3: {
              this.calendar.RecurringPattern.Tuesday = 1;
              break;
            }
            case 4: {
              this.calendar.RecurringPattern.Wednesday = 1;
              break;
            }
            case 5: {
              this.calendar.RecurringPattern.Thusday = 1;
              break;
            }
            case 6: {
              this.calendar.RecurringPattern.Friday = 1;
              break;
            }
            case 7: {
              this.calendar.RecurringPattern.Saturday = 1;
              break;
            }
          }
        }
        break;
      }
      case 3: {
        this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
        this.calendar.RecurringPattern.SeperationCount = this.SeperationCountYearly;
        this.calendar.RecurringPattern.Sunday = 0;
        this.calendar.RecurringPattern.Monday = 0;
        this.calendar.RecurringPattern.Tuesday = 0;
        this.calendar.RecurringPattern.Wednesday = 0;
        this.calendar.RecurringPattern.Thusday = 0;
        this.calendar.RecurringPattern.Friday = 0;
        this.calendar.RecurringPattern.Saturday = 0;

        if (this.isrbYearlyMonths === 1) {
          this.calendar.RecurringPattern.MonthOfYear = this.MonthOfYearYearly;
          this.calendar.RecurringPattern.DayOfMonth = this.DayOfMonthYearly;
        } else {
          this.calendar.RecurringPattern.Sunday = 0;
          this.calendar.RecurringPattern.Monday = 0;
          this.calendar.RecurringPattern.Tuesday = 0;
          this.calendar.RecurringPattern.Wednesday = 0;
          this.calendar.RecurringPattern.Thusday = 0;
          this.calendar.RecurringPattern.Friday = 0;
          this.calendar.RecurringPattern.Saturday = 0;

          this.calendar.RecurringPattern.WeekOfMonth = this.YearlyWeekOfMonth;
          this.calendar.RecurringPattern.MonthOfYear = this.YearlyMonthOfYear;
          switch (this.YearlyDayOfMonth) {
            case 1: {
              this.calendar.RecurringPattern.Sunday = 1;
              break;
            }
            case 2: {
              this.calendar.RecurringPattern.Monday = 1;
              break;
            }
            case 3: {
              this.calendar.RecurringPattern.Tuesday = 1;
              break;
            }
            case 4: {
              this.calendar.RecurringPattern.Wednesday = 1;
              break;
            }
            case 5: {
              this.calendar.RecurringPattern.Thusday = 1;
              break;
            }
            case 6: {
              this.calendar.RecurringPattern.Friday = 1;
              break;
            }
            case 7: {
              this.calendar.RecurringPattern.Saturday = 1;
              break;
            }
          }
        }
        break;
      }
    }
  }

  public getCurrentTime() {
    this.currentStartTime = this.utilitiesService.getFormattedTime(new Date());
    this.calendar.StartTime = this.currentStartTime; // New Schedule Current Start Time
    const dt = new Date(); // New Schedule End Time
    dt.setMinutes(dt.getMinutes() + 30);
    this.currentEndTime = this.utilitiesService.getFormattedTime(dt);
    this.calendar.EndTime = this.currentEndTime;
  }

  public onTabChanged(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
  }

  public scheduleDialogOpen(templateRef: TemplateRef<any>) {
    this.confirmInspectionScreen = false;
    this.eventIsRecurring = false;
    this.calendar = new CalModule();
    this.calendar.Venue = new VenueModule();
    this.calendar.Location = new LocationModule();
    this.calendar.ScheduleType = new ScheduleTypeModule();
    this.calendar.Supervisor = new ScheduleUser();
    this.calendar.AssigneTo = new ScheduleUser();
    this.calendar.Inspector = new ScheduleUser();
    this.calendar.RecurringPattern = new RecurringPattern();

    this.scheduleDialogTitle = 'New Schedule';
    this.refresh.next();
    this.scheduleDialog.open(templateRef, { width: '800px', disableClose: true });

    this.setDefaultValues();
  }

  public setDefaultValues() {
    this.calendar.ScheduleTitle = '';
    this.calendar.ScheduleDescription = '';
    this.calendar.CorporationId = 0;
    this.calendar.Venue.VenueId = 0;

    this.calendar.IsRecurring = 0;
    this.selectedTabIndex = 0;
    this.SeperationCountMonthly2 = 1;
    this.SeperationCountDaily = 1;
    this.DayOfMonthMonthly1 = 1;
    this.SeperationCountMonthly1 = 1;
    this.SeperationCountYearly = 1;
    this.calendar.RecurringPattern.SeperationCount = 1;
    this.DayOfMonthYearly = 1;
    this.calendar.RecurringPattern.Occurences = 10;

    this.isrbDailyDays = 1;
    this.isrbMonthlyDays1 = 1;
    this.isrbYearlyMonths = 1;
    this.IsVisible = false;
    this.isNewState = true;
    this.IsrbRecurrenceNoEndDateCheked = true;
    this.IsrbRecurrenceEndAfterChecked = false;
    this.IsrbRecurrenceEndbyChecked = false;
    this.IsrbDailyDaysChecked = true;
    this.rbDailyEveryWeekdays = false;
    this.rbMonthlyDays = true;
    this.rbMonthlyMonths = false;
    this.rbYearlyMonths = true;
    this.rbYearlyMonthWeekDay = false;

    this.IsFullDayVisible = true;
    this.IsRecurringVisible = true;
    this.IsrbJustThisOneChecked = true;
    this.IsrbTheEntireSeriesChecked = false;
    this.IsEntireSeriesModifiedControl = 'rbJustThisOne';
    this.activeDayIsOpen = false;
    this.IsFullDayDatePanalVisible = true;
    this.rangeOfRecurrenceRadioControl = '1';

    // this.calendar.StartDate = this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd');
    // this.calendar.EndDate = this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd');

    this.TodayDate = new Date();
    this.calendar.StartDate = this.TodayDate;
    this.calendar.EndDate = this.TodayDate;

    this.StartTimeDisabled = false;
    this.EndTimeDisabled = false;

    this.calendar.RecurringPattern.Sunday = 0;
    this.calendar.RecurringPattern.Monday = 0;
    this.calendar.RecurringPattern.Tuesday = 0;
    this.calendar.RecurringPattern.Wednesday = 0;
    this.calendar.RecurringPattern.Thusday = 0;
    this.calendar.RecurringPattern.Friday = 0;
    this.calendar.RecurringPattern.Saturday = 0;

    this.calendar.RecurringPattern.DayOfMonth = 0;
    this.calendar.RecurringPattern.WeekOfMonth = 0;
    this.calendar.RecurringPattern.MonthOfYear = 0;

    this.isReqComplete = false;

    this.WeekOfMonthMonthly = 0;
    this.DayOfMonthMonthly2 = 0;
    this.MonthOfYearYearly = 0;
    this.YearlyWeekOfMonth = 0;
    this.YearlyDayOfMonth = 0;
    this.YearlyMonthOfYear = 0;
    this.getCurrentTime();
    this.IsRecurringDisabled = false;

    this.calendar.ScheduleType.ScheduleTypeId = 2;
    this.calendar.ScheduleType.ScheduleTypeName = 'Cleaning Alert';
    this.calendar.ScheduleType.ScheduleTypeCode = 'CLEANING_ALERT';

    if (this.qcMaster.Location != null && this.qcMaster.Location.LocationId != null) {
      this.calendar.Location.LocationId = this.qcMaster.Location.LocationId;
      this.calendar.Location.LocationName = this.qcMaster.Location.LocationName;
    }
    this.calendar.WorkorderId = this.qcMaster.WorkorderId;
    this.BindAllSupervisors();
  }

  public BindAllSupervisors() {
    if (
      this.calendar.StartDate !== null &&
      this.calendar.StartDate !== '' &&
      this.calendar.StartTime !== null &&
      this.calendar.StartTime !== '' &&
      this.calendar.EndTime !== null &&
      this.calendar.EndTime !== ''
    ) {
    } else {
      this.workers = [];
    }

    if (this.authState.getStoredContextVenueId()) {
      this.getVenueById(this.authState.getStoredContextVenueId());
    }
  }

  public getVenueById(venueId: number) {
    this.loaderService.display(true);
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.venueService.GetSingle(CPvenue).subscribe(
      (data) => {
        if (data != null) {
          this.venueServerTime = this.datePipe.transform(data.VenueCurrrentTime, 'HH:mm');
          this.venueTimeZoneText = data.Timezone;
        }

        this.loaderService.display(false);
        this.getAllCorporations();
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllCorporations() {
    this.loaderService.display(true);

    this.corporationService.GetCorporationListinHeader().subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.corporations = data;

          let corp = this.corporations.filter((c) => c.CorporationId == this.authState.getStoredContextCorporationId());
          if (corp && corp[0]) {
            this.calendar.CorporationId = corp[0].CorporationId;
            this.calendar.CorporationName = corp[0].CorporationName;

            this.GetAllVenues(this.calendar.CorporationId);
          }
          this.corporationDisabled = false;
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'corporationService GetCorporationListinHeader call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public GetAllVenues(corporationId: any) {
    this.loaderService.display(true);
    const CPcorporation = new CorporationModule();
    CPcorporation.CorporationCPId = this.authState.AESEncryptText(corporationId);

    this.corporationService.GetVenueByCorporation(CPcorporation).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0) {
          this.Venues = data;

          let venue = this.Venues.filter((v) => v.VenueId == this.authState.getStoredContextVenueId());
          if (venue && venue[0]) {
            this.calendar.Venue.VenueId = venue[0].VenueId;
            this.calendar.Venue.VenueName = venue[0].VenueName;

            if (
              this.calendar.ScheduleType.ScheduleTypeCode &&
              this.calendar.CorporationId &&
              this.calendar.Venue.VenueId &&
              this.calendar.Location.LocationId
            ) {
              this.getAllSupervisorsWorkersInspectors(
                this.calendar.ScheduleType.ScheduleTypeCode,
                this.calendar.CorporationId,
                this.calendar.Venue.VenueId,
                this.calendar.Location.LocationId,
              );
            }
          }
          this.venueDisabled = false;
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'corporationService GetVenueByCorporation call failed. status:' +
            error.status +
            'Status Text: ' +
            error.statusText,
        );
      },
    );
  }

  public getAllSupervisorsWorkersInspectors(
    eventType: string,
    corporationId: number,
    venueId: number,
    locationId: number,
  ) {
    let payload = { EventTypeCode: eventType, CorporationId: corporationId, VenueId: venueId, LocationId: locationId };

    // Get Worker
    if (eventType == 'CLEANING_ALERT' && corporationId != null && venueId != null && locationId != null) {
      this.loaderService.display(true);
      this.calendarservice.GetAllSupervisorsWorkersInspectors(JSON.stringify(payload)).subscribe(
        (data) => {
          this.loaderService.display(false);
          if (data != null && data.length > 0) {
            data.forEach(function (value) {
              value.FirstName = value.FirstName + ' ' + value.LastName;
            });
            this.workers = data;
          }
        },
        (error) => {
          console.log(
            'calendarservice GetAllSupervisorsWorkersInspectors Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
          );
          this.loaderService.display(false);
        },
      );
    }
  }

  public addSchedule(scheduleForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (scheduleForm.status == 'INVALID' && scheduleForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Calendar', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.calendar.ScheduleTitle = this.calendar.ScheduleTitle;
    this.calendar.ScheduleDescription = this.calendar.ScheduleDescription;

    const storeStartTime = this.calendar.StartTime;
    const storeEndTime = this.calendar.EndTime;

    // if (this.calendar.StartDate !== null && this.calendar.StartDate !== '') {
    //     this.calendar.StartDate = this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd');
    // }

    // if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
    //     this.calendar.EndDate = this.datePipe.transform(this.calendar.EndDate, 'yyyy-MM-dd');
    // }

    if (
      this.calendar.StartDate !== null &&
      this.calendar.StartDate !== '' &&
      this.datePipe.transform(this.calendar.StartDate, 'yyyy-MM-dd') ===
        this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd') &&
      this.utilitiesService.dateParseFromTime(this.calendar.StartTime) <=
        this.utilitiesService.dateParseFromTime(this.venueServerTime)
    ) {
      this.utilitiesService.openWarningModal(
        'Calendar',
        'Schedule start time should not be less than current time (Timezone: ' + this.venueTimeZoneText + ').',
      );
      this.loaderService.display(false);
      this.isReqComplete = false;
      return false;
    }

    if (this.checkValidation() === false) {
      this.isReqComplete = false;
      this.loaderService.display(false);
      return;
    }

    if (this.calendar.StartTime !== null && this.calendar.StartTime !== '') {
      this.calendar.StartTime = this.utilitiesService.convert12To24Hr(this.calendar.StartTime);
    }

    if (this.calendar.EndTime !== null && this.calendar.EndTime !== '') {
      this.calendar.EndTime = this.utilitiesService.convert12To24Hr(this.calendar.EndTime);
    }

    this.calendar.IsFullDayEvent = this.calendar.IsFullDayEvent ? 1 : 0;
    this.calendar.IsRecurring = this.calendar.IsRecurring ? 1 : 0;
    this.calendar.AddedBy = this.currentUser.UserId;

    if (this.calendar.ScheduleType != null) {
      this.calendar.ScheduleType.ScheduleTypeId = this.calendar.ScheduleType.ScheduleTypeId;
    }

    if (this.calendar.IsRecurring === 1) {
      if (this.rangeOfRecurrenceRadioControl === '1' || this.rangeOfRecurrenceRadioControl === '3') {
        this.calendar.RecurringPattern.Occurences = 0;
      }
      if (this.rangeOfRecurrenceRadioControl === '1' || this.rangeOfRecurrenceRadioControl === '2') {
        this.calendar.EndDate = '';
      }
      this.calendar.RecurringPattern.RecurringTypeId = this.selectedTabIndex + 1;
      this.getValueFromSelectedTab(this.selectedTabIndex);
    }

    this.calendar.Venue.Timezone = this.calendar.TimeZone;
    this.calendar.ScheduleId = 0;

    this.calendar.CorporationId = this.calendar.CorporationId;
    this.calendar.Venue.VenueId = this.calendar.Venue.VenueId;
    this.calendar.Location.LocationId = this.calendar.Location.LocationId;
    this.calendar.WorkorderId = this.calendar.WorkorderId;

    if (this.calendar.ScheduleType.ScheduleTypeCode == 'CLEANING_ALERT') {
      this.calendar.Supervisor = null;
      this.calendar.Inspector = null;
      this.calendar.AssigneTo.UserId = this.calendar.AssigneTo.UserId;
    }

    if (this.calendar.IsRecurring == 0) {
      this.calendar.RecurringPattern.Sunday = 0;
      this.calendar.RecurringPattern.Monday = 0;
      this.calendar.RecurringPattern.Tuesday = 0;
      this.calendar.RecurringPattern.Wednesday = 0;
      this.calendar.RecurringPattern.Thusday = 0;
      this.calendar.RecurringPattern.Friday = 0;
      this.calendar.RecurringPattern.Saturday = 0;
      this.calendar.RecurringPattern.RecurringTypeId = 0;
    }

    if (
      storeStartTime == '12:00 am' ||
      storeStartTime == '12:00 AM' ||
      storeStartTime == '00:00 am' ||
      storeStartTime == '00:00 AM'
    ) {
      this.calendar.StartTime = '00:00';
    }

    if (
      storeEndTime == '12:00 am' ||
      storeEndTime == '12:00 AM' ||
      storeEndTime == '00:00 am' ||
      storeEndTime == '00:00 AM'
    ) {
      this.calendar.EndTime = '00:00';
    }
    // console.log('create Schedule: ' + JSON.stringify(this.calendar));

    this.calendarservice.Add(this.utilitiesService.stripScript(this.calendar)).subscribe(
      () => {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal('Quality Control Check', 'Schedule created successfully!');
        this.scheduleDialogClose(scheduleForm, '');

        this.cleaningAlertAcceptRejectQC('no', true);
      },
      (error) => {
        this.isReqComplete = true;
        this.loaderService.display(false);
        console.log('CalendarService Add Call Failed. Status:' + error.status + 'Status Text' + error.statusText);
      },
    );
  }

  public scheduleDialogClose(popupReset: NgForm, modalFlag: string) {
    this.calendar = new CalModule();
    this.calendar.Venue = new VenueModule();
    this.calendar.Location = new LocationModule();
    this.calendar.AssigneTo = new ScheduleUser();
    this.calendar.Supervisor = new ScheduleUser();
    this.calendar.Inspector = new ScheduleUser();
    this.calendar.ScheduleType = new ScheduleTypeModule();
    this.calendar.RecurringPattern = new RecurringPattern();

    if (popupReset) {
      popupReset.reset();
    }

    this.scheduleForm.reset();
    this.scheduleDialog.closeAll();
    if (modalFlag != 'closeScheduleModal') {
      this.setDefaultValues();
    }
  }

  public selectJustThisOneDate(IsStartDateCall: number) {
    if (IsStartDateCall === 1) {
      if (this.calendar.StartDate !== null && this.calendar.StartDate !== '') {
        this.calendar.StartDate = this.calendar.StartDate;
        this.calendar.EndDate = this.calendar.StartDate;
      }
    } else if (this.calendar.EndDate !== null && this.calendar.EndDate !== '') {
      this.calendar.StartDate = this.calendar.EndDate;
      this.calendar.EndDate = this.calendar.EndDate;
    }
    this.BindAllSupervisors();
  }
}
