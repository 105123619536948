import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Configuration } from '../../../app.constants';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocationTypeModule, ImportLocationTypeLog, VenueArea } from '../locationtype.module';
import { VenueTask } from '../../venuetaskconfiguration/venuetaskconfiguration.module';
import { VenueInventory, VenueModule } from '../../../venuemanagement/venue/venue.module';
import { VenueElement } from '../../venueelementconfiguration/venueelementconfiguration.module';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';

@Injectable({
  providedIn: 'root',
})
export class LocationTypeService {
  private headers: HttpHeaders;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (body: LocationTypeModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/type';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: LocationTypeModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/type';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetAll = (body: string, page: number, size: number): Observable<LocationTypeModule[]> => {
    const _url: string = this.configuration.Server + 'venue/all/venue/location/types/' + page + '/' + size;
    return this.http.post<LocationTypeModule[]>(_url, body);
  };

  public GetSingle = (body: LocationTypeModule): Observable<LocationTypeModule> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/type/details';
    return this.http.post<LocationTypeModule>(_url, body);
  };

  public GetLocationTypeTasks = (body: LocationTypeModule): Observable<VenueTask[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/type/tasks';
    return this.http.post<VenueTask[]>(_url, body);
  };

  public GetLocationTypeInventories = (body: LocationTypeModule): Observable<VenueInventory[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/type/inventories';
    return this.http.post<VenueInventory[]>(_url, body);
  };

  public GetLocationTypeElements = (body: LocationTypeModule): Observable<VenueElement[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/type/elements';
    return this.http.post<VenueElement[]>(_url, body);
  };

  public GetLocationTypeAreas = (body: LocationTypeModule): Observable<VenueArea[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/type/areas';
    return this.http.post<VenueArea[]>(_url, body);
  };

  public GetAllCorporations = (body: any): Observable<CorporationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/import/location/type/corporations';
    return this.http.post<CorporationModule[]>(_url, body);
  };

  public GetAllVenues = (body: any): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'venue/import/location/type/venues';
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetAllImportLocationTypes = (body: ImportLocationTypeLog): Observable<LocationTypeModule[]> => {
    const _url: string = this.configuration.Server + 'venue/import/venue/location/types';
    return this.http.post<LocationTypeModule[]>(_url, body);
  };

  public saveImportLocationTypes = (body: any): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/import/location/types';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public GetAllImportedLocationTypes = (body: any, page: number, size: number): Observable<ImportLocationTypeLog[]> => {
    const _url: string = this.configuration.Server + 'venue/import/venue/location/type/log/' + page + '/' + size;
    return this.http.post<ImportLocationTypeLog[]>(_url, body);
  };

  public downloadImportLogFile(body: any): Observable<any> {
    const url: string = this.configuration.Server + 'venue/download/import/location/type/log';
    return new Observable((obs) => {
      const oReq = new XMLHttpRequest();
      oReq.open('POST', url, true);
      oReq.setRequestHeader('content-type', 'application/json');
      oReq.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      oReq.responseType = 'arraybuffer';

      oReq.onload = function () {
        const arrayBuffer = oReq.response;
        const byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };
      oReq.send(JSON.stringify(body));
    });
  }
}
