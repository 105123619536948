import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from '../../../app.constants';

import { LevelModule } from '../level.module';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';

@Injectable({
  providedIn: 'root',
})
export class LevelService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetLevelList = (body: string, page: number, size: number): Observable<LevelModule[]> => {
    const _url: string = this.configuration.Server + 'venue/all/levels/' + page + '/' + size;
    return this.http.post<LevelModule[]>(_url, body);
  };

  public GetLevelDropdown = (body: string, page: number, size: number): Observable<LevelModule[]> => {
    const _url: string = this.configuration.Server + 'venue/levels/' + page + '/' + size;
    return this.http.post<LevelModule[]>(_url, body);
  };

  public AddLevel = (level: LevelModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/level';
    return this.http.post<ResponseMessage>(_url, level);
  };

  public UpdateLevel = (level: LevelModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/level';
    return this.http.put<ResponseMessage>(_url, level);
  };

  public GetSingle = (body: LevelModule): Observable<LevelModule> => {
    const _url: string = this.configuration.Server + 'venue/level/details';
    return this.http.post<LevelModule>(_url, body);
  };
}
