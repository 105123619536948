import { Injectable } from '@angular/core';

// calendar
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Configuration } from '../../../app.constants';
import { AlertColor, AlertComponent, ModalAlertData } from '../alertmodal/alertmodal.component';
import { ConfirmComponent, ModalConfirmData } from '../confirmmodal/confirmmodal.component';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  modal: any;
  public alphanumericRegex: RegExp = /^[a-zA-Z0-9\s.\-_&()']+$/;
  public alphanumericRegexMessage = 'This field accepts alphanumeric values';

  public emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  public emailRegexMessage = 'Email address is invalid';

  public numericRegex: RegExp = /[0-9\-]+/;
  public numericRegexMessage = 'Country code should be in numbers';

  public mobileNumberRegex: RegExp = /^\d{3}-\d{3}-\d{4}$/;
  public mobileNumberRegexMessage = 'Mobile number should be in given format (123-456-7890)';

  public latitudeRegex: RegExp = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/;
  public latitudeRegexMessage = 'Latitude must be between -90.0 and 90.0';

  public longitudeRegex: RegExp = /[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
  public longitudeRegexMessage = 'Longitude must be between -180.0 and 180.0';

  public alphanumericWithoutSpecialCharRegex: RegExp = /^[a-zA-Z0-9-_:]+$/;
  public alphanumericWithoutSpecialCharRegexMessage =
    'This field accepts only alphanumeric values and special characters underscore(_) , hyphen(-) and colon(:).';

  public venueNumericRegex = new RegExp('[1-999999999]');

  public retriveModules: any[] = [];

  public webSiteRegex: RegExp =
    /^((https?|ftp|smtp):\/\/)?(www.)?[a-zA-Z0-9._-]+(\.[a-zA-Z]{2,}){1,3}(#?\/?[a-zA-Z0-9#?]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-\/_+=.&%]+&?)?$/;
  public authProviderRegex: RegExp =
    /^((https?|ftp|smtp):\/\/)?(www\.)?[a-zA-Z0-9._-]+(\.[a-zA-Z]{2,}){1,3}(\/[a-zA-Z0-9._\-\/]*)*(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-\/_+=.&%]+&?)*$/;
  public webSiteRegexMessage = 'URL is invalid';
  public fileUploadSize = 10;
  public fileUploadErrorMessage =
    'A file name cannot contain any of the further characters: / : * ? " < > | # { } % & =';

  public isAreaMenuShow = false;

  public listviewtypes = [
    { id: '1', name: 'Active' },
    { id: '0', name: 'Inactive' },
    { id: '2', name: 'Both' },
  ];

  public locationSortList = [
    { id: 'Visitor Count', name: 'Visitor Count' },
    { id: 'Location Name', name: 'Location Name' },
    { id: 'Last Clean', name: 'Last Clean' },
    { id: 'Last Visit', name: 'Last Visit' },
  ];

  public locationSortTypeList = [
    { id: 'DESC', name: 'Descending' },
    { id: 'ASC', name: 'Ascending' },
  ];

  public pageSizes = [
    { id: '10', name: '10' },
    { id: '25', name: '25' },
    { id: '50', name: '50' },
    { id: '60', name: '60' },
  ];

  public LocationStatusList = [
    { id: 'Open', name: 'Open' },
    { id: 'Closed', name: 'Closed' },
    { id: 'Emergency', name: 'Emergency' },
  ];

  public weekSorter = {
    sunday: 1,
    monday: 2,
    tuesday: 3,
    wednesday: 4,
    thursday: 5,
    friday: 6,
    saturday: 7,
  };

  public weekColors = [
    { ColorId: '1', ColorCode: 'rgb(41, 188, 248)' },
    { ColorId: '2', ColorCode: 'rgb(52, 181, 236)' },
    { ColorId: '3', ColorCode: 'rgb(91, 157, 195)' },
    { ColorId: '4', ColorCode: 'rgb(108, 146, 176)' },
    { ColorId: '5', ColorCode: 'rgb(161, 114, 120)' },
    { ColorId: '6', ColorCode: 'rgb(194, 93, 84)' },
    { ColorId: '7', ColorCode: 'rgb(221, 76, 55)' },
  ];

  public monthSorter = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  public monthColors = [
    { ColorId: '1', ColorCode: 'rgb(41, 188, 248)' },
    { ColorId: '2', ColorCode: 'rgb(52, 181, 236)' },
    { ColorId: '3', ColorCode: 'rgb(63, 174, 225)' },
    { ColorId: '4', ColorCode: 'rgb(91, 157, 195)' },
    { ColorId: '5', ColorCode: 'rgb(108, 146, 176)' },
    { ColorId: '6', ColorCode: 'rgb(161, 114, 120)' },
    { ColorId: '7', ColorCode: 'rgb(140, 126, 142)' },
    { ColorId: '8', ColorCode: 'rgb(179, 102, 100)' },
    { ColorId: '9', ColorCode: 'rgb(185, 98, 94)' },
    { ColorId: '10', ColorCode: 'rgb(194, 93, 84)' },
    { ColorId: '11', ColorCode: 'rgb(209, 83, 68)' },
    { ColorId: '12', ColorCode: 'rgb(221, 76, 55)' },
  ];

  public weekOfMonths = [
    { id: 1, name: 'first' },
    { id: 2, name: 'second' },
    { id: 3, name: 'third' },
    { id: 4, name: 'fourth' },
    { id: 5, name: 'last' },
  ];

  public monthlyDays = [
    { id: 1, name: 'Sunday' },
    { id: 2, name: 'Monday' },
    { id: 3, name: 'Tuesday' },
    { id: 4, name: 'Wednesday' },
    { id: 5, name: 'Thursday' },
    { id: 6, name: 'Friday' },
    { id: 7, name: 'Saturday' },
  ];

  public monthsOfYear = [
    { id: 1, name: 'January' },
    { id: 2, name: 'February' },
    { id: 3, name: 'March' },
    { id: 4, name: 'April' },
    { id: 5, name: 'May' },
    { id: 6, name: 'June' },
    { id: 7, name: 'July' },
    { id: 8, name: 'August' },
    { id: 9, name: 'September' },
    { id: 10, name: 'October' },
    { id: 11, name: 'November' },
    { id: 12, name: 'December' },
  ];

  public maliciousTags = [
    { id: 1, name: 'script' },
    { id: 2, name: 'system' },
    { id: 3, name: 'php' },
    { id: 4, name: 'echo' },
    { id: 5, name: 'java' },
    { id: 6, name: 'alert' },
    { id: 7, name: 'powershell' },
    { id: 8, name: 'notepad' },
    { id: 9, name: 'iframe' },
    { id: 10, name: 'function' },
    { id: 11, name: 'shell' },
  ];

  constructor(
    public dialog: MatDialog,
    private configuration: Configuration,
  ) {}

  public smmodal(_title: string, _Message: string) {
    this.openAlertModal(_title, _Message, true, '300px');
  }

  public mdmodal(_title: string, _Message: string) {
    this.openAlertModal(_title, _Message, true, '600px');
  }

  public lgmodal(_title: string, _Message: any) {
    this.modal.alert().size('lg').showClose(true).title(_title).body(_Message).open();
  }

  public removeInnerSpaces(inputValue: string) {
    if (inputValue !== undefined) {
      inputValue = inputValue.replace(/\s+/g, ' ');
      inputValue = inputValue.trim();
    }
    return inputValue;
  }

  public ScheduleValidator(boundayFromTime: any, boundaryToTime: any, innerFromTime: any, innerToTime: any) {
    const innerFromArray = innerFromTime.split(':');
    let innerFromHrInt = parseFloat(innerFromArray[0]) + parseFloat(innerFromArray[1]) / 60;

    // this value set to check condition is break > 12 hr
    const checkInnerFromHrInt = parseFloat(innerFromArray[0]) + parseFloat(innerFromArray[1]) / 60;

    if (innerFromHrInt < 1) {
      innerFromHrInt += 24;
    }

    const innerToArray = innerToTime.split(':');
    let innerToHrInt = parseFloat(innerToArray[0]) + parseFloat(innerToArray[1]) / 60;
    if (innerToHrInt < 1) {
      innerToHrInt += 24;
    }

    if (innerFromHrInt > innerToHrInt) {
      innerToHrInt += 24;
    }

    const boundayFromArray = boundayFromTime.split(':');
    let boundayFromInt = parseFloat(boundayFromArray[0]) + parseFloat(boundayFromArray[1]) / 60;

    // this condition added for any time between 12:00AM - 12:59AM appear in any break time
    if (boundayFromInt < 1) {
      boundayFromInt += 24;
    }

    const boundayToArray = boundaryToTime.split(':');
    let boundayToInt = parseFloat(boundayToArray[0]) + parseFloat(boundayToArray[1]) / 60;

    if (boundayFromInt > boundayToInt) {
      boundayToInt += 24;
      if (innerFromHrInt <= 12) {
        innerFromHrInt += 24;
        innerToHrInt += 24;
      } else if (
        checkInnerFromHrInt > 12 &&
        checkInnerFromHrInt <= boundayToInt &&
        checkInnerFromHrInt <= boundayFromInt
      ) {
        innerFromHrInt += 24;
        innerToHrInt += 24;
      }
    }

    if (
      innerFromHrInt >= boundayFromInt &&
      innerFromHrInt <= boundayToInt &&
      innerToHrInt >= boundayFromInt &&
      innerToHrInt <= boundayToInt
    ) {
      return true;
    } else {
      return false;
    }
  }

  public checkTimeValidator(checkTime: any, boundayFromTime: any, boundaryToTime: any) {
    const checkTimeArray = checkTime.split(':');
    let checkTimeHrInt = parseFloat(checkTimeArray[0]) + parseFloat(checkTimeArray[1]) / 60;
    if (checkTimeHrInt < 1) {
      checkTimeHrInt += 24;
    }

    const boundayFromArray = boundayFromTime.split(':');
    const boundayFromInt = parseFloat(boundayFromArray[0]) + parseFloat(boundayFromArray[1]) / 60;

    const boundayToArray = boundaryToTime.split(':');
    let boundayToInt = parseFloat(boundayToArray[0]) + parseFloat(boundayToArray[1]) / 60;

    if (boundayFromInt > boundayToInt) {
      boundayToInt += 24;
      if (checkTimeHrInt <= 12) {
        checkTimeHrInt += 24;
      }
    }

    if (checkTimeHrInt >= boundayFromInt && checkTimeHrInt <= boundayToInt) {
      return true;
    } else {
      return false;
    }
  }

  public getTimeDiff(firstInTime: any, lastOutTime: any) {
    let timeval = '00:00:00:00';
    if (lastOutTime != undefined || lastOutTime != null) {
      //// Calculate total time difference in millisecond
      let timeDiffValue = new Date(lastOutTime).getTime() - new Date(firstInTime).getTime();

      //// Convert time from millisecond to total seconds
      timeDiffValue = timeDiffValue / 1000;

      const day = Math.floor(timeDiffValue / 3600 / 24);
      const hour = Math.floor((timeDiffValue / 3600) % 24);
      const min = Math.floor((timeDiffValue / 60) % 60);
      const sec = Math.floor((timeDiffValue % 3600) % 60);

      timeval = day + ':' + hour + ':' + min + ':' + sec;
    }
    return timeval;
  }

  public listViewType(isActive: any) {
    return isActive == 2 ? null : isActive;
  }

  public checkHost(userEmail: string, listVenueAuthentication: any) {
    let check = false;
    if (userEmail !== null) {
      const email = userEmail.split('@')[1];
      if (listVenueAuthentication.indexOf(email) > -1) {
        check = true;
      }
    }
    return check;
  }

  public convert12To24Hr(time: any) {
    const h = time.split(':')[0];
    const m = time.split(':')[1].split(' ')[0];
    const mr = time.split(' ')[1];
    let hours = Number(h);
    const minutes = Number(m);
    const meridian = mr !== undefined ? mr.toLowerCase() : '';

    if (meridian === 'pm' && hours < 12) {
      hours += 12;
    } else if (meridian === 'am' && hours === 12) {
      hours -= 12;
    }

    let formattedTime = this.pad(hours) + ':' + this.pad(minutes);

    if (formattedTime == '00:00') {
      formattedTime = '12:00';
    }
    return formattedTime;
  }

  public convert24To12HrWithMeridian(time: any) {
    if (time != null) {
      if (time.split(':').length >= 2) {
        const h = time.split(':')[0];
        let m = time.split(':')[1];
        if (m === '') {
          m = '00';
        }
        const ampm = h < 12 ? 'AM' : 'PM';
        m = m.length === 1 ? this.pad(m) : m;
        let newTime = '';

        if (h === 0) {
          newTime = '12' + ':' + m;
        } else if (h > 12) {
          newTime = this.pad(h - 12) + ':' + m;
        } else {
          newTime = h.length === 1 ? this.pad(h) : h + ':' + m;
        }
        return newTime + ' ' + ampm;
      }
    }
  }

  public pad(number: any) {
    return (number < 10 ? '0' : '') + number;
  }

  public ConvertDateTimeTZFormat(dateValue: any) {
    if (dateValue) {
      return new Date(new Date(dateValue).toString().split('GMT')[0] + ' UTC').toISOString().slice(0, -1);
    }
  }

  public LocaltoVenueTime(timeDiff: any) {
    if (timeDiff) {
      let timeSplit = timeDiff.toString().split('.');
      if (timeSplit.length == 2) {
        if (timeSplit[1].length == 1) {
          timeDiff = timeSplit[0] + '.' + timeSplit[1] + '0';
        }
      }
      let timeSplit2 = timeDiff.toString().split('.');

      let CurrentDateTime = new Date();
      let utcCurrentDateTime = new Date(
        CurrentDateTime.getUTCFullYear(),
        CurrentDateTime.getUTCMonth(),
        CurrentDateTime.getUTCDate(),
        CurrentDateTime.getUTCHours(),
        CurrentDateTime.getUTCMinutes(),
        CurrentDateTime.getUTCSeconds(),
      );
      if (timeSplit2.length == 2) {
        utcCurrentDateTime.setHours(
          utcCurrentDateTime.getHours() + parseInt(timeSplit2[0]),
          utcCurrentDateTime.getMinutes() + parseInt(timeSplit2[1]),
        );
      } else {
        utcCurrentDateTime.setHours(utcCurrentDateTime.getHours() + parseInt(timeSplit2[0]));
      }

      let nd = this.ConvertDateTimeTZFormat(utcCurrentDateTime);
      return nd;
    }
  }

  ///////////////// For Calendar  ////////////////

  // public getAlertTitle(alertType: AlertType) {
  //     switch (alertType) {
  //         case AlertType.check_circle_outline:
  //             return 'check_circle_outline';
  //         case AlertType.WARNING:
  //             return 'WARNING';
  //         case AlertType.error_outline:
  //             return 'error_outline';
  //         case AlertType.help_outline:
  //             return 'help_outline';
  //         case AlertType.delete_forever:
  //             return 'delete_forever';
  //     }
  // }

  public getColor(alertColor: AlertColor) {
    switch (alertColor) {
      case AlertColor.green:
        return 'green';
      case AlertColor.red:
        return 'red';
    }
  }

  public openAlertModal(title: string, message: string, flag, width) {
    const flagValue = flag;
    const dialogRef = this.dialog.open(AlertComponent, {
      width: width,
      data: new ModalAlertData({
        // icon: this.getAlertTitle(alertType),
        // color: this.getColor(alertColor),
        title: title,
        content: message,
        closeButtonLabel: 'OK',
        flagVal: flagValue,
        // alertType: alertType,
        // alertColor: alertColor,
      }),
    });

    dialogRef.afterClosed().subscribe((_result) => {
      // console.log('After Close Modal', result);
    });
  }

  public openInfoModal(title: string, message: string) {
    this.openAlertModal(title, message, false, '300px');
  }

  public openWarningModal(title: string, message: string) {
    this.openAlertModal(title, message, false, '300px');
  }

  public openErrorModal(title: string, message: string) {
    this.openAlertModal(title, message, false, '300px');
  }

  public openConfirmationModal(title: string, message: string) {
    this.openAlertModal(title, message, false, '300px');
  }

  public openDeleteModal(title: string, message: string) {
    this.openAlertModal(title, message, false, '300px');
  }

  public openConfirmModal(message: string, callBackFunction: Function) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '300px',
      data: new ModalConfirmData({
        title: 'CONFIRM',
        content: message,
        confirmButtonLabel: 'Confirm',
        closeButtonLabel: 'Close',
      }),
    });
    dialogRef.afterClosed().subscribe((result) => callBackFunction(result));
  }

  public openCustomConfirmModal(
    modalTitle: string,
    message: string,
    confirmButtonLabel: string,
    closeButtonLabel: string,
    callBackFunction: Function,
  ) {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '300px',
      data: new ModalConfirmData({
        title: modalTitle,
        content: message,
        confirmButtonLabel: confirmButtonLabel,
        closeButtonLabel: closeButtonLabel,
      }),
    });
    return dialogRef.afterClosed().subscribe((result) => callBackFunction(result));
  }

  public checkVenueFeatureConfiguration(featureCode: any) {
    let isValidCode = false;
    if (this.configuration.venueFeaturesConfig) {
      this.retriveModules = this.configuration.venueFeaturesConfig;

      if (this.retriveModules != null && this.retriveModules.length > 0) {
        this.retriveModules.forEach(function (item) {
          item.ModuleFeatures.forEach(function (moduleFeature) {
            moduleFeature.ModuleFeatureTypes.forEach(function (ModuleFeatureType) {
              ModuleFeatureType.FeatureConfigurations.forEach(function (FeatureConfiguration) {
                if (FeatureConfiguration.FeatureConfigurationCode == featureCode) {
                  isValidCode = true;
                }
              });
            });
          });
        });
      }
    }
    return isValidCode;
  }

  public checkVenueModuleConfiguration(moduleCode: any) {
    let isValidCode = false;
    if (this.configuration.venueFeaturesConfig) {
      this.retriveModules = this.configuration.venueFeaturesConfig;

      if (this.retriveModules != null && this.retriveModules.length > 0) {
        this.retriveModules.forEach(function (item) {
          if (item.ModuleCode == moduleCode) {
            isValidCode = true;
          }
        });
      }
    }
    return isValidCode;
  }

  public checkLocationFeature(featureList: any, featureCode: any) {
    let isValidCode = false;
    if (featureList != null) {
      featureList.forEach(function (FeatureConfiguration) {
        if (
          FeatureConfiguration.FeatureConfigurationCode != null &&
          FeatureConfiguration.FeatureConfigurationCode == featureCode
        ) {
          isValidCode = true;
        }
      });
    }
    return isValidCode;
  }

  public dateParseFromTime(time: any) {
    return Date.parse('01/01/2020 ' + time);
  }

  public checkFeatureConfigTimeValueValidation(featureValues: any, formName: string) {
    let isValidate = true;
    if (featureValues != null && featureValues.length > 0) {
      for (let m = 0; m < featureValues.length; m++) {
        if (featureValues[m].FeatureConfigurationValue != null && featureValues[m].FeatureConfigurationValue != '') {
          if (!this.venueNumericRegex.test(featureValues[m].FeatureConfigurationValue)) {
            this.smmodal(
              formName,
              '"' + featureValues[m].FeatureConfigurationName + '"' + ' textbox value accept only numeric value.',
            );
            isValidate = false;
            break;
          }
        }
      }
    }
    return isValidate;
  }

  public signageUrl(locationId: number, venueName: string) {
    let baseURL = window.location.href.split('#')[0];
    if (venueName == 'ATL') {
      return baseURL + '#' + '/signage-atl/' + locationId;
    } else if (venueName == 'DFW') {
      return baseURL + '#' + '/signage-dfw/' + locationId;
    } else {
      return baseURL + '#' + '/signage/' + locationId;
    }
  }

  public listQuestionResponseSectionHeadings = [
    { id: 'SadReasons', name: 'Image Choice (Sad Reasons)' },
    { id: 'HappyOrSad', name: 'Image Choice (Happy or Sad)' },
    { id: 'FreeFromText', name: 'Free Form Text' },
  ];

  stripScript(data: any) {
    const originaldata = JSON.stringify(data);
    const updateddata = originaldata.replace(/<[^>]*>/g, '');
    return JSON.parse(updateddata);
  }

  public TouchlessFeedbackPageUrl(locationId: number, _venueName: string) {
    let baseURL = this.configuration.TouchlessFeedbackAppBaseURL;
    return baseURL + locationId;
  }

  public CleaningAlertRequestPageUrl(locationId: number, _venueName: string) {
    let baseURL = this.configuration.cleaningAlertRequestURL;
    return baseURL + locationId;
  }

  public ordinal_suffix_of(i: any) {
    let j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + 'st';
    }
    if (j == 2 && k != 12) {
      return i + 'nd';
    }
    if (j == 3 && k != 13) {
      return i + 'rd';
    }
    return i + 'th';
  }

  public sortByLocaleCompare(sortArray: any) {
    return sortArray.sort((a, b) => a.name.localeCompare(b.name));
  }

  public getMonth(date: any) {
    const currntMonth = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const month = new Date(date);
    return currntMonth[month.getMonth()];
  }

  public getDay(date: any) {
    const week = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
    const d = new Date(date.replace(/-/g, '/').replace(/T.+/, ''));
    const day = week[d.getDay()];
    return day;
  }

  public colorGenerator = (colorLength: any) => {
    let colorRange: any[] = [];
    let t,
      f,
      m = 1;
    f = 'rgba(221, 76, 55,1)'; // Top Color
    t = 'rgba(41, 188, 248,1)'; // Bottom Color
    let resultColor: any;
    colorRange = [];

    for (let i = 0; i < colorLength; i++) {
      resultColor = this.pSBC(i / colorLength, f, t, m);
      colorRange.push(resultColor);
    }
    return colorRange;
  };

  public pSBC = (p, c0, c1, l) => {
    let r,
      g,
      b,
      P,
      f,
      t,
      h,
      m = Math.round,
      a: any = typeof c1 == 'string';

    if (
      typeof p != 'number' ||
      p < -1 ||
      p > 1 ||
      typeof c0 != 'string' ||
      (c0[0] != 'r' && c0[0] != '#') ||
      (c1 && !a)
    ) {
      return null;
    }
    (h = c0.length > 9),
      (h = a ? (c1.length > 9 ? true : c1 == 'c' ? !h : false) : h),
      (f = this.pSBCr(c0)),
      (P = p < 0),
      (t = c1 && c1 != 'c' ? this.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 }),
      (p = P ? p * -1 : p),
      (P = 1 - p);

    if (!f || !t) {
      return null;
    }

    if (l) {
      (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
    } else {
      (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
        (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
        (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
      (a = f.a), (t = t.a), (f = a >= 0 || t >= 0), (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
    }

    if (h) {
      return 'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')';
    } else {
      return (
        '#' +
        (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
          .toString(16)
          .slice(1, f ? undefined : -2)
      );
    }
  };

  public pSBCr = (d) => {
    let r: any;
    let g: any;
    let b: any;
    let a: any;
    const i = parseInt,
      m = Math.round;
    let n = d.length,
      x = { r, g, b, a };

    if (n > 9) {
      const [r, g, b, a] = (d = d.split(','));
      n = d.length;
      if (n < 3 || n > 4) {
        return null;
      }
      (x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))), (x.g = i(g)), (x.b = i(b)), (x.a = a ? parseFloat(a) : -1);
    } else {
      if (n == 8 || n == 6 || n < 4) {
        return null;
      }

      if (n < 6) {
        d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '');
        d = i(d.slice(1), 16);
      }

      if (n == 9 || n == 5) {
        (x.r = (d >> 24) & 255), (x.g = (d >> 16) & 255), (x.b = (d >> 8) & 255), (x.a = m((d & 255) / 0.255) / 1000);
      } else {
        (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
      }
    }
    return x;
  };

  public toHHMMSS(totalSeconds: any) {
    if (!Number.isInteger(totalSeconds)) {
      totalSeconds = Math.round(totalSeconds);
    }

    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    let seconds = totalSeconds - hours * 3600 - minutes * 60;

    // round seconds
    seconds = Math.round(seconds * 100) / 100;

    let result = hours < 10 ? '0' + hours : hours;
    result += ':' + (minutes < 10 ? '0' + minutes : minutes);
    result += ':' + (seconds < 10 ? '0' + seconds : seconds);
    return result;
  }

  getTimeSlots(start, end, IntervalMinutes) {
    let startTime = moment(start, 'HH:mm');
    let endTime = moment(end, 'HH:mm');

    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }

    let timeStops = [];

    while (startTime <= endTime) {
      timeStops.push(moment(startTime).format('HH:mm'));
      startTime.add(IntervalMinutes, 'minutes');
    }
    return timeStops;
  }

  public isLeapYear(year: number) {
    return year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;
  }

  public checkUploadedFileName(name: any) {
    let returnValue = false;
    for (let i = 0; i < name.length; i++) {
      if (
        name[i] == '<' ||
        name[i] == '>' ||
        name[i] == '%' ||
        name[i] == ':' ||
        name[i] == '?' ||
        name[i] == '/' ||
        name[i] == '*' ||
        name[i] == '"' ||
        name[i] == '|' ||
        name[i] == '#' ||
        name[i] == '{' ||
        name[i] == '}' ||
        name[i] == '&' ||
        name[i] == '='
      ) {
        returnValue = true;
        break;
      } else {
        returnValue = false;
      }
    }
    return returnValue;
  }

  keyPress($event: any) {
    if ($event.key == '+' || $event.key == '-' || $event.key == 'e' || $event.target.value.length + 1 > 4) {
      $event.preventDefault();
      return false;
    }
  }

  /**
   * Get Formatted time to bind in the timepicker
   */
  getFormattedTime(date: any) {
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric', // Use 'numeric' or '2-digit' for hour format
      minute: 'numeric', // Use 'numeric' or '2-digit' for minute format
      hour12: true, // Include AM/PM if using 12-hour format (adjust based on your locale)
    };
    return date.toLocaleTimeString('en-US', options);
  }
}
