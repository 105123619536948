import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { User } from '../../usermanagement/user/user.model';
import { VenueModule } from '../../venuemanagement/venue/venue.module';
import { GatewayComponent } from './components/getway.component';
import { GatewayRoutes } from './getway.routes';

@NgModule({
  imports: [CommonModule, GatewayRoutes, FormsModule, NgxPaginationModule, HomeModule],

  declarations: [GatewayComponent],
})
export class GatewayModule extends GenericEntity {
  public GatewayId: number;
  public GatewayCPId: string;
  public GatewayName: string;
  public GatewayIdentifier: string;
  public Venue: VenueModule;
  public User: User;
  public Range: any;
  public IsOnline: number;
  public LocationNames: string;
  public LastUpdatedDate: any;
  public LastSeenDate: any;
}
