import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';
import { UserLicenseRequestLogRoutes } from './userlicenserequestlog.routes';
import { UserLicenseRequestLogComponent } from './components/userlicenserequestlog.component';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, UserLicenseRequestLogRoutes, HomeModule],
  declarations: [UserLicenseRequestLogComponent],
})
export class UserLicenseRequestLogModule extends GenericEntity {
  public LicenseRequestId: number;
  public TraxRequestId: string;
  public SalesForceNumber: string;
  public UserId: number;
  public FirstName: string;
  public LastName: string;
  public RequestedUserLicence: number;
  public CurrentLicenseCount: number;
  public RequestDate: any;
  public RequestStatus: string;

  public SalesforceLicenseRequestId: any;
  public CurrentInternalLicenseCount: number;
  public CurrentExternalLicenseCount: number;
  public CurrentTotalLicenseCount: number;
  public RequestedInternalLicenseCount: number;
  public RequestedExternalLicenseCount: number;
  public RequestedTotalLicenseCount: number;
  public SalesforceContractId: string;
  public IsUserAgree: number;
}

export class SalesforceUserLicenseDetails extends GenericEntity {
  public SalesforceUserLicenseDetailId: number;
  public LicenseRequestId: number;
  public LicenseRequestCPId: number;
  public RequestedUserLicense: number;
  public TotalUserLicense: number;
  public RequestStatus: string;
}

export class SProviderSalesforceRequestResponseLogModule extends GenericEntity {
  public SalesforceRequestResponseLogId: any;
  public TraxRequestId: string;
  public CurrentInternalLicenseCount: number;
  public CurrentExternalLicenseCount: number;
  public CurrentTotalLicenseCount: number;
  public RequestedInternalLicenseCount: number;
  public RequestedExternalLicenseCount: number;
  public RequestedTotalLicenseCount: number;
  public SalesforceContractId: string;
  public RequestDate: any;
  public RequestStatus: string;
  public ExternalUserLicenseCount: number;
  public InternalUserLicenseCount: number;
  public TotalUserLicenseCount: number;
}

export class ServiceProviderActiveUserLicensesModule extends GenericEntity {
  public ServiceProviderId: number;
  public UserId: number;
  public UserRoleId: number;
  public ContextServiceProviderId: number;
  public ContextCorporationId: number;
  public ContextVenueId: number;
  public TotalUserLicenseCount: number;
  public TotalInternalUserLicenseCount: number;
  public TotalExternalUserLicenseCount: number;
  public TotalInternalActiveUsersPresent: number;
  public TotalExternalActiveUsersPresent: number;
  public ServiceProviderName: string;
  public InternalUserList: InternalUserModule[];
  public ExternalUserList: ExternalUserModule[];

  public InternalUserLicenseFee: any;
  public ExternalUserLicenseFee: any;
  public BillingDay: number;
  public ContractId: number;
}

export class InternalUserModule extends GenericEntity {
  public UserId: number;
  public FirstName: string;
  public LastName: string;
  public UserRoleId: number;
  public UserRole: string;
  public Email: string;
  public Corporations: string;
  public Venues: string;
  public ServiceProviderId: number;
  public ServiceProviderName: string;
  public IsUserDelete: any;
  public UserType: string;
}

export class ExternalUserModule extends GenericEntity {
  public UserId: number;
  public FirstName: string;
  public LastName: string;
  public UserRoleId: number;
  public UserRole: string;
  public Email: string;
  public Corporations: string;
  public Venues: string;
  public ServiceProviderId: number;
  public ServiceProviderName: string;
  public IsUserDelete: any;
  public UserType: string;
}

export class InternalExternalUserModule extends GenericEntity {
  public UserId: number;
  public FirstName: string;
  public LastName: string;
  public UserRoleId: number;
  public UserRole: string;
  public Email: string;
  public Corporations: string;
  public Venues: string;
  public ServiceProviderId: number;
  public ServiceProviderName: string;
  public IsUserDelete: any;
  public UserType: string;
}

export class SProviderSalesforceLicenseRequestModule extends GenericEntity {
  public SalesforceLicenseRequestId: any;
  public TraxRequestId: string;
  public CurrentInternalLicenseCount: number;
  public CurrentExternalLicenseCount: number;
  public CurrentTotalLicenseCount: number;
  public RequestedInternalLicenseCount: any;
  public RequestedExternalLicenseCount: any;
  public RequestedTotalLicenseCount: number;
  public SalesforceContractId: string;
  public RequestDate: any;
  public RequestStatus: string;
  public SProviderSalesforceRequestResponseLogs: SProviderSalesforceRequestResponseLogModule[];
  public IsUserAgree: number;
  public RequestType: number;
}
