import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../home/home.module';
import { GenericEntity } from '../shared/genericentity/genericentity';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { CityModule } from '../venuemanagement/city/city.module';
import { CountryModule } from '../venuemanagement/country/country.module';
import { StateModule } from '../venuemanagement/state/state.module';
import { ServiceProviderComponent } from './components/serviceprovider.component';
import { ServiceProviderRoutes } from './serviceprovider.routes';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import {
  DefaultTreeviewEventParser,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewI18n,
  TreeviewI18nDefault,
  TreeviewModule,
} from 'ngx-treeview';

@NgModule({
  imports: [
    CommonModule,
    ServiceProviderRoutes,
    FormsModule,

    NgxPaginationModule,
    HomeModule,
    NgxMaskModule.forRoot(options),
    TreeviewModule,
  ],
  providers: [
    TreeviewConfig,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
  ],
  declarations: [ServiceProviderComponent],
})
export class ServiceProviderModule extends GenericEntity {
  public SpFirstName: string;
  public SpLastName: string;
  public Address1: string;
  public Address2: string;
  public PhoneNumber: string;
  public MobileNumber: string;
  public Country: CountryModule;
  public State: StateModule;
  public City: CityModule;
  public Zipcode: string;
  public Modules: Module[] = [];
  public Provider: AuthProviderModule;
  public LogoImageName: string;
  public LogoImageType: string;

  public ThroughputSummaryURL: string;
  public CleaningSummaryURL: string;
  public SurveySummaryURL: string;
  public PredectiveSummaryURL: string;
  public InspectionSummaryURL: string;
  public ZurnFlushValveSummaryReportURL: string;
  public GreenColourMin?: number;
  public GreenColourMax?: number;
  public RedColourMin?: number;
  public RedColourMax?: number;
  public YellowColourMin?: number;
  public YellowColourMax?: number;

  public SPLicenseKey: string;
  public SPLicenseExpiryDate: any;
  public AllowToShareUser: any;

  public SalesforceContractId: string;
  public InternalUserLicenseCount: number;
  public ExternalUserLicenseCount: number;
  public TotalUserLicenseCount: number;
  public InternalUserLicenseFee: any;
  public ExternalUserLicenseFee: any;
  public BillingDay: number;
  public AttachmentURL: string;

  public ClientID: string;
  public ClientSecret: string;
  public KeysURL: string;
  public MetadataURL: string;
  public RedirectURL: string;
  public AuthoriseURL: string;
  public TokenURL: string;
  public GrantType: string;
  public AzureOAuthDetails: AzureOAuthDetails;
  public IsSSOEnabled: any;
  public Issuer: string;
  public EndSessionUrl: string;
}

export class Module extends GenericEntity {
  public ModuleId: number;
  public ModuleName: string;
  public ModuleCode: string;
  public IsActive: any;
  public IsSelected: any;
  public ModuleFeatures: ModuleFeature[];
  public ModuleFeature: ModuleFeature;
}
export class AzureOAuthDetails extends GenericEntity {
  public ClientID: string;
  public ClientSecret: string;
  public KeysURL: string;
  public MetadataURL: string;
  public RedirectURL: string;
  public AuthoriseURL: string;
  public TokenURL: string;
  public GrantType: string;
  public Issuer: string;
  public EndSessionUrl: string;
}

export class ModuleFeature extends GenericEntity {
  public ModuleFeatureId: number;
  public ModuleFeatureName: string;
  public ModuleFeatureCode: string;
  public IsSelected: any;
  public IsActive: any;
  public ModuleFeatureTypes: ModuleFeatureType[];
  public ModuleFeatureType: ModuleFeatureType;
}

export class ModuleFeatureType extends GenericEntity {
  public FeatureTypeCode: string;
  public FeatureTypeName: string;
  public FeatureTypeDataType: string;
  public FeatureConfigurations: FeatureConfiguration[];
  public FeatureConfiguration: FeatureConfiguration;
}

export class FeatureConfiguration extends GenericEntity {
  public FeatureConfigurationId: number;
  public FeatureConfigurationName: string;
  public FeatureType: string;
  public FeatureTypeDataType: string;
  public FeatureConfigurationCode: string;
  public FeatureConfigurationDataType: string;
  public FeatureConfigurationValue: string;
  public ConfigurationSectionCode: string;
  public IsActive: any;
}

export class AuthProviderModule extends GenericEntity {
  public AuthProviderId: number;
  public AuthProviderName: string;
}
