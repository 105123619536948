import { CustomFooterComponent } from './components/customfooter/customfooter.component';
//import { NavigationComponent } from './components/navigation/navigation.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { NgxPaginationModule } from 'ngx-pagination';
import { AlertComponent } from './components/alertmodal/alertmodal.component';
import { ConfirmComponent } from './components/confirmmodal/confirmmodal.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    // ModalModule.forRoot(),
    // BootstrapModalModule,
    FormsModule,
    NgxPaginationModule,
    MatDialogModule,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    ReactiveFormsModule,
  ],
  declarations: [CustomFooterComponent, AlertComponent, ConfirmComponent],
  exports: [CustomFooterComponent, MatDialogModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  public pw1 = 'S)8#d@34';
}

export class StateMaintain {
  public pageName: string;
  public pageState: number;
  public pageSize: number;
  public statusState: number;
}
