import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';
import {
  TreeviewModule,
  TreeviewConfig,
  TreeviewI18nDefault,
  TreeviewI18n,
  DefaultTreeviewEventParser,
  TreeviewEventParser,
} from 'ngx-treeview';

import { UserRoleTemplateRoutes } from './userroletemplate.routes';
import { UserRoleTemplateComponent } from './components/userroletemplate.component';
import { ParentPermissions } from '../role/role.module';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, UserRoleTemplateRoutes, HomeModule, TreeviewModule],
  providers: [
    TreeviewConfig,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
  ],
  declarations: [UserRoleTemplateComponent],
})
export class UserRoleTemplateModule extends GenericEntity {
  public UserRoleTemplateId: number;
  public UserRoleTemplateCPId: string;
  public RoleTemplateName: string;
  public ParentPermissionsList: ParentPermissions[];
  public RoleTemplatePermissionList: UserRoleTemplatePermissions[];
}

export class UserRoleTemplatePermissions extends GenericEntity {
  public UserRoleTemplatePermissionsId: any;
  public PAccess: number;
  public UserRoleTemplateId: number;
  public UserRoleTemplateCPId: string;
  public RoleTemplateName: string;
  public Permission: ParentPermissions;
}
