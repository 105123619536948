import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { HomeModule } from '../home/home.module';
import { OnlyNumber } from '../shared/directives/commandirectives.service';
import { GenericEntity } from '../shared/genericentity/genericentity';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { CalendarRoutes } from './calendar.routes';
import { CalendarComponent } from './component/calendar.component';

import { CorporationModule } from '../corporationmanagement/corporation/corporation.module';
import { LocationModule } from '../staffscheduling/location/location.module';
import { VenueModule } from '../venuemanagement/venue/venue.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    CalendarRoutes,
    HomeModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule,
    MatDialogModule,
    MatButtonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [OnlyNumber, CalendarComponent],
  exports: [CalendarComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CalModule extends GenericEntity {
  public ScheduleId: any;
  public ScheduleCPId: string;
  public ScheduleTitle = '';
  public ScheduleDescription = '';

  public StartDate: any;
  public EndDate: any;

  public StartTime: any;
  public EndTime: any;
  public IsAdHoc = 0;
  public IsFullDayEvent = 0;
  public IsRecurring = 0;
  public IsPreviousDate = false;
  public IsFullDayEndDate: any;
  public TimeZone: any;
  public VenueTimezoneCurrentDate: any;
  public TimezoneCurrentDate: any;

  public VenueEndTime: any;
  public VenueStartTime: any;

  public CorporationId = 0;
  public CorporationName = '';

  public Venues: VenueModule[] = [];
  public Venue: VenueModule;

  public Location: LocationModule;

  public AssigneTo: ScheduleUser;
  public Supervisor: ScheduleUser;
  public Inspector: ScheduleUser;

  public venueTimezone: VenueModule;
  public venueTimezones: VenueModule[] = [];

  public ParentScheduleId = 0;
  public RecurringPattern: RecurringPattern;
  public UserId = 0;
  public Company: Company[] = [];
  public RecurringDates: RecurringDates[] = [];

  public IsEntireSeriesModified = 0;
  public IsWeekdays = 0;
  public IsSchedulePreviousDate = false;
  public Corporation: CorporationModule;
  public ScheduleType: ScheduleTypeModule;

  public ScheduleIdOutPut: number;
  public ServiceProviderId: number;

  public IsFowllowUp: number;
  public InspectionId: number;

  public Latitude: any;
  public Longitude: any;
  public WorkorderId: number;

  public IsBillableWork = 0;

  public IsBetweenSelectedTime: any;
  public IntervalMinutes: number;
  public QcId: number;
}

export class RecurringPattern {
  RecurringPatternId = 0;
  ScheduleId = 0;
  RecurringTypeId = 0;
  RecurringTypeName = '';
  SeperationCount = 0;
  Occurences = 0;
  WeekOfMonth = 0;
  DayOfMonth = 0;
  MonthOfYear = 0;
  Sunday = 0;
  Monday = 0;
  Tuesday = 0;
  Wednesday = 0;
  Thusday = 0;
  Friday = 0;
  Saturday = 0;
}

export class Company {
  companyId = 0;
}

export class RecurringDates {
  StartDate = '';
  IsPreviousDate = false;
}

export class ScheduleUser {
  UserId = 0;
  FirstName = '';
  LastName = '';
}

export class ScheduleTypeModule extends GenericEntity {
  public ScheduleTypeId: number;
  public ScheduleTypeName: string;
  public ScheduleTypeCode: string;
}

export class ScheduleIntervalMinutes {
  public ScheduleIntervalMinutesId: number;
  public ScheduleIntervalName: string;
  public ScheduleIntervalValue: any;
  public ScheduleIntervalCode: string;
}

export class DisplaySlot {
  public Time: any;
  public RowId: number;
}
