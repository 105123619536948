<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-5 col-md-12 col-sm-12 form-heading svgRestroom">
        <svg
          class="restroomOvr1"
          enable-background="new 0 0 218.356 218.356"
          version="1.1"
          viewBox="0 0 218.36 218.36"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m142.68 0h-91.73c-16.143 0-29.278 13.135-29.278 29.278v159.8c0 16.143 13.135 29.278 29.278 29.278h116.46c16.143 0 29.275-13.135 29.275-29.278v-128.71l-54.009-60.369zm4.208 27.525 25.179 28.141h-25.179v-28.141zm34.587 161.55c-3e-3 7.754-6.308 14.061-14.061 14.061h-116.46c-7.753 0-14.061-6.307-14.061-14.061v-159.8c0-7.753 6.307-14.061 14.061-14.061h80.722v55.667h49.803v118.19z"
          />
          <rect x="92.758" y="161.8" width="70.879" height="15.217" />
          <rect x="92.758" y="125.45" width="70.879" height="15.217" />
          <rect x="92.758" y="88.957" width="70.879" height="15.217" />
          <polygon points="61.909 96.664 56.899 91.845 49.865 99.152 62.117 110.94 79.829 92.88 72.589 85.776" />
          <polygon points="61.909 133.16 56.899 128.34 49.865 135.65 62.117 147.44 79.829 129.38 72.589 122.27" />
          <polygon points="61.909 169.51 56.899 164.69 49.865 172 62.117 183.79 79.829 165.72 72.589 158.62" />
        </svg>
        <span class="list-heading" *ngIf="formList">{{ pageTitle }}</span>
        <span class="list-heading" *ngIf="!formList">QUALITY CONTROL CHECK</span>
      </div>

      <div class="col-lg-7 col-sm-12 col-md-12" *ngIf="formList">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-2 col-xs-12 pull-right list-heading pageSize">
          <ngx-atlas-select
            (model)="QCWorkOrderPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-7 col-md-7 col-sm-8 col-xs-12 input-group pull-right p-search">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="searchWorkorder(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="formList">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div *ngIf="!hasData() && Isdataloaded" class="col-lg-12 col-md-12 col-sm-12">
        <h3 class="listerror">Sorry no data found!</h3>
      </div>
      <div class="box-body table-responsive" *ngIf="hasData()">
        <table class="table table-hover table-responsive">
          <thead>
            <tr>
              <!--<th class="table-sort-header" (click)="sortColumn('QcId',columnSortOrder)" *ngIf="isSupervisor">Quality Check # <span *ngIf="selectedColumnName=='QcId' && columnSortOrder=='DESC'"><i class="fa fa-sort-up"></i></span><span *ngIf="selectedColumnName=='QcId' && columnSortOrder=='ASC'"><i class="fa fa-sort-down"></i></span></th>
                            <th class="table-sort-header" (click)="sortColumn('WorkorderId',columnSortOrder)" *ngIf="isSupervisor">Work Order # <span *ngIf="selectedColumnName=='WorkorderId' && columnSortOrder=='DESC'"><i class="fa fa-sort-up"></i></span><span *ngIf="selectedColumnName=='WorkorderId' && columnSortOrder=='ASC'"><i class="fa fa-sort-down"></i></span></th>-->
              <th>Quality Check #</th>
              <th>Work Order #</th>
              <th class="table-sort-header" (click)="sortColumn('Title', columnSortOrder)">
                Title
                <span *ngIf="selectedColumnName == 'Title' && columnSortOrder == 'DESC'"
                  ><i class="fa fa-sort-up"></i></span
                ><span *ngIf="selectedColumnName == 'Title' && columnSortOrder == 'ASC'"
                  ><i class="fa fa-sort-down"></i
                ></span>
              </th>
              <th class="table-sort-header" (click)="sortColumn('Status', columnSortOrder)">
                Status
                <span *ngIf="selectedColumnName == 'Status' && columnSortOrder == 'DESC'"
                  ><i class="fa fa-sort-up"></i></span
                ><span *ngIf="selectedColumnName == 'Status' && columnSortOrder == 'ASC'"
                  ><i class="fa fa-sort-down"></i
                ></span>
              </th>
              <th class="table-sort-header" (click)="sortColumn('ScheduleStartDate', columnSortOrder)">
                Planned Start Date
                <span *ngIf="selectedColumnName == 'ScheduleStartDate' && columnSortOrder == 'DESC'"
                  ><i class="fa fa-sort-up"></i></span
                ><span *ngIf="selectedColumnName == 'ScheduleStartDate' && columnSortOrder == 'ASC'"
                  ><i class="fa fa-sort-down"></i
                ></span>
              </th>
            </tr>
          </thead>

          <tbody
            *ngFor="
              let workorder of workorders | paginate: { itemsPerPage: size, currentPage: page, totalItems: count }
            "
          >
            <tr (click)="getMaintenanceWorkorderQCById(workorder.QcId)">
              <td>{{ workorder.WorkorderId }}</td>
              <td>{{ workorder.WorkorderId }}</td>
              <td>{{ workorder.Title }}</td>
              <td>{{ workorder.WorkorderStatus.Status }}</td>
              <td>{{ workorder.AddedOn | date: 'MM/dd/yyyy' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination-controls
        class="pagination pull-right"
        (pageChange)="pageChanged($event)"
        *ngIf="hasData() && Isdataloaded && count > 10"
      ></pagination-controls>
    </div>
  </div>

  <div class="row" *ngIf="!formList">
    <form #cleaningQCWOForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="isReadOnlyField">
          <label for="WorkorderId">Work Order #</label>
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping">{{ workorder.WorkorderId }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="isReadOnlyField">
          <label for="technician">Technician</label>
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping">{{ workorder.ReportedBy.FirstName }} {{ workorder.ReportedBy.LastName }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="isReadOnlyField">
          <label for="title">Title</label>
          <div>
            <div class="clipping-wrapper">
              <p class="readOnly clipping">{{ workorder.Title }}</p>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="col-sm-12 col-md-12 col-lg-12 table-top" *ngIf="workorderInspections.length > 0">
        <div class="col-sm-12 col-md-12 col-lg-12 presetquestions table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th><span id="red-circle">&#x25CF;</span> Task</th>
                <th>QC Input</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let woInspection of workorderInspections">
                <tr>
                  <td style="cursor: auto">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Task Details Here"
                      [(ngModel)]="woInspection.InspectionTask"
                      name="inspectionTask_{{ woInspection.Sequence }}_{{
                        woInspection.WorkorderInspectionId ? woInspection.WorkorderInspectionId : null
                      }}"
                      #inspectionTask="ngModel"
                      maxlength="249"
                      [pattern]="utilitiesService.alphanumericRegex"
                      required
                    />
                    <div
                      *ngIf="inspectionTask.errors && (inspectionTask.dirty || inspectionTask.touched)"
                      class="alert alert-danger"
                    >
                      <div [hidden]="!inspectionTask.errors.required" class="text-danger">Task is required</div>
                      <div [hidden]="!inspectionTask.errors.pattern" class="text-danger">
                        {{ utilitiesService.alphanumericRegexMessage }}
                      </div>
                    </div>
                  </td>
                  <td style="cursor: auto">
                    <label class="radio-inline">
                      <input
                        type="radio"
                        name="optradio_{{ woInspection.Sequence }}_{{
                          woInspection.WorkorderInspectionId ? woInspection.WorkorderInspectionId : null
                        }}"
                        value="S"
                        [(ngModel)]="woInspection.QcInput"
                      />Satisfactory
                    </label>
                    <label class="radio-inline">
                      <input
                        type="radio"
                        name="optradio_{{ woInspection.Sequence }}_{{
                          woInspection.WorkorderInspectionId ? woInspection.WorkorderInspectionId : null
                        }}"
                        value="U"
                        [(ngModel)]="woInspection.QcInput"
                      />Unsatisfactory
                    </label>
                  </td>
                  <td class="table-center" *ngIf="woInspection.WorkorderInspectionId == null">
                    <button
                      class="btn btn-purple"
                      (click)="removeTasks(woInspection.Sequence)"
                      *ngIf="woInspection.Sequence < 0"
                    >
                      <i class="fa fa-times"></i>
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="col-sm-12 col-md-12 col-lg-12">
          <input type="button" class="btn btn-type pull-left btn-save" value="+ Add Task" (click)="addTask()" />
        </div>
      </div>

      <hr />

      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <label for="comment"> Comment</label>
          <div>
            <textarea
              type="text"
              rows="2"
              placeholder="Comment"
              name="comment"
              class="form-control"
              [(ngModel)]="qcWorkorder.QcComment"
            ></textarea>
          </div>
        </div>
      </div>

      <hr />

      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-6 col-md-6 col-sm-6 form-group">
          <label for="categoryname">Choose Files</label>
          <div>
            <input
              #fileInput
              type="file"
              id="chooseFile"
              class="btn btn-default form-control"
              value="Choose Files"
              multiple
              (change)="fileChange($event)"
              accept="image/*"
            />

            <span id="selectedtext" style="color: black">Selected Files below</span>
            <div
              class="col-lg-12 col-md-12 col-sm-12 form-group"
              style="display: block"
              *ngFor="let browsfiles of multiFiles; let i = index"
            >
              <div class="col-lg-1 col-md-1 col-sm-1">
                <span>{{ i + 1 }}) </span>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6">
                <span>{{ browsfiles.name }}<br /></span>
              </div>
              <div class="col-lg-2 col-md-2 col-sm-2">
                <button class="btn btn-purple" (click)="removeBrowsFile(browsfiles.name)">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div
            class="col-lg-3 col-md-3 col-sm-3"
            *ngFor="let attachments of qcWorkorder.WorkorderQCImages"
            style="margin-top: 10px"
          >
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="box" style="border-top: none; border: 1px solid #d2d6de">
                <div class="box-body">
                  <img
                    class="img-responsive cursor-pointer"
                    src="{{ woAttachmentURL }}{{ attachments.ImageName }}"
                    alt="{{ attachments.ImageName }}"
                    style="height: 155px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <input
            type="button"
            value="History"
            *ngIf="!isNewState && isHistoryShow"
            class="btn btn-type btn-save"
            (click)="getMaintenanceWorkorderHistory(workorder.WorkorderId)"
          />
          <input type="button" value="Cancel" class="btn btn-type1 pull-right btn-cancel" (click)="clearFormData()" />
          <input
            type="button"
            value="Accept"
            class="btn btn-type btn-save"
            [disabled]="!cleaningQCWOForm.form.valid || isReqComplete || !canAccess('QC_WORK_ORDER_MODIFY', 'view')"
            (click)="openconfirmationModal()"
          />
          <input
            type="button"
            value="Reject"
            class="btn btn-type pull-right btn-delete"
            [disabled]="!cleaningQCWOForm.form.valid || isReqComplete || !canAccess('QC_WORK_ORDER_MODIFY', 'view')"
            (click)="openQCRejectconfirmationModal()"
          />
        </div>
      </div>
    </form>
  </div>
</section>

<div class="modal" id="qcAssignedModal" [ngStyle]="qcAssignedModal ? { display: 'block' } : { display: 'none' }">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeQcAssignedModal()">&times;</button>
        <h3 class="modal-title">Do you want to accept/decline QC?</h3>
      </div>

      <div class="modal-body">
        <div class="row" *ngIf="qcMaster && qcMaster.QCAssignedTo">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <span *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0"
              ><b>Cleaning Alert #:</b> {{ qcMaster.WorkorderId }}</span
            ><br *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0" />
            <span *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0"
              ><b>Custodial Worker:</b> {{ qcMaster.CAAssignedTo.FirstName }} {{ qcMaster.CAAssignedTo.LastName }}</span
            ><br *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0" />
            <span *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0"
              ><b>Supervisor:</b> {{ qcMaster.QCAssignedTo.FirstName }} {{ qcMaster.QCAssignedTo.LastName }}</span
            ><br *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0" />
            <span *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0"
              ><b>Location:</b> {{ qcMaster.Location.LocationName }}</span
            ><br *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0" />
            <span *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0"
              ><b>Zone:</b> {{ qcMaster.Location.Zone.ZoneName }}</span
            ><br *ngIf="qcMaster.WorkorderId != null && qcMaster.WorkorderId > 0" />

            <span *ngIf="qcMaster.WorkorderId == null || qcMaster.WorkorderId <= 0"
              ><b>QC #:</b> {{ qcMaster.QcId }}</span
            ><br *ngIf="qcMaster.WorkorderId == null || qcMaster.WorkorderId <= 0" />
            <span *ngIf="qcMaster.WorkorderId == null || qcMaster.WorkorderId <= 0"
              ><b>Supervisor:</b> {{ qcMaster.QCAssignedTo.FirstName }} {{ qcMaster.QCAssignedTo.LastName }}</span
            ><br *ngIf="qcMaster.WorkorderId == null || qcMaster.WorkorderId <= 0" />
            <span *ngIf="qcMaster.WorkorderId == null || qcMaster.WorkorderId <= 0"
              ><b>Location:</b> {{ qcMaster.Location.LocationName }}</span
            ><br *ngIf="qcMaster.WorkorderId == null || qcMaster.WorkorderId <= 0" />
            <span *ngIf="qcMaster.WorkorderId == null || qcMaster.WorkorderId <= 0"
              ><b>Zone:</b> {{ qcMaster.Location.Zone.ZoneName }}</span
            >
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col-md-12 btn-footer">
          <input
            type="button"
            value="Accept"
            class="btn btn-type btn-save"
            [disabled]="isReqComplete"
            (click)="acceptRejectCleaningQC(10, qcMaster.WorkorderId, qcMaster.QcId)"
          />
          <input
            type="button"
            value="Decline"
            class="btn btn-type btn-cancel"
            (click)="acceptRejectCleaningQC(8, qcMaster.WorkorderId, qcMaster.QcId)"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="cleaningAlertHistoryModal"
  [ngStyle]="cleaningAlertHistoryModal ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeCleaningAlertHistoryModal()">
          &times;
        </button>
        <h3 class="modal-title">Cleaning Alert History</h3>
      </div>

      <div class="modal-body" style="text-align: left !important">
        <div class="row text-align-left" *ngIf="workorderHistoryDetails.length > 0">
          <div class="col-lg-12 col-md-12 form-group">
            <div class="panel-group" id="accordion" style="margin-top: 15px">
              <div class="panel panel-default" *ngFor="let workorderHistory of workorderHistoryDetails; let i = index">
                <div class="panel-heading" id="caHistoryheading{{ i }}" style="height: 35px">
                  <h4 class="panel-title">
                    <a
                      id="woHistory{{ workorderHistory.QCDetails.Title }}"
                      class="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      data-target="#QCDetails{{ i }}"
                      href="#QCDetails{{ i }}"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span style="font-weight: bold; float: left">QC # {{ workorderHistory.QCDetails.QcId }}</span>
                      <span style="float: left">{{ workorderHistory.QCDetails.Title }}</span>
                      <span style="float: right; padding: 0 8px 0 0"
                        >Completion Date: {{ workorderHistory.QCDetails.QCCompletionDate | date: 'short' }}</span
                      >
                    </a>
                  </h4>
                </div>
                <div id="QCDetails{{ i }}" class="panel-collapse collapse">
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-6 history-box">
                          <span class="history-header">Quality Check #: </span>
                          <span class="history-text">{{ workorderHistory.QCDetails.QcId }}</span>
                        </div>

                        <div class="col-md-6 history-box">
                          <span class="history-header">Quality Check Name: </span>
                          <span class="history-text">{{ workorderHistory.QCDetails.Title }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-12 history-box">
                          <span class="history-header">QC Performed By: </span>
                          <span class="history-text"
                            >{{ workorderHistory.QCDetails.QCAssignedTo.FirstName }}
                            {{ workorderHistory.QCDetails.QCAssignedTo.LastName }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div
                      *ngIf="
                        workorderHistory.QCDetails.QCTaskComment == null ||
                        workorderHistory.QCDetails.QCTaskComment.length <= 0
                      "
                      class="col-lg-12 col-md-12 col-sm-12"
                    >
                      <h4 class="listerror">No Comment available.</h4>
                      <hr class="hr-margin" />
                    </div>

                    <hr style="margin: 5px !important; border-top: 1px solid #3d5c70" />
                    <div
                      *ngIf="
                        workorderHistory.QCDetails != null &&
                        workorderHistory.QCDetails.QCTaskComment != null &&
                        workorderHistory.QCDetails.QCTaskComment.QcComment != null
                      "
                    >
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <h4>
                          QC Comment:&nbsp;<span>{{ workorderHistory.QCDetails.QCTaskComment.QcComment }}</span>
                        </h4>
                        <hr class="hr-margin" />
                      </div>
                    </div>

                    <div
                      *ngIf="
                        workorderHistory.QCDetails != null &&
                        (workorderHistory.QCDetails.PreQCFeedback == null ||
                          workorderHistory.QCDetails.PreQCFeedback.length <= 0)
                      "
                      class="col-lg-12 col-md-12 col-sm-12"
                    >
                      <h4 class="listerror">No Feedback available.</h4>
                      <hr class="hr-margin" />
                    </div>

                    <div *ngIf="workorderHistory.QCDetails != null && workorderHistory.QCDetails.PreQCFeedback != null">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <h4>
                          QC Feedback:&nbsp;<span>{{ workorderHistory.QCDetails.PreQCFeedback }}</span>
                        </h4>
                        <hr class="hr-margin" />
                      </div>
                    </div>

                    <div>
                      <div
                        *ngIf="
                          workorderHistory.QCDetails != null &&
                          (workorderHistory.QCDetails.QCTaskFeedbacks == null ||
                            workorderHistory.QCDetails.QCTaskFeedbacks <= 0)
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Task data.</h4>
                        <hr class="hr-margin" />
                      </div>
                      <div
                        id="workorderTasks"
                        *ngIf="
                          workorderHistory.QCDetails != null &&
                          workorderHistory.QCDetails.QCTaskFeedbacks != null &&
                          workorderHistory.QCDetails.QCTaskFeedbacks.length > 0
                        "
                      >
                        <h4>Tasks</h4>
                        <div class="box-body table-responsive">
                          <table class="table table-hover table-responsive">
                            <thead>
                              <tr>
                                <th>Task</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let feedback of workorderHistory.QCDetails.QCTaskFeedbacks">
                                <td style="cursor: auto">{{ feedback.TaskName }}</td>
                                <td style="cursor: auto">{{ feedback.QcInput }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <hr class="hr-margin" />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        *ngIf="
                          (workorderHistory.QCDetails != null &&
                            workorderHistory.QCDetails.QCInventoryRefills == null) ||
                          workorderHistory.QCDetails.QCInventoryRefills.length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Inventory data.</h4>
                        <hr class="hr-margin" />
                      </div>
                      <div
                        id="workorderTasks"
                        *ngIf="
                          workorderHistory.QCDetails.QCInventoryRefills != null &&
                          workorderHistory.QCDetails.QCInventoryRefills.length > 0
                        "
                      >
                        <h4>Inventory</h4>
                        <div class="box-body table-responsive">
                          <table class="table table-hover table-responsive">
                            <thead>
                              <tr>
                                <th>Inventory</th>
                                <th>Quantity</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let refill of workorderHistory.QCDetails.QCInventoryRefills">
                                <td style="cursor: auto">{{ refill.InventoryName }}</td>
                                <td style="cursor: auto">{{ refill.Quantity }}</td>
                              </tr>
                            </tbody>
                          </table>
                          <hr class="hr-margin" />
                        </div>
                      </div>
                    </div>

                    <div
                      class="row"
                      *ngIf="
                        workorderHistory.QCDetails.QCImages != null && workorderHistory.QCDetails.QCImages.length > 0
                      "
                    >
                      <span class="history-header" style="margin-left: 30px">QC Attachments:</span>
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div
                          class="col-lg-3 col-md-3 col-sm-3"
                          *ngFor="let attachments of workorderHistory.QCDetails.QCImages"
                          style="margin-top: 10px"
                        >
                          <div class="box" style="border-top: none; border: 1px solid #d2d6de">
                            <div class="box-body">
                              <a
                                data-toggle="tooltip"
                                data-html="true"
                                title="View"
                                *ngIf="
                                  attachments.ImageType == 'MP4' ||
                                  attachments.ImageType == 'mp4' ||
                                  attachments.ImageType == 'MOV' ||
                                  attachments.ImageType == 'mov' ||
                                  attachments.ImageType == 'avi' ||
                                  attachments.ImageType == 'AVI' ||
                                  attachments.ImageType == 'mpg' ||
                                  attachments.ImageType == 'MPG' ||
                                  attachments.ImageType == 'mpeg' ||
                                  attachments.ImageType == 'MPEG'
                                "
                                (click)="downloadWOImage(attachments.ImageName)"
                                class="fa fa-download pull-right"
                                style="color: black; cursor: pointer; height: 100px"
                              ></a>
                              <img
                                *ngIf="
                                  attachments.ImageType == 'PNG' ||
                                  attachments.ImageType == 'png' ||
                                  attachments.ImageType == 'jpg' ||
                                  attachments.ImageType == 'JPG' ||
                                  attachments.ImageType == 'jpeg' ||
                                  attachments.ImageType == 'JPEG' ||
                                  attachments.ImageType == 'gif' ||
                                  attachments.ImageType == 'GIF' ||
                                  attachments.ImageType == 'doc' ||
                                  attachments.ImageType == 'DOC'
                                "
                                class="img-responsive cursor-pointer"
                                src="{{ woAttachmentURL }}{{ attachments.ImageName }}"
                                alt="{{ attachments.ImageName }}"
                                style="height: 100px; cursor: pointer"
                                (click)="openQCAttachment(attachments.ImageName)"
                                data-toggle="modal"
                                data-target="#imageModal"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer"></div>
    </div>
  </div>
</div>

<div class="modal" id="woMaintananceHistoryModal" [ngStyle]="{ display: woMaintananceHistoryModal ? 'block' : 'none' }">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="CloseWoMaintananceHistoryModal()">
          &times;
        </button>
        <h3 class="modal-title">Work Order History</h3>
      </div>

      <div class="modal-body">
        <div class="row text-align-left">
          <div class="col-lg-12 col-md-12 form-group" *ngIf="maintenanceWOHistoryDetails.length > 0">
            <div class="panel-group" id="accordion">
              <div
                class="panel panel-default"
                *ngFor="let workorderHistory of maintenanceWOHistoryDetails; let i = index"
                style="text-align: left"
              >
                <div class="panel-heading" id="woHistoryheading{{ i }}">
                  <h4 class="panel-title">
                    <a
                      id="woHistory{{ workorderHistory.Title }}"
                      class="collapsed"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      data-target="#woHistory{{ i }}"
                      href="#woHistory{{ i }}"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span style="font-weight: bold">Work Order # {{ workorderHistory.WorkorderId }} - </span>
                      <span>{{ workorderHistory.Title }}</span>
                    </a>
                  </h4>
                </div>

                <div id="woHistory{{ i }}" class="panel-collapse collapse">
                  <div class="panel-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-6 history-box">
                          <span class="history-header" style="font-weight: bold">Work Order #: </span>
                          <span class="history-text">{{ workorderHistory.WorkorderId }}</span>
                        </div>

                        <div class="col-md-6 history-box">
                          <span class="history-header" style="font-weight: bold">Work Order Title: </span>
                          <span class="history-text">{{ workorderHistory.Title }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <div class="col-md-12 history-box">
                          <span class="history-header" style="font-weight: bold">QC Performed By: </span>
                          <span class="history-text"
                            >{{ workorderHistory.Supervisor.FirstName }}
                            {{ workorderHistory.Supervisor.LastName }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <hr class="wohistory-hr" />
                    <div
                      class="row"
                      *ngIf="
                        workorderHistory.WorkorderTaskComment == null ||
                        workorderHistory.WorkorderTaskComment.WorkorderInspections == null ||
                        workorderHistory.WorkorderTaskComment.WorkorderInspections.length <= 0
                      "
                    >
                      <div class="col-lg-12 col-md-12">
                        <div class="col-lg-12 col-md-12">
                          <span>No inspection task available.</span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row"
                      *ngIf="
                        workorderHistory.WorkorderTaskComment != null &&
                        workorderHistory.WorkorderTaskComment.WorkorderInspections != null &&
                        workorderHistory.WorkorderTaskComment.WorkorderInspections.length > 0
                      "
                    >
                      <div class="col-lg-12 col-md-12">
                        <div class="box-body table-responsive">
                          <table class="table table-hover table-responsive">
                            <thead
                              style="
                                border-bottom: 2px solid #c8cccd;
                                background: #eaedee;
                                text-shadow: 0 0 0 #fff;
                                font-weight: bold;
                              "
                            >
                              <tr>
                                <td>Inspection Task</td>
                                <td>Status</td>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container
                                *ngFor="
                                  let inspection of workorderHistory.WorkorderTaskComment.WorkorderInspections;
                                  let i = index
                                "
                              >
                                <tr>
                                  <td>style="cursor:auto;"{{ inspection.InspectionTask }}</td>
                                  <td style="cursor: auto">
                                    <span *ngIf="inspection.QcInput == 'S'"><i class="fa fa-check-square-o"></i></span>
                                    <span *ngIf="inspection.QcInput == 'U'"><i class="fa fa-window-close-o"></i></span>
                                  </td>
                                </tr>
                              </ng-container>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <hr class="wohistory-hr" />
                    <div
                      class="row"
                      *ngIf="
                        workorderHistory.WorkorderTaskComment == null ||
                        workorderHistory.WorkorderTaskComment.QcComment == null
                      "
                    >
                      <div class="col-lg-12 col-md-12">
                        <div class="col-lg-12 col-md-12">
                          <span>No QC Comment available.</span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row"
                      *ngIf="
                        workorderHistory.WorkorderTaskComment != null &&
                        workorderHistory.WorkorderTaskComment.QcComment != null
                      "
                    >
                      <div class="col-md-12">
                        <div class="col-md-12 history-box">
                          <span class="history-header">QC Comment: </span>
                          <span class="history-text">{{ workorderHistory.WorkorderTaskComment.QcComment }}</span>
                        </div>
                      </div>
                    </div>

                    <hr class="wohistory-hr" />
                    <div
                      class="row"
                      *ngIf="
                        workorderHistory.WorkorderQcImages == null || workorderHistory.WorkorderQcImages.length <= 0
                      "
                    >
                      <div class="col-lg-12 col-md-12">
                        <div class="col-lg-12 col-md-12">
                          <span>No QC Attachments available.</span>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row"
                      *ngIf="
                        workorderHistory.WorkorderQcImages != null && workorderHistory.WorkorderQcImages.length > 0
                      "
                    >
                      <span class="history-header" style="margin-left: 30px">QC Attachments:</span>

                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <div
                          class="col-lg-3 col-md-3 col-sm-3"
                          *ngFor="let attachments of workorderHistory.WorkorderQcImages"
                          style="margin-top: 10px"
                        >
                          <div class="box" style="border-top: none; border: 1px solid #d2d6de">
                            <div class="box-body">
                              <img
                                class="img-responsive cursor-pointer"
                                src="{{ woAttachmentURL }}{{ attachments.ImageName }}"
                                alt="{{ attachments.ImageName }}"
                                style="height: 100px"
                                (click)="openQCAttachment(attachments.ImageName)"
                                data-toggle="modal"
                                data-target="#imageModal"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer"></div>
    </div>
  </div>
</div>

<div class="modal fade" id="imageModal" role="dialog" style="z-index: 1051">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <img class="img-responsive" src="{{ qcAttachmentImg }}" style="max-height: 424px; margin: auto" />
      </div>
    </div>
  </div>
</div>

<div class="modal" id="confirmationModal" [ngStyle]="confirmationModal ? { display: 'block' } : { display: 'none' }">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeconfirmationModal()">&times;</button>
        <h3 class="modal-title">Quality Control Check</h3>
      </div>

      <div class="modal-body">Are you sure you want to accept this work order QC?</div>

      <div class="modal-footer">
        <div class="col-md-12 btn-footer">
          <input
            type="button"
            value="Yes"
            class="btn btn-type btn-save"
            [disabled]="isReqComplete"
            (click)="maintenanceAcceptRejectQC(6)"
          />
          <input type="button" value="No" class="btn btn-type btn-cancel" (click)="closeconfirmationModal()" />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="QCRejectconfirmationModal"
  [ngStyle]="QCRejectconfirmationModal ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeQCRejectconfirmationModal()">
          &times;
        </button>
        <h3 class="modal-title">Quality Control Check</h3>
      </div>

      <div class="modal-body">Are you sure you want to reject this work order QC?</div>

      <div class="modal-footer">
        <div class="col-md-12 btn-footer">
          <input
            type="button"
            value="With Workorder"
            class="btn btn-type btn-save"
            [disabled]="isReqComplete"
            (click)="maintenanceAcceptRejectQC('yes')"
          />
          <input
            type="button"
            value="Without Workorder"
            class="btn btn-type btn-cancel"
            [disabled]="isReqComplete"
            (click)="maintenanceAcceptRejectQC('no')"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="cleaningAlertAcceptQCModal"
  [ngStyle]="cleaningAlertAcceptQCModal ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closecleaningAlertAcceptQCModal()">
          &times;
        </button>
        <h3 class="modal-title">Quality Control Check</h3>
      </div>

      <div class="modal-body">Are you sure you want to accept this cleaning alert QC?</div>

      <div class="modal-footer">
        <div class="col-md-12 btn-footer">
          <input
            type="button"
            value="Yes"
            class="btn btn-type btn-save"
            [disabled]="isReqComplete"
            (click)="cleaningAlertAcceptRejectQC(6, false)"
          />
          <input type="button" value="No" class="btn btn-type btn-cancel" (click)="closecleaningAlertAcceptQCModal()" />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="cleaningAlertRejectQCModal"
  [ngStyle]="cleaningAlertRejectQCModal ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closecleaningAlertRejectQCModal()">
          &times;
        </button>
        <h3 class="modal-title">Quality Control Check</h3>
      </div>

      <div class="modal-body">Are you sure you want to reject this cleaning alert Quality Check?</div>

      <div class="modal-footer">
        <div class="col-md-12 btn-footer">
          <input
            type="button"
            value="With Cleaning Alert"
            class="btn btn-type btn-save"
            [disabled]="isReqComplete"
            (click)="openInspectNowOrLaterModal()"
            *ngIf="isQC_RatingShow && canAccess('CLEANING_ALERT_ADD', 'view')"
          />
          <input
            type="button"
            value="Without Cleaning Alert"
            class="btn btn-type btn-cancel"
            [disabled]="isReqComplete"
            (click)="cleaningAlertAcceptRejectQC('no', false)"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="confirmInspectionScreen"
  [ngStyle]="confirmInspectionScreen ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="closeConfirmInspectionScreen()">
          &times;
        </button>
        <h3 class="modal-title">Create Cleaning Alert</h3>
      </div>

      <div class="modal-body">Would you like to create an cleaning alert?</div>

      <div class="modal-footer">
        <div class="col-md-12 btn-footer">
          <input
            type="button"
            value="Create Now"
            class="btn btn-type btn-save"
            (click)="cleaningAlertAcceptRejectQC('yes', false)"
          />
          <input
            type="button"
            value="Create Later"
            class="btn btn-type btn-cancel"
            (click)="scheduleDialogOpen(scheduleDialog)"
          />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #scheduleDialog>
  <button
    aria-label="Close"
    class="close"
    type="button"
    (click)="scheduleDialogClose(scheduleForm, 'closeScheduleModal')"
    style="margin: 13px 20px 0px 0px"
  >
    <span aria-hidden="true">×</span>
  </button>
  <h2 matDialogTitle>{{ scheduleDialogTitle }}</h2>
  <mat-dialog-content class="outer-box">
    <form role="form" #scheduleForm="ngForm" class="form-horizontal scheduleBox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <label for="Title"><span id="red-circle">&#x25CF;</span> Title</label>
          <div>
            <input
              type="text"
              autocomplete="invalid"
              class="form-control"
              name="ScheduleTitle"
              placeholder="Title"
              [(ngModel)]="calendar.ScheduleTitle"
              #ScheduleTitle="ngModel"
              maxlength="100"
              required
              [pattern]="utilitiesService.alphanumericRegex"
            />
            <div
              *ngIf="ScheduleTitle.errors && (ScheduleTitle.dirty || ScheduleTitle.touched)"
              class="alert alert-danger"
              style="padding: 5px"
            >
              <div [hidden]="!ScheduleTitle.errors.required" class="text-danger">Title is required.</div>
              <div [hidden]="!ScheduleTitle.errors.pattern" class="text-danger">
                {{ utilitiesService.alphanumericRegexMessage }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-group">
          <label for="description">Description</label>
          <div>
            <textarea
              id="description"
              name="description"
              #description="ngModel"
              type="text"
              class="form-control"
              placeholder="Description"
              [(ngModel)]="calendar.ScheduleDescription"
              autocomplete="off"
              maxlength="250"
            ></textarea>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 form-group" *ngIf="calendar.ScheduleType">
          <label for="ScheduleType"><span id="red-circle">&#x25CF;</span> Event Types</label>
          <div>
            <div class="clipping-wrapper">
              <p class="clipping">{{ calendar.ScheduleType.ScheduleTypeName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group">
          <label for="Corporation"><span id="red-circle">&#x25CF;</span> Corporation</label>
          <div>
            <div class="clipping-wrapper">
              <p class="clipping">{{ calendar.CorporationName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group">
          <label for="Venue"><span id="red-circle">&#x25CF;</span> Venue</label>
          <div>
            <div class="clipping-wrapper">
              <p class="clipping" style="height: 20px">{{ calendar.Venue.VenueName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group">
          <label for="Location"><span id="red-circle">&#x25CF;</span> Location</label>
          <div>
            <div class="clipping-wrapper">
              <p class="clipping">{{ calendar.Location.LocationName }}</p>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12" style="padding: 0px">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="Worker"> Worker</label>
            <ngx-atlas-select
              (model)="calendar.AssigneTo.UserId = $event"
              [list]="workers"
              [idField]="'UserId'"
              [textField]="'FirstName'"
              [placeholder]="'Select Worker'"
              [allowClear]="true"
              [multiple]="false"
              [required]="false"
              [disabled]="isCA_ASSIGNMENT"
              [selectedValue]="calendar.AssigneTo.UserId"
            >
            </ngx-atlas-select>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group">
          <label for="startTimeValue"><span id="red-circle">&#x25CF;</span> Start Time</label>
          <div>
            <div style="display: flex">
              <input
                id="startTimeValue"
                name="startTimeValue"
                class="form-control dateFormat"
                aria-label="default time"
                [ngxTimepicker]="startTimeValue"
                required
                [(ngModel)]="calendar.StartTime"
                placeholder="Select Start Time"
                [disableClick]="true"
                readonly
              />
              <ngx-material-timepicker-toggle
                [for]="startTimeValue"
                [disabled]="StartTimeDisabled"
              ></ngx-material-timepicker-toggle>
              <ngx-material-timepicker #startTimeValue></ngx-material-timepicker>
            </div>
            <div
              *ngIf="startTimeValue.errors && (startTimeValue.dirty || startTimeValue.touched)"
              class="alert alert-danger validation-message"
            >
              <div [hidden]="!startTimeValue.errors.required" class="text-danger">Start time is required.</div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-6 form-group">
          <label for="endTimeValue"><span id="red-circle">&#x25CF;</span> End Time</label>
          <div>
            <div style="display: flex">
              <input
                id="endTimeValue"
                name="endTimeValue"
                class="form-control dateFormat"
                aria-label="default time"
                [ngxTimepicker]="endTimeValue"
                required
                [(ngModel)]="calendar.EndTime"
                placeholder="Select Start Time"
                [disableClick]="true"
                readonly
              />
              <ngx-material-timepicker-toggle
                [for]="endTimeValue"
                [disabled]="EndTimeDisabled"
              ></ngx-material-timepicker-toggle>
              <ngx-material-timepicker #endTimeValue></ngx-material-timepicker>
            </div>
            <div
              *ngIf="endTimeValue.errors && (endTimeValue.dirty || endTimeValue.touched)"
              class="alert alert-danger validation-message"
            >
              <div [hidden]="!endTimeValue.errors.required" class="text-danger">End time is required.</div>
            </div>
          </div>
        </div>

        <div
          *ngIf="IsFullDayDatePanalVisible"
          class="col-lg-12 col-md-12 col-sm-12 form-group"
          style="border: 1px solid; margin: 0 0 15px 14px"
        >
          <div class="col-lg-6 col-md-6 col-sm-6 form-group float-left">
            <mat-form-field style="padding-top: 16px">
              <input
                matInput
                [(ngModel)]="calendar.StartDate"
                name="StartDate"
                (focus)="picker1.open()"
                [min]="TodayDate"
                [matDatepicker]="picker1"
                placeholder="Start Date"
                (dateChange)="selectJustThisOneDate(1)"
                readonly
                [disabled]="eventIsRecurring"
              />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group float-left">
            <mat-form-field style="padding-top: 16px">
              <input
                matInput
                [(ngModel)]="calendar.EndDate"
                name="EndDate"
                (focus)="picker2.open()"
                [min]="calendar.StartDate"
                [matDatepicker]="picker2"
                placeholder="End Date"
                (dateChange)="selectJustThisOneDate(2)"
                readonly
                [disabled]="eventIsRecurring"
              />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

        <div
          *ngIf="IsVisible"
          class="col-lg-12 col-md-12 col-sm-12 form-group"
          style="border: 1px solid; margin: 0 0 5px 9px; padding-top: 15px"
        >
          <mat-label><b>Recurring Type</b></mat-label>

          <mat-tab-group
            animationDuration="500ms"
            #tabGroup
            (selectedTabChange)="onTabChanged($event)"
            [selectedIndex]="selectedTabIndex"
          >
            <mat-tab label="Daily" id="tab1">
              <mat-radio-group class="col-md-2" name="dailyRadioControl">
                <mat-radio-button
                  id="rbDailyDays"
                  (change)="rbDailyChange($event)"
                  value="rbDailyDays"
                  [checked]="IsrbDailyDaysChecked"
                  >Every</mat-radio-button
                >
              </mat-radio-group>

              <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                <input
                  matInput
                  type="number"
                  min="1"
                  (focus)="DailyonFocus('rbDailyDays')"
                  [(ngModel)]="SeperationCountDaily"
                  maxlength="3"
                  oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                  autocomplete="invalid"
                  name="dayControl"
                  app_onlynumber
                />
              </mat-form-field>
              day(s)

              <br />

              <mat-radio-group class="col-lg-12 col-md-12 col-sm-12" name="dailyRadioControl">
                <mat-radio-button
                  id="rbDailyEveryWeekdays"
                  (change)="rbDailyChange($event)"
                  value="rbDailyEveryWeekdays"
                  [checked]="rbDailyEveryWeekdays"
                  >Every Weekday</mat-radio-button
                >
              </mat-radio-group>
            </mat-tab>

            <mat-tab label="Weekly" id="tab2">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label class="col-md-2"> Recur Every</label>

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    min="1"
                    [(ngModel)]="calendar.RecurringPattern.SeperationCount"
                    autocomplete="invalid"
                    maxlength="2"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    name="weekControl"
                    app_onlynumber
                  />
                </mat-form-field>

                <label class="col-md-2"> week(s) on:</label>
              </div>

              <br />

              <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-checkbox
                  name="sundaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Sunday"
                  id="sundaycheckboxControl"
                  >Sunday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="mondaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Monday"
                  id="mondaycheckboxControl"
                  >Monday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="thuesdaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Tuesday"
                  id="thuesdaycheckboxControl"
                  >Tuesday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="wednesdaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Wednesday"
                  id="wednesdaycheckboxControl"
                  >Wednesday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="thursdaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Thusday"
                  id="thursdaycheckboxControl"
                  >Thursday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="fridaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Friday"
                  id="fridaycheckboxControl"
                  >Friday</mat-checkbox
                >
                &nbsp;&nbsp;
                <mat-checkbox
                  name="saturdaycheckboxControl"
                  [(ngModel)]="calendar.RecurringPattern.Saturday"
                  id="saturdaycheckboxControl"
                  >Saturday</mat-checkbox
                >
                &nbsp;&nbsp;
              </div>
            </mat-tab>

            <mat-tab label="Monthly" id="tab3">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-radio-group class="col-md-2" name="dayRadioControl" id="dayRadioControl">
                  <mat-radio-button
                    id="rbMonthlyDays"
                    (change)="rbMonthlyChange($event)"
                    value="rbMonthlyDays"
                    [checked]="rbMonthlyDays"
                    >Day</mat-radio-button
                  >
                </mat-radio-group>

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    [(ngModel)]="DayOfMonthMonthly1"
                    (focus)="MonthlyonFocus1()"
                    type="number"
                    min="1"
                    maxlength="2"
                    digitOnly
                    autocomplete="invalid"
                    name="everyDayControl"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    app_onlynumber
                  />
                </mat-form-field>
                <div class="col-md-2">of every</div>

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    [(ngModel)]="SeperationCountMonthly1"
                    (focus)="MonthlyonFocus1()"
                    type="number"
                    min="1"
                    maxlength="2"
                    digitOnly
                    autocomplete="invalid"
                    name="everyMonthControl"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    app_onlynumber
                  />
                </mat-form-field>

                <div class="col-md-2">month(s)</div>
              </div>
              <br />
              <div class="col-lg-12 col-md-12 col-sm-12 monthYearDrodown">
                <mat-radio-group class="col-md-2" name="dayRadioControl" id="dayRadioControl">
                  <mat-radio-button
                    id="rbMonthlyMonths"
                    (change)="rbMonthlyChange($event)"
                    value="rbMonthlyMonths"
                    [checked]="rbMonthlyMonths"
                    >The</mat-radio-button
                  >
                </mat-radio-group>

                <div class="col-md-2" title="Select Week Of Month">
                  <ngx-atlas-select
                    (model)="MonthlyonFocusWeek($event)"
                    [list]="utilitiesService.weekOfMonths"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Week Of Month'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="WeekOfMonthMonthly"
                  >
                  </ngx-atlas-select>
                </div>
                &nbsp;&nbsp;

                <div class="col-md-2" title="Select Monthly Day">
                  <ngx-atlas-select
                    (model)="MonthlyonFocusDays($event)"
                    [list]="utilitiesService.monthlyDays"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Monthly Day'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="DayOfMonthMonthly2"
                  >
                  </ngx-atlas-select>
                </div>

                <label class="col-md-2">of every</label>

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    min="1"
                    (focus)="MonthlyonFocus2()"
                    [(ngModel)]="SeperationCountMonthly2"
                    maxlength="2"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    autocomplete="invalid"
                    name="everyMonthsControl"
                    app_onlynumber
                  />
                </mat-form-field>

                <label class="col-md-2">month(s)</label>
              </div>
            </mat-tab>

            <mat-tab label="Yearly" id="tab4">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <label class="col-md-2">Recur Every</label>
                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    type="number"
                    min="1"
                    [(ngModel)]="SeperationCountYearly"
                    autocomplete="invalid"
                    maxlength="2"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    name="yearsControl"
                    app_onlynumber
                  />
                </mat-form-field>
                <label class="col-md-2">year(s)</label>
              </div>

              <br />

              <div class="col-lg-12 col-md-12 col-sm-12 monthYearDrodown">
                <mat-radio-group class="col-md-2" name="yearlyWeekRadioControl" fid="yearlyWeekRadioControl">
                  <mat-radio-button
                    id="rbYearlyMonths"
                    (change)="rbYearlyChange($event)"
                    value="rbYearlyMonths"
                    [checked]="rbYearlyMonths"
                    >On the:</mat-radio-button
                  >
                </mat-radio-group>

                <div class="col-md-2" title="Select Month Of Year">
                  <ngx-atlas-select
                    (model)="YearlyonFocusOnMonth1($event)"
                    [list]="utilitiesService.monthsOfYear"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Month Of Year'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="MonthOfYearYearly"
                  >
                  </ngx-atlas-select>
                </div>
                &nbsp;&nbsp;

                <mat-form-field appearance="outline" class="col-md-1" style="padding: 0 0 10px; width: 75px">
                  <input
                    matInput
                    [(ngModel)]="DayOfMonthYearly"
                    (focus)="YearlyonFocus1()"
                    type="number"
                    min="1"
                    autocomplete="invalid"
                    name="monthsDayControl"
                    maxlength="2"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    app_onlynumber
                  />
                </mat-form-field>
              </div>

              <br />

              <div class="col-lg-12 col-md-12 col-sm-12 monthYearDrodown">
                <mat-radio-group class="col-md-2" name="yearlyWeekRadioControl" id="yearlyWeekRadioControl">
                  <mat-radio-button
                    id="rbYearlyMonthWeekDay"
                    (change)="rbYearlyChange($event)"
                    value="rbYearlyMonthWeekDay"
                    [checked]="rbYearlyMonthWeekDay"
                    >The</mat-radio-button
                  >
                </mat-radio-group>

                <div class="col-md-2" title="Select Week Of Month">
                  <ngx-atlas-select
                    (model)="YearlyonFocusonWeek2($event)"
                    [list]="utilitiesService.weekOfMonths"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Week Of Month'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="YearlyWeekOfMonth"
                  >
                  </ngx-atlas-select>
                </div>
                &nbsp;&nbsp;

                <div class="col-md-2" title="Select Monthly Day">
                  <ngx-atlas-select
                    (model)="YearlyonFocusonDay2($event)"
                    [list]="utilitiesService.monthlyDays"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Monthly Day'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="YearlyDayOfMonth"
                  >
                  </ngx-atlas-select>
                </div>

                <label class="col-md-1">of</label>

                <div class="col-md-2" title="Select Month Of Year">
                  <ngx-atlas-select
                    (model)="YearlyonFocusonMonth2($event)"
                    [list]="utilitiesService.monthsOfYear"
                    [idField]="'id'"
                    [textField]="'name'"
                    [placeholder]="'Select Month Of Year'"
                    [allowClear]="true"
                    [multiple]="false"
                    [required]="false"
                    [selectedValue]="YearlyMonthOfYear"
                  >
                  </ngx-atlas-select>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>

        <div
          *ngIf="IsVisible"
          class="col-lg-12 col-md-12 col-sm-12 form-group"
          style="border: 1px solid; padding-top: 15px; margin: 0 0 15px 9px"
        >
          <div class="col-md-4 col-lg-4 form-group float-left">
            <label class="">Range of Recurrence</label>

            <div class="col-lg-12 col-md-12 col-sm-12">
              <mat-form-field style="padding-top: 16px">
                <input
                  matInput
                  [(ngModel)]="calendar.StartDate"
                  name="StartDate"
                  [min]="TodayDate"
                  (focus)="picker1.open()"
                  [matDatepicker]="picker1"
                  placeholder="Start Date"
                  (dateChange)="BindAllSupervisors()"
                  readonly
                />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <div class="col-md-8 col-lg-8 form-group">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-radio-group
                  name="rangeOfRecurrenceRadioControl"
                  id="rangeOfRecurrenceRadioControl"
                  (change)="onChangerangeOfRecurrenceRadioControl($event)"
                >
                  <mat-radio-button
                    #rbRecurrenceNoEndDate
                    id="rbRecurrenceNoEndDate"
                    value="1"
                    (focus)="rangeOfRecurrenceFocus('rbRecurrenceNoEndDate')"
                    (change)="rangeOfRecurrenceRadioclick($event)"
                    [checked]="IsrbRecurrenceNoEndDateCheked"
                    >No end date</mat-radio-button
                  >
                </mat-radio-group>
              </div>
            </div>
            <br />

            <div class="col-lg-12 col-md-12 col-sm-12 rangeOccurance">
              <div class="col-md-4 col-lg-4">
                <mat-radio-group
                  name="rangeOfRecurrenceRadioControl"
                  id="rangeOfRecurrenceRadioControl"
                  (change)="onChangerangeOfRecurrenceRadioControl($event)"
                >
                  <mat-radio-button
                    id="rbRecurrenceEndAfter"
                    value="2"
                    (change)="rangeOfRecurrenceRadioclick($event)"
                    [checked]="IsrbRecurrenceEndAfterChecked"
                    >End after:</mat-radio-button
                  > </mat-radio-group
                >&nbsp;&nbsp;
              </div>

              <div class="col-md-4 col-lg-4">
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="number"
                    min="1"
                    (focus)="rangeOfRecurrenceFocus('rbRecurrenceEndAfter')"
                    [(ngModel)]="calendar.RecurringPattern.Occurences"
                    maxlength="3"
                    oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    autocomplete="invalid"
                    name="occurencesControl"
                    app_onlynumber
                  />
                </mat-form-field>
              </div>

              <div class="col-md-4 col-lg-4" style="margin-top: 10px">occurences</div>
            </div>

            <br />

            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <mat-radio-group
                  name="rangeOfRecurrenceRadioControl"
                  id="rangeOfRecurrenceRadioControl"
                  (change)="onChangerangeOfRecurrenceRadioControl($event)"
                >
                  <mat-radio-button
                    id="rbRecurrenceEndby"
                    value="3"
                    (change)="rangeOfRecurrenceRadioclick($event)"
                    [checked]="IsrbRecurrenceEndbyChecked"
                    >End by: &nbsp;&nbsp;</mat-radio-button
                  >
                </mat-radio-group>

                <mat-form-field>
                  <input
                    matInput
                    [(ngModel)]="calendar.EndDate"
                    [min]="calendar.StartDate"
                    name="EndDate"
                    (focus)="[picker2.open(), rangeOfRecurrenceFocus('rbRecurrenceEndby')]"
                    [matDatepicker]="picker2"
                    placeholder="End Date"
                    (dateChange)="BindAllSupervisors()"
                    readonly
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <br />
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <button
            class="btn btn-types schedule-save"
            (click)="addSchedule(scheduleForm)"
            [disabled]="
              calendar.ScheduleTitle == '' ||
              !calendar.ScheduleType.ScheduleTypeId ||
              !calendar.CorporationId ||
              !calendar.Venue.VenueId ||
              !calendar.Location.LocationId ||
              isReqComplete
            "
          >
            Save
          </button>
          <button
            class="btn btn-types pull-right schedule-cancel"
            (click)="scheduleDialogClose(scheduleForm, 'closeScheduleModal')"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  </mat-dialog-content>
</ng-template>
