<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-12 col-md-12 col-sm-12 form-heading">
        <i class="fa fa-search heading-icon"></i>
        <span class="list-heading">ASSIGN INSPECTION LOCATIONS</span>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row">
    <form #userLocationAssignmentForm="ngForm" class="form-horizontal">
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="col-lg-12 col-md-12 col-sm-12" style="padding-left: 0px">
            <div class="col-lg-4 col-md-4 col-sm-4 form-group" style="padding-right: 55px">
              <label for="user"><span id="red-circle">&#x25CF;</span> User</label>
              <div>
                <ngx-atlas-select
                  (model)="changeUser($event)"
                  [list]="users"
                  [idField]="'UserId'"
                  [textField]="'FirstName'"
                  [placeholder]="'Select User'"
                  [allowClear]="true"
                  [multiple]="false"
                  [required]="true"
                  [selectedValue]="userLocationAssignment.User.UserId"
                >
                </ngx-atlas-select>
              </div>
            </div>
          </div>
          <div class="col-lg-11 col-md-11 col-sm-11" style="padding-left: 0px">
            <div class="row col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-4 col-md-4 col-sm-4 form-group" *ngIf="userLocationAssignment.User.UserId">
                <label for="corporation"> Corporation</label>
                <div>
                  <ng-multiselect-dropdown
                    [settings]="corporationDropdownSettings"
                    [placeholder]="'Select Corporation'"
                    [data]="corporations"
                    [(ngModel)]="corporationIds"
                    id="corporation-multiselect"
                    name="corporation"
                    (onSelect)="changeCorporation($event)"
                    (onSelectAll)="selectAllCorporations($event)"
                    (onDeSelect)="deSelectCorporation($event)"
                    (onDeSelectAll)="deSelectAllCorporations($event)"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 form-group" *ngIf="userLocationAssignment.User.UserId">
                <label for="venue"> Venue</label>
                <div>
                  <ng-multiselect-dropdown
                    [settings]="venueDropdownSettings"
                    [placeholder]="'Select Venue'"
                    [data]="venues"
                    [(ngModel)]="venueIds"
                    id="venue-multiselect"
                    name="venue"
                    (onSelect)="changeVenue($event)"
                    (onSelectAll)="selectAllVenues($event)"
                    (onDeSelect)="deSelectVenue($event)"
                    (onDeSelectAll)="deSelectAllVenues($event)"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 form-group" *ngIf="userLocationAssignment.User.UserId">
                <label for="building">Building</label>
                <div>
                  <ng-multiselect-dropdown
                    [settings]="buildingDropdownSettings"
                    [placeholder]="'Select Building'"
                    [data]="buildings"
                    [(ngModel)]="buildingIds"
                    id="building-multiselect"
                    name="building"
                    (onSelect)="changeBuilding($event)"
                    (onSelectAll)="selectAllBuildings($event)"
                    (onDeSelect)="deSelectBuilding($event)"
                    (onDeSelectAll)="deSelectAllBuildings($event)"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
            <div class="row col-lg-12 col-md-12 col-sm-12">
              <div class="col-lg-4 col-md-4 col-sm-4 form-group" *ngIf="userLocationAssignment.User.UserId">
                <label for="zone">Zone</label>
                <div>
                  <ng-multiselect-dropdown
                    [settings]="zoneDropdownSettings"
                    [placeholder]="'Select Zone'"
                    [data]="zones"
                    [(ngModel)]="zoneIds"
                    id="zone-multiselect"
                    name="zone"
                    (onSelect)="changeZone($event)"
                    (onSelectAll)="selectAllZones($event)"
                    (onDeSelect)="deSelectZone($event)"
                    (onDeSelectAll)="deSelectAllZones($event)"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 form-group" *ngIf="userLocationAssignment.User.UserId">
                <label for="zone">Level</label>
                <div>
                  <ng-multiselect-dropdown
                    [settings]="levelDropdownSettings"
                    [placeholder]="'Select Level'"
                    [data]="levels"
                    [(ngModel)]="levelIds"
                    id="Level-multiselect"
                    name="level"
                    (onSelect)="changeLevel($event)"
                    (onSelectAll)="selectAllLevels($event)"
                    (onDeSelect)="deSelectLevel($event)"
                    (onDeSelectAll)="deSelectAllLevels($event)"
                  >
                  </ng-multiselect-dropdown>
                </div>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-4 form-group" *ngIf="userLocationAssignment.User.UserId">
                <label for="restrooms">Location</label>
                <ng-multiselect-dropdown
                  [settings]="locationDropdownSettings"
                  [placeholder]="'Select Location'"
                  [data]="Locations"
                  [(ngModel)]="LocationIds"
                  name="location"
                  (onSelect)="changeLocation($event)"
                  (onSelectAll)="onSelectAllLocation($event)"
                  (onDeSelect)="onItemDeSelectLocation($event)"
                  (onDeSelectAll)="onItemDeSelectAllLocation($event)"
                >
                </ng-multiselect-dropdown>
              </div>
            </div>
          </div>
          <div
            class="col-lg-1 col-md-1 col-sm-1 form-group"
            style="padding-left: 2px !important; margin-top: 30px !important"
          >
            <button
              type="button"
              class="btn btn-type btn-dashboard"
              style="float: right"
              *ngIf="userLocationAssignment.User.UserId"
              (click)="getAllLocations(corporationIds, venueIds, buildingIds, zoneIds, levelIds, LocationIds)"
              [disabled]="!corporationIds.length > 0 && !venueIds.length > 0"
            >
              Apply
            </button>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="hasData()">
          <div class="box-body table-responsive">
            <table class="table table-hover table-responsive" *ngFor="let corp of userLocations">
              <ng-container *ngFor="let venue of corp.InspectorAssignmentVenuesList">
                <thead>
                  <tr>
                    <th>
                      <span
                        ><b>Corporation: {{ corp.CorporationName }}</b></span
                      >
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td style="background-color: #d5dde6; cursor: auto">
                      <mat-checkbox
                        id="v_{{ venue.VenueId }}"
                        name="v_{{ venue.VenueId }}"
                        [(ngModel)]="venue.IsActive"
                        (change)="venueCheckboxChange(venue.IsActive, venue.VenueId)"
                      ></mat-checkbox
                      >&nbsp;
                      <span
                        ><b>Venue: {{ venue.VenueName }}</b></span
                      >
                    </td>
                  </tr>

                  <tr *ngFor="let loc of venue.Locations">
                    <td style="cursor: auto">
                      <mat-checkbox
                        id="location_{{ loc.LocationId }}"
                        name="location_{{ loc.LocationId }}"
                        [(ngModel)]="loc.IsActive"
                        (change)="locationCheckboxChange(venue.VenueId, loc.LocationId, loc.IsActive)"
                      ></mat-checkbox
                      >&nbsp;
                      <span>{{ loc.LocationName }}</span>
                    </td>
                  </tr>
                </tbody>
              </ng-container>
            </table>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 tboday-scroll">
          <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
            <h3 class="listerror">Sorry no data found!</h3>
          </div>
        </div>

        <div
          class="col-lg-12 col-md-12 col-sm-12 btn-section1"
          *ngIf="userLocationAssignment.User.UserId"
          style="padding: 18px 8px 18px 26px"
        >
          <input type="button" value="Cancel" class="btn btn-type1 pull-right btn-cancel" (click)="clearFormData()" />
          <input
            type="button"
            value="Save"
            class="btn btn-type btn-save"
            [disabled]="
              !userLocationAssignmentForm.form.valid ||
              isReqComplete ||
              !userLocationAssignment.User.UserId ||
              !canAccess('INSPECTION_LOCATION_ASGMT_EDIT', 'view')
            "
            (click)="addInspectorLocationAssignment(userLocationAssignmentForm)"
          />
        </div>
      </div>
    </form>
  </div>
</section>
