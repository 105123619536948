import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { LocationModule } from '../../../staffscheduling/location/location.module';
import { ZoneModule } from '../../../staffscheduling/zone/zone.module';
import { User } from '../../../usermanagement/user/user.model';
import { BuildingModule } from '../../../venuemanagement/building/building.module';
import { LevelModule } from '../../../venuemanagement/level/level.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import {
  FilterInputs,
  InspectionLocationAssignment,
  InspectorAssignmentCorporations,
  InspectorLocationAssignmentModule,
} from '../inspectorlocationassignment.module';

@Injectable({
  providedIn: 'root',
})
export class InspectorLocationAssignmentService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (body: InspectorLocationAssignmentModule[]): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/user/location/assignment';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public GetUseLocationAssignmentList = (
    body: string,
    page: number,
    size: number,
  ): Observable<InspectorLocationAssignmentModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/location/assignment/' + page + '/' + size;
    return this.http.post<InspectorLocationAssignmentModule[]>(_url, body);
  };

  public GetAllUnassignedLocations = (body: string, page: number, size: number): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/unassinged/users/' + page + '/' + size;
    return this.http.post<LocationModule[]>(_url, body);
  };

  public GetAllInspectors = (body: string): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'inspection/location/assignment/inspectors';
    return this.http.post<User[]>(_url, body);
  };

  public AddInspectorLocationAssignment = (body: InspectorLocationAssignmentModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'inspection/inspector/location/assignment';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public GetCoporationList = (body: FilterInputs): Observable<CorporationModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/filter/corporations';
    return this.http.post<CorporationModule[]>(_url, body);
  };

  public GetVenueList = (body: FilterInputs): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/filter/venues';
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetBuildingList = (body: FilterInputs): Observable<BuildingModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/filter/buildings';
    return this.http.post<BuildingModule[]>(_url, body);
  };

  public GetZoneList = (body: FilterInputs): Observable<ZoneModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/filter/zones';
    return this.http.post<ZoneModule[]>(_url, body);
  };

  public GetLevelList = (body: FilterInputs): Observable<LevelModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/filter/levels';
    return this.http.post<LevelModule[]>(_url, body);
  };

  public GetAllLocationsByInspector = (body: FilterInputs): Observable<InspectorAssignmentCorporations[]> => {
    const _url: string = this.configuration.Server + 'inspection/inspector/assignment/venue/locations';
    return this.http.post<InspectorAssignmentCorporations[]>(_url, body);
  };

  public GetAssignmentDetails = (body: FilterInputs): Observable<InspectionLocationAssignment> => {
    const _url: string = this.configuration.Server + 'inspection/inspector/locations/assignment';
    return this.http.post<InspectionLocationAssignment>(_url, body);
  };

  public GetAllLocationsDropdownList = (body: string): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/filter/locations';
    return this.http.post<LocationModule[]>(_url, body);
  };
}
