import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthState } from 'src/app/store/auth.state';
import { Configuration } from '../../../app.constants';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { VenueService } from '../../venue/components/venue.http.service';
import { VenueModule } from '../../venue/venue.module';
import { LevelModule } from '../level.module';
import { LevelService } from './level.http.service';

@Component({
  selector: 'level-component',
  templateUrl: 'level.component.html',
  providers: [LevelService, UtilitiesService, VenueService],
})
export class LevelComponent implements OnInit {
  currentUser: User;

  page = 1;
  size = 10;
  count = 0;

  searchString = '';
  isNewState = true;
  formList = true;
  Isdataloaded = false;
  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  public levels: LevelModule[];
  public level: LevelModule = new LevelModule();

  public venues: VenueModule[];

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  corporationNameForm: string;
  public pageState = [{ pageName: 'levelForm', page: this.page, viewType: this.viewType }];

  constructor(
    private levelService: LevelService,
    private utilitiesService: UtilitiesService,
    private venueService: VenueService,
    private configuration: Configuration,
    private loaderService: LoaderService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.getPageState();
    this.getAllVenues('', -1, -1);
    this.corporationNameForm = '';
    this.corporationNameForm = this.configuration.CorporationName;
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.viewType = this.pageState[0].viewType;
      this.getAllLevels(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllLevels(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.isNewState = true;
    this.formList = false;
    this.searchString = '';
    this.getAllVenues('', -1, -1);
  }

  public setUpdateForm() {
    this.isNewState = false;
    this.formList = false;
    this.searchString = '';
  }

  public clearFormData() {
    this.formList = true;
    this.isNewState = true;
    this.searchString = '';
    this.page = 1;
    this.pageChanged(this.page);
    this.level = new LevelModule();
    this.getAllLevels('', this.page, this.size, this.viewType);
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLevels('', this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllLevels('', this.page, this.size, this.viewType);
  }

  public levelsSearch(searchString: string, page: number, size: number) {
    this.page = page;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLevels(searchString, page, size, this.viewType);
  }

  // Show message when data not available
  public hasData(): boolean {
    return this.levels ? this.levels !== null && this.levels.length > 0 : false;
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState[0].page = this.page;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLevels(this.searchString, this.page, this.size, this.viewType);
  }

  // Get All Levels
  public getAllLevels(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 0,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };
    this.levelService.GetLevelList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.Isdataloaded = true;
        this.levels = data;
        if (this.levels.length < 0 && this.levels[0].Count / 10 < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'bldgService getAllBuildings Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.levels[0] ? this.levels[0].Count : 0),
    );
  }

  // Create Level
  public addLevel(levelForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (levelForm.status == 'INVALID' && levelForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Level', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.level.LevelName = this.level.LevelName.trim();
    this.level.Ordinal = this.level.Ordinal;
    this.level.Venue.VenueId = this.level.Venue.VenueId;

    this.level.AddedBy = this.currentUser.UserId;

    this.levelService.AddLevel(this.utilitiesService.stripScript(this.level)).subscribe(
      (data) => {
        this.page = 1;
        levelForm.reset();
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Level', data.Message);
        this.clearFormData();
      },
      (error) => {
        this.loaderService.display(false);
        this.isReqComplete = false;
        console.log('levelService addLevel Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  // Update Level
  public updateLevel(levelForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (levelForm.status == 'INVALID' && levelForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Level', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.level.LevelName = this.level.LevelName.trim();
    this.level.Ordinal = this.level.Ordinal;
    this.level.Venue.VenueId = this.level.Venue.VenueId;

    this.level.ModifiedBy = this.currentUser.UserId;
    this.level.IsActive = this.level.IsActive ? 1 : 0;

    this.levelService.UpdateLevel(this.utilitiesService.stripScript(this.level)).subscribe(
      (data) => {
        levelForm.reset();
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Level', data.Message);
        this.clearFormData();
      },
      (error) => {
        this.loaderService.display(false);
        this.isReqComplete = false;
        console.log(
          'levelService updateLevel Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  // Get Level By Id
  public getLevelById(levelId: number) {
    this.loaderService.display(true);
    const CPlevel = new LevelModule();
    CPlevel.LevelCPId = this.authState.AESEncryptText(levelId);

    this.levelService.GetSingle(CPlevel).subscribe(
      (data) => {
        this.level = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'levelService getLevelById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService GetVenueDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllLevels(this.searchString, this.page, this.size, this.viewType);
  }
}
