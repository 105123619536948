<section class="content-header">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-xs-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-podcast heading-icon"></i>
        <span class="list-heading" *ngIf="sensors">SENSORS</span>
        <span class="list-heading" *ngIf="!sensors && !sensor.SensorId">ADD SENSOR</span>
        <span class="list-heading" *ngIf="!sensors && sensor.SensorId">EDIT SENSOR</span>
      </div>

      <div class="col-lg-9 col-md-12">
        <div id="page-size" class="col-lg-1 col-sm-2 col-md-4 col-xs-12 pull-right list-heading" *ngIf="sensors">
          <ngx-atlas-select
            (model)="SensorPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="sensors && !sensor.SensorId && canAccess('SENSORS_ADD', 'view')"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Sensor
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12 pull-right list-heading" *ngIf="sensors">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="sensors">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="sensorsSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="sensors">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('SensorName', columnSortOrder)">
                  Sensor
                  <span *ngIf="selectedColumnName == 'SensorName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'SensorName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('SensorTypeName', columnSortOrder)">
                  Sensor Type
                  <span *ngIf="selectedColumnName == 'SensorTypeName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'SensorTypeName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('SensorManufacturerName', columnSortOrder)">
                  Sensor Mfr
                  <span *ngIf="selectedColumnName == 'SensorManufacturerName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'SensorManufacturerName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                  Venue
                  <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('SensorIdentifier', columnSortOrder)">
                  Identifier
                  <span *ngIf="selectedColumnName == 'SensorIdentifier' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'SensorIdentifier' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('LastUpdatedDate', columnSortOrder)">
                  Last Updated
                  <span *ngIf="selectedColumnName == 'LastUpdatedDate' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'LastUpdatedDate' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('LocationName', columnSortOrder)">
                  Location
                  <span *ngIf="selectedColumnName == 'LocationName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'LocationName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsOnline', columnSortOrder)">
                  Live Status
                  <span *ngIf="selectedColumnName == 'IsOnline' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'IsOnline' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">
                  Status
                  <span *ngIf="selectedColumnName == 'IsActive' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'IsActive' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let sensor of sensors
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getSensorById(sensor.SensorId, sensor.SensorType.SensorTypeCode)">
                <td>{{ sensor.SensorName }}</td>
                <td>{{ sensor.SensorType.SensorTypeName }}</td>
                <td>{{ sensor.SensorManufacturer.SensorManufacturerName }}</td>
                <td>{{ sensor.Venue.VenueName }}</td>
                <td>{{ sensor.SensorIdentifier }}</td>
                <td>{{ sensor.LastUpdatedDate | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                <td>{{ sensor.LocationName }}</td>
                <td *ngIf="sensor.IsOnline">
                  <span class="label label-success">Online</span>
                </td>
                <td *ngIf="!sensor.IsOnline">
                  <span class="label label-danger" style="font-weight: normal">Offline</span>
                </td>
                <td *ngIf="sensor.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!sensor.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!sensors">
    <form #SensorForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="SensorName"><span id="red-circle">&#x25CF;</span> Sensor Name</label>
            <div>
              <input
                id="SensorName"
                name="SensorName"
                #SensorName="ngModel"
                type="text"
                class="form-control"
                placeholder="Sensor Name"
                [(ngModel)]="sensor.SensorName"
                required
                maxlength="99"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div *ngIf="SensorName.errors && (SensorName.dirty || SensorName.touched)" class="alert alert-danger">
                <div [hidden]="!SensorName.errors.required" class="text-danger">Sensor name is required.</div>
                <div [hidden]="!SensorName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="Venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div>
              <ngx-atlas-select
                (model)="sensor.Venue.VenueId = $event"
                [list]="venues"
                [idField]="'VenueId'"
                [textField]="'VenueName'"
                [placeholder]="'Select Venue'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="sensor.Venue.VenueId"
              >
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="SensorIdentifier"><span id="red-circle">&#x25CF;</span> Sensor Identifier</label>
            <div>
              <input
                id="SensorIdentifier"
                name="SensorIdentifier"
                #SensorIdentifier="ngModel"
                type="text"
                class="form-control"
                placeholder="Sensor Identifier"
                [(ngModel)]="sensor.SensorIdentifier"
                required
                maxlength="249"
                autocomplete="off"
              />
              <div
                *ngIf="SensorIdentifier.errors && (SensorIdentifier.dirty || SensorIdentifier.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!SensorIdentifier.errors.required" class="text-danger">
                  Sensor identifier is required.
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="InternalSensorId"><span id="red-circle">&#x25CF;</span> Internal Sensor Id</label>
            <div>
              <input
                [disabled]="sensor.SensorId && !('ADMIN' | checkUserPermission)"
                id="InternalSensorId"
                name="InternalSensorId"
                #InternalSensorId="ngModel"
                type="text"
                class="form-control"
                placeholder="Internal Sensor Id"
                [(ngModel)]="sensor.InternalSensorId"
                required
                maxlength="40"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericWithoutSpecialCharRegex"
              />
              <div
                *ngIf="InternalSensorId.errors && (InternalSensorId.dirty || InternalSensorId.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!InternalSensorId.errors.required" class="text-danger">
                  Internal Sensor Id is required
                </div>
                <div [hidden]="!InternalSensorId.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericWithoutSpecialCharRegexMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="Venue"><span id="red-circle">&#x25CF;</span> Sensor Type</label>
            <div>
              <ngx-atlas-select
                (model)="changeSensorType($event)"
                [list]="sensorTypes"
                [idField]="'SensorTypeId'"
                [textField]="'SensorTypeName'"
                [placeholder]="'Select Sensor Type'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="sensor.SensorType.SensorTypeId"
              >
              </ngx-atlas-select>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="SensorName"><span id="red-circle">&#x25CF;</span> Sensor Manufacturer</label>
            <div>
              <ngx-atlas-select
                (model)="sensorManufactureChange($event)"
                [list]="sensormanufactureres"
                [idField]="'SensorManufacturerId'"
                [textField]="'SensorManufacturerName'"
                [placeholder]="'Sensor Manufacturer'"
                [allowClear]="true"
                [multiple]="false"
                [selectedValue]="sensor.SensorManufacturer.SensorManufacturerId"
              >
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="isAirQuality && !isHaltian">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="AirQualitySensorTempratureFId"
              ><span id="red-circle">&#x25CF;</span> Air Quality Sensor Temprature Farenheit Id</label
            >
            <div>
              <input
                id="AirQualitySensorTempratureFId"
                name="AirQualitySensorTempratureFId"
                #AirQualitySensorTempratureFId="ngModel"
                type="text"
                class="form-control"
                placeholder="Air Quality Sensor Temprature Farenheit Id"
                [(ngModel)]="sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureFId"
                required
                maxlength="99"
                autocomplete="off"
              />
              <div
                *ngIf="
                  AirQualitySensorTempratureFId.errors &&
                  (AirQualitySensorTempratureFId.dirty || AirQualitySensorTempratureFId.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!AirQualitySensorTempratureFId.errors.required" class="text-danger">
                  Air quality sensor temprature Farenheit id is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="AirQualitySensorTempratureCId"
              ><span id="red-circle">&#x25CF;</span> Air Quality Sensor Temprature Celcius Id</label
            >
            <div>
              <input
                id="AirQualitySensorTempratureCId"
                name="AirQualitySensorTempratureCId"
                #AirQualitySensorTempratureCId="ngModel"
                type="text"
                class="form-control"
                placeholder="Air Quality Sensor Temprature Celcius Id"
                [(ngModel)]="sensor.AirQualitySensorConfiguration.AirQualitySensorTempratureCId"
                required
                maxlength="99"
                autocomplete="off"
              />
              <div
                *ngIf="
                  AirQualitySensorTempratureCId.errors &&
                  (AirQualitySensorTempratureCId.dirty || AirQualitySensorTempratureCId.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!AirQualitySensorTempratureCId.errors.required" class="text-danger">
                  Air quality sensor temprature Celcius id is required.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="isAirQuality && !isHaltian">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="SensorAirQualityId"><span id="red-circle">&#x25CF;</span> Sensor Air Quality Id</label>
            <div>
              <input
                id="SensorAirQualityId"
                name="SensorAirQualityId"
                #SensorAirQualityId="ngModel"
                type="text"
                class="form-control"
                placeholder="Sensor Air Quality Id"
                [(ngModel)]="sensor.AirQualitySensorConfiguration.SensorAirQualityId"
                required
                maxlength="99"
                autocomplete="off"
              />
              <div
                *ngIf="SensorAirQualityId.errors && (SensorAirQualityId.dirty || SensorAirQualityId.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!SensorAirQualityId.errors.required" class="text-danger">
                  Sensor air quality id is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="AirQualitySensorHumidityId"
              ><span id="red-circle">&#x25CF;</span> Air Quality Sensor Humidity Id</label
            >
            <div>
              <input
                id="AirQualitySensorHumidityId"
                name="AirQualitySensorHumidityId"
                #AirQualitySensorHumidityId="ngModel"
                type="text"
                class="form-control"
                placeholder="Air Quality Sensor Humidity Id"
                [(ngModel)]="sensor.AirQualitySensorConfiguration.AirQualitySensorHumidityId"
                required
                maxlength="99"
                autocomplete="off"
              />
              <div
                *ngIf="
                  AirQualitySensorHumidityId.errors &&
                  (AirQualitySensorHumidityId.dirty || AirQualitySensorHumidityId.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!AirQualitySensorHumidityId.errors.required" class="text-danger">
                  Air quality sensor humidity id is required.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="isAirQuality && !isHaltian">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="AirQualitySensorCO2Id"
              ><span id="red-circle">&#x25CF;</span> Air Quality Sensor CO<sub>2</sub> Id</label
            >
            <div>
              <input
                id="AirQualitySensorCO2Id"
                name="AirQualitySensorCO2Id"
                #AirQualitySensorCO2Id="ngModel"
                type="text"
                class="form-control"
                placeholder="Air Quality Sensor CO2 Id"
                [(ngModel)]="sensor.AirQualitySensorConfiguration.AirQualitySensorCO2Id"
                required
                maxlength="99"
                autocomplete="off"
              />
              <div
                *ngIf="AirQualitySensorCO2Id.errors && (AirQualitySensorCO2Id.dirty || AirQualitySensorCO2Id.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!AirQualitySensorCO2Id.errors.required" class="text-danger">
                  Air quality sensor CO<sub>2</sub> id is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="AirQualitySensorCH2OId"
              ><span id="red-circle">&#x25CF;</span> Air Quality Sensor CH<sub>2</sub>O Id</label
            >
            <div>
              <input
                id="AirQualitySensorCH2OId"
                name="AirQualitySensorCH2OId"
                #AirQualitySensorCH2OId="ngModel"
                type="text"
                class="form-control"
                placeholder="Air Quality Sensor CH2O Id"
                [(ngModel)]="sensor.AirQualitySensorConfiguration.AirQualitySensorCH2OId"
                required
                maxlength="99"
                autocomplete="off"
              />
              <div
                *ngIf="
                  AirQualitySensorCH2OId.errors && (AirQualitySensorCH2OId.dirty || AirQualitySensorCH2OId.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!AirQualitySensorCH2OId.errors.required" class="text-danger">
                  Air quality sensor CH<sub>2</sub>O id is required.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="isAirQuality && !isHaltian">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="AirQualitySensorPressureId"
              ><span id="red-circle">&#x25CF;</span> Air Quality Sensor Pressure Id</label
            >
            <div>
              <input
                id="AirQualitySensorPressureId"
                name="AirQualitySensorPressureId"
                #AirQualitySensorPressureId="ngModel"
                type="text"
                class="form-control"
                placeholder="Air Quality Sensor Pressure Id"
                [(ngModel)]="sensor.AirQualitySensorConfiguration.AirQualitySensorPressureId"
                required
                maxlength="99"
                autocomplete="off"
              />
              <div
                *ngIf="
                  AirQualitySensorPressureId.errors &&
                  (AirQualitySensorPressureId.dirty || AirQualitySensorPressureId.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!AirQualitySensorPressureId.errors.required" class="text-danger">
                  Air quality sensor pressure id is required.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="AirQualitySensorStatusId"
              ><span id="red-circle">&#x25CF;</span> Air Quality Sensor Status Id</label
            >
            <div>
              <input
                id="AirQualitySensorStatusId"
                name="AirQualitySensorStatusId"
                #AirQualitySensorStatusId="ngModel"
                type="text"
                class="form-control"
                placeholder="Air Quality Sensor Status Id"
                [(ngModel)]="sensor.AirQualitySensorConfiguration.AirQualitySensorStatusId"
                required
                maxlength="99"
                autocomplete="off"
              />
              <div
                *ngIf="
                  AirQualitySensorStatusId.errors &&
                  (AirQualitySensorStatusId.dirty || AirQualitySensorStatusId.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!AirQualitySensorStatusId.errors.required" class="text-danger">
                  Air quality sensor status id is required.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="isHaltian && !isAirQuality">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="FilledDistanceInMilimeters"
              ><span id="red-circle">&#x25CF;</span> Filled Distance In Millimeters</label
            >
            <div>
              <input
                id="FilledDistanceInMilimeters"
                name="FilledDistanceInMilimeters"
                #FilledDistanceInMilimeters="ngModel"
                type="number"
                class="form-control"
                placeholder="Filled Distance In Millimeters"
                [(ngModel)]="sensor.FilledDistanceInMillimeters"
                min="0"
                pattern="^(\d{1,5}|\d{0,5}\.\d{1,3})$"
                autocomplete="off"
                required
              />
              <div
                *ngIf="
                  FilledDistanceInMilimeters.errors &&
                  (FilledDistanceInMilimeters.dirty || FilledDistanceInMilimeters.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!FilledDistanceInMilimeters.errors.required" class="text-danger">
                  Filled distance in millimeters is required.
                </div>
                <div [hidden]="!FilledDistanceInMilimeters.errors.pattern" class="text-danger">
                  Filled distance in millimeters should not exceed more than 3 decimal.
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="EmptyDistanceInMilimeters"
              ><span id="red-circle">&#x25CF;</span> Empty Distance In Millimeters</label
            >
            <div>
              <input
                id="EmptyDistanceInMilimeters"
                name="EmptyDistanceInMilimeters"
                #EmptyDistanceInMilimeters="ngModel"
                type="number"
                class="form-control"
                placeholder="Empty Distance In Millimeters"
                [(ngModel)]="sensor.EmptyDistanceInMillimeters"
                min="0"
                pattern="^(\d{1,5}|\d{0,5}\.\d{1,3})$"
                autocomplete="off"
                required
              />
              <div
                *ngIf="
                  EmptyDistanceInMilimeters.errors &&
                  (EmptyDistanceInMilimeters.dirty || EmptyDistanceInMilimeters.touched)
                "
                class="alert alert-danger"
              >
                <div [hidden]="!EmptyDistanceInMilimeters.errors.required" class="text-danger">
                  Empty distance in millimeters is required.
                </div>
                <div [hidden]="!EmptyDistanceInMilimeters.errors.pattern" class="text-danger">
                  Empty distance in millimeters should not exceed more than 3 decimal.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="latitude">Latitude</label>
            <div>
              <input
                type="number"
                placeholder="Latitude"
                name="latitude"
                #Latitude="ngModel"
                min="-90"
                max="90"
                class="form-control"
                [(ngModel)]="sensor.Latitude"
                pattern="^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)"
              />
              <div *ngIf="Latitude.errors && (Latitude.dirty || Latitude.touched)" class="alert alert-danger">
                <div [hidden]="!Latitude.errors.pattern" class="text-danger">
                  {{ utilitiesService.latitudeRegexMessage }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="longitude">Longitude</label>
            <div>
              <input
                type="number"
                placeholder="Longitude"
                name="longitude"
                #Longitude="ngModel"
                min="-180"
                max="180"
                class="form-control"
                [(ngModel)]="sensor.Longitude"
                pattern="[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$"
              />
              <div *ngIf="Longitude.errors && (Longitude.dirty || Longitude.touched)" class="alert alert-danger">
                <div [hidden]="!Longitude.errors.pattern" class="text-danger">
                  {{ utilitiesService.longitudeRegexMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="Altitude">Altitude</label>
            <div>
              <input
                type="number"
                placeholder="Altitude"
                name="altitude"
                #Altitude="ngModel"
                class="form-control"
                [(ngModel)]="sensor.Altitude"
                pattern="\d+(\.\d*)?|\.\d+"
              />
              <div *ngIf="Altitude.errors && (Altitude.dirty || Altitude.touched)" class="alert alert-danger">
                <div [hidden]="!Altitude.errors.pattern" class="text-danger">Altitude pattern does not match.</div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section" *ngIf="sensor.SensorId">
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <div>
              <mat-checkbox [(ngModel)]="sensor.IsActive" name="isActive">Is Active</mat-checkbox>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type1 pull-right btn-cancel"
            (click)="clearFormData(SensorForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!sensor.SensorId"
            class="btn btn-type btn-save"
            [disabled]="
              !SensorForm.form.valid ||
              isReqComplete ||
              !sensor.SensorType.SensorTypeId ||
              !sensor.Venue.VenueId ||
              !sensor.SensorManufacturer.SensorManufacturerId
            "
            (click)="addSensor(SensorForm)"
          />
          <input
            type="button"
            value="Save and Add New"
            *ngIf="!sensor.SensorId"
            class="btn btn-type btn-save btn-add-new"
            [disabled]="
              !SensorForm.form.valid ||
              isReqComplete ||
              !sensor.SensorType.SensorTypeId ||
              !sensor.Venue.VenueId ||
              !sensor.SensorManufacturer.SensorManufacturerId
            "
            (click)="addSensor(SensorForm, true)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="sensor.SensorId"
            class="btn btn-type btn-save"
            [disabled]="
              !SensorForm.form.valid ||
              isReqComplete ||
              !sensor.SensorType.SensorTypeId ||
              !sensor.Venue.VenueId ||
              !sensor.SensorManufacturer.SensorManufacturerId ||
              !canAccess('SENSORS_EDIT', 'view')
            "
            (click)="updateSensor(SensorForm)"
          />
        </div>
      </div>
    </form>
  </div>
</section>
