import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthState } from 'src/app/store/auth.state';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { BuildingModule } from '../../../venuemanagement/building/building.module';
import { VenueService } from '../../../venuemanagement/venue/components/venue.http.service';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { LocationService } from '../../location/components/location.http.service';
import { ZoneModule } from '../zone.module';
import { ZoneService } from './zone.http.service';

@Component({
  selector: 'zone.component',
  templateUrl: 'zone.component.html',
  providers: [ZoneService, UtilitiesService, VenueService, LocationService],
})
export class ZoneComponent implements OnInit {
  currentUser: User;

  public zones: ZoneModule[] = [];
  public nearByZones: ZoneModule[] = [];
  public zone: ZoneModule = new ZoneModule();
  public venues: VenueModule[] = [];
  public venue: VenueModule = new VenueModule();
  public buildings: BuildingModule[] = [];
  // public building: BuildingModule = new BuildingModule();

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  buildingDisabled = true;
  zoneDisabled = true;
  selectedZoneId;
  isReqComplete = false;
  isNearByZoneSelect = true;
  isBuildingInVenue = false;
  isZoneInBuilding = false;
  checkCount = 0;
  listviewtypes: any[];
  viewType: any = null;
  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  corporationNameForm: string;
  venueBind: number;
  public pageState = [{ pageName: 'zoneForm', page: this.page, size: this.size, viewType: this.viewType }];

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private zoneService: ZoneService,
    private venueService: VenueService,
    private locationService: LocationService,
    private loaderService: LoaderService,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.zone.Building = new BuildingModule();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();

    this.venueBind = null;
    this.venueBind = this.authState.getStoredContextVenueId();
  }

  public canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.viewType = this.pageState[0].viewType;
      this.getAllZones(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllZones(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.searchString = '';
    this.zones = null;
    this.count = 0;
    this.selectedZoneId = null;
    this.checkCount = 0;
    this.zone = new ZoneModule();
    this.zone.Building = new BuildingModule();
    this.zone.Building.Venue = new VenueModule();

    this.getAllVenues('', -1, -1);
    setTimeout(() => {
      const element = document.getElementById('zoneName');
      element.focus();
    }, 1000);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.zones = null;
    this.count = 0;
    this.checkCount = 0;
    this.getAllVenues('', -1, -1);
  }

  public hasData(): boolean {
    return this.zones !== null && this.zones.length > 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllZones(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public ZonePageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllZones(this.searchString, this.page, this.size, this.viewType);
  }

  public clearFormData(_ZoneForm: NgForm) {
    _ZoneForm.reset();
    this.searchString = '';
    this.buildings = [];
    this.buildingDisabled = true;
    this.zone = new ZoneModule();
    this.zone.Building = new BuildingModule();
    this.zone.Building.Venue = new VenueModule();

    this.getAllZones(this.searchString, this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllZones(this.searchString, this.page, this.size, this.viewType);
  }

  public zonesSearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllZones(searchString, 1, this.size, this.viewType);
  }

  public addZone(ZoneForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (ZoneForm.status == 'INVALID' && ZoneForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Zone', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.zone.ZoneName = this.utilitiesService.removeInnerSpaces(this.zone.ZoneName);
    if (this.zone.ZoneName === null || this.zone.ZoneName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }
    this.zone.AddedBy = this.currentUser.UserId;

    this.zoneService.Add(this.utilitiesService.stripScript(this.zone), this.isNearByZoneSelect).subscribe(
      (data) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Zone', data.Message);
        this.clearFormData(ZoneForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('zoneService addZone Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public updateZone(ZoneForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (ZoneForm.status == 'INVALID' && ZoneForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Zone', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.zone.ZoneName = this.utilitiesService.removeInnerSpaces(this.zone.ZoneName);
    if (this.zone.ZoneName === null || this.zone.ZoneName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }
    this.zone.ModifiedBy = this.currentUser.UserId;
    this.zone.IsActive = this.zone.IsActive ? 1 : 0;

    this.zoneService.Update(this.utilitiesService.stripScript(this.zone), this.isNearByZoneSelect).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Zone', data.Message);
        this.clearFormData(ZoneForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('zoneService updateZone Call :' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public getZoneById(ZoneId: number) {
    if (this.canAccess('ZONE_VIEW', 'view')) {
      this.loaderService.display(true);
      this.selectedZoneId = ZoneId;
      const CPzone = new ZoneModule();
      CPzone.ZoneCPId = this.authState.AESEncryptText(ZoneId);

      this.zoneService.GetSingle(CPzone).subscribe(
        (data) => {
          this.zone = data;
          if (this.zone.Building.Venue.VenueId !== null && this.zone.Building.Venue.VenueId > 0) {
            this.getAllBuildings(this.zone.Building.Venue.VenueId);
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'zoneService getZoneById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
          this.loaderService.display(false);
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Zone', 'You do not have permission to view zone details.');
    }
  }

  public getAllZones(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.zoneService.GetZoneList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.zones = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log('zoneService getAllZones Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
      () => (this.count = this.zones[0] ? this.zones[0].Count : 0),
    );
  }

  // Get List Of Venue
  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;

        if (!this.zones && !this.zone.ZoneId) {
          const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
          if (obj[0]) {
            this.zone.Building.Venue.VenueId = obj[0].VenueId;
            this.getAllBuildings(this.zone.Building.Venue.VenueId);
          }
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService getAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  // Get list of buildings on the basis of venue
  public getAllBuildings($event: any) {
    this.zone.Building.Venue.VenueId = $event;
    this.venue.VenueId = $event;

    if (this.venue.VenueId === null) {
      this.buildings = [];
      this.buildingDisabled = true;
      this.zone.Building.BuildingId = null;
      this.checkIsValueSelected(this.zone.Building.BuildingId, 'building');
    } else {
      if (this.venue.VenueId) {
        this.loaderService.display(true);
        const CPvenue = new VenueModule();
        CPvenue.VenueCPId = this.authState.AESEncryptText(this.venue.VenueId);

        this.locationService.GetBuildingDropdown(CPvenue).subscribe(
          (data) => {
            this.buildings = data;
            this.buildingDisabled = false;
            this.checkIsValueSelected(this.zone.Building.BuildingId, 'building');
            this.loaderService.display(false);
          },
          (error) => {
            this.loaderService.display(false);
            console.log(
              'locationService GetAllBuildings Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
            );
          },
        );
      }
    }
  }

  public checkIsValueSelected(value: any, control: any) {
    switch (control) {
      case 'building':
        if (value !== null) {
          const buildingList = this.buildings;
          if (buildingList.length > 0) {
            for (let i = 0; i < buildingList.length; i++) {
              const item = buildingList[i];
              if (value === item.BuildingId) {
                this.isBuildingInVenue = true;
                this.zone.Building.BuildingId = value;
                break;
              } else if (i === buildingList.length - 1) {
                this.isBuildingInVenue = false;
                this.zone.Building.BuildingId = null;
              } else {
                this.isBuildingInVenue = false;
              }
            }
          } else {
            this.isBuildingInVenue = false;
            this.zone.Building.BuildingId = null;
          }
        } else {
          this.isBuildingInVenue = false;
          this.zone.Building.BuildingId = null;
        }
        break;
    }
    this.checkCount++;
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState[0].viewType = this.viewType;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllZones(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllZones(this.searchString, this.page, this.size, this.viewType);
  }
}
