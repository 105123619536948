import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';
import { SafePipe, UserComponent } from './components/user.component';
import { PasswordResetDialogComponent } from './passwordreset/password-reset-dialog.component';
import { UserRoutes } from './user.routes';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    UserRoutes,
    FormsModule,
    NgxPaginationModule,
    HomeModule,
    NgxMaskModule.forRoot(options),
    NgMultiSelectDropDownModule,
  ],

  declarations: [UserComponent, SafePipe, PasswordResetDialogComponent],
})
export class UserModule {}
