import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { User } from '../../../usermanagement/user/user.model';
// import { AssetModule } from '../../../assetmanagement/asset/asset.module';
import { AssetModule } from 'src/app/assetmanagement/asset/asset.module';
import { LocationModule } from 'src/app/staffscheduling/location/location.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { ClosureTemplateModule, Closuretemplatesteps } from '../../closuretemplate/closuretemplate.module';
import { QCMaster } from '../../qcworkorder/qcworkorder.module';
import { MaintenanceWorkorderModule } from '../maintenanceworkorder.module';
import {
  WorkorderAndQCLogAndHistoryDetails,
  WorkorderModule,
  WorkorderPriority,
  WorkorderSeverity,
  WorkorderStatus,
} from '../workorder.module';

@Injectable({
  providedIn: 'root',
})
export class WorkorderService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) { }

  public GetAll = (body: string, page: number, size: number): Observable<WorkorderModule[]> => {
    const _url: string = this.configuration.Server + 'workorder/workorders/' + page + '/' + size;
    return this.http.post<WorkorderModule[]>(_url, body);
  };

  public GetSingle = (body: WorkorderModule): Observable<WorkorderModule> => {
    const _url: string = this.configuration.Server + 'workorder/details';
    return this.http.post<WorkorderModule>(_url, body);
  };

  public Add = (body: WorkorderModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/asset/location';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: WorkorderModule): Observable<WorkorderModule> => {
    const _url: string = this.configuration.Server + 'workorder/asset/location';
    return this.http.put<WorkorderModule>(_url, body);
  };

  public GetAllAssignedToByZone = (body: WorkorderModule): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'workorder/workorder/zone/users';
    return this.http.post<User[]>(_url, body);
  };

  public UpdateWorkorderAssignee = (body: WorkorderModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/assignee';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetWorkorderDetails = (body: WorkorderModule): Observable<WorkorderAndQCLogAndHistoryDetails> => {
    const _url: string = this.configuration.Server + 'workorder/history/details';
    return this.http.post<WorkorderAndQCLogAndHistoryDetails>(_url, body);
  };

  public GetWorkorderHistory = (body: WorkorderModule): Observable<WorkorderAndQCLogAndHistoryDetails[]> => {
    const _url: string = this.configuration.Server + 'workorder/cleaning/alert/history';
    return this.http.post<WorkorderAndQCLogAndHistoryDetails[]>(_url, body);
  };

  public GetQCWorkorderHistory = (body: QCMaster): Observable<WorkorderAndQCLogAndHistoryDetails[]> => {
    const _url: string = this.configuration.Server + 'workorder/qc/history';
    return this.http.post<WorkorderAndQCLogAndHistoryDetails[]>(_url, body);
  };

  public GetMaintenanceWorkorderHistory = (body: WorkorderModule): Observable<MaintenanceWorkorderModule[]> => {
    const _url: string = this.configuration.Server + 'workorder/maintenance/history';
    return this.http.post<MaintenanceWorkorderModule[]>(_url, body);
  };

  public GetMaintenanceWorkorderLog = (body: WorkorderModule): Observable<MaintenanceWorkorderModule> => {
    const _url: string = this.configuration.Server + 'workorder/maintenance/log';
    return this.http.post<MaintenanceWorkorderModule>(_url, body);
  };

  public GetWorkorderStatus = (body: WorkorderStatus): Observable<WorkorderStatus[]> => {
    const _url: string = this.configuration.Server + 'workorder/status/type';
    return this.http.post<WorkorderStatus[]>(_url, body);
  };

  public UpdateWorkorder = (body: WorkorderModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/workorder';
    return this.http.put<ResponseMessage>(_url, body);
  };

  // Maintenance workorder calls below--------------------------------------------------------------

  public AddMaintenanceWO = (body: WorkorderModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/workorder';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public UpdateMaintenanceWO = (body: WorkorderModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'workorder/maintenance/workorder';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetAllWOList = (
    body: WorkorderModule,
    page: number,
    size: number,
  ): Observable<MaintenanceWorkorderModule[]> => {
    const _url: string = this.configuration.Server + 'workorder/venue/maintenance/workorders/' + page + '/' + size;
    return this.http.post<MaintenanceWorkorderModule[]>(_url, body);
  };

  public GetSingleMaintenanceWO = (body: WorkorderModule): Observable<MaintenanceWorkorderModule> => {
    const _url: string = this.configuration.Server + 'workorder/maintenance/details';
    return this.http.post<MaintenanceWorkorderModule>(_url, body);
  };

  public GetAllPriorities = (): Observable<WorkorderPriority[]> => {
    const _url: string = this.configuration.Server + 'workorder/priorities';
    return this.http.get<WorkorderPriority[]>(_url);
  };

  public GetAllSeverities = (): Observable<WorkorderSeverity[]> => {
    const _url: string = this.configuration.Server + 'workorder/severities';
    return this.http.get<WorkorderSeverity[]>(_url);
  };

  public GetAllSupervisorsByVenue = (body: User): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'admin/venue/supervisors';
    return this.http.post<User[]>(_url, body);
  };

  public GetAllAssignedToBySupervisor = (body): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'admin/user/child/users';
    return this.http.post<User[]>(_url, body);
  };

  public GetAllClosureTemplatesByworkorderCategory = (
    body: ClosureTemplateModule,
    page: number,
    size: number,
  ): Observable<ClosureTemplateModule[]> => {
    const _url: string = this.configuration.Server + 'workorder/pin/category/templates/' + page + '/' + size;
    return this.http.post<ClosureTemplateModule[]>(_url, body);
  };

  public GetAllClosureTemplateSteps = (body: Closuretemplatesteps): Observable<Closuretemplatesteps[]> => {
    const _url: string = this.configuration.Server + 'workorder/workorder/closuretemplate/steps';
    return this.http.post<Closuretemplatesteps[]>(_url, body);
  };

  public GetAllUsers = (body: VenueModule): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'admin/venue/roles/usernames';
    return this.http.post<User[]>(_url, body);
  };

  public GetAllAssetsByLocation = (body: LocationModule, page: number, size: number): Observable<AssetModule[]> => {
    const _url: string = this.configuration.Server + 'asset/venue/building/location/assets/' + page + '/' + size;
    return this.http.post<AssetModule[]>(_url, body);
  };
}
