import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { AuthState } from 'src/app/store/auth.state';
import { Configuration } from '../../../app.constants';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { SensorTypeModule } from '../sensortype.module';
import { SensorTypeService } from './sensortype.http.service';

@Component({
  selector: 'sensor-type',
  templateUrl: 'sensortype.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [SensorTypeService, UtilitiesService],
})
export class SensorTypeComponent implements OnInit {
  @ViewChild('fileInput') fileInputVariable: any;
  currentUser: User;

  public sensortypes: SensorTypeModule[] = [];
  public sensortype: SensorTypeModule = new SensorTypeModule();

  page = 1;
  size = 10;
  count = 0;
  searchString = '';
  pageSizes: any[] = [];

  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  file: File = null;
  sensorTypeImgSrc: any = null;
  sensorTypeImg = 'assets/no-image.jpg';
  corporationNameForm: string;
  fileExtension: string;
  sensorTypeImgPath = this.configuration.Server_Sensor_Type_Image_Folder;
  sensorImg = '';
  public pageState = [{ pageName: 'sensortypeForm', page: this.page, size: this.size, viewType: this.viewType }];

  constructor(
    private sensortypeService: SensorTypeService,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    private configuration: Configuration,
    private sanitizer: DomSanitizer,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.viewType = this.pageState[0].viewType;
      this.getAllSensorTypes(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllSensorTypes(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.sensortype = new SensorTypeModule();
    this.searchString = '';
    this.sensortypes = null;
    this.count = 0;

    setTimeout(() => {
      const element = document.getElementById('SensorTypeName');
      element.focus();
    }, 1000);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.sensortypes = null;
    this.count = 0;
  }

  public clearFormData(sensortypeForm: NgForm) {
    this.sensortype = new SensorTypeModule();
    sensortypeForm.reset();
    this.searchString = '';
    this.sensorTypeImgSrc = null;
    this.fileExtension = '';
    this.getAllSensorTypes('', this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllSensorTypes('', this.page, this.size, this.viewType);
  }

  public sensorTypesSearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensorTypes(searchString, 1, this.size, this.viewType);
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensorTypes(this.searchString, this.page, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.sensortypes != null && this.sensortypes.length > 0;
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensorTypes('', this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public SensorTypesPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensorTypes(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllSensorTypes(this.searchString, this.page, this.size, this.viewType);
  }

  public addSensorType(sensortypeForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (sensortypeForm.status == 'INVALID' && sensortypeForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Sensor Type',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.sensortype.SensorTypeName = this.utilitiesService.removeInnerSpaces(this.sensortype.SensorTypeName);
    if (this.sensortype.SensorTypeName == null || this.sensortype.SensorTypeName == '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }
    this.sensortype.AddedBy = this.currentUser.UserId;

    this.sensortypeService.Add(this.utilitiesService.stripScript(this.sensortype)).subscribe(
      (data) => {
        if (this.file) {
          let that = this;
          this.sensortypeService
            .SensorTypeImage(this.file, data.id)
            .then(function (_response) {
              that.clearAddSensorTypeFun(sensortypeForm, data.Message);
            })
            .catch((err) => {
              console.log(err);
              this.clearAddSensorTypeFun(sensortypeForm, data.Message);
            });
        } else {
          this.clearAddSensorTypeFun(sensortypeForm, data.Message);
        }
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log('sensortypeService Add Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public clearAddSensorTypeFun(sensortypeForm: any, msg: any) {
    this.page = 1;
    this.isReqComplete = false;
    this.loaderService.display(false);
    this.clearFormData(sensortypeForm);
    this.utilitiesService.smmodal('Sensor Type', msg);
  }

  public updateSensorType(sensortypeForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (sensortypeForm.status == 'INVALID' && sensortypeForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Sensor Type',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.sensortype.SensorTypeName = this.utilitiesService.removeInnerSpaces(this.sensortype.SensorTypeName);
    if (this.sensortype.SensorTypeName == null || this.sensortype.SensorTypeName == '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    this.sensortype.ModifiedBy = this.currentUser.UserId;
    this.sensortype.IsActive = this.sensortype.IsActive ? 1 : 0;

    this.sensortypeService.Update(this.utilitiesService.stripScript(this.sensortype)).subscribe(
      (data) => {
        if (this.file) {
          let that = this;
          this.sensortypeService
            .SensorTypeImage(this.file, this.sensortype.SensorTypeId)
            .then(function (_response) {
              that.clearUpdateSensorTypeFun(sensortypeForm, data.Message);
            })
            .catch((err) => {
              console.log(err);
              this.clearUpdateSensorTypeFun(sensortypeForm, data.Message);
            });
        } else if (this.sensortype.SensorTypeImageName && this.sensorTypeImgSrc == null) {
          this.sensortypeService.RemoveSensorTypeImage(this.sensortype.SensorTypeId).subscribe(
            (_res) => {
              this.clearUpdateSensorTypeFun(sensortypeForm, data.Message);
            },
            (error) => {
              this.isReqComplete = false;
              this.loaderService.display(false);
              console.log(
                'sensortypeService removeImage Call Failed. Status:' +
                error.status +
                ' Status Text:' +
                error.statusText,
              );
            },
          );
        } else {
          this.clearUpdateSensorTypeFun(sensortypeForm, data.Message);
        }
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'sensortypeService Update Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public clearUpdateSensorTypeFun(sensortypeForm: any, msg: any) {
    this.isReqComplete = false;
    this.loaderService.display(false);
    this.clearFormData(sensortypeForm);
    this.utilitiesService.smmodal('Sensor Type', msg);
  }

  public getAllSensorTypes(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 0,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.sensortypeService.GetSensorTypesList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.sensortypes = data;
        if (this.sensortypes.length < 0 && this.sensortypes[0].Count / 10 < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'sensortypeService GetAllSensorTypes Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.sensortypes[0] ? this.sensortypes[0].Count : 0),
    );
  }

  public getSensorTypeById(SensorTypeId: number) {
    this.loaderService.display(true);
    const CPsensortype = new SensorTypeModule();
    CPsensortype.SensorTypeCPId = this.authState.AESEncryptText(SensorTypeId);

    this.sensortypeService.GetSingle(CPsensortype).subscribe(
      (data) => {
        this.sensortype = data;
        if (this.sensortype.SensorTypeImageName != null) {
          this.sensorTypeImgSrc =
            this.configuration.Server_Sensor_Type_Image_Folder + this.sensortype.SensorTypeImageName;
        } else {
          this.sensorTypeImg = 'assets/no-image.jpg';
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'sensortypeService GetSingle Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public fileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal('Sensor Type', this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        return;
      }

      const extention = event.target.files[0].name.split('.');
      const ext = extention[extention.length - 1].toLowerCase();
      this.fileExtension = ext;
      if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'svg') {
        if (event.target.files && event.target.files[0]) {
          const filesize = event.target.files[0].size / Math.pow(1024, 2);
          if (filesize > this.utilitiesService.fileUploadSize) {
            this.utilitiesService.mdmodal(
              'Sensor Type',
              'Picture should not be more than ' + this.utilitiesService.fileUploadSize + ' MB.',
            );
            event.target.value = '';
            this.loaderService.display(false);
            return;
          } else {
            const fileList: FileList = event.target.files;
            if (fileList.length > 0) {
              this.file = fileList[0];
              const reader = new FileReader();
              reader.onload = this._handleReaderLoaded.bind(this);
              reader.readAsBinaryString(this.file);
            }
          }
        }
      } else {
        this.utilitiesService.smmodal('Sensor Type', 'Please upload image of type .jpg, .jpeg, .png, .svg.');
        event.target.value = '';
      }
    }
  }

  _handleReaderLoaded(readerEvt: any) {
    const binaryString = readerEvt.target.result;
    if (this.fileExtension === 'svg') {
      let isMaliciousTagsFound = false;
      for (const element of this.utilitiesService.maliciousTags) {
        if (binaryString.search(element.name) >= 1) {
          isMaliciousTagsFound = true;
          break;
        }
      }
      if (isMaliciousTagsFound) {
        this.fileInputVariable.nativeElement.value = '';
        this.file = null;
        this.utilitiesService.smmodal(
          'Asset',
          'Your image file contains malicious code, thus please upload valid image file.',
        );
        return;
      } else {
        const testSVG = DOMPurify.sanitize(binaryString);
        const fileName = this.file.name;
        const newFile = new File([testSVG], fileName, { type: 'image/svg+xml', lastModified: new Date().getTime() });
        this.file = newFile;
        this.sensorTypeImgSrc = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(newFile));
      }
    } else {
      this.sensorTypeImgSrc = 'data:image/jpeg;base64,' + btoa(binaryString); // Converting binary string data.
    }
  }

  fileReset() {
    this.fileInputVariable.nativeElement.value = '';
    this.file = null;
    this.sensorTypeImgSrc = null;
  }
  public openSensorImage(imageName: string) {
    this.sensorImg = '';
    this.sensorImg = this.sensorTypeImgPath + imageName;
  }
}
