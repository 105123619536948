<ng-select
  *ngIf="required"
  [allowClear]="allowClear"
  bindLabel="text"
  bindValue="id"
  [clearable]="allowClear"
  [items]="items"
  (change)="selected($event)"
  [readonly]="disabled"
  (typed)="typedfn($event)"
  (remove)="removedfn($event)"
  (data)="refreshValue($event)"
  [multiple]="multiple"
  [placeholder]="placeholder"
  (clear)="removedfn($event)"
  [ngClass]="{ selected: isValueSelected }"
  [(ngModel)]="selectedValue"
  required
>
</ng-select>

<ng-select
  *ngIf="!required"
  [allowClear]="allowClear"
  bindLabel="text"
  bindValue="id"
  [clearable]="allowClear"
  [items]="items"
  (change)="selected($event)"
  [readonly]="disabled"
  (typed)="typedfn($event)"
  (remove)="removedfn($event)"
  (clear)="removedfn($event)"
  (data)="refreshValue($event)"
  [multiple]="multiple"
  [placeholder]="placeholder"
  [ngClass]="{ selected: isValueSelected }"
  [(ngModel)]="selectedValue"
>
</ng-select>
