import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Configuration } from '../../../app.constants';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AccessGroupPermissionModule } from '../accessgrouppermission.module';
import { AccessGroupModule } from '../../accessgroup/accessgroup.module';
import { RelUserRolePermissions } from '../../role/role.module';

@Injectable({
  providedIn: 'root',
})
export class AccessGroupPermissionService {
  private headers: HttpHeaders;
  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (body: AccessGroupModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/accessgroup';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: AccessGroupModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/accessgroup';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetAll = (body: string, page: number, size: number): Observable<AccessGroupPermissionModule[]> => {
    const _url: string = this.configuration.Server + 'admin/access/group/permissions/details/' + page + '/' + size;
    return this.http.post<AccessGroupPermissionModule[]>(_url, body);
  };

  public GetById = (accessGroupPermissionId: number): Observable<AccessGroupPermissionModule> => {
    const _url: string = this.configuration.Server + 'admin/access/group/permission/' + accessGroupPermissionId;
    return this.http.get<AccessGroupPermissionModule>(_url);
  };

  public GetAllPermissions = (body: string, page: number, size: number): Observable<RelUserRolePermissions[]> => {
    const _url: string = this.configuration.Server + 'admin/access/group/permissions/' + page + '/' + size;
    return this.http.post<RelUserRolePermissions[]>(_url, body);
  };
}
