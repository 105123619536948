<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="beacons">BEACONS</span>
        <span class="list-heading" *ngIf="!beacons && !beacon.BeaconId">ADD BEACON</span>
        <span class="list-heading" *ngIf="!beacons && beacon.BeaconId">EDIT BEACON</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="beacons">
          <ngx-atlas-select
            (model)="BeaconPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="beacons && !beacon.BeaconId && canAccess('BEACON_ADD', 'view')"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Beacon
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="beacons">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="beacons">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="beaconsSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="beacons">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('BeaconName', columnSortOrder)">
                  Name
                  <span *ngIf="selectedColumnName == 'BeaconName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BeaconName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('BeaconCode', columnSortOrder)">
                  Code
                  <span *ngIf="selectedColumnName == 'BeaconCode' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BeaconCode' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('Range', columnSortOrder)">
                  Range
                  <span *ngIf="selectedColumnName == 'Range' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'Range' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('BatteryLevel', columnSortOrder)">
                  Battery Level
                  <span *ngIf="selectedColumnName == 'BatteryLevel' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'BatteryLevel' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header">Location</th>
                <th class="table-sort-header" (click)="sortColumn('VenueName', columnSortOrder)">
                  Venue
                  <span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">Status</th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let beacon of beacons
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getBeacontById(beacon.BeaconId)">
                <td>{{ beacon.BeaconName }}</td>
                <td>{{ beacon.BeaconCode }}</td>
                <td>{{ beacon.Range }} (in meters)</td>
                <td *ngIf="beacon.BatteryLevel != null">{{ beacon.BatteryLevel }}%</td>
                <td *ngIf="beacon.BatteryLevel == null">-</td>
                <td style="word-break: break-word">{{ beacon.LocationName }}</td>
                <td>{{ beacon.VenueName }}</td>
                <td *ngIf="beacon.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!beacon.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!beacons">
    <form #BeaconForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="beaconcode"><span id="red-circle">&#x25CF;</span> Beacon Code</label>
            <div>
              <input
                id="BeaconCode"
                type="text"
                class="form-control"
                name="Beaconcode"
                placeholder="Beacon Code"
                [(ngModel)]="beacon.BeaconCode"
                required
                #BeaconCode="ngModel"
                pattern="^[a-zA-Z0-9-_]+$"
                maxlength="49"
                autocomplete="off"
              />
              <div *ngIf="BeaconCode.errors && (BeaconCode.dirty || BeaconCode.touched)" class="alert alert-danger">
                <div [hidden]="!BeaconCode.errors.required" class="text-danger beacons">Beacon code is required.</div>
                <div [hidden]="!BeaconCode.errors.pattern" class="text-danger">
                  Beacon code does not accept blank space and special characters.
                  <!--{{utilitiesService.alphanumericRegexMessage}}-->
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="beaconname"><span id="red-circle">&#x25CF;</span> Beacon Name</label>
            <div>
              <input
                type="text"
                class="form-control"
                name="Beaconname"
                placeholder="Beacon Name"
                [(ngModel)]="beacon.BeaconName"
                required
                #BeaconName="ngModel"
                maxlength="49"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div *ngIf="BeaconName.errors && (BeaconName.dirty || BeaconName.touched)" class="alert alert-danger">
                <div [hidden]="!BeaconName.errors.required" class="text-danger">Beacon name is required.</div>
                <div [hidden]="!BeaconName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="Range"><span id="red-circle">&#x25CF;</span> Range ( in meters )</label>
            <div>
              <input
                type="number"
                class="form-control"
                name="Range"
                placeholder="Range"
                [(ngModel)]="beacon.Range"
                required
                #range="ngModel"
                min="0"
                step="0.01"
                validation="pattern=/[0-9]{2}\.[0-9]{2}$/:alt=Invalid number"
                (keypress)="keyPressCheck($event)"
              />
              <div *ngIf="range.errors && (range.dirty || range.touched)" class="alert alert-danger">
                <div [hidden]="!range.errors.required" class="text-danger">Range is required.</div>
                <div [hidden]="!range.errors.pattern" class="text-danger">Range accepts only numeric value.</div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="batterylevel"> Battery Level</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping" *ngIf="beacon.BatteryLevel != null">{{ beacon.BatteryLevel }}%</p>
                <p class="readOnly clipping" *ngIf="beacon.BatteryLevel == null">-</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="Venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div>
              <ngx-atlas-select
                (model)="changeVenue($event)"
                [list]="venues"
                [idField]="'VenueId'"
                [textField]="'VenueName'"
                [placeholder]="'Select Venue'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="beacon.Venue.VenueId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="BeaconManufacturer">Beacon Manufacturer</label>
            <div>
              <input
                type="text"
                class="form-control"
                name="BeaconManufacturer"
                [(ngModel)]="beacon.Venue.BeaconManufacturer"
                #BeaconManufacturer="ngModel"
                maxlength="100"
                autocomplete="off"
                readonly="readonly"
                placeholder="Beacon Manufacturer"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 form-section">
          <div class="col-md-6 col-xs-12 form-group">
            <label for="BeaconType"><span id="red-circle">&#x25CF;</span> Beacon Type</label>
            <div>
              <ngx-atlas-select
                (model)="beaconTypeSelection($event)"
                [list]="beaconTypes"
                [idField]="'idfield'"
                [textField]="'textfield'"
                [placeholder]="'Select Beacon Type'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="beacon.BeaconType"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="beacon.BeaconType == 2">
            <label for="identifier"><span id="red-circle">&#x25CF;</span> Identifier</label>
            <div>
              <input
                type="text"
                class="form-control"
                name="Identifier"
                placeholder="Identifier"
                [(ngModel)]="beacon.Identifier"
                required
                #Identifier="ngModel"
                pattern="[A-Za-z0-9]+$"
              />
              <div *ngIf="Identifier.errors && (Identifier.dirty || Identifier.touched)" class="alert alert-danger">
                <div [hidden]="!Identifier.errors.required" class="text-danger">Identifier is required.</div>
                <div [hidden]="!Identifier.errors.pattern" class="text-danger">
                  Identifier accepts only alphanumeric value.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="beacon.BeaconType == 1">
            <label for="uuId"><span id="red-circle">&#x25CF;</span> UUID</label>
            <div>
              <input
                type="text"
                class="form-control"
                name="UuId"
                placeholder="UUID"
                [(ngModel)]="beacon.UuId"
                required
                #uuId="ngModel"
                pattern="[A-Za-z0-9-]+$"
              />
              <!--pattern="[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}"-->
              <div *ngIf="uuId.errors && (uuId.dirty || uuId.touched)" class="alert alert-danger">
                <div [hidden]="!uuId.errors.required" class="text-danger">UUID is required.</div>
                <div [hidden]="!uuId.errors.pattern" class="text-danger">
                  UUID must be alphanumeric, special characters and space not allowed.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="beacon.BeaconType == 1">
            <label for="MajorId"><span id="red-circle">&#x25CF;</span> Major Id</label>
            <div>
              <input
                type="number"
                min="0"
                max="65535"
                class="form-control"
                name="Majorid"
                placeholder="Major Id"
                [(ngModel)]="beacon.MajorId"
                required
                #majorId="ngModel"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                pattern="[0-9]*$"
              />
              <div *ngIf="majorId.errors && (majorId.dirty || majorId.touched)" class="alert alert-danger">
                <div [hidden]="!majorId.errors.required" class="text-danger">Major id is required.</div>
                <div [hidden]="!majorId.errors.pattern" class="text-danger">
                  Major id does not accepts decimal values.
                </div>
                <div [hidden]="!majorId.errors.max" class="text-danger">
                  Major id does not accepts value greater than 65535.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="beacon.BeaconType == 1">
            <label for="MinorId"><span id="red-circle">&#x25CF;</span> Minor Id</label>
            <div>
              <input
                type="number"
                min="0"
                max="65535"
                class="form-control"
                name="Minorid"
                placeholder="Minor Id"
                [(ngModel)]="beacon.MinorId"
                required
                #minorId="ngModel"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                pattern="[0-9]*$"
              />
              <div *ngIf="minorId.errors && (minorId.dirty || minorId.touched)" class="alert alert-danger">
                <div [hidden]="!minorId.errors.required" class="text-danger">Minor id is required.</div>
                <div [hidden]="!minorId.errors.pattern" class="text-danger">
                  Minor id does not accepts decimal values.
                </div>
                <div [hidden]="!minorId.errors.max" class="text-danger">
                  Minor id not accepts value greater than 65535.
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 form-group" *ngIf="beacon.BeaconId">
            <div>
              <div>
                <mat-checkbox [(ngModel)]="beacon.IsActive" name="isActive">Is Active</mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 btn-section">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type1 pull-right btn-cancel"
            (click)="clearFormData(BeaconForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!beacon.BeaconId"
            class="btn btn-type btn-save"
            [disabled]="!BeaconForm.form.valid || isReqComplete || !beacon.BeaconType || !beacon.Venue.VenueId"
            (click)="addBeacon(BeaconForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="beacon.BeaconId"
            class="btn btn-type btn-save"
            [disabled]="
              !BeaconForm.form.valid ||
              isReqComplete ||
              !beacon.BeaconType ||
              !beacon.Venue.VenueId ||
              !canAccess('BEACON_EDIT', 'view')
            "
            (click)="updateBeacon(BeaconForm)"
          />
        </div>
      </div>
    </form>
  </div>
</section>
