import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';
import { GenericEntity } from '../../shared/genericentity/genericentity';

import { ElementComponent } from './components/element.component';
import { ElementRoutes } from './element.routes';

import { VenueModule } from '../../venuemanagement/venue/venue.module';

@NgModule({
  imports: [CommonModule, ElementRoutes, FormsModule, NgxPaginationModule, HomeModule],

  declarations: [ElementComponent],
})
export class ElementModule extends GenericEntity {
  public ElementId: number;
  public ElementCPId: string;
  public ElementName: string;
  public Sequence: number;
  public Venue: VenueModule;
  public ImageName: string;
  public ImageType: string;
  public ImageTypeName: string;

  public VenueLocationTypeId: number;
  public VenueLocationTypeName: string;
  public isAllowToEdit: any;

  public IsImageRemoved: any;
  public ImageBase64String: string;
  public showElementImage: string;
  public MasterElementId: number;
  public IsMasterElement: any;
  public AttachmentURL: string;
}
