<header class="main-header">
  <a (click)="navigationClick('dashboard')" class="logo">
    <span class="logo-lg">
      <!-- <img class="full-logo img-responsive" alt="Logo" [src]="logoName" /> -->
      <img *ngIf="defaultlogo" class="full-logo-default img-responsive" />
      <img *ngIf="!defaultlogo" class="full-logo img-responsive" [src]="logoName" />
    </span>
  </a>

  <nav class="navbar navbar-static-top">
    <a class="sidebar-toggle" data-toggle="offcanvas" role="button" title="Menu" (click)="resizeStatisticsChart()">
      <span class="sr-only">Toggle navigation</span>
    </a>

    <label class="powered-by">{{ poweredBy }}</label>

    <div class="header-bind col-xs-12">
      <span style="display: flex; cursor: default"
        ><b style="margin-right: 3px; font-weight: lighter; cursor: default">Corporation:</b>
        {{ configuration.CorporationName }}</span
      >
      <span style="display: flex; cursor: default"
        ><b style="margin-right: 3px; font-weight: lighter; cursor: default">Venue:</b>
        {{ configuration.VenueName }}</span
      >
    </div>

    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav">
        <li class="dropdown user user-menu">
          <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="true">
            <span class="hidden-xs pull-right">{{ currentUserVal.FirstName }} {{ currentUserVal.LastName }}</span>
            <img [src]="profileImgSrc != null ? profileImgSrc : profileImg" class="user-image" alt="Profile" />
          </a>

          <ul class="dropdown-menu col-md-12 col-lg-12 col-xs-12">
            <li class="user-profile-dropdown col-md-12 col-lg-12 col-xs-12">
              <div class="userProfile col-md-12 col-lg-12 col-xs-12">
                <a
                  class="role-name"
                  *ngIf="currentUserVal.ContextRoleId == null || currentUserVal.ContextRoleId == 0"
                  >{{ currentUserVal.UserRole.RoleName }}</a
                >
                <a class="role-name" *ngIf="currentUserVal.ContextRoleId != null || currentUserVal.ContextRoleId > 0">{{
                  currentUserVal.ContextRoleName
                }}</a>
              </div>

              <div
                class="userProfile col-md-12 col-lg-12 col-xs-12"
                *ngIf="canAccess('profile-menu', 'view')"
                (click)="navigationClick('profile-menu')"
              >
                <a class="profileName"><i class="fa fa-id-badge"></i>Profile</a>
              </div>

              <div class="userProfile col-md-12 col-lg-12 col-xs-12" (click)="configurationPopup()">
                <a class="profileName"> <i class="fa fa-cog"></i>Configuration</a>
              </div>

              <div class="userProfile col-md-12 col-lg-12 col-xs-12" (click)="logout()">
                <a class="profileName"> <i class="fa fa-sign-out"></i>Sign Out</a>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</header>

<aside class="main-sidebar" id="main-sidebar">
  <section class="sidebar" id="sidebar">
    <ul class="sidebar-menu tree" data-widget="tree">
      <li
        class="treeview"
        *ngIf="canAccess('dashboard', 'view')"
        [routerLink]="'dashboard'"
        [routerLinkActive]="['active']"
        (click)="navSlide()"
      >
        <a (click)="navigationClick('dashboard')">
          <i class="fa fa-tachometer"></i><span>Dashboard</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        class="treeview"
        *ngIf="canAccess('overview-dashboard', 'view')"
        [routerLinkActive]="['active']"
        (click)="navSlide()"
      >
        <a (click)="navigationClick('overview-dashboard')" style="padding: 4px">
          <svg id="dashboard_copy" data-name="dashboard copy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <rect x="324.1" y="329.27" width="150.35" height="147.2" fill="#5b5f60" />
            <rect x="40.27" y="329.27" width="150.35" height="147.2" fill="#5b5f60" />
            <rect x="324.1" y="36.36" width="150.35" height="147.2" fill="#5b5f60" />
            <rect x="179.27" y="95.97" width="150.35" height="27.99" fill="#5b5f60" />
            <rect x="179.27" y="388.88" width="150.35" height="27.99" fill="#5b5f60" />
            <rect
              x="324.1"
              y="244.13"
              width="150.35"
              height="27.99"
              transform="translate(657.4 -141.15) rotate(90)"
              fill="#5b5f60"
            />
            <rect
              x="40.27"
              y="244.13"
              width="150.35"
              height="27.99"
              transform="translate(373.57 142.68) rotate(90)"
              fill="#5b5f60"
            />
            <rect x="40.27" y="36.36" width="150.35" height="147.2" fill="#5b5f60" />
          </svg>
          <span class="restroomOvr">Inspection Overview</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        class="treeview"
        *ngIf="canAccess('inspection-dashboard', 'view')"
        [routerLinkActive]="['active']"
        (click)="navSlide()"
      >
        <a style="padding: 4px" (click)="navigationClick('inspection-dashboard')">
          <svg xmlns="http://www.w3.org/2000/svg" id="Dashboard" viewBox="0 0 512 512">
            <rect x="31" y="38" width="228" height="228" fill="#0e2c44" />
            <rect x="321" y="116" width="156" height="150" fill="#0e2c44" />
            <rect x="321" y="330" width="156" height="150" fill="#0e2c44" />
            <rect x="103" y="330" width="156" height="150" fill="#0e2c44" />
          </svg>
          <span class="restroomOvr">Inspection Dashboard</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <!--<li class="treeview" *ngIf="canAccess('inspection-statistics', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
                <a (click)="navigationClick('inspection-statistics')">
                    <i class="fa fa-line-chart" aria-hidden="true" style="font-size: 20px;"></i>
                    <span style="margin: 0 0 0 2px;">Inspection Statistics</span>
                    <div class="triangle-left"></div>
                </a>
            </li>-->

      <li class="treeview" *ngIf="canAccess('calendar', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
        <a (click)="navigationClick('calendar')">
          <i class="fa fa-calendar"></i><span>Calendar</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        class="treeview"
        *ngIf="canAccess('restroom-overview', 'view')"
        [routerLinkActive]="['active']"
        (click)="navSlide()"
      >
        <a (click)="navigationClick('restroom-overview')" style="padding: 4px">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.48 497.2">
            <path d="M51.28,54.75c4.25-75.45,108.08-70.51,110.07,0C157.1,130.19,53.28,125.25,51.28,54.75Z" />
            <path d="M330.78,54.75c4.26-75.45,108.08-70.51,110.08,0C436.6,130.19,332.78,125.25,330.78,54.75Z" />
            <rect x="243.22" y="9.42" width="12.95" height="487.78" />
            <path
              d="M334.11,191S316.76,266.16,311,284.86,277.44,299,280.67,273.25s12.89-96,12.89-96,2.58-61.88,41.9-61.24,93.46,0,93.46,0,29.65-5.15,40.61,30.94S482.27,231,488.87,267.45c5.14,28.35-23.72,37.46-32.87,5.8-4.66-16.11-13.6-82.91-13.6-82.91h-3.93L438,460.19c-.82,29.9-38.08,31.11-40.61,3.22l.7-152-20.69-.18c-1.3,130.82,9.1,170.29-16.65,172.71-29.61-.83-20.61-30.78-22.62-53.38-2.75-116.3-2.76-162.56-1.57-239.75h.59Z"
            />
            <path
              d="M53.7,191s-17.34,75.18-23.14,93.88S-3,299,.26,273.25s12.89-96,12.89-96,2.58-61.88,41.9-61.24,93.47,0,93.47,0,29.65-5.15,40.61,30.94S201.86,231,208.46,267.45c5.14,28.35-23.72,37.46-32.87,5.8-4.66-16.11-13.6-82.91-13.6-82.91h-3.92l-.53,269.85c-.82,29.9-38.07,31.11-40.61,3.22l.71-152L97,311.28C95.65,442.1,106.05,481.57,80.3,484c-29.62-.83-20.62-30.78-22.63-53.38-2.74-116.3-2.76-162.56-1.56-239.75h.59Z"
            />
            <polygon points="58.79 212.2 153.35 212.2 174.82 349.58 36.9 349.58 58.79 212.2" />
          </svg>
          <span class="restroomOvr">Location Overview</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        class="treeview"
        *ngIf="canAccess('service-provider', 'view')"
        [routerLinkActive]="['active']"
        (click)="navSlide()"
      >
        <a (click)="navigationClick('service-provider')">
          <i class="fa fa-thumbs-up"></i><span>Service Providers</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        class="treeview menu-open"
        *ngIf="
          canAccess('profile-menu', 'view') ||
          canAccess('user', 'view') ||
          canAccess('user-role-permission-template', 'view') ||
          canAccess('role', 'view') ||
          canAccess('device-registration', 'view') ||
          canAccess('userlocationassignment', 'view') ||
          canAccess('share-user', 'view') ||
          canAccess('acquired-users', 'view') ||
          canAccess('transfer-ownership', 'view') ||
          canAccess('subscription', 'view') ||
          isSysAdmin
        "
      >
        <a>
          <i class="fa fa-user"></i><span>User Management</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="canAccess('profile-menu', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('profile-menu')" [routerLink]="['/profile-menu']">
              <span><b>&#x25CF;&nbsp;</b> My Profile</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('user-role-permission-template', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a
              (click)="navigationClick('user-role-permission-template')"
              [routerLink]="['/user-role-permission-template']"
            >
              <span><b>&#x25CF;&nbsp;</b> User Role Templates</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('role', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('role')" [routerLink]="['/role']">
              <span><b>&#x25CF;&nbsp;</b> User Roles</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('user', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('user')" [routerLink]="['/user']">
              <span><b>&#x25CF;&nbsp;</b> Users</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('device-registration', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('device-registration')" [routerLink]="['/device-registration']">
              <span><b>&#x25CF;&nbsp;</b> Device Information</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('subscription')" [routerLink]="['/subscription']">
              <span><b>&#x25CF;&nbsp;</b> Subscriptions</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="
              canAccess('userlocationassignment', 'view') && (isLocationUserAssignmentShow || isEitherOneOrTheseShow)
            "
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a [routerLink]="['/userlocationassignment']">
              <span><b>&#x25CF;&nbsp;</b> Assign Clean/QC Locations</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('user-license-request-log', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('user-license-request-log')" [routerLink]="['/user-license-request-log']">
              <span><b>&#x25CF;&nbsp;</b> User License Request Log</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('share-user', 'view') && isAllowToShareUserShow"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a [routerLink]="['/share-user']">
              <span><b>&#x25CF;&nbsp;</b> Share Users</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('acquired-users', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a [routerLink]="['/acquired-users']">
              <span><b>&#x25CF;&nbsp;</b> Acquire Users</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('transfer-ownership', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a [routerLink]="['/transfer-ownership']">
              <span><b>&#x25CF;&nbsp;</b> Transfer Ownership</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li
        class="treeview"
        *ngIf="
          canAccess('corporation', 'view') ||
          canAccess('venue', 'view') ||
          (canAccess('area', 'view') && utilitiesService.isAreaMenuShow) ||
          canAccess('building', 'view') ||
          canAccess('zone', 'view') ||
          canAccess('beacon', 'view') ||
          canAccess('level-assignment', 'view') ||
          canAccess('location-type', 'view') ||
          canAccess('location-template', 'view') ||
          canAccess('location', 'view') ||
          canAccess('badge', 'view') ||
          canAccess('gateway', 'view') ||
          canAccess('gate', 'view') ||
          canAccess('location-gate-group', 'view') ||
          isSysAdmin
        "
      >
        <a>
          <i class="fa fa-building"></i><span>Facilities</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu facilities-menu">
          <li *ngIf="canAccess('corporation', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('corporation')" [routerLink]="['/corporation']">
              <span><b>&#x25CF;&nbsp;</b> Corporations</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('venue', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('venue')" [routerLink]="['/venue']">
              <span><b>&#x25CF;&nbsp;</b> Venues</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('area', 'view') && utilitiesService.isAreaMenuShow"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('area')" [routerLink]="['/area']">
              <span><b>&#x25CF;&nbsp;</b> Areas</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('building', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('building')" [routerLink]="['/building']">
              <span><b>&#x25CF;&nbsp;</b> Buildings</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('zone', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('zone')" [routerLink]="['/zone']">
              <span><b>&#x25CF;&nbsp;</b> Zones</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('beacon', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('beacon')" [routerLink]="['/beacon']">
              <span><b>&#x25CF;&nbsp;</b> Beacons</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('level-assignment', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('level-assignment')" [routerLink]="['/level-assignment']">
              <span><b>&#x25CF;&nbsp;</b> Level Assignments</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('location-type', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('location-type')" [routerLink]="['/location-type']">
              <span><b>&#x25CF;&nbsp;</b> Location Types</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('location-template', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('location-template')" [routerLink]="['/location-template']">
              <span><b>&#x25CF;&nbsp;</b> Location Templates</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('location', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('location')" [routerLink]="['/location']">
              <span><b>&#x25CF;&nbsp;</b> Locations</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('badge', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('badge')" [routerLink]="['/badge']">
              <span><b>&#x25CF;&nbsp;</b> Badges</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('gateway', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('gateway')" [routerLink]="['/gateway']">
              <span><b>&#x25CF;&nbsp;</b> Gateways</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('gate', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('gate')" [routerLink]="['/gate']">
              <span><b>&#x25CF;&nbsp;</b> Gates</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('location-gate-group', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('location-gate-group')" [routerLink]="['/location-gate-group']">
              <span><b>&#x25CF;&nbsp;</b> Location Gate Groups</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('country')" [routerLink]="['/country']">
              <span><b>&#x25CF;&nbsp;</b> Countries</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('state')" [routerLink]="['/state']">
              <span><b>&#x25CF;&nbsp;</b> States</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('city')" [routerLink]="['/city']">
              <span><b>&#x25CF;&nbsp;</b> Cities</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li
        class="treeview"
        *ngIf="canAccess('stall-occupancy-status', 'view')"
        [routerLinkActive]="['active']"
        (click)="navSlide()"
      >
        <a (click)="navigationClick('stall-occupancy-status')" [routerLink]="['/stall-occupancy-status']">
          <i class="fa fa-adjust"></i><span>Stall Occupancy Status</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        id="staffSchedule"
        class="treeview"
        *ngIf="
          (canAccess('shift', 'view') ||
            canAccess('shift-break-schedule', 'view') ||
            canAccess('staff-schedule', 'view')) &&
          (isShiftStaffScheduleShow || isEitherOneOrTheseShow)
        "
      >
        <a>
          <i class="fa fa-clock-o"></i><span>Schedule</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="canAccess('shift', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('shift')" [routerLink]="['/shift']">
              <span><b>&#x25CF;&nbsp;</b> Shifts</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('shift-break-schedule', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('shift-break-schedule')" [routerLink]="['/shift-break-schedule']">
              <span><b>&#x25CF;&nbsp;</b> Shift Break Schedules</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('staff-schedule', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('staff-schedule')" [routerLink]="['/staff-schedule']">
              <span><b>&#x25CF;&nbsp;</b> Staff Schedules</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li
        id="touchlessfeedback"
        class="treeview"
        *ngIf="
          (canAccess('feedback-configuration', 'view') || canAccess('venue-feedback-reason', 'view')) &&
          isTouchlessFeedbackShow
        "
      >
        <a style="padding: 4px; display: none">
          <svg id="touchlessfeedback" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <rect x="114.01" y="17.64" width="282.54" height="433.93" rx="31.82" fill="#152735" />
            <rect
              id="touchlessfeedback-rect"
              x="127.93"
              y="38.86"
              width="254.69"
              height="351"
              rx="20.87"
              fill="#0E2C45"
            />
            <circle cx="255.27" cy="425.37" r="16.41" fill="#0E2C45" />
            <polygon points="132.97 440.48 209.74 495.6 195.22 442.83 132.97 440.48" fill="#152735" />
          </svg>
          <span class="restroomOvr"> Touchless Feedback</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="canAccess('feedback-configuration', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('feedback-configuration')" [routerLink]="['/feedback-configuration']">
              <span><b>&#x25CF;&nbsp;</b> Feedback Configuration</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('venue-feedback-reason', 'view') && isTouchlessFeedbackReasonShow"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('venue-feedback-reason')" [routerLink]="['/venue-feedback-reason']">
              <span><b>&#x25CF;&nbsp;</b> Feedback Reasons</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" *ngIf="isSysAdmin">
        <a>
          <i class="fa fa-cog"></i><span>Venue Survey Config</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a
              (click)="navigationClick('survey-question-configuration')"
              [routerLink]="['/survey-question-configuration']"
            >
              <span><b>&#x25CF;&nbsp;</b> Survey Questions</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a
              (click)="navigationClick('survey-question-response-configuration')"
              [routerLink]="['/survey-question-response-configuration']"
            >
              <span><b>&#x25CF;&nbsp;</b> Survey Qn Responses</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li
        class="treeview"
        *ngIf="
          canAccess('cleaning-alert', 'view') ||
          canAccess('venue-inventory-configuration', 'view') ||
          canAccess('venue-inventory-configuration', 'view') ||
          canAccess('venue-task-configuration', 'view') ||
          isSysAdmin
        "
        [routerLinkActive]="['active']"
      >
        <a style="padding: 4px; display: none">
          <svg
            enable-background="new 0 0 79.707 79.707"
            version="1.1"
            viewBox="0 0 79.707 79.707"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m67.239 79.707-8.41-28.273c-0.207-7.525-4.795-13.963-11.309-16.849v-26.918c0-4.228-3.439-7.667-7.667-7.667-4.227 0-7.666 3.439-7.666 7.667v26.918c-6.513 2.886-11.099 9.323-11.306 16.844l-8.413 28.275h54.771v3e-3zm-12.81-6-3.431-12.806c-0.287-1.065-1.377-1.704-2.449-1.413-1.067 0.284-1.7 1.381-1.414 2.448l3.152 11.771h-8.436v-12.29c0-1.104-0.896-2-2-2s-2 0.896-2 2v12.287h-7.435l3.153-11.771c0.286-1.065-0.347-2.164-1.414-2.449-1.068-0.289-2.164 0.349-2.449 1.414l-3.431 12.806h-5.765l5.579-18.75h0.012 27.5 0.015l5.575 18.75h-4.763l1e-3 3e-3zm-16.242-66.04c0-0.919 0.748-1.667 1.666-1.667 0.919 0 1.667 0.748 1.667 1.667v25.373c-0.55-0.048-1.104-0.084-1.666-0.084s-1.117 0.036-1.667 0.084v-25.373zm1.667 31.289c6.135 0 11.275 4.276 12.637 10h-25.274c1.36-5.725 6.503-10 12.637-10z"
            />
          </svg>
          <span class="restroomOvr"> Cleaning Alerts</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="canAccess('cleaning-alert', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('cleaning-alert')" [routerLink]="['/cleaning-alert']">
              <span><b>&#x25CF;&nbsp;</b> Cleaning Alert Logs</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('task')" [routerLink]="['/task']">
              <span><b>&#x25CF;&nbsp;</b> Tasks</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('inventory')" [routerLink]="['/inventory']">
              <span><b>&#x25CF;&nbsp;</b> Inventories</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('venue-task-configuration', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('venue-task-configuration')" [routerLink]="['/venue-task-configuration']">
              <span><b>&#x25CF;&nbsp;</b> Venue Tasks</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('venue-inventory-configuration', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a
              (click)="navigationClick('venue-inventory-configuration')"
              [routerLink]="['/venue-inventory-configuration']"
            >
              <span><b>&#x25CF;&nbsp;</b> Venue Inventories</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" *ngIf="canAccess('quality-check-log', 'view')" (click)="navSlide()">
        <a (click)="navigationClick('quality-check-log')" style="padding: 4px; display: none">
          <svg
            enable-background="new 0 0 79.707 79.707"
            version="1.1"
            viewBox="0 0 79.707 79.707"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m67.239 79.707-8.41-28.273c-0.207-7.525-4.795-13.963-11.309-16.849v-26.918c0-4.228-3.439-7.667-7.667-7.667-4.227 0-7.666 3.439-7.666 7.667v26.918c-6.513 2.886-11.099 9.323-11.306 16.844l-8.413 28.275h54.771v3e-3zm-12.81-6-3.431-12.806c-0.287-1.065-1.377-1.704-2.449-1.413-1.067 0.284-1.7 1.381-1.414 2.448l3.152 11.771h-8.436v-12.29c0-1.104-0.896-2-2-2s-2 0.896-2 2v12.287h-7.435l3.153-11.771c0.286-1.065-0.347-2.164-1.414-2.449-1.068-0.289-2.164 0.349-2.449 1.414l-3.431 12.806h-5.765l5.579-18.75h0.012 27.5 0.015l5.575 18.75h-4.763l1e-3 3e-3zm-16.242-66.04c0-0.919 0.748-1.667 1.666-1.667 0.919 0 1.667 0.748 1.667 1.667v25.373c-0.55-0.048-1.104-0.084-1.666-0.084s-1.117 0.036-1.667 0.084v-25.373zm1.667 31.289c6.135 0 11.275 4.276 12.637 10h-25.274c1.36-5.725 6.503-10 12.637-10z"
            />
          </svg>
          <span class="restroomOvr">Quality Check Logs</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        class="treeview"
        *ngIf="
          canAccess('asset', 'view') ||
          canAccess('asset-type', 'view') ||
          canAccess('product', 'view') ||
          canAccess('product-model', 'view') ||
          canAccess('product-manufacturer', 'view') ||
          canAccess('supplier', 'view')
        "
      >
        <a>
          <i class="fa fa-book fa-2"></i><span>Asset Management</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu asset-menu">
          <li *ngIf="canAccess('supplier', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('supplier')" [routerLink]="['/supplier']">
              <span><b>&#x25CF;&nbsp;</b> Suppliers</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('product-manufacturer', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('product-manufacturer')" [routerLink]="['/product-manufacturer']">
              <span><b>&#x25CF;&nbsp;</b> Product Manufacturers</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('product-model', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('product-model')" [routerLink]="['/product-model']">
              <span><b>&#x25CF;&nbsp;</b> Product Models</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('product', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('product')" [routerLink]="['/product']">
              <span><b>&#x25CF;&nbsp;</b> Products</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('asset', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('asset')" [routerLink]="['/asset']">
              <span><b>&#x25CF;&nbsp;</b> Assets</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('asset-type', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('asset-type')" [routerLink]="['/asset-type']">
              <span><b>&#x25CF;&nbsp;</b> Asset Types</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li
        class="treeview"
        *ngIf="
          canAccess('workorder-category', 'view') ||
          canAccess('workorder-type', 'view') ||
          canAccess('closure-template', 'view') ||
          canAccess('workorder', 'view')
        "
      >
        <a style="padding: 4px; display: none">
          <svg
            class="restroomOvr1"
            enable-background="new 0 0 460 460"
            version="1.1"
            viewBox="0 0 460 460"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m393.94 0h-327.89c-15.655 0-28.346 12.691-28.346 28.346v403.31c0 15.656 12.691 28.347 28.346 28.347h327.89c15.655 0 28.346-12.691 28.346-28.346v-403.31c0-15.655-12.691-28.346-28.346-28.346zm-284.7 351.67c-15.28 0-27.667-12.387-27.667-27.667s12.387-27.667 27.667-27.667 27.667 12.387 27.667 27.667-12.387 27.667-27.667 27.667zm0-94c-15.28 0-27.667-12.387-27.667-27.667s12.387-27.667 27.667-27.667 27.667 12.387 27.667 27.667-12.387 27.667-27.667 27.667zm0-94c-15.28 0-27.667-12.387-27.667-27.667s12.387-27.667 27.667-27.667 27.667 12.387 27.667 27.667-12.387 27.667-27.667 27.667zm245.17 185.33h-170.91c-13.807 0-25-11.193-25-25s11.193-25 25-25h170.91c13.807 0 25 11.193 25 25s-11.193 25-25 25zm0-94h-170.91c-13.807 0-25-11.193-25-25s11.193-25 25-25h170.91c13.807 0 25 11.193 25 25s-11.193 25-25 25zm0-94h-170.91c-13.807 0-25-11.193-25-25s11.193-25 25-25h170.91c13.807 0 25 11.193 25 25s-11.193 25-25 25z"
            />
          </svg>
          <span class="restroomOvr">Work Order Mgmt</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="canAccess('workorder-category', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('workorder-category')" [routerLink]="['/workorder-category']">
              <span><b>&#x25CF;&nbsp;</b> Work Order Categories</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('workorder-type', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('workorder-type')" [routerLink]="['/workorder-type']">
              <span><b>&#x25CF;&nbsp;</b> Work Order Types</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('closure-template', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('closure-template')" [routerLink]="['/closure-template']">
              <span><b>&#x25CF;&nbsp;</b> Closure Templates</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('workorder', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('workorder')" [routerLink]="['/workorder']">
              <span><b>&#x25CF;&nbsp;</b> Work Orders</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li
        class="treeview"
        *ngIf="
          canAccess('inspection-log', 'view') ||
          canAccess('inspection', 'view') ||
          canAccess('venue-element-configuration', 'view') ||
          canAccess('inspectorlocationassignment', 'view') ||
          canAccess('location-overview', 'view') ||
          canAccess('inspection-notification-alert', 'view') ||
          isSysAdmin
        "
        [routerLinkActive]="['active']"
      >
        <a>
          <i class="fa fa-search" aria-hidden="true"></i><span>Inspections</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="canAccess('inspection-log', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('inspection-log')" [routerLink]="['/inspection-log']">
              <span><b>&#x25CF;&nbsp;</b> Inspection Logs</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a
              (click)="navigationClick('inspection-score-factor-configuration')"
              [routerLink]="['/inspection-score-factor-configuration']"
            >
              <span><b>&#x25CF;&nbsp;</b> Inspection Score Factor</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('inspection', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('inspection')" [routerLink]="['/inspection']">
              <span><b>&#x25CF;&nbsp;</b> Inspections</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('inspectorlocationassignment', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('inspectorlocationassignment')" [routerLink]="['/inspectorlocationassignment']">
              <span><b>&#x25CF;&nbsp;</b> Assign Inspection Locations</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('location-overview', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('location-overview')" [routerLink]="['/location-overview']">
              <span><b>&#x25CF;&nbsp;</b> Inspection Locations</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('element')" [routerLink]="['/element']">
              <span><b>&#x25CF;&nbsp;</b> Elements</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('venue-element-configuration', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('venue-element-configuration')" [routerLink]="['/venue-element-configuration']">
              <span><b>&#x25CF;&nbsp;</b> Venue Elements</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('inspection-notification-alert', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a
              (click)="navigationClick('inspection-notification-alert')"
              [routerLink]="['/inspection-notification-alert']"
            >
              <span><b>&#x25CF;&nbsp;</b> Notification Alerts</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" *ngIf="canAccess('project-work-log', 'view') || isSysAdmin">
        <a style="padding: 4px; display: none">
          <svg id="projectWorkSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <g>
              <rect
                x="90"
                y="70"
                width="331"
                height="394"
                rx="4.82"
                ry="4.82"
                style="fill: none; stroke: #edefee; stroke-width: 30px"
              />
              <path
                id="pwPathOne"
                d="M184,33h141c6.62,0,12,5.38,12,12v51H172V45c0-6.62,5.38-12,12-12Z"
                class="navigation"
              />
              <g>
                <path
                  id="pwPathTwo"
                  d="M142.8,350s172.4,1.67,199.18-151.48"
                  style="fill: none; stroke: #edefee; stroke-width: 20px"
                />
                <polyline
                  points="291.97 229.15 344.11 189.11 368 255.58"
                  style="fill: none; stroke: #edefee; stroke-width: 20px"
                />
              </g>
            </g>
          </svg>

          <span class="restroomOvr"> Project Work</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="canAccess('project-work-log', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('project-work-log')" [routerLink]="['/project-work-log']">
              <span><b>&#x25CF;&nbsp;</b> Project Work Logs</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" *ngIf="canAccess('infor-workorder', 'view')">
        <a>
          <i class="fa fa-file-text"></i><span>Infor Work Order Mgmt</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li *ngIf="canAccess('infor-workorder', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('infor-workorder')" [routerLink]="['/infor-workorder']">
              <span><b>&#x25CF;&nbsp;</b> Infor Work Orders</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" *ngIf="canAccess('qc-cleaning-alert', 'view')" (click)="navSlide()">
        <a (click)="navigationClick('qc-cleaning-alert')" style="padding: 6px">
          <svg
            class="restroomOvr1"
            enable-background="new 0 0 218.356 218.356"
            version="1.1"
            viewBox="0 0 218.36 218.36"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m142.68 0h-91.73c-16.143 0-29.278 13.135-29.278 29.278v159.8c0 16.143 13.135 29.278 29.278 29.278h116.46c16.143 0 29.275-13.135 29.275-29.278v-128.71l-54.009-60.369zm4.208 27.525 25.179 28.141h-25.179v-28.141zm34.587 161.55c-3e-3 7.754-6.308 14.061-14.061 14.061h-116.46c-7.753 0-14.061-6.307-14.061-14.061v-159.8c0-7.753 6.307-14.061 14.061-14.061h80.722v55.667h49.803v118.19z"
            />
            <rect x="92.758" y="161.8" width="70.879" height="15.217" />
            <rect x="92.758" y="125.45" width="70.879" height="15.217" />
            <rect x="92.758" y="88.957" width="70.879" height="15.217" />
            <polygon points="61.909 96.664 56.899 91.845 49.865 99.152 62.117 110.94 79.829 92.88 72.589 85.776" />
            <polygon points="61.909 133.16 56.899 128.34 49.865 135.65 62.117 147.44 79.829 129.38 72.589 122.27" />
            <polygon points="61.909 169.51 56.899 164.69 49.865 172 62.117 183.79 79.829 165.72 72.589 158.62" />
          </svg>
          <span class="restroomOvr">QC - Cleaning Alerts</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li class="treeview" *ngIf="canAccess('qc-workorder', 'view')" (click)="navSlide()">
        <a (click)="navigationClick('qc-workorder')" style="padding: 6px">
          <svg
            class="restroomOvr1"
            enable-background="new 0 0 218.356 218.356"
            version="1.1"
            viewBox="0 0 218.36 218.36"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m142.68 0h-91.73c-16.143 0-29.278 13.135-29.278 29.278v159.8c0 16.143 13.135 29.278 29.278 29.278h116.46c16.143 0 29.275-13.135 29.275-29.278v-128.71l-54.009-60.369zm4.208 27.525 25.179 28.141h-25.179v-28.141zm34.587 161.55c-3e-3 7.754-6.308 14.061-14.061 14.061h-116.46c-7.753 0-14.061-6.307-14.061-14.061v-159.8c0-7.753 6.307-14.061 14.061-14.061h80.722v55.667h49.803v118.19z"
            />
            <rect x="92.758" y="161.8" width="70.879" height="15.217" />
            <rect x="92.758" y="125.45" width="70.879" height="15.217" />
            <rect x="92.758" y="88.957" width="70.879" height="15.217" />
            <polygon points="61.909 96.664 56.899 91.845 49.865 99.152 62.117 110.94 79.829 92.88 72.589 85.776" />
            <polygon points="61.909 133.16 56.899 128.34 49.865 135.65 62.117 147.44 79.829 129.38 72.589 122.27" />
            <polygon points="61.909 169.51 56.899 164.69 49.865 172 62.117 183.79 79.829 165.72 72.589 158.62" />
          </svg>
          <span class="restroomOvr">QC - Work Orders</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li class="treeview" *ngIf="canAccess('alert-template', 'view')">
        <a>
          <i class="fa fa-users fa-2" aria-hidden="true"></i><span>Corp/Venue Mgmt</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu corporation-menu">
          <li *ngIf="canAccess('alert-template', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('alert-template')" [routerLink]="['/alert-template']">
              <span><b>&#x25CF;&nbsp;</b> Alert Templates</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li
        class="treeview"
        *ngIf="
          canAccess('sensor-type', 'view') || canAccess('sensor-manufacturer', 'view') || canAccess('sensor', 'view')
        "
      >
        <a>
          <i class="fa fa-podcast" aria-hidden="true"></i><span>Sensor Configuration</span>
          <span class="pull-right-container">
            <i class="fa fa-caret-left fa-angle-left"></i>
          </span>
        </a>

        <ul class="treeview-menu corporation-menu">
          <li *ngIf="canAccess('sensor-manufacturer', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('sensor-manufacturer')" [routerLink]="['/sensor-manufacturer']">
              <span><b>&#x25CF;&nbsp;</b> Sensor Manufacturers</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('sensor-type', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('sensor-type')" [routerLink]="['/sensor-type']">
              <span><b>&#x25CF;&nbsp;</b> Sensor Types</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('sensor', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('sensor')" [routerLink]="['/sensor']">
              <span><b>&#x25CF;&nbsp;</b> Sensors</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview" *ngIf="canAccess('statistics', 'view')" (click)="navSlide()">
        <a (click)="navigationClick('statistics')">
          <i class="fa fa-bar-chart"></i><span>Statistics</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        class="treeview"
        *ngIf="
          canAccess('throughput-custom-report', 'view') ||
          canAccess('feedback-custom-report', 'view') ||
          canAccess('dispenser-custom-report', 'view') ||
          canAccess('trash-custom-report', 'view') ||
          canAccess('cleaning-alert-custom-report', 'view') ||
          canAccess('inventory-custom-report', 'view') ||
          canAccess('task-custom-report', 'view') ||
          canAccess('inspection-log-report', 'view') ||
          canAccess('badge-summary', 'view') ||
          canAccess('flush-valve-custom-report', 'view') ||
          canAccess('equipment-pm-custom-report', 'view') ||
          canAccess('project-work-custom-report', 'view') ||
          canAccess('user-log-activity-custom-report', 'view') ||
          canAccess('user-attendance-report', 'view') ||
          canAccess('calender-schedules-custom-report', 'view') ||
          isSysAdmin
        "
      >
        <a>
          <i class="fa fa-file-text-o" aria-hidden="true"></i><span>Reports</span>
          <span class="pull-right-container">
            <i class="fa fa-angle-left pull-right"></i>
          </span>
        </a>

        <ul class="treeview-menu">
          <li
            *ngIf="canAccess('throughput-custom-report', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('throughput-custom-report')" [routerLink]="['/throughput-custom-report']">
              <span><b>&#x25CF;&nbsp;</b>Throughput</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('feedback-custom-report', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('feedback-custom-report')" [routerLink]="['/feedback-custom-report']">
              <span><b>&#x25CF;&nbsp;</b>Feedback</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('dispenser-custom-report', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('dispenser-custom-report')" [routerLink]="['/dispenser-custom-report']">
              <span><b>&#x25CF;&nbsp;</b>Dispenser</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('trash-custom-report', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('trash-custom-report')" [routerLink]="['/trash-custom-report']">
              <span><b>&#x25CF;&nbsp;</b>Trash</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('cleaning-alert-custom-report', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a
              (click)="navigationClick('cleaning-alert-custom-report')"
              [routerLink]="['/cleaning-alert-custom-report']"
            >
              <span><b>&#x25CF;&nbsp;</b>Cleaning Alert</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('inventory-custom-report', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('inventory-custom-report')" [routerLink]="['/inventory-custom-report']">
              <span><b>&#x25CF;&nbsp;</b>Inventory</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('task-custom-report', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('task-custom-report')" [routerLink]="['/task-custom-report']">
              <span><b>&#x25CF;&nbsp;</b>Task</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('inspection-log-report', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('inspection-log-report')" [routerLink]="['/inspection-log-report']">
              <span><b>&#x25CF;&nbsp;</b>Inspection Summary</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('badge-summary', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('badge-summary')" [routerLink]="['/badge-summary']">
              <span><b>&#x25CF;&nbsp;</b>Badge Summary</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('flush-valve-custom-report', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('flush-valve-custom-report')" [routerLink]="['/flush-valve-custom-report']">
              <span><b>&#x25CF;&nbsp;</b>Flush Valve</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('equipment-pm-custom-report', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('equipment-pm-custom-report')" [routerLink]="['/equipment-pm-custom-report']">
              <span><b>&#x25CF;&nbsp;</b>Equipment PM</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('project-work-custom-report', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a (click)="navigationClick('project-work-custom-report')" [routerLink]="['/project-work-custom-report']">
              <span><b>&#x25CF;&nbsp;</b> Project Work</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('user-log-activity-custom-report', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a
              (click)="navigationClick('user-log-activity-custom-report')"
              [routerLink]="['/user-log-activity-custom-report']"
            >
              <span><b>&#x25CF;&nbsp;</b>User Login Logout Activity</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="isSysAdmin" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('user-billing-custom-report')" [routerLink]="['/user-billing-custom-report']">
              <span><b>&#x25CF;&nbsp;</b> Users Billing</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li *ngIf="canAccess('user-attendance-report', 'view')" [routerLinkActive]="['active']" (click)="navSlide()">
            <a (click)="navigationClick('user-attendance-report')" [routerLink]="['/user-attendance-report']">
              <span><b>&#x25CF;&nbsp;</b> Users Attendance</span>
              <div class="triangle-left"></div>
            </a>
          </li>

          <li
            *ngIf="canAccess('calender-schedules-custom-report', 'view')"
            [routerLinkActive]="['active']"
            (click)="navSlide()"
          >
            <a
              (click)="navigationClick('calender-schedules-custom-report')"
              [routerLink]="['/calender-schedules-custom-report']"
            >
              <span><b>&#x25CF;&nbsp;</b> Calendar Schedule</span>
              <div class="triangle-left"></div>
            </a>
          </li>
        </ul>
      </li>

      <li class="treeview AL-Blue" *ngIf="canAccess('predictive-analytics', 'view')">
        <a (click)="navigationClick('predictive-analytics')" style="padding: 4px; display: none">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 405.93 449" class="svgAL-blue">
            <g id="Layer_2" data-name="Layer 2">
              <g id="AI_1_svg">
                <path
                  d="M192.43,391.5c-156,13-136-124-136-124-1.68,7.58-116.83-95.42,3-161-2-41,22-120,139-75"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="10"
                />
                <path
                  d="M59.93,105c-.28,7.08,55.49-34.22,75,18"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="10"
                />
                <path d="M66.93,265s14-52,88-42" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="10" />
                <path d="M116.93,77s-7-27,39-35" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="10" />
                <path
                  d="M121.43,306.85s71.81,3.2,79.5,91.15V27"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="10.46"
                />
                <path d="M157.93,300s22-35-1-54" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="10" />
                <path d="M60.93,127s-22,14,0,42" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="10" />
                <polyline
                  points="204.93 92 301.93 91 368.93 119"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="10"
                />
                <polyline
                  points="200.93 54 269.93 54 327.93 27"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="10"
                />
                <polyline
                  points="204.93 231.5 285.93 231.5 347.93 182.5"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="10.83"
                />
                <polyline
                  points="205.93 353 323.93 354 360.93 417"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="10"
                />
                <polyline
                  points="227.41 232.01 269.37 232.01 311.43 289.5"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="10.83"
                />
                <circle
                  cx="344.43"
                  cy="21.5"
                  r="18"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="7"
                />
                <circle
                  cx="384.43"
                  cy="123.5"
                  r="18"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="7"
                />
                <circle
                  cx="360.43"
                  cy="171.5"
                  r="18"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="7"
                />
                <circle
                  cx="322.43"
                  cy="298.5"
                  r="18"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="7"
                />
                <circle
                  cx="366.43"
                  cy="427.5"
                  r="18"
                  fill="none"
                  stroke="#000"
                  stroke-miterlimit="10"
                  stroke-width="7"
                />
              </g>
            </g>
          </svg>
          <span class="predictive-heading">Predictive Analytics</span>
          <div class="triangle-left"></div>
        </a>
      </li>

      <li
        class="treeview"
        *ngIf="canAccess('import-data', 'view')"
        [routerLinkActive]="['active']"
        (click)="navSlide()"
      >
        <a (click)="navigationClick('import-data')">
          <i class="fa fa-upload"></i><span>Import Data</span>
          <div class="triangle-left"></div>
        </a>
      </li>
      <li class="version-nav">V. {{ appVersion }} ({{ apiVersion | async }})</li>
    </ul>
  </section>
</aside>

<div
  class="modal"
  id="LogoutConfirmationModal"
  [ngStyle]="LogoutConfirmationModal ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Sign Out</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="CloseLogoutConfirmationModal()"
          style="margin-top: -37px"
        >
          &times;
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <h4>Are you sure you want to Sign Out?</h4>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
          <button class="btn btn-type btn-save" (click)="signOut()">Yes</button>
          <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="CloseLogoutConfirmationModal()">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
