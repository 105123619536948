import { Component, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  DownlineTreeviewItem,
  OrderDownlineTreeviewEventParser,
  TreeviewComponent,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewItem,
} from 'ngx-treeview';
import { AuthState } from 'src/app/store/auth.state';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { ServiceProviderModule } from '../../../serviceprovider/serviceprovider.module';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { User } from '../../user/user.model';
import { UserRoleTemplateService } from '../../userroletemplate/components/userroletemplate.http.service';
import { UserRoleTemplateModule } from '../../userroletemplate/userroletemplate.module';
import { ChildPermissions, ParentPermissions, UserRoleModule } from '../role.module';
import { UserRoleService } from './role.http.service';

@Injectable({
  providedIn: 'root',
})
export class config extends TreeviewConfig {
  hasAllCheckBox = false;
  hasFilter = true;
  hasCollapseExpand = false;
  maxHeight = 500;
}

@Component({
  selector: 'role-component',
  templateUrl: 'role.component.html',
  styleUrls: ['./role.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    UserRoleService,
    UtilitiesService,
    UserRoleTemplateService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: config },
  ],
})
export class UserRoleComponent implements OnInit {
  currentUser: User;
  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;
  @ViewChild('treeviewComponentTemplate', { static: true }) treeviewComponentTemplate: TreeviewComponent;
  public items: TreeviewItem[];
  public itemsTemplate: TreeviewItem[];
  public config: {} = {};
  public isAllFeatureConfigurationChecked = false;
  public isCheckBoxVisible = false;

  public isAllFeatureConfigurationCheckedTemplate = false;
  public isCheckBoxVisibleTemplate = false;

  public userRoles: UserRoleModule[] = [];
  public userRole: UserRoleModule = new UserRoleModule();

  public userRoleTemplate: UserRoleTemplateModule = new UserRoleTemplateModule();
  public userRoleTemplates: UserRoleTemplateModule[] = [];
  public userRoleTemplateId = null;

  public serviceProviders: ServiceProviderModule[] = [];
  public corporations: CorporationModule[] = [];
  public venues: VenueModule[] = [];

  public page = 1;
  public size = 10;
  public count = 0;
  public pageSizes: any[] = [];
  public searchString = '';
  public isReqComplete = false;

  public listviewtypes: any[];
  public viewType: any = null;

  public columnName: string;
  public sortOrder: string;
  public columnSortOrder = 'ASC';
  public selectedColumnName: string;
  public pageState = [{ pageName: 'roleForm', page: this.page, size: this.size, viewType: this.viewType }];
  public isSysAdmin = false;
  public templateModal = false;

  constructor(
    private userRoleService: UserRoleService,
    public utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    private userRoleTemplateService: UserRoleTemplateService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.userRole = new UserRoleModule();
    const permission: ParentPermissions[] = [];
    this.userRole.ParentPermissionsList = permission;

    this.userRoleTemplate = new UserRoleTemplateModule();
    const permissions: ParentPermissions[] = [];
    this.userRoleTemplate.ParentPermissionsList = permissions;

    this.config = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: true,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 10,
    });
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;

    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;

    this.getAllUserRoleTemplates();
  }

  public canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.viewType = this.pageState[0].viewType;
      this.getAllUserRoles(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllUserRoles(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.searchString = '';
    this.userRoles = null;
    this.count = 0;
    this.userRole = new UserRoleModule();
    const permission: ParentPermissions[] = [];
    this.userRole.ParentPermissionsList = permission;
    this.isCheckBoxVisible = false;

    this.userRoleTemplate = new UserRoleTemplateModule();
    const permissions: ParentPermissions[] = [];
    this.userRoleTemplate.ParentPermissionsList = permissions;
    this.isCheckBoxVisibleTemplate = false;

    this.GetAllServiceProviders(0);
    setTimeout(() => {
      const element = document.getElementById('Rolename');
      element.focus();
    }, 1000);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.userRoles = null;
    this.count = 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUserRoles(this.searchString, this.page, this.size, this.viewType);
  }

  public clearFormData(userRoleForm: NgForm) {
    this.userRole = new UserRoleModule();
    const permission: ParentPermissions[] = [];
    this.userRole.ParentPermissionsList = permission;
    this.searchString = '';
    this.isSysAdmin = false;
    this.isCheckBoxVisible = false;
    this.isAllFeatureConfigurationChecked = false;
    this.userRoleTemplateId = null;
    userRoleForm.reset();

    this.userRoleTemplate = new UserRoleTemplateModule();
    const permissions: ParentPermissions[] = [];
    this.userRoleTemplate.ParentPermissionsList = permissions;
    this.isCheckBoxVisibleTemplate = false;
    this.isAllFeatureConfigurationCheckedTemplate = false;

    this.items = [];
    this.config = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 10,
    });
    this.getAllUserRoles('', this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllUserRoles('', this.page, this.size, this.viewType);
  }

  public userRoleSearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUserRoles(searchString, 1, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUserRoles(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public RolePageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUserRoles(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllUserRoles(this.searchString, this.page, this.size, this.viewType);
  }

  public addUserRole(userRoleForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (userRoleForm.status === 'INVALID' && userRoleForm.disabled === false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('User Role', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.userRole.RoleName = this.utilitiesService.removeInnerSpaces(this.userRole.RoleName);
    if (this.userRole.RoleName === null || this.userRole.RoleName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    let permissionPayload: ParentPermissions[] = [];
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let parentPermission = new ParentPermissions();
          parentPermission.ParentPermissionId = it.value;
          parentPermission.PAccess = 1;
          parentPermission.ChildPermissionsList = [];
          if (it.children != null) {
            it.children.forEach(function (chield) {
              if (chield.checked || chield.checked == undefined) {
                let childrenPermission = new ChildPermissions();
                childrenPermission.ChildPermissionId = chield.value;
                childrenPermission.PAccess = 1;

                parentPermission.ChildPermissionsList.push(childrenPermission);
              }
            });
          }
          permissionPayload.push(parentPermission);
        }
      });

      if (permissionPayload.length == 0) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal('User Role', 'Please select atleast one permission.');
        return;
      }
    }
    this.userRole.ParentPermissionsList = permissionPayload;
    this.userRole.AddedBy = this.currentUser.UserId;

    this.userRoleService.Add(this.utilitiesService.stripScript(this.userRole)).subscribe(
      (data) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('User Role', data.Message);
        this.clearFormData(userRoleForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'userRoleService addUserRole Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public updateUserRole(userRoleForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (userRoleForm.status === 'INVALID' && userRoleForm.disabled === false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('User Role', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    this.userRole.RoleName = this.utilitiesService.removeInnerSpaces(this.userRole.RoleName);
    if (this.userRole.RoleName === null || this.userRole.RoleName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    let permissionPayload: ParentPermissions[] = [];
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let parentPermission = new ParentPermissions();
          parentPermission.ParentPermissionId = it.value;
          parentPermission.PAccess = 1;
          parentPermission.ChildPermissionsList = [];
          if (it.children != null) {
            it.children.forEach(function (chield) {
              if (chield.checked || chield.checked == undefined) {
                let childrenPermission = new ChildPermissions();
                childrenPermission.ChildPermissionId = chield.value;
                childrenPermission.PAccess = 1;

                parentPermission.ChildPermissionsList.push(childrenPermission);
              }
            });
          }
          permissionPayload.push(parentPermission);
        }
      });

      if (permissionPayload.length == 0) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal('User Role', 'Please select atleast one permission.');
        return;
      }
    }
    this.userRole.ParentPermissionsList = permissionPayload;
    this.userRole.IsActive = this.userRole.IsActive ? 1 : 0;
    this.userRole.ModifiedBy = this.currentUser.UserId;

    this.userRoleService.Update(this.utilitiesService.stripScript(this.userRole)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('User Role', data.Message);
        this.clearFormData(userRoleForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'userRoleService updateUserRole Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllUserRoles(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    let recCount = 0;

    this.userRoleService.GetUserroleList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.userRoles = [];
        if (data != null && data.length > 0) {
          const obj = data.filter((r) => r.RoleCode != 'sysadmin');
          this.userRoles = obj;
        }

        if (
          this.userRoles != null &&
          this.userRoles.length > 0 &&
          this.userRoles[0].Count != null &&
          this.userRoles[0].Count > 0
        ) {
          recCount = this.userRoles[0].Count - 1;
        }

        if (this.userRoles != null && this.userRoles.length < 0 && recCount / 10 < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log('userRoleService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
        this.loaderService.display(false);
      },
      () => (this.count = this.userRoles[0] ? recCount : 0),
    );
  }

  public hasData(): boolean {
    return this.userRoles !== null && this.userRoles.length > 0;
  }

  public getUserRoleById(UserRoleId: number, serviceProviderId: number) {
    if (this.canAccess('ROLE_VIEW', 'view')) {
      this.loaderService.display(true);
      const CProle = new UserRoleModule();
      CProle.ServiceProviderCPId = this.authState.AESEncryptText(serviceProviderId);
      CProle.CorporationCPId = this.authState.AESEncryptText(this.authState.getStoredContextCorporationId());
      CProle.VenueCPId = this.authState.AESEncryptText(this.authState.getStoredContextVenueId());
      CProle.UserRoleCPId = this.authState.AESEncryptText(UserRoleId);

      this.userRoleService.GetSingle(CProle).subscribe(
        (data) => {
          this.userRole = data;
          this.loaderService.display(false);
          if (this.userRole != null) {
            if (
              this.userRole.ServiceProviderId == null &&
              this.userRole.CorporationId == null &&
              this.userRole.VenueId == null
            ) {
              this.isSysAdmin = true;
            } else {
              this.isSysAdmin = false;
            }
            if (this.userRole.ParentPermissionsList != null && this.userRole.ParentPermissionsList.length > 0) {
              this.config = TreeviewConfig.create({
                hasAllCheckBox: false,
                hasFilter: true,
                hasCollapseExpand: false,
                decoupleChildFromParent: false,
                maxHeight: 500,
              });

              this.items = [];
              let that = this;
              this.isCheckBoxVisible = true;
              this.userRole.ParentPermissionsList.forEach(function (ParentPermission) {
                let childrens = [];
                if (ParentPermission.ChildPermissionsList != null && ParentPermission.ChildPermissionsList.length > 0) {
                  ParentPermission.ChildPermissionsList.forEach(function (item) {
                    item.PAccess = item.PAccess ? true : false;
                    childrens.push({
                      text:
                        item.PermissionDisplayName +
                        ' (Description: ' +
                        item.PermissionDescription +
                        ')' +
                        ' (Type: ' +
                        item.UserType +
                        ')',
                      checked: item.PAccess,
                      value: item.ChildPermissionId,
                      collapsed: true,
                    });
                  });
                }
                that.items.push(
                  new TreeviewItem({
                    text:
                      ParentPermission.PermissionDisplayName +
                      ' (Description: ' +
                      ParentPermission.PermissionDescription +
                      ')' +
                      ' (Type: ' +
                      ParentPermission.UserType +
                      ')',
                    value: ParentPermission.ParentPermissionId,
                    checked: ParentPermission.PAccess ? true : false,
                    children: childrens,
                    collapsed: true,
                  }),
                );
              });

              if (this.items && this.items.length > 0) {
                let itemObj = this.items.filter((tree) => tree.checked == false || tree.checked == undefined);
                if (itemObj && itemObj.length > 0) {
                  this.isAllFeatureConfigurationChecked = false;
                } else {
                  this.isAllFeatureConfigurationChecked = true;
                }
              }
            }
            this.GetAllServiceProviders(this.userRole.UserRoleId);
          }
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'userRoleService GetSingle Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('User Role', 'You do not have permission to view user role details.');
    }
  }

  public GetAllServiceProviders(userRoleId: number) {
    this.loaderService.display(true);
    const CPSP = new UserRoleModule();
    CPSP.UserRoleCPId = this.authState.AESEncryptText(userRoleId);

    this.userRoleService.GetServiceProviderListForDropdown(CPSP).subscribe(
      (data) => {
        this.serviceProviders = data;
        this.loaderService.display(false);
        if (this.userRole.UserRoleId == null) {
          const obj = this.serviceProviders.filter(
            (v) => v.ServiceProviderId == this.authState.getStoredContextServiceProviderId(),
          );
          if (obj && obj[0]) {
            this.userRole.ServiceProviderId = obj[0].ServiceProviderId;
          }

          this.getAllUserRolePermissions(0, this.authState.getStoredContextServiceProviderId(), 0, 0);
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'serviceProviderService GetServiceProviderListForDropdown Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getAllUserRolePermissions(
    userRoleId: number,
    serviceProviderId: number,
    corporationId: number,
    venueId: number,
  ) {
    this.loaderService.display(true);
    const CPVenue = new UserRoleModule();
    CPVenue.UserRoleId = this.authState.AESEncryptText(userRoleId);
    CPVenue.ServiceProviderCPId = this.authState.AESEncryptText(serviceProviderId);
    CPVenue.CorporationCPId = this.authState.AESEncryptText(corporationId);
    CPVenue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.userRoleService.GetRolePermissions(CPVenue, -1, -1).subscribe(
      (data) => {
        if (data.ParentPermissionsList != null && data.ParentPermissionsList.length > 0) {
          this.config = TreeviewConfig.create({
            hasAllCheckBox: false,
            hasFilter: true,
            hasCollapseExpand: false,
            decoupleChildFromParent: false,
            maxHeight: 500,
          });

          this.items = [];
          let that = this;
          data.ParentPermissionsList.forEach(function (ParentPermission) {
            let childrens = [];
            that.isCheckBoxVisible = true;
            if (ParentPermission.ChildPermissionsList != null && ParentPermission.ChildPermissionsList.length > 0) {
              ParentPermission.ChildPermissionsList.forEach(function (item) {
                item.PAccess = item.PAccess ? true : false;
                childrens.push({
                  text:
                    item.PermissionDisplayName +
                    ' (Description: ' +
                    item.PermissionDescription +
                    ')' +
                    ' (Type: ' +
                    item.UserType +
                    ')',
                  checked: item.PAccess,
                  value: item.ChildPermissionId,
                  collapsed: true,
                });
              });
            }
            that.items.push(
              new TreeviewItem({
                text:
                  ParentPermission.PermissionDisplayName +
                  ' (Description: ' +
                  ParentPermission.PermissionDescription +
                  ')' +
                  ' (Type: ' +
                  ParentPermission.UserType +
                  ')',
                value: ParentPermission.ParentPermissionId,
                checked: false,
                children: childrens,
                collapsed: true,
              }),
            );
          });
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'userRoleService GetRolePermissions Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public onSelectedChange(_downlineItems: DownlineTreeviewItem[]) {
    if (this.treeviewComponent && this.treeviewComponent.items != null && this.treeviewComponent.items.length > 0) {
      //// If user search any child permission and then check/uncheck it
      //// we checked their parent permission & changed it accordingly.
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked == false) {
          if (it.children != null) {
            it.children.forEach(function (child) {
              if (child.checked) {
                it.checked = undefined;
              }
            });
          }
        }
      });

      let item = this.treeviewComponent.items.filter((tree) => tree.checked == false || tree.checked == undefined);
      if (item && item.length > 0) {
        this.isAllFeatureConfigurationChecked = false;
      } else {
        this.isAllFeatureConfigurationChecked = true;
      }
    }
  }

  public onAllCheckedChange(event: any) {
    let that = this;
    this.treeviewComponent.items.forEach(function (it) {
      it.checked = event.checked;
      that.changeChildrenSelection(it, event.checked);
    });
  }

  public changeChildrenSelection(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }

  //// User role template code below -------------------------------------
  public getAllUserRoleTemplates() {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: '', GenericSearch: 1, IsActive: 1 };

    this.userRoleService.GetAllUserRoleTemplates(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.userRoleTemplates = data;
        this.loaderService.display(false);
        this.getPageState();
      },
      (error) => {
        console.log(
          'userRoleTemplateService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
        this.getPageState();
      },
    );
  }

  public changeUserRoleTemplate($event: number) {
    if ($event) {
      this.userRoleTemplate = new UserRoleTemplateModule();
      this.getUserRoleTemplateById($event);
    } else {
      this.userRoleTemplate = new UserRoleTemplateModule();
    }
  }

  public getUserRoleTemplateById(userRoleTemplateId: number) {
    this.loaderService.display(true);
    const CProle = new UserRoleTemplateModule();
    CProle.ServiceProviderCPId = this.authState.AESEncryptText(this.authState.getStoredContextServiceProviderId());
    CProle.CorporationCPId = this.authState.AESEncryptText(this.authState.getStoredContextCorporationId());
    CProle.VenueCPId = this.authState.AESEncryptText(this.authState.getStoredContextVenueId());
    CProle.UserRoleTemplateCPId = this.authState.AESEncryptText(userRoleTemplateId);

    this.userRoleTemplateService.GetSingle(CProle).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.userRoleTemplate = data;
        if (this.userRoleTemplate != null) {
          this.userRole.ServiceProviderId = this.userRoleTemplate.ServiceProviderId;
          if (
            this.userRoleTemplate.ParentPermissionsList != null &&
            this.userRoleTemplate.ParentPermissionsList.length > 0
          ) {
            this.items = [];
            let that = this;
            this.isCheckBoxVisible = true;
            this.userRoleTemplate.ParentPermissionsList.forEach(function (ParentPermission) {
              let childrens = [];
              if (ParentPermission.ChildPermissionsList != null && ParentPermission.ChildPermissionsList.length > 0) {
                ParentPermission.ChildPermissionsList.forEach(function (item) {
                  item.PAccess = item.PAccess ? true : false;
                  childrens.push({
                    text:
                      item.PermissionDisplayName +
                      ' (Description: ' +
                      item.PermissionDescription +
                      ')' +
                      ' (Type: ' +
                      item.UserType +
                      ')',
                    checked: item.PAccess,
                    value: item.ChildPermissionId,
                    collapsed: true,
                  });
                });
              }

              that.items.push(
                new TreeviewItem({
                  text:
                    ParentPermission.PermissionDisplayName +
                    ' (Description: ' +
                    ParentPermission.PermissionDescription +
                    ')' +
                    ' (Type: ' +
                    ParentPermission.UserType +
                    ')',
                  value: ParentPermission.ParentPermissionId,
                  checked: ParentPermission.PAccess ? true : false,
                  children: childrens,
                  collapsed: true,
                }),
              );
            });

            if (this.items && this.items.length > 0) {
              let itemObj = this.items.filter((tree) => tree.checked == false || tree.checked == undefined);
              if (itemObj && itemObj.length > 0) {
                this.isAllFeatureConfigurationChecked = false;
              } else {
                this.isAllFeatureConfigurationChecked = true;
              }
            }
          }
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'userRoleTemplateService GetSingle Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public openRoleTemplateModalPopup() {
    this.templateModal = true;
    this.userRoleTemplate = new UserRoleTemplateModule();
    const permissions: ParentPermissions[] = [];
    this.userRoleTemplate.ParentPermissionsList = permissions;

    this.userRoleTemplate.ServiceProviderId = this.userRole.ServiceProviderId;
    let permissionPayload: ParentPermissions[] = [];
    this.itemsTemplate = [];
    this.isAllFeatureConfigurationCheckedTemplate = false;

    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        let parentPermission = new ParentPermissions();
        parentPermission.ParentPermissionId = it.value;
        parentPermission.PermissionDisplayName = it.text;
        if (it.checked == true || it.checked == undefined) {
          parentPermission.PAccess = 1;
        } else {
          parentPermission.PAccess = 0;
        }
        parentPermission.ChildPermissionsList = [];

        if (it.children != null) {
          it.children.forEach(function (chield) {
            let childrenPermission = new ChildPermissions();
            childrenPermission.ChildPermissionId = chield.value;
            childrenPermission.PermissionDisplayName = chield.text;
            if (chield.checked == true || chield.checked == undefined) {
              childrenPermission.PAccess = 1;
            } else {
              childrenPermission.PAccess = 0;
            }
            parentPermission.ChildPermissionsList.push(childrenPermission);
          });
        }
        permissionPayload.push(parentPermission);
      });

      if (permissionPayload != null && permissionPayload.length > 0) {
        let that = this;
        this.isCheckBoxVisibleTemplate = true;
        permissionPayload.forEach(function (ParentPermission) {
          let childrens = [];
          if (ParentPermission.ChildPermissionsList != null && ParentPermission.ChildPermissionsList.length > 0) {
            ParentPermission.ChildPermissionsList.forEach(function (item) {
              item.PAccess = item.PAccess ? true : false;
              childrens.push({
                text: item.PermissionDisplayName,
                checked: item.PAccess,
                value: item.ChildPermissionId,
                collapsed: true,
              });
            });
          }
          that.itemsTemplate.push(
            new TreeviewItem({
              text: ParentPermission.PermissionDisplayName,
              value: ParentPermission.ParentPermissionId,
              checked: ParentPermission.PAccess ? true : false,
              children: childrens,
              collapsed: true,
            }),
          );
        });

        if (this.itemsTemplate && this.itemsTemplate.length > 0) {
          let itemObj = this.itemsTemplate.filter((tree) => tree.checked == false || tree.checked == undefined);
          if (itemObj && itemObj.length > 0) {
            this.isAllFeatureConfigurationCheckedTemplate = false;
          } else {
            this.isAllFeatureConfigurationCheckedTemplate = true;
          }
        }
      }
    } else {
      this.treeviewComponent.items.forEach(function (it) {
        let parentPermission = new ParentPermissions();
        parentPermission.ParentPermissionId = it.value;
        parentPermission.PermissionDisplayName = it.text;
        parentPermission.PAccess = 0;
        parentPermission.ChildPermissionsList = [];
        if (it.children != null) {
          it.children.forEach(function (chield) {
            let childrenPermission = new ChildPermissions();
            childrenPermission.PermissionDisplayName = chield.text;
            childrenPermission.ChildPermissionId = chield.value;
            childrenPermission.PAccess = 0;

            parentPermission.ChildPermissionsList.push(childrenPermission);
          });
        }
        permissionPayload.push(parentPermission);
      });

      if (permissionPayload != null && permissionPayload.length > 0) {
        let that = this;
        this.isCheckBoxVisibleTemplate = true;
        permissionPayload.forEach(function (ParentPermission) {
          let childrens = [];
          if (ParentPermission.ChildPermissionsList != null && ParentPermission.ChildPermissionsList.length > 0) {
            ParentPermission.ChildPermissionsList.forEach(function (item) {
              item.PAccess = item.PAccess ? true : false;
              childrens.push({
                text: item.PermissionDisplayName,
                checked: item.PAccess,
                value: item.ChildPermissionId,
                collapsed: true,
              });
            });
          }
          that.itemsTemplate.push(
            new TreeviewItem({
              text: ParentPermission.PermissionDisplayName,
              value: ParentPermission.ParentPermissionId,
              checked: ParentPermission.PAccess ? true : false,
              children: childrens,
              collapsed: true,
            }),
          );
        });

        if (this.itemsTemplate && this.itemsTemplate.length > 0) {
          let itemObj = this.itemsTemplate.filter((tree) => tree.checked == false || tree.checked == undefined);
          if (itemObj && itemObj.length > 0) {
            this.isAllFeatureConfigurationCheckedTemplate = false;
          } else {
            this.isAllFeatureConfigurationCheckedTemplate = true;
          }
        }
      }
    }
  }

  public closeRoleTemplateModal() {
    this.templateModal = false;
  }

  public onAllCheckedChangeTemplate(event: any) {
    let that = this;
    this.treeviewComponentTemplate.items.forEach(function (it) {
      it.checked = event.checked;
      that.changeTemplateChildrenSelection(it, event.checked);
    });
  }

  public changeTemplateChildrenSelection(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }

  public onSelectedChangeTemplate(_downlineItems: DownlineTreeviewItem[]) {
    if (
      this.treeviewComponentTemplate &&
      this.treeviewComponentTemplate.items != null &&
      this.treeviewComponentTemplate.items.length > 0
    ) {
      let item = this.treeviewComponentTemplate.items.filter(
        (tree) => tree.checked == false || tree.checked == undefined,
      );
      if (item && item.length > 0) {
        this.isAllFeatureConfigurationCheckedTemplate = false;
      } else {
        this.isAllFeatureConfigurationCheckedTemplate = true;
      }
    }
  }

  public addUserRoleTemplate(roleTemplateForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (roleTemplateForm.status === 'INVALID' && roleTemplateForm.disabled === false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'User Role Template',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.userRoleTemplate.RoleTemplateName = this.utilitiesService.removeInnerSpaces(
      this.userRoleTemplate.RoleTemplateName,
    );
    if (this.userRoleTemplate.RoleTemplateName === null || this.userRoleTemplate.RoleTemplateName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    let permissionPayload: ParentPermissions[] = [];
    if (this.treeviewComponentTemplate.items != null) {
      this.treeviewComponentTemplate.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let parentPermission = new ParentPermissions();
          parentPermission.ParentPermissionId = it.value;
          parentPermission.PAccess = 1;
          parentPermission.ChildPermissionsList = [];
          if (it.children != null) {
            it.children.forEach(function (chield) {
              if (chield.checked || chield.checked == undefined) {
                let childrenPermission = new ChildPermissions();
                childrenPermission.ChildPermissionId = chield.value;
                childrenPermission.PAccess = 1;

                parentPermission.ChildPermissionsList.push(childrenPermission);
              }
            });
          }
          permissionPayload.push(parentPermission);
        }
      });
    }

    if (permissionPayload.length == 0) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('User Role Template', 'Please select atleast one permission.');
      return;
    }

    this.userRoleTemplate.ParentPermissionsList = permissionPayload;
    this.userRoleTemplate.AddedBy = this.currentUser.UserId;

    this.userRoleTemplateService.Add(this.utilitiesService.stripScript(this.userRoleTemplate)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.closeRoleTemplateModal();
        this.utilitiesService.smmodal('User Role Template', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'userRoleTemplateService addUserRoleTemplate Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }
}
