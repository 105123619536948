import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Configuration } from '../../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SensorTypeModule } from '../sensortype.module';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';

@Injectable({
  providedIn: 'root',
})
export class SensorTypeService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  // Create Sensor Type
  public Add = (body: SensorTypeModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/type';
    return this.http.post<ResponseMessage>(_url, body);
  };

  // Update Sensor Type
  public Update = (body: SensorTypeModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/type';
    return this.http.put<ResponseMessage>(_url, body);
  };

  // Get All Sensor Type
  public GetSensorTypesList = (body: string, page: number, size: number): Observable<SensorTypeModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/all/sensor/types/' + page + '/' + size;
    return this.http.post<SensorTypeModule[]>(_url, body);
  };

  // Get All Sensor Type For Drop Down
  public GetSensorTypesDropDown = (body: string, page: number, size: number): Observable<SensorTypeModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/types/' + page + '/' + size;
    return this.http.post<SensorTypeModule[]>(_url, body);
  };

  // Get Sensor Type By Id
  public GetSingle = (body: SensorTypeModule): Observable<SensorTypeModule> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/type/details';
    return this.http.post<SensorTypeModule>(_url, body);
  };

  public RemoveSensorTypeImage = (SensorTypeId: number): Observable<ResponseMessage> => {
    const toUpdate = JSON.stringify({});
    const _url: string = this.configuration.Server + 'corporation/remove/sensor/type/' + SensorTypeId + '/image';
    return this.http.put<ResponseMessage>(_url, toUpdate);
  };

  SensorTypeImage(filedata: File, id: number): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'corporation/sensor/type/' + id + '/image';

      xhr.open('PUT', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }
}
