import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthState } from 'src/app/store/auth.state';
import { environment } from 'src/environments/environment';
import { version } from '../../../environments/version';
import { Configuration } from '../../app.constants';
import { Module } from '../../serviceprovider/serviceprovider.module';
import { UtilitiesService } from '../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../shared/directives/loader.service';
import { AccessGroupPermissionService } from '../../usermanagement/accessgrouppermission/components/accessgrouppermission.http.service';
import { RelUserRolePermissions } from '../../usermanagement/role/role.module';
import { User } from '../../usermanagement/user/user.model';
import { VenueService } from '../../venuemanagement/venue/components/venue.http.service';
import { ClientLogo, VenueModule } from '../../venuemanagement/venue/venue.module';
import { Login, UserTokenAuthentication } from '../login.module';
import { LoginService } from './login.http.service';
@Component({
  selector: 'login-component',
  templateUrl: 'login.component.html',
  styleUrls: ['login.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LoginService, UtilitiesService, AccessGroupPermissionService, VenueService],
})
export class LoginComponent implements OnInit {
  logginForm: FormGroup;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('closeforgot') closeforgot: ElementRef;

  fileInputVariable: any;

  Islogin: boolean;
  loggedInUser: User = new User();
  loggedInUserObj: Login = new Login();
  public users: User[] = [];
  forgotPasswordConfirmModal = false;
  isTraxLogin: boolean;
  public rolePermissions: RelUserRolePermissions[] = [];
  public modules: Module[] = [];
  public clientLogo: ClientLogo = new ClientLogo();
  public venues: VenueModule[] = [];

  isReqComplete = false;
  isRequest = false;
  hide = true;
  public appVersion: string = version;

  constructor(
    private router: Router,
    private authState: AuthState,
    private loginService: LoginService,
    private utilitiesService: UtilitiesService,
    private configuration: Configuration,
    private loaderService: LoaderService,
    public venueService: VenueService,

    private route: ActivatedRoute,
    private accessGroupPermissionService: AccessGroupPermissionService,
    private fb: FormBuilder,
  ) {
    this.logginForm = this.fb.group({
      emailControl: ['', [Validators.required, Validators.email]],
      passwordControl: ['', [Validators.required]],
      rememberMeControl: ['', []],
    });

    this.authState.isAuthenticated.subscribe((value) => {
      this.Islogin = value;
    });
  }

  ngOnInit() {
    const result = this.authState.CheckLogin(null);
    this.loggedInUser = result.user;
    this.loggedInUserObj = result.login;
    this.rolePermissions = result.rolePermissions;
    this.route.queryParams.subscribe((params) => {
      const code = params['code'];
      const state = params['state'];
      if (code != undefined && state != undefined) {
        this.userSSOLogin(code, state);
      }
      // Call AuthService method to handle the received code
      /* this.authService.handleCallback(code).subscribe(
        (data) => {
          let test = data;
        },
        (error) => {
          console.log(
            'loginService forgotPassword Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );*/
    });
  }

  public checkIsSSOEnabled() {
    this.isReqComplete = true;
    this.loaderService.display(true);
    const email = this.loggedInUserObj.username;
    this.loginService.isSSOEnabled(email).subscribe((data) => {
      if (data.isSSOEnabled) {
        this.loginService.loginSSO(email, data);
      } else {
        this.isTraxLogin = true;
      }
    });
  }

  public userLogin() {
    this.isReqComplete = true;
    this.loaderService.display(true);
    const rememberMe = this.loggedInUserObj.rememberMe;
    const email = this.loggedInUserObj.username;

    const userObj = new Login();
    userObj.username = this.loggedInUserObj.username;
    userObj.password = this.authState.AESEncryptText('Password1');
    userObj.grant_type = 'password';
    userObj.ip_address = this.authState.getStoredIPAddress();

    this.loginService.loginDetails(userObj).subscribe(
      (data) => {
        this.loggedInUser = data;

        /// If check the rememberMe checkbox email id will be stored
        if (rememberMe) {
          this.authState.storeUserEmail(email);
        } else {
          this.authState.clearUserEmail();
        }
        this.isReqComplete = false;
        this.logginForm.reset();
        this.loaderService.display(false);

        if (this.loggedInUser.DefaultVenue !== null) {
          if (this.loggedInUser.DefaultVenue.ServiceProvider !== null) {
            this.configuration.contextServiceProviderId =
              this.loggedInUser.DefaultVenue.ServiceProvider.ServiceProviderId;
            this.authState.storedContextServiceProviderId(this.configuration.contextServiceProviderId);

            this.configuration.ServiceProviderName = this.loggedInUser.DefaultVenue.ServiceProvider.ServiceProviderName;
            this.authState.storedServiceProviderName(this.configuration.ServiceProviderName);
          }

          if (
            this.loggedInUser.DefaultVenue.Corporation !== null &&
            (this.loggedInUser.DefaultVenue.Corporation.CorporationId !== null ||
              this.loggedInUser.DefaultVenue.Corporation.CorporationId > 0)
          ) {
            this.configuration.contextCorporationId = this.loggedInUser.DefaultVenue.Corporation.CorporationId;
            this.authState.storedContextCorporationId(this.configuration.contextCorporationId);

            this.configuration.CorporationName = this.loggedInUser.DefaultVenue.Corporation.CorporationName;
            this.authState.storedCorporationName(this.configuration.CorporationName);
          }

          if (this.loggedInUser.DefaultVenue.VenueId !== null && this.loggedInUser.DefaultVenue.VenueId > 0) {
            this.configuration.contextVenueId = this.loggedInUser.DefaultVenue.VenueId;
            this.authState.storedContextVenueId(this.configuration.contextVenueId);

            this.configuration.VenueName = this.loggedInUser.DefaultVenue.VenueName;
            this.authState.storedVenueName(this.configuration.VenueName);
          } else {
            this.configuration.contextVenueId = 0;
          }
        } else {
          this.configuration.contextServiceProviderId = 0;
          this.configuration.contextCorporationId = 0;
          this.configuration.contextVenueId = 0;
        }
        this.insertUserTokenAuthentication();

        const user = data;
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        // this.utilitiesService.smmodal(
        //   'Sign In',
        //   'Authentication failed.<br>Email ID or Password is incorrect or your license has expired.',
        // );
        console.log('loginService login Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public userSSOLogin(code: string, state: number) {
    this.isReqComplete = true;
    this.loaderService.display(true);
    const rememberMe = this.loggedInUserObj.rememberMe;
    const email = this.loggedInUserObj.username;

    const userObj = new Login();
    userObj.username = this.loggedInUserObj.username;
    userObj.password = this.authState.AESEncryptText(this.loggedInUserObj.password);
    userObj.grant_type = 'password';
    userObj.ip_address = this.authState.getStoredIPAddress();

    this.loginService.handleCallback(code, state).subscribe(
      (data) => {
        this.loggedInUser = data;

        /// If check the rememberMe checkbox email id will be stored
        if (rememberMe) {
          this.authState.storeUserEmail(email);
        } else {
          this.authState.clearUserEmail();
        }
        this.isReqComplete = false;
        this.logginForm.reset();
        this.loaderService.display(false);

        if (this.loggedInUser.DefaultVenue !== null) {
          if (this.loggedInUser.DefaultVenue.ServiceProvider !== null) {
            this.configuration.contextServiceProviderId =
              this.loggedInUser.DefaultVenue.ServiceProvider.ServiceProviderId;
            this.authState.storedContextServiceProviderId(this.configuration.contextServiceProviderId);

            this.configuration.ServiceProviderName = this.loggedInUser.DefaultVenue.ServiceProvider.ServiceProviderName;
            this.authState.storedServiceProviderName(this.configuration.ServiceProviderName);
          }

          if (
            this.loggedInUser.DefaultVenue.Corporation !== null &&
            (this.loggedInUser.DefaultVenue.Corporation.CorporationId !== null ||
              this.loggedInUser.DefaultVenue.Corporation.CorporationId > 0)
          ) {
            this.configuration.contextCorporationId = this.loggedInUser.DefaultVenue.Corporation.CorporationId;
            this.authState.storedContextCorporationId(this.configuration.contextCorporationId);

            this.configuration.CorporationName = this.loggedInUser.DefaultVenue.Corporation.CorporationName;
            this.authState.storedCorporationName(this.configuration.CorporationName);
          }

          if (this.loggedInUser.DefaultVenue.VenueId !== null && this.loggedInUser.DefaultVenue.VenueId > 0) {
            this.configuration.contextVenueId = this.loggedInUser.DefaultVenue.VenueId;
            this.authState.storedContextVenueId(this.configuration.contextVenueId);

            this.configuration.VenueName = this.loggedInUser.DefaultVenue.VenueName;
            this.authState.storedVenueName(this.configuration.VenueName);
          } else {
            this.configuration.contextVenueId = 0;
          }
        } else {
          this.configuration.contextServiceProviderId = 0;
          this.configuration.contextCorporationId = 0;
          this.configuration.contextVenueId = 0;
        }
        this.insertUserTokenAuthentication();

        const user = data;
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        // this.utilitiesService.smmodal(
        //   'Sign In',
        //   'Authentication failed.<br>Email ID or Password is incorrect or your license has expired.',
        // );
        console.log('loginService login Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public insertUserTokenAuthentication() {
    this.loaderService.display(true);
    const payload = new UserTokenAuthentication();
    payload.Venue = new VenueModule();
    payload.ServiceProviderId = this.configuration.contextServiceProviderId;
    payload.CorporationId = this.configuration.contextCorporationId;
    payload.Venue.VenueId = this.configuration.contextVenueId;

    this.loginService.InsertUserTokenAuthentication(payload).subscribe(
      () => {
        this.loaderService.display(false);
        if (this.authState.getStoredContextVenueId()) {
          this.getVenueById(this.authState.getStoredContextVenueId());
        } else {
          this.setRolePermissions();
        }
      },
      (error) => {
        console.log(
          'loginService InsertUserTokenAuthentication Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
        );
        this.loaderService.display(false);
        if (this.authState.getStoredContextVenueId()) {
          this.getVenueById(this.authState.getStoredContextVenueId());
        } else {
          this.setRolePermissions();
        }
      },
    );
  }

  public getVenueById(VenueId: number) {
    this.loaderService.display(true);
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.authState.AESEncryptText(VenueId);

    this.venueService.GetSingle(CPvenue).subscribe(
      (data) => {
        if (data != null) {
          if (data.SignalRServiceURL != null && data.SignalRServiceURL != '') {
            this.authState.storedSignalrURL(data.SignalRServiceURL);
          }
          if (data.UserAssignmentRule != null) {
            this.authState.storedShiftStaffSchedule(data.UserAssignmentRule.ShiftStaffSchedule);
            this.authState.storedLocationUserAssignment(data.UserAssignmentRule.LocationUserAssignment);
            this.authState.storedEitherOneOrThese(data.UserAssignmentRule.EitherOneOrThese);
          }
          this.authState.storedBadge(data.IsBadgeEnable);
        }
        this.loaderService.display(false);
        this.setRolePermissions();
      },
      (error) => {
        console.log(
          'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
        this.setRolePermissions();
      },
    );
  }

  private setRolePermissions() {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: '', GenericSearch: 0 };

    this.accessGroupPermissionService.GetAllPermissions(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.rolePermissions = data;
        this.loaderService.display(false);

        if (this.authState.getStoredTraxToken() !== null && this.authState.getStoredTraxCurrentUser() !== null) {
          if (this.rolePermissions != null && this.rolePermissions.length > 0) {
            this.authState.isAuthenticated.next(true);
            this.authState.setCurrentUser(this.loggedInUser);
            this.authState.setUserRolePermisisons(this.rolePermissions);

            if (
              this.configuration.contextVenueId == null ||
              this.configuration.contextVenueId == 0 ||
              this.loggedInUser.IsSharedUser == 1
            ) {
              this.router.navigate(['configure-default-venue']);
            } else if (this.authState.canAccessFeature(this.rolePermissions, 'dashboard', 'view')) {
              this.router.navigate(['dashboard']);
            } else if (this.authState.canAccessFeature(this.rolePermissions, 'restroom-overview', 'view')) {
              this.router.navigate(['restroom-overview']);
            } else if (this.authState.canAccessFeature(this.rolePermissions, 'profile-menu', 'view')) {
              this.router.navigate(['profile-menu']);
            } else {
              let shift = this.rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName == 'shift' &&
                  (this.authState.getStoredShiftStaffSchedule() || this.authState.getStoredEitherOneOrThese()),
              );
              if (shift && shift.length > 0) {
                this.router.navigate(['shift']);
                return;
              }

              let shiftBreak = this.rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName == 'shift-break-schedule' &&
                  (this.authState.getStoredShiftStaffSchedule() || this.authState.getStoredEitherOneOrThese()),
              );
              if (shiftBreak && shiftBreak.length > 0) {
                this.router.navigate(['shift-break-schedule']);
                return;
              }

              let staffSchedule = this.rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName == 'staff-schedule' &&
                  (this.authState.getStoredShiftStaffSchedule() || this.authState.getStoredEitherOneOrThese()),
              );
              if (staffSchedule && staffSchedule.length > 0) {
                this.router.navigate(['staff-schedule']);
                return;
              }

              let userLocation = this.rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName == 'userlocationassignment' &&
                  (this.authState.getStoredLocationUserAssignment() || this.authState.getStoredEitherOneOrThese()),
              );
              if (userLocation && userLocation.length > 0) {
                this.router.navigate(['userlocationassignment']);
                return;
              }

              let redirectScreen = this.rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName != 'shift' &&
                  s.Permission.PermissionName != 'SHIFT_VIEW' &&
                  s.Permission.PermissionName != 'SHIFT_ADD' &&
                  s.Permission.PermissionName != 'SHIFT_EDIT' &&
                  s.Permission.PermissionName != 'SHIFT_BRK_SCHEDULE_VIEW' &&
                  s.Permission.PermissionName != 'SHIFT_BRK_SCHEDULE_ADD' &&
                  s.Permission.PermissionName != 'shift-break-schedule' &&
                  s.Permission.PermissionName != 'SHIFT_BRK_SCHEDULE_EDIT' &&
                  s.Permission.PermissionName != 'staff-schedule' &&
                  s.Permission.PermissionName != 'STAFF_SCHEDULE_VIEW' &&
                  s.Permission.PermissionName != 'STAFF_SCHEDULE_ADD' &&
                  s.Permission.PermissionName != 'STAFF_SCHEDULE_EDIT' &&
                  s.Permission.PermissionName != 'userlocationassignment' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_VIEW_USERS_RECORDS' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_VIEW_UNASSIGNED_LOCATION' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_EDIT' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_ASSIGN_SELF' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_ASSIGN_TO_OTHERS',
              );
              if (redirectScreen && redirectScreen.length > 0) {
                this.router.navigate([redirectScreen[0].Permission.PermissionName]);
              } else {
                this.router.navigate(['configure-default-venue']);
              }
            }
          } else {
            this.loggedInUser = new User();
            const permissions: RelUserRolePermissions[] = [];
            this.rolePermissions = permissions;

            this.authState.logout();
            this.router.navigate(['login']);
            this.utilitiesService.smmodal('Sign In', 'You do not have necessary permissions.');
          }
        } else {
          this.authState.logout();
          this.router.navigate(['login']);
        }
      },
      (error) => {
        this.loaderService.display(false);
        if (error.status == 401) {
          this.authState.logout();
        }
      },
    );
  }

  public openForgotPasswordConfirmModal(loginForm: NgForm) {
    if (loginForm.controls.emailControl.status === 'INVALID') {
      this.utilitiesService.smmodal('Forgot Password', 'Please enter your Email ID.');
      this.isRequest = false;
      this.loaderService.display(false);
      return;
    } else {
      this.forgotPasswordConfirmModal = true;
    }
  }

  public closeForgotPasswordConfirmModal() {
    this.forgotPasswordConfirmModal = false;
  }

  public forgotPassword() {
    this.loaderService.display(true);
    this.isRequest = true;
    if (!this.loggedInUserObj || !this.loggedInUserObj.username) {
      this.utilitiesService.smmodal('Forgot Password', 'Please enter your Email ID.');
      this.isRequest = false;
      this.loaderService.display(false);
      return;
    }
    this.loggedInUser.Email = this.loggedInUserObj.username;
    this.loggedInUser.ApplicationType = environment.applicationTypeName;

    this.loginService.ForgotPassword(this.loggedInUser).subscribe(
      (data) => {
        this.utilitiesService.smmodal('Forgot Password', data.Message);
        this.isRequest = false;
        this.loaderService.display(false);
        this.forgotPasswordConfirmModal = false;
      },
      (error) => {
        this.isRequest = false;
        this.loaderService.display(false);
        this.forgotPasswordConfirmModal = false;
        this.utilitiesService.smmodal('Forgot Password', 'Email ID does not exist.');
        console.log(
          'loginService forgotPassword Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }
}
