<div *ngIf="!loggedInUser || !loggedInUser.UserId">
  <div class="limiter" *ngIf="!Islogin">
    <div class="box2">
      <div class="wave -one"></div>
      <div class="wave -two"></div>
      <div class="wave -three"></div>
    </div>

    <div class="container-login100">
      <ul style="z-index: -1">
        <li><span></span></li>
        <li><span></span></li>
        <li><span></span></li>
        <li><span></span></li>
        <li><span></span></li>
        <li><span></span></li>
        <li><span></span></li>
        <li><span></span></li>
      </ul>

      <div class="wrap-login100">
        <form class="login100-form validate-form" [formGroup]="logginForm" autocomplete="off">
          <span class="login100-form-title p-b-26" style="padding-bottom: 32px">
            <img class="full-logo img-responsive" />
          </span>

          <span class="login100-form-title p-b-48"></span>

          <mat-form-field>
            <mat-label>Email</mat-label>
            <input
              matInput
              type="text"
              autocomplete="off"
              formControlName="emailControl"
              class="form-control"
              name="Email"
              placeholder="Email ID"
              [(ngModel)]="loggedInUserObj.username"
              required
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
              spellcheck="false"
            />
            <mat-error *ngIf="logginForm.get('emailControl').errors?.required"> Email ID is required. </mat-error>
            <mat-error *ngIf="logginForm.get('emailControl').errors?.pattern"> Email ID is invalid. </mat-error>
          </mat-form-field>
          <div *ngIf="isTraxLogin">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input
                matInput
                [type]="hide ? 'password' : 'text'"
                autocomplete="new-password"
                formControlName="passwordControl"
                class="form-control"
                name="Password"
                placeholder="Password"
                [(ngModel)]="loggedInUserObj.password"
                required
              />
              <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
              <mat-error *ngIf="logginForm.get('passwordControl').errors?.required">
                Login with Trax Password.
              </mat-error>
            </mat-form-field>
          </div>
          <div class="container-login100-form-btn">
            <div class="wrap-login100-form-btn">
              <div class="login100-form-bgbtn"></div>
              <button class="login100-form-btn" type="submit" (click)="checkIsSSOEnabled()">Sign In</button>
            </div>
          </div>

          <span style="float: right; padding-top: 27px">Version: {{ appVersion }}</span>
        </form>
      </div>
    </div>
  </div>
</div>

<div id="dropDownSelect1"></div>

<div
  class="modal"
  id="forgotPasswordConfirmModal"
  [ngStyle]="forgotPasswordConfirmModal ? { display: 'block' } : { display: 'none' }"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title">Forgot Password?</h3>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          (click)="closeForgotPasswordConfirmModal()"
          style="margin-top: -37px"
        >
          &times;
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <p class="forgotmsg">Click on forgot button to reset your password.</p>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="col-md-12 btn-footer">
          <button
            type="button"
            class="btn btn-type btn-save"
            data-dismiss="modal"
            (click)="forgotPassword()"
            [disabled]="isRequest"
          >
            Forgot
          </button>
          <button type="button" class="btn btn-type btn-cancel" (click)="closeForgotPasswordConfirmModal()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<script>
  $('a').click(function () {
    return false;
  });
  $('a').off('click');
  $('a').unbind('click');
</script>
