import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Userpilot } from 'userpilot';
import { Configuration } from './app.constants';
import { LoaderService } from './shared/directives/loader.service';
import { AuthState } from './store/auth.state';

@Component({
  selector: 'trax-app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss', '../style/app.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  showLoader: boolean;
  Islogin: boolean;

  constructor(
    private authState: AuthState,
    private loaderService: LoaderService,
    public cdRef: ChangeDetectorRef,
    public configuration: Configuration,
  ) {
    this.authState.isAuthenticated.subscribe((value) => {
      this.Islogin = value;
      // this.cdRef.detach();
      // setInterval(() => {
      //   this.cdRef.detectChanges();
      // }, 100);
    });

    this.authState.currentUser.subscribe((_value) => {
      this.authState.userInfoChanged.next('userInfoChanged');
    });
  }

  ngOnInit() {
    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });

    this.configuration.CorporationName = this.authState.getStoredCorporationName();
    this.configuration.VenueName = this.authState.getStoredVenueName();

    //Initialize Userpilot
    Userpilot.initialize('NX-15ad5535');

    document.title = document.title.replace(document.title, environment.projectTitle);
    const a: any = document.getElementById('faviconId');
    a.href = environment.faviconUrl;
  }
}
