import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';
import { GenericEntity } from '../../shared/genericentity/genericentity';

import {
  DefaultTreeviewEventParser,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewI18n,
  TreeviewI18nDefault,
  TreeviewModule,
} from 'ngx-treeview';

import { LocationSensor, SensorModule } from '../../corporationmanagement/sensor/sensor.module';
import { SensorTypeModule } from '../../corporationmanagement/sensortype/sensortype.module';
import { FeatureConfiguration, Module } from '../../serviceprovider/serviceprovider.module';
import { AreaModule } from '../../venuemanagement/area/area.module';
import { BuildingModule } from '../../venuemanagement/building/building.module';
import { GatewayModule } from '../../venuemanagement/gateway/getway.module';
import { InspectionScoreFactor, VenueModule } from '../../venuemanagement/venue/venue.module';
import { BeaconModule } from '../beacon/beacon.module';
import { ZoneModule } from '../zone/zone.module';
import { LocationComponent } from './components/location.component';
import { GateRoutes } from './location.routes';

@NgModule({
  imports: [
    CommonModule,
    GateRoutes,
    FormsModule,

    NgxPaginationModule,
    HomeModule,
    RouterModule,
    TreeviewModule,
  ],
  providers: [
    TreeviewConfig,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
  ],
  declarations: [LocationComponent],
})
export class LocationModule extends GenericEntity {
  public LocationTemplateCPId: number;
  public LocationId: number;
  public LocationCPId: string;
  public VenueCPId: string;
  public LocationName: string;
  public Latitude: number;
  public Longitude: number;
  public Altitude: number;
  public LocationType: string;
  public LevelId: number;
  public LevelName: string;
  public LevelOrdinal: number;
  // public restroomFeedback: string;
  public SensorTypeAvgColor: string;
  public LastUpdated: any;
  public RestroomFeedback: RestroomFeedback[];
  public Zone: ZoneModule;
  public LocationBeacons: LocationBeacon[];
  public LocationSensors: LocationSensor[];
  public SensorTypes: SensorTypeModule[];
  public sensorTypesWithoutStallOccupancy: SensorTypeModule[];
  public Building: BuildingModule;
  public Venue: VenueModule;
  public RandomNumber: number;
  public RandomPercentage: number;
  /// this StallOcupancySensorType property is to display details of male canvas
  public StallOccupancySensorType: SensorTypeModule;
  public VisitorCountPercentage: number;
  public LocationMapFiles: any[] = [];
  public LastCleanedDate: any;
  public CleaningOpenedDate: any;
  public LastVisited: any;
  public SurveyIconColor: string;
  public isGaugeShow: any;
  public PeopleCounterThreshold: number;
  public isAQShow: any;
  public HeadCount: any;
  public AirQuality: number;
  public AQStatusColor: string;
  public ariaExpanded: boolean;
  public NextRestroomDistance: number;
  public NextRestroomDirection: string;
  public LocationStatus: string;
  public LocationSVGMap: string;
  public isPeopleCounterShow: any;
  public isLinkShow: any;

  public LocationGateways: LocationGateway[];
  public LocationTasks: LocationTaskModule[];
  public LocationInventories: LocationInventoryModule[];
  public LocationElements: LocationElementModule[];
  public Modules: Module[] = [];
  public LocationFeatureConfigurations: FeatureConfiguration[] = [];
  public isCA = false;
  public MapID: any;

  public VenueLocationTypeId: number;
  public VenueLocationTypeName: string;
  public AllowGender: any;
  public LocationFeedbackReasons: VenueLocationTouchlessFeedbackReason[];
  public CapacityCount: number;
  public AvailabilityPercentage: number;
  public TouchlessFeedbackIdentifier: string;
  public IsFeedbackIconBlinking: boolean;
  public LocationTemplateId: number;
  public CleaningStatus: string;

  public IsTouchlessFeedbackEnableForVenue: any;
  public IsTouchlessFeedbackEnableForLocation: any;
}

export class LocationBeacon extends GenericEntity {
  public LocationBeaconId: number;
  public Beacon: BeaconModule;
}

export class RestroomFeedback extends GenericEntity {
  public feedback: string;
  public icon: string;
  public date: any;
}

export class LocationSensorDetails extends GenericEntity {
  public locationSensorId: number;
  public SensorType: SensorTypeModule;
  public Sensors: SensorModule[] = [];
  public Threshold: any;
  public WarnPercentage: any;
}

export class LocationGenderType extends GenericEntity {
  public LocationTypeId: number;
  public LocationTypeName: string;
  public LocationTypeCode: string;
  public LocationTypeDescription: string;
}

export class LocationGateway extends GenericEntity {
  public LocationGatewayId: number;
  public Gateway: GatewayModule;
}

export class LocationTaskModule extends GenericEntity {
  public LocationTaskId: number;
  public LocationTaskName: string;
  public Sequence: number;
  public ImageType: string;
  public ImageTypeName: string;
  public LocationId: number;
  public IsMasterLocation: number;
  public MasterLocationId: number;
  public TaskId: number;
  public VenueTaskId: number;
  public VenueLocationTypeId: number;
  public LocationTemplateId: number;
  public LocationTemplateName: string;
}

export class LocationInventoryModule extends GenericEntity {
  public LocationInventoryId: number;
  public LocationId: number;
  public LocationName: string;
  public InventoryId: number;
  public InventoryName: string;
  public Quantity: number;
  public Identifier: string;
  public VenueInventoryId: number;
  public VenueLocationTypeId: number;
  public LocationTemplateId: number;
  public LocationTemplateName: string;
}

export class LocationElementModule extends GenericEntity {
  public LocationElementId: number;
  public ElementId: number;
  public LocationId: number;
  public LocationName: string;
  public ElementName: string;
  public VenueElementId: number;
  public Rating: string;
  public InspectionScoreFactor: InspectionScoreFactor;
  public ElementAttachmentCount: number;
  public VenueLocationTypeId: number;
  public LocationTemplateId: number;
  public LocationTemplateName: string;
  public ElementWeightage: any;

  public Area: AreaModule;
  public Areas: AreaModule[];
  public scoreFactorsArray: any[];
  public InspectionScoreFactorTransactions: InspectionScoreFactorTransactions[];
}

export class InspectionScoreFactorTransactions {
  SPInspectionScoreFactorId: number;
  SPInspectionScoreFactorLable: string;
  SPInspectionScoreFactorTransactionId: number;
  SequenceOfRating: number;
  ActiveSPInspectionScoreFactorLable: string;
}

export class VenueLocationTouchlessFeedbackReason extends GenericEntity {
  public VenueLocationFeedbackReasonId: number;
  public VenueFeedbackReasonId: number;
  public LocationReason: string;
  public ResponseType: string;
  public Reason: string;

  public LocationTemplateId: number;
  public LocationTemplateName: string;
  public TouchlessFeedbackIdentifier: string;
}

export class WorkorderCreatorAttachments {
  public WorkorderCreatorAttachmentId: number;
  public AttachmentTitle: string;
  public AttachmentName: string;
  public AttachmentType: string;
  public Comment: string;

  public rowid: any;
  public attachmentImage: any;
  public attachmentImageFile: any;
  public attachmentImageType: string;
  public IsActive: number;
}
