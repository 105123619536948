import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';
import { GenericEntity } from '../../shared/genericentity/genericentity';

import { CityRoutes } from './city.routes';
import { CityComponent } from './components/city.component';

import { StateModule } from '../state/state.module';
import { CountryModule } from '../country/country.module';

@NgModule({
  imports: [CommonModule, CityRoutes, NgxPaginationModule, FormsModule, HomeModule],

  declarations: [CityComponent],
})
export class CityModule extends GenericEntity {
  public CityId: number;
  public CityCPId: string;
  public CityName: string;
  public Country: CountryModule;
  public State: StateModule;
}
