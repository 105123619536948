<section class="content-header">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg-3 col-md-4 col-sm-12 form-heading">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="gateways">GATEWAYS</span>
        <span class="list-heading" *ngIf="!gateways && !gateway.GatewayId">Add Gateway</span>
        <span class="list-heading" *ngIf="!gateways && gateway.GatewayId">Edit Gateway</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12">
        <div id="page-size" class="col-lg-1 col-sm-4 col-md-4 col-xs-12 pull-right list-heading" *ngIf="gateways">
          <ngx-atlas-select
            (model)="GateWayPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="gateways && !gateway.GatewayId && canAccess('GATEWAY_ADD', 'view')"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Gateway
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="gateways">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="gateways">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="gateWaySearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="gateways">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>
        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-striped table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('GatewayName', columnSortOrder)">
                  Gateway
                  <span *ngIf="selectedColumnName == 'GatewayName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'GatewayName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('GatewayIdentifier', columnSortOrder)">
                  Gateway Identifier
                  <span *ngIf="selectedColumnName == 'GatewayIdentifier' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'GatewayIdentifier' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th>Venue</th>
                <th class="table-sort-header" (click)="sortColumn('IsOnline', columnSortOrder)">
                  Live Status
                  <span *ngIf="selectedColumnName == 'IsOnline' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'IsOnline' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('LastSeenDate', columnSortOrder)">
                  Last Seen
                  <span *ngIf="selectedColumnName == 'LastSeenDate' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'LastSeenDate' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header">Location</th>
                <th class="table-sort-header" (click)="sortColumn('IsActive', columnSortOrder)">
                  Status
                  <span *ngIf="selectedColumnName == 'IsActive' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'IsActive' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
              </tr>
            </thead>

            <tbody
              *ngFor="
                let gateway of gateways
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getGatewayById(gateway.GatewayId)">
                <td>{{ gateway.GatewayName }}</td>
                <td>{{ gateway.GatewayIdentifier }}</td>
                <td>{{ gateway.Venue.VenueName }}</td>

                <td *ngIf="gateway.IsOnline">
                  <span class="label label-success">Online</span>
                </td>
                <td *ngIf="!gateway.IsOnline">
                  <span class="label label-danger" style="font-weight: normal">Offline</span>
                </td>

                <td>{{ gateway.LastSeenDate | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                <td>{{ gateway.LocationNames }}</td>

                <td *ngIf="gateway.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!gateway.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!gateways">
    <form #gatewayForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div class="">
              <ngx-atlas-select
                (model)="gateway.Venue.VenueId = $event"
                [list]="venues"
                [idField]="'VenueId'"
                [textField]="'VenueName'"
                [placeholder]="'Select Venue'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [selectedValue]="gateway.Venue.VenueId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="GatewayName"><span id="red-circle">&#x25CF;</span> Gateway Name</label>
            <div class="">
              <input
                type="text"
                placeholder="Gateway Name"
                name="GatewayName"
                class="form-control"
                [(ngModel)]="gateway.GatewayName"
                required
                #GatewayName="ngModel"
                autocomplete="off"
                [pattern]="utilitiesService.alphanumericRegex"
              />
              <div *ngIf="GatewayName.errors && (GatewayName.dirty || GatewayName.touched)" class="alert alert-danger">
                <div [hidden]="!GatewayName.errors.required" class="text-danger">Gateway name is required.</div>
                <div [hidden]="!GatewayName.errors.pattern" class="text-danger">
                  {{ utilitiesService.alphanumericRegexMessage }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="GatewayIdentifier"><span id="red-circle">&#x25CF;</span> Gateway Identifier</label>
            <div class="">
              <input
                type="text"
                placeholder="Gateway Identifier"
                name="GatewayIdentifier"
                class="form-control"
                [(ngModel)]="gateway.GatewayIdentifier"
                required
                #GatewayIdentifier="ngModel"
                autocomplete="off"
              />
              <div
                *ngIf="GatewayIdentifier.errors && (GatewayIdentifier.dirty || GatewayIdentifier.touched)"
                class="alert alert-danger"
              >
                <div [hidden]="!GatewayIdentifier.errors.required" class="text-danger">
                  Gateway identifier is required.
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="range"><span id="red-circle">&#x25CF;</span> Range (in meters)</label>
            <div class="">
              <input
                type="number"
                class="form-control"
                name="range"
                placeholder="Range"
                [(ngModel)]="gateway.Range"
                required
                #range="ngModel"
                min="0"
                step="0.01"
                validation="pattern=/[0-9]{2}\.[0-9]{2}$/:alt=Invalid number"
                (keypress)="keyPressCheck($event)"
              />
              <div *ngIf="range.errors && (range.dirty || range.touched)" class="alert alert-danger">
                <div [hidden]="!range.errors.required" class="text-danger">Range is required.</div>
                <div [hidden]="!range.errors.pattern" class="text-danger">Range accepts only numeric value.</div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group" *ngIf="gateway.GatewayId">
              <div class="">
                <mat-checkbox [(ngModel)]="gateway.IsActive" name="isActive">Is Active</mat-checkbox>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-12 col-md-12 col-sm-12 btn-section1">
            <input
              type="button"
              value="Cancel"
              class="btn btn-type pull-right btn-cancel"
              (click)="clearFormData(gatewayForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="gateway.GatewayId"
              class="btn btn-type btn-save"
              [disabled]="
                !gatewayForm.form.valid || !gateway.Venue.VenueId || isReqComplete || !canAccess('GATEWAY_EDIT', 'view')
              "
              (click)="updateGateway(gatewayForm)"
            />
            <input
              type="button"
              value="Save"
              *ngIf="!gateway.GatewayId"
              class="btn btn-type btn-save"
              [disabled]="!gatewayForm.form.valid || !gateway.Venue.VenueId || isReqComplete"
              (click)="addGateway(gatewayForm)"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
