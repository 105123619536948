import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { FeatureConfiguration } from '../../../serviceprovider/serviceprovider.module';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { LocationElementModule, LocationModule } from '../../../staffscheduling/location/location.module';
import { User } from '../../../usermanagement/user/user.model';
import { InspectionStatus } from '../../../workordermanagement/inspectionlog/inspectionlog.module';
import { QCMaster } from '../../../workordermanagement/qcworkorder/qcworkorder.module';
import { WorkorderDetailsModule } from '../../../workordermanagement/workorder/workorderdetails.module';
import { FilterInputs } from '../../inspectorlocationassignment/inspectorlocationassignment.module';
import { InspectionDetailModule, InspectionElementModule } from '../inspection.module';

@Injectable({
  providedIn: 'root',
})
export class InspectionDetailService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetAll = (body: FilterInputs, page: number, size: number): Observable<InspectionDetailModule[]> => {
    const _url: string = this.configuration.Server + 'inspection/inspections/inspector/' + page + '/' + size;
    return this.http.post<InspectionDetailModule[]>(_url, body);
  };

  public GetElement = (body: LocationModule, page: number, size: number): Observable<LocationElementModule[]> => {
    const _url: string = this.configuration.Server + 'inspection/location/elements/' + page + '/' + size;
    return this.http.post<LocationElementModule[]>(_url, body);
  };

  public GetStatus = (body: any): Observable<InspectionStatus[]> => {
    const _url: string = this.configuration.Server + 'inspection/status';
    return this.http.post<InspectionStatus[]>(_url, body);
  };

  public GetAllSupervisorsWorkersInspectors = (body: string): Observable<User[]> => {
    const _url: string = this.configuration.Server + 'inspection/assignee/users';
    return this.http.post<User[]>(_url, body);
  };

  public ViewInspection = (inspection): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'inspection/transaction';
    return this.http.post<ResponseMessage>(_url, inspection);
  };

  public StartInspection = (inspection): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'inspection/transaction';
    return this.http.post<ResponseMessage>(_url, inspection);
  };

  public GetLastCleaningAlert = (body: LocationModule): Observable<WorkorderDetailsModule> => {
    const _url: string = this.configuration.Server + 'workorder/last/cleaning/alert/location';
    return this.http.post<WorkorderDetailsModule>(_url, body);
  };

  public GetLastQualityCheck = (body: LocationModule): Observable<QCMaster> => {
    const _url: string = this.configuration.Server + 'workorder/last/qc/location/details';
    return this.http.post<QCMaster>(_url, body);
  };

  public CompleteInspection = (body: InspectionElementModule): Observable<InspectionElementModule> => {
    const _url: string = this.configuration.Server + 'inspection/inspection/element';
    return this.http.post<InspectionElementModule>(_url, body);
  };

  public AddElementAttachments(filedata: any[], ElementId: any, InspectionId: any): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };

      const _url: string = this.configuration.Server + 'inspection/element/attachment';

      xhr.open('POST', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      xhr.setRequestHeader('ElementId', ElementId);
      xhr.setRequestHeader('InspectionId', InspectionId);

      const formData = new FormData();
      for (let i = 0; i < filedata.length; i++) {
        let attachmentName = 'attachment_' + filedata[i].rowId + '_' + InspectionId + '_' + ElementId;
        if (filedata[i].file != null) {
          formData.append(attachmentName, filedata[i].file, filedata[i].file.name);
        }

        if (filedata[i].comment !== '') {
          formData.append(attachmentName, filedata[i].comment);
        }
      }
      xhr.send(formData);
    });
  }

  public InspectionAttachmentAvailable = (body: InspectionElementModule): Observable<InspectionElementModule> => {
    const _url: string = this.configuration.Server + 'inspection/attachment/available';
    return this.http.put<InspectionElementModule>(_url, body);
  };

  public InspectionAttachmentUpload = (body: InspectionElementModule): Observable<InspectionElementModule> => {
    const _url: string = this.configuration.Server + 'inspection/attachment/uploaded';
    return this.http.put<InspectionElementModule>(_url, body);
  };

  public GetAllFilterLocations = (body: FilterInputs, page: number, size: number): Observable<LocationModule[]> => {
    const _url: string = this.configuration.Server + 'venue/venue/filter/locations/' + page + '/' + size;
    return this.http.post<LocationModule[]>(_url, body);
  };

  public locationFeatureConfigurations = (body: any): Observable<FeatureConfiguration[]> => {
    const _url: string = this.configuration.Server + 'venue/location/feature/configurations';
    return this.http.post<FeatureConfiguration[]>(_url, body);
  };
}
