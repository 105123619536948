import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthState } from '../store/auth.state';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authState: AuthState) {}

  public getModulesData() {
    return this.authState.getModulesData();
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authState.canActivate(_route, state);
  }

  public canAccess(feature: string, _access: string) {
    return this.authState.canAccess(feature, _access);
  }

  public logout = () => {
    return this.authState.logout();
  };
}
