import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthState } from 'src/app/store/auth.state';
import { Configuration } from '../../../app.constants';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { BeaconModule } from '../../../staffscheduling/beacon/beacon.module';
import { LocationModule } from '../../../staffscheduling/location/location.module';
import { ZoneModule } from '../../../staffscheduling/zone/zone.module';
import { User } from '../../../usermanagement/user/user.model';
import { BuildingModule } from '../../../venuemanagement/building/building.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { InspectionBeacon, InspectionModule } from '../../inspectionlog/inspectionlog.module';
import { QCBeacon, QCMaster } from '../../qcworkorder/qcworkorder.module';
import {
  WoStatus,
  WorkorderAndQCLogAndHistoryDetails,
  WorkorderCategoryModule,
  WorkorderModule,
  WorkorderPriority,
  WorkorderSeverity,
  WorkorderStatus,
  WorkorderTransaction,
} from '../workorder.module';
import {
  CleaningAlertDetailsBadge,
  WorkorderDetailsBeaconModule,
  WorkorderDetailsInventoryModule,
  WorkorderDetailsModule,
  WorkorderDetailsStatusModule,
  WorkorderDetailsTaskCommentModule,
  WorkorderQCImagesModule,
} from '../workorderdetails.module';
import { WorkorderTaskCommentModule } from '../workordertaskcomment.module';
import { WorkorderService } from './workorder.http.service';

@Component({
  selector: 'workorder.component',
  templateUrl: 'workorder.component.html',
  styleUrls: ['./workorder.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [WorkorderService, UtilitiesService],
})
export class WorkorderComponent implements OnInit {
  currentUser: User;

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  searchTerm: string;
  isReadOnlyField = false;
  assignedToDisabled = true;
  isWorkorderDetails = false;
  isTaskComment = false;
  isQCTaskComment = false;
  isQCFeedback = false;

  showReadOnlyCompletionDate = false;
  showCompletionDate = false;
  workorderGetStatus: string;
  CompletionDate: any;

  isHistoryAvailable = true;
  wo_Status = true;
  woAttachmentURL = '';
  qcAttachmentURL = '';
  noImg = 'assets/no-image.jpg';
  isQCLogShow = true;
  today: any;
  qcAttachmentImg = '';
  reAssignErrorMsg: string;
  reAssignModal = false;
  // isStatusInprogress = false;
  showVenueTagTask = false;
  showVenueTagInventory = false;
  showVenueTagBeacon = false;
  showVenueTagElements = false;
  showVenuePreCleaningFeedback = false;
  showHistoryButton = false;
  showInspectionHistoryButton = false;
  isQC_CreationShow = false;
  isBadgeEnable = false;
  isCAAttachmentUploadAllow = false;

  retriveModules: any[] = [];
  isStatusComplete = false;
  isStatus = false;

  public workorders: WorkorderModule[] = [];
  public workorder: WorkorderModule = new WorkorderModule();
  public workorderStatus: WorkorderStatus[] = [];

  assignedToUsers: User[];
  workorderAndQCLogAndHistoryDetails: WorkorderAndQCLogAndHistoryDetails = new WorkorderAndQCLogAndHistoryDetails();
  qcDetails: QCMaster = new QCMaster();
  workorderDetails: WorkorderDetailsModule = new WorkorderDetailsModule();
  workorderDetailsBeacons: WorkorderDetailsBeaconModule[] = [];
  workorderDetailsBadges: CleaningAlertDetailsBadge[] = [];
  workorderDetailsTasks: WorkorderDetailsTaskCommentModule = new WorkorderDetailsTaskCommentModule();
  workorderDetailsInventories: WorkorderDetailsInventoryModule[];
  workorderDetailsStatus: WorkorderDetailsStatusModule[];
  lastBeaconDetails: WorkorderDetailsBeaconModule;
  workorderQCImages: WorkorderQCImagesModule[];
  qcDetailsBeacons: QCBeacon[] = [];
  lastQCBeaconDetails: QCBeacon;
  inspectionDetailsBeacons: InspectionBeacon[] = [];
  lastinspectionBeaconDetails: InspectionBeacon;
  public workorderHistoryDetails: WorkorderAndQCLogAndHistoryDetails[];

  public columnName: string;
  public sortOrder: string;
  public columnSortOrder = 'ASC';
  public selectedColumnName: string;
  public cleaningAlertRejectQCModal = false;
  public pageState = [{ pageName: 'workorderForm', page: undefined, size: undefined }];
  isAssignedTo = false;
  InspectAttachment = '';
  public inspectionHistory = false;
  public visibleIndex = -1;
  OverallComment = 'No comment available.';
  InspectionImg = '';
  public totalCleanTime: any;
  caAttachmentURL = '';
  CreatorsAttachmentImg = '';
  storedStatus = '';

  constructor(
    public authState: AuthState,
    private utilitiesService: UtilitiesService,
    private WOService: WorkorderService,
    private loaderService: LoaderService,
    private configuration: Configuration,
  ) {
    this.workorder.WorkorderTransaction = new WorkorderTransaction();
    this.workorder.WorkorderStatus = new WorkorderStatus();
    this.currentUser = this.authState.getCurrentUser();
    this.workorderAndQCLogAndHistoryDetails.QCDetails = new QCMaster();
  }

  ngOnInit() {
    this.InspectAttachment = this.configuration.Server_Inspection_Attachment_Folder;
    this.woAttachmentURL = this.configuration.Server_Closure_Workorder_Attachment;
    this.qcAttachmentURL = this.configuration.Server_Task_Image_Folder;
    this.caAttachmentURL = this.configuration.Server_Cleaning_Alert_Attachment;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
    const date = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().slice(0, -1);
    this.today = date.split(':')[0] + ':' + date.split(':')[1];
    this.getModuleFeatureConfiguration();
  }

  public canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.getAllWorkorders(this.searchString, this.page, this.size);
    } else {
      this.getAllWorkorders(this.searchString, this.page, this.size);
    }
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllWorkorders(this.searchString, this.page, this.size);
  }

  /// this funcation used to get result as per page size change
  public WorkOrderPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllWorkorders(this.searchString, this.page, this.size);
  }

  public clearFormData(WOForm: NgForm) {
    this.workorderStatus = [];
    this.workorder = new WorkorderModule();
    this.workorder.Inspection = new InspectionModule();
    this.workorder.Building = new BuildingModule();
    this.workorder.Building.Venue = new VenueModule();
    this.workorder.WorkorderStatus = new WorkorderStatus();
    this.workorder.WorkorderTransaction = new WorkorderTransaction();
    this.workorder.WorkorderTaskComment = new WorkorderTaskCommentModule();
    this.workorder.WorkorderPriority = new WorkorderPriority();
    this.workorder.WorkorderSeverity = new WorkorderSeverity();
    this.workorder.AssignedTo = new User();
    this.getAllWorkorders('', this.page, this.size);
    this.searchString = '';
    WOForm.reset();
    this.isWorkorderDetails = false;
    this.workorderHistoryDetails = [];
    this.wo_Status = true;
    this.isQCLogShow = true;
    this.showCompletionDate = false;
    this.showReadOnlyCompletionDate = false;
    this.isBadgeEnable = false;

    this.visibleIndex = -1;
    this.inspectionHistory = false;

    this.workorderDetails = new WorkorderDetailsModule();
    this.isStatusComplete = false;
    this.storedStatus = '';
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllWorkorders('', this.page, this.size);
  }

  public workordersSearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllWorkorders(searchString, 1, this.size);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.workorders = null;
    this.count = 0;
  }

  public hasData(): boolean {
    return this.workorders != null && this.workorders.length > 0;
  }

  // Get list of workorders
  public getAllWorkorders(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? this.utilitiesService.removeInnerSpaces(searchTerm) : '',
      GenericSearch: 0,
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
      user: { UserId: this.currentUser.UserId },
      building: { venue: { venueId: this.configuration.userVenueId } },
    };

    this.WOService.GetAll(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.workorders = data;
        if (this.workorders.length < 0 && this.workorders[0].Count / 10 < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'WOService GetAllWorkorders Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.workorders[0] ? this.workorders[0].Count : 0),
    );
  }

  // Get work order details by work order id
  public getWorkorderById(WorkorderId: number) {
    if (
      this.canAccess('CLEANING_ALERT_VIEW_MY_RECORDS', 'view') ||
      this.canAccess('CLEANING_ALERT_VIEW_ALL_RECORDS', 'view') ||
      this.canAccess('CLEANING_ALERT_VIEW_MY_LOCATIONS', 'view')
    ) {
      const CPworkorder = new WorkorderModule();
      CPworkorder.WorkorderCPId = this.authState.AESEncryptText(WorkorderId);
      this.loaderService.display(true);

      this.WOService.GetSingle(CPworkorder).subscribe(
        (data) => {
          this.workorder = data;
          this.getModuleFeatureConfiguration();

          this.storedStatus = this.workorder.WorkorderStatus.Status;
          if (this.authState.getStoredBadge() == 1) {
            this.isBadgeEnable = true;
          } else {
            if (
              this.workorder.WorkorderStatus.Status === 'Complete' ||
              this.workorder.WorkorderStatus.Status === 'Cancel' ||
              this.workorder.WorkorderStatus.Status === 'QC Rejected' ||
              this.workorder.WorkorderStatus.Status === 'Awaiting QC' ||
              this.workorder.WorkorderStatus.Status === 'QC In Progress' ||
              this.workorder.WorkorderStatus.Status === 'QC Assigned' ||
              this.workorder.WorkorderStatus.Status === 'QC Approved' ||
              this.workorder.WorkorderStatus.Status === 'Closed – Not Complete'
            ) {
              this.isBadgeEnable = true;
              this.isStatusComplete = true;
            }
          }

          // if (this.workorder.WorkorderStatus.Status == 'Inprogress' || this.workorder.WorkorderStatus.Status == 'Close') {
          //     this.isStatusInprogress = true;
          // } else {
          //     this.isStatusInprogress = false;
          // }

          if (
            this.workorder.WorkorderStatus.Status === 'Complete' ||
            this.workorder.WorkorderStatus.Status === 'Cancel' ||
            this.workorder.WorkorderStatus.Status === 'Awaiting QC' ||
            this.workorder.WorkorderStatus.Status === 'QC Approved' ||
            this.workorder.WorkorderStatus.Status === 'QC Rejected' ||
            this.workorder.WorkorderStatus.Status === 'QC In Progress' ||
            this.workorder.WorkorderStatus.Status === 'Open' ||
            this.workorder.WorkorderStatus.Status === 'QC Assigned' ||
            this.workorder.WorkorderStatus.Status === 'Closed – Not Complete' ||
            this.authState.getStoredBadge() === 1
          ) {
            this.isStatusComplete = true;
            this.assignedToDisabled = true;
          } else {
            this.isStatusComplete = false;
            this.assignedToDisabled = false;
          }

          if (this.workorder.ParentWorkorderId != null) {
            this.wo_Status = false;
          }

          this.inspectionDetailsBeacons = [];
          if (this.workorder.Inspection != null && this.workorder.Inspection.InspectionBeacons != null) {
            this.inspectionDetailsBeacons = this.getInspectionBeaconValues(this.workorder.Inspection.InspectionBeacons);
            // console.log(this.qcDetailsBeacons);
          }

          if (this.workorder.Inspection != null && this.workorder.Inspection.OverallRating != null) {
            this.workorder.Inspection.OverallRating = this.workorder.Inspection.OverallRating.toLowerCase();
          }

          this.isReadOnlyField = true;
          this.workorderGetStatus = this.workorder.WorkorderStatus.Status;

          // if (this.workorder.scheduleStartDate) {
          //     this.workorder.scheduleStartDate = this.workorder.scheduleStartDate.split('T')[0];
          // }

          if (this.workorder.WorkorderStatus.Status === 'Complete') {
            this.showReadOnlyCompletionDate = true;
          } else {
            this.showReadOnlyCompletionDate = false;
          }

          // if (this.workorder.location.zone.ZoneId != null &&
          //     this.workorder.workorderStatus.Status != 'Complete') {
          //    this.getAssignedToUsers(this.workorder.location.zone.ZoneId, this.workorder.workorderId);
          // }

          this.loaderService.display(false);
          if (this.workorder.Location.Zone.ZoneId != null) {
            this.getAssignedToUsers(this.workorder.Location.Zone.ZoneId, this.workorder.WorkorderId);
          }

          if (this.workorder.WorkorderStatus.StatusId) {
            this.getWorkorderStatus(this.workorder.WorkorderStatus.StatusId);
          }

          if (
            this.workorder.Building &&
            this.workorder.Building.Venue &&
            this.workorder.WorkorderStatus.Status !== 'Complete'
          ) {
            this.workorder.ModifiedOn = this.utilitiesService.LocaltoVenueTime(this.workorder.Building.Venue.Timediff);
            // this.getVenueDate(this.workorder.building.venue.timediff);
          }
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'WOService GetWorkorbyderById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Cleaning Alert', 'You do not have permission to view cleaning alert details.');
    }
  }

  // bind list of all staff users by zone id
  public getAssignedToUsers(zoneId: number, workorderId: number) {
    const CPworkorder = new WorkorderModule();
    CPworkorder.Location = new LocationModule();
    CPworkorder.Location.Zone = new ZoneModule();
    CPworkorder.WorkorderCPId = this.authState.AESEncryptText(workorderId);
    CPworkorder.Location.Zone.ZoneCPId = this.authState.AESEncryptText(zoneId);
    this.loaderService.display(true);

    this.WOService.GetAllAssignedToByZone(CPworkorder).subscribe(
      (data) => {
        this.assignedToUsers = data;

        if (this.assignedToUsers.length > 0) {
          this.assignedToUsers.forEach(function (value) {
            value.FirstName = value.FirstName + ' ' + value.LastName;
          });
        }
        // this.assignedToDisabled = false;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'WOService GetAssignedToUsers Call Failed.Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  // Update Workorder Assignee
  public updateWorkorderAssignee(workorderForm: NgForm) {
    this.loaderService.display(true);

    if (workorderForm.status == 'INVALID' && workorderForm.disabled == false) {
      this.loaderService.display(false);
      this.utilitiesService.smmodal(
        'Cleaning Alert',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.workorder.AddedBy = this.currentUser.UserId;
    this.workorder.WorkorderId = this.workorder.WorkorderId;

    const _assignedTo: User = new User();
    _assignedTo.UserId = this.workorder.AssignedTo.UserId;
    this.workorder.AssignedTo = _assignedTo;

    this.WOService.UpdateWorkorderAssignee(this.utilitiesService.stripScript(this.workorder)).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Cleaning Alert', data.Message);
        this.clearFormData(workorderForm);
      },
      (error) => {
        console.log(
          'WOService UpdateWorkorderAssignee Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  // Update Workorder
  public checkIsQCReject(workorderForm: NgForm, status: any) {
    const rejectStatus = this.workorderStatus.filter((s) => s.StatusId == WoStatus.QC_Rejected);
    if (this.workorder.WorkorderStatus.StatusId == rejectStatus[0].StatusId) {
      // open modal popup
      this.opencleaningAlertRejectQCModal();
    } else {
      this.updateWorkorder(workorderForm, status);
    }
  }

  public updateWorkorder(workorderForm: NgForm, status: any) {
    this.loaderService.display(true);

    if (workorderForm.status === 'INVALID' && workorderForm.disabled === false) {
      this.loaderService.display(false);
      this.utilitiesService.smmodal(
        'Cleaning Alert',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    if (this.workorder.WorkorderStatus.StatusId == null) {
      this.utilitiesService.smmodal('Cleaning Alert', 'Please select Status.');
      this.loaderService.display(false);
      return;
    }

    if (
      (this.workorder.WorkorderStatus.Status === 'Inprogress' ||
        this.workorder.WorkorderStatus.Status === 'Assigned') &&
      (this.workorder.AssignedTo.UserId == null || this.workorder.AssignedTo.UserId <= 0)
    ) {
      this.utilitiesService.smmodal('Cleaning Alert', 'Please select Assigned To.');
      this.loaderService.display(false);
      return;
    }

    const statusValue = this.workorderStatus.filter((s) => s.StatusId == this.workorder.WorkorderStatus.StatusId);
    if (statusValue[0].Status === 'Complete') {
      if (this.workorder.ModifiedOn === null || this.workorder.ModifiedOn === '') {
        this.utilitiesService.smmodal('Cleaning Alert', 'Please select Completion date.');
        this.loaderService.display(false);
        return;
      }
    }

    this.workorder.WorkorderId = this.workorder.WorkorderId;
    this.workorder.ModifiedBy = this.currentUser.UserId;

    const _assignedTo: User = new User();
    _assignedTo.UserId = this.workorder.AssignedTo.UserId;
    this.workorder.AssignedTo = _assignedTo;

    const _woTransaction: WorkorderTransaction = new WorkorderTransaction();
    _woTransaction.Comments = this.workorder.WorkorderComment;
    this.workorder.WorkorderTransaction = _woTransaction;

    const _priority: WorkorderPriority = new WorkorderPriority();
    _priority.PriorityId = this.workorder.WorkorderPriority.PriorityId;
    this.workorder.WorkorderPriority = _priority;

    const _severity: WorkorderSeverity = new WorkorderSeverity();
    _severity.SeverityId = this.workorder.WorkorderSeverity.SeverityId;
    this.workorder.WorkorderSeverity = _severity;

    const _status: WorkorderStatus = new WorkorderStatus();
    _status.StatusId = this.workorder.WorkorderStatus.StatusId;
    this.workorder.WorkorderStatus = _status;

    this.workorder.Longitude = this.workorder.Longitude;
    this.workorder.Latitude = this.workorder.Latitude;
    this.workorder.RequestLocation = this.workorder.RequestLocation;
    this.workorder.WorkorderTypeId = this.workorder.WorkorderTypeId;
    this.workorder.AssetName = this.workorder.AssetName;
    this.workorder.AssetId = this.workorder.AssetId;
    this.workorder.LocationId = this.workorder.LocationId;
    this.workorder.ReportedById = this.workorder.ReportedById;
    this.workorder.ReportedByEmail = this.workorder.ReportedByEmail;
    this.workorder.ReportedByContactNo = this.workorder.ReportedByContactNo;
    this.workorder.Title = this.workorder.Title;
    this.workorder.Level = this.workorder.Level;

    if (statusValue[0].Status === 'Complete') {
      if (this.workorder.ModifiedOn) {
        this.today = this.utilitiesService.LocaltoVenueTime(this.workorder.Building.Venue.Timediff);
        const compDate = this.utilitiesService.ConvertDateTimeTZFormat(this.workorder.ModifiedOn);
        if (compDate) {
          if (compDate < this.utilitiesService.ConvertDateTimeTZFormat(this.workorder.ScheduleStartDate)) {
            this.utilitiesService.smmodal(
              'Cleaning Alert Log',
              'Completion date should be greater than scheduled start date.',
            );
            this.loaderService.display(false);
            return;
          } else if (compDate > this.today) {
            this.utilitiesService.smmodal(
              'Cleaning Alert Log',
              'Completion date should not be greater than venue current date and time.',
            );
            this.loaderService.display(false);
            return;
          } else {
            this.workorder.ModifiedOn = this.CompletionDate ? this.workorder.ModifiedOn : null;
          }
        }
      }
    }

    const _woCategory: WorkorderCategoryModule = new WorkorderCategoryModule();
    _woCategory.WorkorderCategoryId = this.workorder.WorkorderCategory.WorkorderCategoryId;
    this.workorder.WorkorderCategory = _woCategory;

    this.workorder.ScheduleStartDate = this.workorder.ScheduleStartDate;
    this.workorder.PinId = this.workorder.PinId;
    this.workorder.Description = this.workorder.Description;

    const _building: BuildingModule = new BuildingModule();
    _building.Venue = new VenueModule();
    _building.Venue.VenueId = this.workorder.Building.Venue.VenueId;
    _building.BuildingId = this.workorder.Building.BuildingId;
    this.workorder.Building = _building;

    /// below null set because we not update any record of inventory and task when we update work order details
    this.workorder.workorderTasks = null;
    this.workorder.WorkorderInventory = null;
    this.workorder.WorkorderTaskComment = null;

    if (this.reAssignErrorMsg) {
      this.workorder.IsReassign = 1;
    }

    if (status === 'yes') {
      this.workorder.GenerateNewWorkOrder = 1;
    } else if (status === 'no') {
      this.workorder.GenerateNewWorkOrder = 0;
    }

    this.WOService.UpdateWorkorder(this.utilitiesService.stripScript(this.workorder)).subscribe(
      (_data) => {
        this.loaderService.display(false);
        this.closeReassignModal();
        this.getAllWorkorders(this.searchString, this.page, this.size);
        this.closecleaningAlertRejectQCModal();
        this.utilitiesService.smmodal('Cleaning Alert', _data.Message);

        if (workorderForm != undefined) {
          this.clearFormData(workorderForm);
          workorderForm.reset();
        } else {
          this.getAllWorkorders(this.searchString, this.page, this.size);
          this.workorder = new WorkorderModule();
          this.getAllWorkorders(this.searchString, this.page, this.size);
          this.searchString = '';
          this.isWorkorderDetails = false;
          this.showCompletionDate = false;
          this.showReadOnlyCompletionDate = false;
          this.reAssignErrorMsg = '';
          this.workorder.IsReassign = 0;
        }
      },
      (error) => {
        this.loaderService.display(false);
        if (error.status == 406) {
          const msg = error.error;
          this.reAssignErrorMsg = msg.exception;
          this.reAssignModal = true;
        } else {
          console.log(
            'WOService UpdateWorkorder Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        }
      },
    );
  }

  public closeReassignModal() {
    this.reAssignErrorMsg = '';
    this.workorder.IsReassign = 0;
    this.reAssignModal = false;
  }

  // get work order details
  public getWorkorderDetails(workorderId: number) {
    this.loaderService.display(true);

    const CPworkorder = new WorkorderModule();
    CPworkorder.WorkorderCPId = this.authState.AESEncryptText(workorderId);

    this.WOService.GetWorkorderDetails(CPworkorder).subscribe(
      (data) => {
        this.inspectionHistory = false;
        this.workorderAndQCLogAndHistoryDetails = new WorkorderAndQCLogAndHistoryDetails();
        this.workorderAndQCLogAndHistoryDetails = data;
        this.workorderDetails = new WorkorderDetailsModule();
        this.qcDetails = new QCMaster();

        this.workorderDetails = this.workorderAndQCLogAndHistoryDetails.WorkorderDetails;
        this.qcDetails = this.workorderAndQCLogAndHistoryDetails.QCDetails;

        this.workorderQCImages = [];
        if (this.qcDetails != null) {
          if (this.qcDetails.QCImages != null) {
            this.workorderQCImages = this.qcDetails.QCImages;
          }
        } else {
          this.qcDetails = new QCMaster();
        }

        if (this.workorderDetails.Status.length > 0) {
          for (let i = 0; i < this.workorderDetails.Status.length; i++) {
            if (
              this.workorderDetails.Status[i].StatusId == WoStatus.QC_Rejected ||
              this.workorderDetails.Status[i].StatusId == WoStatus.QC_Approved
            ) {
              this.isQCLogShow = false;
            }
          }
        }

        this.workorderDetailsStatus = [];
        this.workorderDetailsBeacons = [];
        this.workorderDetailsBadges = [];
        this.workorderDetailsTasks = new WorkorderDetailsTaskCommentModule();
        this.workorderDetailsTasks.Comment = '';
        this.workorderDetailsInventories = [];

        this.loaderService.display(false);
        if (this.workorderDetails != null) {
          this.isWorkorderDetails = true;
          this.inspectionHistory = false;
          this.workorderDetailsStatus = this.workorderDetails.Status;
          this.workorderDetailsBeacons = this.getBeaconValues(this.workorderDetails.Beacons);
          this.workorderDetailsBadges = this.getBadgeValues(this.workorderDetails.Badges);

          this.workorderDetailsTasks = new WorkorderDetailsTaskCommentModule();
          if (this.workorderDetails.Task != null) {
            this.workorderDetailsTasks = this.workorderDetails.Task;
          }

          this.workorderDetailsInventories = [];
          if (this.workorderDetails.Inventory != null) {
            this.workorderDetailsInventories = this.workorderDetails.Inventory;
          }
          // this.workorderQCImages = this.workorderDetails.workorderQCImages;
          this.isTaskComment = false;
          if (this.workorderDetailsTasks != null) {
            if (this.workorderDetailsTasks.Comment != null && this.workorderDetailsTasks.Comment != '') {
              this.isTaskComment = true;
            }
          }
        }
        this.isQCTaskComment = false;
        if (this.qcDetails != null) {
          if (this.qcDetails.QCTaskComment != null) {
            if (this.qcDetails.QCTaskComment.QcComment != null && this.qcDetails.QCTaskComment.QcComment != '') {
              this.isQCTaskComment = true;
            }
          }

          if (this.qcDetails.PreQCFeedback != null && this.qcDetails.PreQCFeedback != '') {
            this.isQCFeedback = true;
          }

          this.qcDetailsBeacons = [];
          if (this.qcDetails.QCBeacons != null) {
            this.qcDetailsBeacons = this.getQCBeaconValues(this.qcDetails.QCBeacons);
            // console.log(this.qcDetailsBeacons);
          }
        }
      },
      (error) => {
        console.log(
          'WOService GetWorkorderDetails Call Failed.Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public hasWorkorderBeacons(): boolean {
    return this.workorderDetailsBeacons != null && this.workorderDetailsBeacons.length > 0;
  }

  public hasQCBeacons(): boolean {
    return this.qcDetailsBeacons != null && this.qcDetailsBeacons.length > 0;
  }

  public hasInspectionBeacons(): boolean {
    return this.inspectionDetailsBeacons != null && this.inspectionDetailsBeacons.length > 0;
  }

  public hasWorkorderBadges(): boolean {
    return this.workorderDetailsBadges != null && this.workorderDetailsBadges.length > 0;
  }

  public hasWorkorderTasks(): boolean {
    return this.workorderDetailsTasks.TaskDetails != null && this.workorderDetailsTasks.TaskDetails.length > 0;
  }

  public hasQcTaskFeedback(): boolean {
    return this.qcDetails.QCTaskFeedbacks != null && this.qcDetails.QCTaskFeedbacks.length > 0;
  }

  public hasQcInventoryRefills(): boolean {
    return this.qcDetails.QCInventoryRefills != null && this.qcDetails.QCInventoryRefills.length > 0;
  }

  public hasWorkorderInventories(): boolean {
    return this.workorderDetailsInventories != null && this.workorderDetailsInventories.length > 0;
  }

  public hasworkorderQCImages(): boolean {
    return this.workorderQCImages != null && this.workorderQCImages.length > 0;
  }

  public hasCreatorsAttatchment() {
    return (
      this.workorderDetails.WorkorderCreatorAttachments != null &&
      this.workorderDetails.WorkorderCreatorAttachments.length > 0 &&
      this.workorderDetails.WorkorderCreatorAttachments[0].AttachmentName != null
    );
  }

  // public hasCATaskFeedback(): boolean {
  //     return (this.workorder.WorkorderAndQCLogAndHistoryDetails. != null && this.workorder.workorderTasks > 0);
  // }

  // public hasCAInventoryRefills(): boolean {
  //     return (this.workorderQCImages != null && this.workorderQCImages.length > 0);
  // }

  // bind list of all work order status
  public getWorkorderStatus(statusId: number) {
    const CPworkorderstatus = new WorkorderStatus();
    CPworkorderstatus.StatusCPId = this.authState.AESEncryptText(statusId);
    CPworkorderstatus.CPtype = this.authState.AESEncryptText('C');

    this.WOService.GetWorkorderStatus(CPworkorderstatus).subscribe(
      (data) => {
        this.workorderStatus = data;

        if (
          this.workorderStatus != null &&
          this.workorderStatus.length > 0 &&
          this.workorder.WorkorderId != null &&
          this.workorder.WorkorderStatus.Status === 'Awaiting QC'
        ) {
          let index = -1;
          for (let w = 0; w < this.workorderStatus.length; w++) {
            index = w;
            if (this.workorderStatus[w].Status === 'Complete' || this.workorderStatus[w].Status === 'Cancel') {
              this.workorderStatus.splice(index, 1);
              w = w - 1;
            }
          }
        }
      },
      (error) => {
        console.log(
          'WOService GetAssignedToUsers Call Failed.Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getBeaconValues(beaconHistory: any[]) {
    const nameArrays = Object.create(null);

    let beaconname: any;
    let firstintime: any;
    let lastouttime: any;
    let lastArrayName: any;
    let batteryLevel: any;
    let timeElapse = '';
    // let lastEntryObject: any = {};
    const that = this;
    const newBeaconHistoryArray: WorkorderDetailsBeaconModule[] = [];
    if (beaconHistory != null) {
      if (beaconHistory.length > 0) {
        beaconHistory.forEach(function (item, idx, array) {
          let nameArray = nameArrays[item.Name];
          if (nameArray != lastArrayName && lastArrayName != null) {
            timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

            const lastEntryObject = that.createNewObjectProperties(
              beaconname,
              firstintime,
              lastouttime,
              batteryLevel,
              timeElapse,
            );
            lastArrayName.push(lastEntryObject);

            newBeaconHistoryArray.push(lastEntryObject);
          }

          if (!nameArray) {
            nameArray = nameArrays[item.Name] = [];
            firstintime = item.Optin;
          }

          nameArray.push(item);
          newBeaconHistoryArray.push(item);

          if (item.Optout != null && item.Optout != 'null') {
            lastouttime = item.Optout;
          }

          if (array.length - 1 == 0) {
            lastArrayName = nameArray;
            beaconname = item.Name;
            batteryLevel = item.BatteryLevel;
          }

          if (idx == array.length - 1) {
            timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

            const lastEntry = that.createNewObjectProperties(
              beaconname,
              firstintime,
              lastouttime,
              batteryLevel,
              timeElapse,
            );

            lastArrayName.push(lastEntry);

            newBeaconHistoryArray.push(lastEntry);
          }
          lastArrayName = nameArray;
          beaconname = item.Name;
          batteryLevel = item.BatteryLevel;
        });
      }
    }
    return newBeaconHistoryArray;
  }

  public getQCBeaconValues(beaconHistory: any[]) {
    const nameArrays = Object.create(null);

    let beaconname: any;
    let firstintime: any;
    let lastouttime: any;
    let lastArrayName: any;
    let batteryLevel: any;
    let timeElapse = '';
    // let lastEntryObject: any = {};
    const that = this;
    const newBeaconHistoryArray: QCBeacon[] = [];
    if (beaconHistory != null) {
      if (beaconHistory.length > 0) {
        beaconHistory.forEach(function (item, idx, array) {
          let nameArray = nameArrays[item.Beacon.BeaconName];
          if (nameArray != lastArrayName && lastArrayName != null) {
            timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

            const lastEntryObject = that.createNewQCObjectProperties(
              beaconname,
              firstintime,
              lastouttime,
              batteryLevel,
              timeElapse,
            );
            lastArrayName.push(lastEntryObject);

            newBeaconHistoryArray.push(lastEntryObject);
          }

          if (!nameArray) {
            nameArray = nameArrays[item.Beacon.BeaconName] = [];
            firstintime = item.OptIn;
          }

          nameArray.push(item);
          newBeaconHistoryArray.push(item);

          if (item.OptOut != null && item.OptOut != 'null') {
            lastouttime = item.OptOut;
          }

          if (array.length - 1 == 0) {
            lastArrayName = nameArray;
            beaconname = item.Beacon.BeaconName;
            batteryLevel = item.BatteryLevel;
          }

          if (idx == array.length - 1) {
            timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

            const lastEntry = that.createNewQCObjectProperties(
              beaconname,
              firstintime,
              lastouttime,
              batteryLevel,
              timeElapse,
            );

            lastArrayName.push(lastEntry);

            newBeaconHistoryArray.push(lastEntry);
          }
          lastArrayName = nameArray;
          beaconname = item.Beacon.BeaconName;
          batteryLevel = item.BatteryLevel;
        });
      }
    }
    return newBeaconHistoryArray;
  }

  public getInspectionBeaconValues(beaconHistory: any[]) {
    const nameArrays = Object.create(null);

    let beaconname: any;
    let firstintime: any;
    let lastouttime: any;
    let lastArrayName: any;
    let batteryLevel: any;
    let timeElapse = '';
    // let lastEntryObject: any = {};
    const that = this;
    const newBeaconHistoryArray: InspectionBeacon[] = [];
    if (beaconHistory != null) {
      if (beaconHistory.length > 0) {
        beaconHistory.forEach(function (item, idx, array) {
          let nameArray = nameArrays[item.Beacon.BeaconName];
          if (nameArray != lastArrayName && lastArrayName != null) {
            timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

            const lastEntryObject = that.createNewInspectionObjectProperties(
              beaconname,
              firstintime,
              lastouttime,
              batteryLevel,
              timeElapse,
            );
            lastArrayName.push(lastEntryObject);

            newBeaconHistoryArray.push(lastEntryObject);
          }

          if (!nameArray) {
            nameArray = nameArrays[item.Beacon.BeaconName] = [];
            firstintime = item.OptIn;
          }

          nameArray.push(item);
          newBeaconHistoryArray.push(item);

          if (item.OptOut != null && item.OptOut != 'null') {
            lastouttime = item.OptOut;
          }

          if (array.length - 1 == 0) {
            lastArrayName = nameArray;
            beaconname = item.Beacon.BeaconName;
            batteryLevel = item.BatteryLevel;
          }

          if (idx == array.length - 1) {
            timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

            const lastEntry = that.createNewInspectionObjectProperties(
              beaconname,
              firstintime,
              lastouttime,
              batteryLevel,
              timeElapse,
            );

            lastArrayName.push(lastEntry);

            newBeaconHistoryArray.push(lastEntry);
          }
          lastArrayName = nameArray;
          beaconname = item.Beacon.BeaconName;
          batteryLevel = item.BatteryLevel;
        });
      }
    }
    return newBeaconHistoryArray;
  }

  // public getBadgeValues(badgeHistory: any[]) {
  //    const nameArrays = Object.create(null);

  //    let beaconname: any;
  //    let firstintime: any;
  //    let lastouttime: any;
  //    let lastArrayName: any;
  //    let batteryLevel: any;
  //    let timeElapse = '';
  //    // let lastEntryObject: any = {};
  //    const that = this;
  //    const newBeaconHistoryArray: WorkorderDetailsBeaconModule[] = [];
  //    if (badgeHistory != null) {
  //        if (badgeHistory.length > 0) {
  //            badgeHistory.forEach(function (item, idx, array) {
  //                let nameArray = nameArrays[item.Name];
  //                if (nameArray != lastArrayName && lastArrayName != null) {

  //                    timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

  //                    const lastEntryObject =
  //                        that.createNewObjectProperties(
  //                            beaconname, firstintime, lastouttime, batteryLevel, timeElapse);
  //                    lastArrayName.push(lastEntryObject);

  //                    newBeaconHistoryArray.push(lastEntryObject);
  //                }

  //                if (!nameArray) {
  //                    nameArray = nameArrays[item.Name] = [];
  //                    firstintime = item.Optin;
  //                }

  //                nameArray.push(item);
  //                newBeaconHistoryArray.push(item);

  //                if (item.Optout != null && item.Optout != 'null') {
  //                    lastouttime = item.Optout;
  //                }

  //                if (array.length - 1 == 0) {
  //                    lastArrayName = nameArray;
  //                    beaconname = item.Name;
  //                    batteryLevel = item.BatteryLevel;
  //                }

  //                if (idx == array.length - 1) {
  //                    timeElapse = that.utilitiesService.getTimeDiff(firstintime, lastouttime);

  //                    const lastEntry =
  //                        that.createNewObjectProperties(
  //                            beaconname, firstintime, lastouttime, batteryLevel, timeElapse);

  //                    lastArrayName.push(lastEntry);

  //                    newBeaconHistoryArray.push(lastEntry);
  //                }
  //                lastArrayName = nameArray;
  //                beaconname = item.Name;
  //                batteryLevel = item.BatteryLevel;
  //            });
  //        }
  //    }
  //    return newBeaconHistoryArray;
  // }

  public getBadgeValues(badgeHistory: any[]) {
    const newBadgeHistoryArray: WorkorderDetailsBeaconModule[] = [];
    this.totalCleanTime = null;

    if (badgeHistory != null && badgeHistory.length > 0) {
      let totalSeconds = null;
      badgeHistory.forEach(function (item) {
        item.BatteryLevel = null;
        let inDate, outDate;

        if (item.Optin != null && item.Optout != null) {
          if (item.Optin.includes('.') == true) {
            item.Optin = item.Optin.split('.')[0];
          }

          if (item.Optout.includes('.') == true) {
            item.Optout = item.Optout.split('.')[0];
          }

          inDate = new Date(item.Optin);
          outDate = new Date(item.Optout);

          item.BatteryLevel = (outDate.getTime() - inDate.getTime()) / 1000;
          totalSeconds = totalSeconds + item.BatteryLevel;
        }

        newBadgeHistoryArray.push(item);
      });
      this.totalCleanTime = new Date(1000 * totalSeconds).toISOString().substr(11, 8);
    }
    return newBadgeHistoryArray;
  }

  public createNewObjectProperties(name: any, optin: any, optout: any, batteryLevel: any, timeElapsed: any) {
    this.lastBeaconDetails = new WorkorderDetailsBeaconModule();
    this.lastBeaconDetails.Name = name;
    this.lastBeaconDetails.Optin = optin;
    this.lastBeaconDetails.Optout = optout;
    this.lastBeaconDetails.BatteryLevel = batteryLevel;
    this.lastBeaconDetails.TimeElapsed = timeElapsed;
    const entry = this.lastBeaconDetails;
    return entry;
  }

  public createNewQCObjectProperties(name: any, optin: any, optout: any, batteryLevel: any, timeElapsed: any) {
    this.lastQCBeaconDetails = new QCBeacon();
    this.lastQCBeaconDetails.Beacon = new BeaconModule();
    this.lastQCBeaconDetails.Beacon.BeaconName = name;
    this.lastQCBeaconDetails.OptIn = optin;
    this.lastQCBeaconDetails.OptOut = optout;
    this.lastQCBeaconDetails.BatteryLevel = batteryLevel;
    this.lastQCBeaconDetails.timeElapsed = timeElapsed;
    const entry = this.lastQCBeaconDetails;
    return entry;
  }

  public createNewInspectionObjectProperties(name: any, optin: any, optout: any, batteryLevel: any, timeElapsed: any) {
    this.lastinspectionBeaconDetails = new InspectionBeacon();
    this.lastinspectionBeaconDetails.Beacon = new BeaconModule();
    this.lastinspectionBeaconDetails.Beacon.BeaconName = name;
    this.lastinspectionBeaconDetails.OptIn = optin;
    this.lastinspectionBeaconDetails.OptOut = optout;
    this.lastinspectionBeaconDetails.BatteryLevel = batteryLevel;
    this.lastinspectionBeaconDetails.timeElapsed = timeElapsed;
    const entry = this.lastinspectionBeaconDetails;
    return entry;
  }

  public statusChanged($event: any) {
    this.workorder.WorkorderStatus.StatusId = $event;
    this.showReadOnlyCompletionDate = false;
    // if (venueId) {
    //     let obj = this.venues.filter(u => u.VenueId == venueId);
    //     this.configuration.Venuename = obj[0].VenueName;
    //     this.configuration.VenueDetails = JSON.stringify(obj[0]);
    // }

    if (this.workorder.WorkorderStatus.StatusId != null) {
      const obj = this.workorderStatus.filter((s) => s.StatusId == this.workorder.WorkorderStatus.StatusId);
      this.workorder.WorkorderStatus.Status = obj[0].Status;

      if (this.workorder.WorkorderStatus.Status === 'Open' || this.workorder.WorkorderStatus.Status === 'Declined') {
        this.workorder.AssignedTo.UserId = null;
        this.workorder.AssignedTo.FirstName = null;
        this.workorder.AssignedTo.LastName = null;
        this.assignedToDisabled = true;
        this.assignedToUsers = [];
      } else {
        this.assignedToDisabled = false;
        this.getAssignedToUsers(this.workorder.Location.Zone.ZoneId, this.workorder.WorkorderId);
      }

      if (this.workorder.WorkorderStatus.Status === 'Complete') {
        if (this.workorderGetStatus == 'Complete') {
          this.showReadOnlyCompletionDate = true;
          this.showCompletionDate = false;
          this.workorder.ModifiedOn =
            this.workorder.ModifiedOn.split(':')[0] + ':' + this.workorder.ModifiedOn.split(':')[1];
          this.CompletionDate = this.workorder.ModifiedOn;
        } else {
          this.CompletionDate = this.utilitiesService.LocaltoVenueTime(this.workorder.Building.Venue.Timediff);
          this.showCompletionDate = true;
        }
      } else {
        this.showCompletionDate = false;
        this.CompletionDate = null;
      }

      // if (this.workorder.WorkorderStatus.Status == 'Inprogress' || this.workorder.WorkorderStatus.Status == 'Close') {
      //     this.isStatusInprogress = true;
      // } else {
      //     this.isStatusInprogress = false;
      // }

      if (
        // this.workorder.WorkorderStatus.Status == 'Complete' ||
        this.workorder.WorkorderStatus.Status === 'Declined' ||
        // this.workorder.WorkorderStatus.Status == 'QC Rejected' ||
        // this.workorder.WorkorderStatus.Status == 'QC Approved' ||
        this.workorder.WorkorderStatus.Status === 'Open' ||
        this.authState.getStoredBadge() === 1
      ) {
        this.isStatusComplete = true;
      } else {
        this.isStatusComplete = false;
      }

      if (this.storedStatus === 'Assigned' && this.workorder.WorkorderStatus.Status === 'Declined') {
        this.isStatusComplete = false;
      }
    }
  }

  refreshCall() {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.authState.storePageState(this.pageState);
    this.getAllWorkorders(this.searchString, this.page, this.size);
  }

  public getWorkorderHistory(workorderId: number) {
    this.loaderService.display(true);
    const CPworkorder = new WorkorderModule();
    CPworkorder.WorkorderCPId = this.authState.AESEncryptText(workorderId);
    this.WOService.GetWorkorderHistory(CPworkorder).subscribe(
      (data) => {
        this.workorderHistoryDetails = data;
        // console.log(this.workorderHistoryDetails);
        // this.woHistoryModal.open();
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'WOService GetWorkorderHistory Call Failed.Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public openQCAttachment(imageName: string) {
    this.qcAttachmentImg = '';
    this.qcAttachmentImg = imageName;
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllWorkorders(this.searchString, this.page, this.size);
  }

  opencleaningAlertRejectQCModal() {
    this.cleaningAlertRejectQCModal = true;
  }

  closecleaningAlertRejectQCModal() {
    this.cleaningAlertRejectQCModal = false;
  }

  public getVenueDate(timeDiff: any) {
    if (timeDiff) {
      // create Date object for current location
      const d = new Date();

      // convert to msec
      // add local time zone offset
      // get UTC time in msec
      const utc = d.getTime() + d.getTimezoneOffset() * 60000;

      // using supplied offset
      // let nd = new Date(utc + (3600000 * timeDiff));

      const nd = new Date(new Date(utc + 3600000 * timeDiff).toString().split('GMT')[0] + ' UTC')
        .toISOString()
        .slice(0, -1);
      this.workorder.ModifiedOn = nd;
      // return nd;
    }
  }

  public getModuleFeatureConfiguration() {
    this.isAssignedTo = this.utilitiesService.checkVenueFeatureConfiguration('CA_ASSIGNMENT_REASSIGN_MANUAL_FROM_WEB');
    this.isStatus = this.utilitiesService.checkVenueFeatureConfiguration('CA_COMPLETION_CRITERIA_MANUAL_FROM_WEB');

    this.showVenueTagTask = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_TASK');
    this.showVenueTagInventory = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_INVENTORIES');
    this.showVenueTagBeacon = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_BEACON');
    this.showVenueTagElements = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_ELEMENTS');
    this.showVenuePreCleaningFeedback =
      this.utilitiesService.checkVenueFeatureConfiguration('VENUE_PRE_CLEAN_FEEDBACK');

    this.showHistoryButton = this.utilitiesService.checkVenueFeatureConfiguration(
      'CA_COMPLETION_CRITERIA_SHOW_HISTORY_ON_CA',
    );
    this.showInspectionHistoryButton = this.utilitiesService.checkVenueFeatureConfiguration(
      'INS_COMPLETION_CRITERIA_SHOW_HISTORY_ON_CA',
    );
    this.isQC_CreationShow = this.utilitiesService.checkVenueFeatureConfiguration(
      'QC_COMPLETION_CRITERIA_MANUAL_FROM_WEB',
    );
    this.isCAAttachmentUploadAllow = this.utilitiesService.checkVenueFeatureConfiguration('ALLOW_ATTACHMENTS_FROM_WEB');
  }

  public getInspectionDetails() {
    this.inspectionHistory = true;
    this.isWorkorderDetails = false;
  }

  public showSubItem(ind) {
    if (this.visibleIndex === ind) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = ind;
    }
  }

  public downloadCTImage(AttachmentName: string) {
    window.open(AttachmentName, '_blank');
  }

  public downloadWOImage(AttachmentName: string) {
    window.open(AttachmentName, '_blank');
  }

  public openInspectionLogImage(imageName: string) {
    this.InspectionImg = '';
    this.InspectionImg = imageName;
  }

  public downloadCreatorsVideo(AttachmentName: string) {
    window.open(AttachmentName, '_blank');
  }

  public openCreatorsPhoto(imageName: string) {
    this.CreatorsAttachmentImg = '';
    this.CreatorsAttachmentImg = imageName;
  }
}
