<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-4 col-md-6 col-sm-12 form-heading svgRestroom">
        <svg
          enable-background="new 0 0 79.707 79.707"
          version="1.1"
          viewBox="0 0 79.707 79.707"
          xml:space="preserve"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m67.239 79.707-8.41-28.273c-0.207-7.525-4.795-13.963-11.309-16.849v-26.918c0-4.228-3.439-7.667-7.667-7.667-4.227 0-7.666 3.439-7.666 7.667v26.918c-6.513 2.886-11.099 9.323-11.306 16.844l-8.413 28.275h54.771v3e-3zm-12.81-6-3.431-12.806c-0.287-1.065-1.377-1.704-2.449-1.413-1.067 0.284-1.7 1.381-1.414 2.448l3.152 11.771h-8.436v-12.29c0-1.104-0.896-2-2-2s-2 0.896-2 2v12.287h-7.435l3.153-11.771c0.286-1.065-0.347-2.164-1.414-2.449-1.068-0.289-2.164 0.349-2.449 1.414l-3.431 12.806h-5.765l5.579-18.75h0.012 27.5 0.015l5.575 18.75h-4.763l1e-3 3e-3zm-16.242-66.04c0-0.919 0.748-1.667 1.666-1.667 0.919 0 1.667 0.748 1.667 1.667v25.373c-0.55-0.048-1.104-0.084-1.666-0.084s-1.117 0.036-1.667 0.084v-25.373zm1.667 31.289c6.135 0 11.275 4.276 12.637 10h-25.274c1.36-5.725 6.503-10 12.637-10z"
          />
        </svg>
        <span class="list-heading" *ngIf="workorders && !workorder.WorkorderId">CLEANING ALERT LOGS</span>
        <span class="list-heading" *ngIf="workorder.WorkorderId">EDIT CLEANING ALERT</span>
      </div>

      <div class="col-lg-8 col-md-12">
        <div
          id="page-size"
          class="col-lg-1 col-sm-2 col-md-2 col-xs-12 pull-right list-heading pageSize"
          *ngIf="workorders"
        >
          <ngx-atlas-select
            (model)="WorkOrderPageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-7 col-md-10 col-sm-10 col-xs-12 input-group pull-right p-search" *ngIf="workorders">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="workordersSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="workorders">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="listerror">Sorry no data found!</h3>
        </div>

        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('WorkorderId', columnSortOrder)">
                  Cleaning Alert #
                  <span *ngIf="selectedColumnName == 'WorkorderId' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'WorkorderId' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('Title', columnSortOrder)">
                  Name
                  <span *ngIf="selectedColumnName == 'Title' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'Title' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('Status', columnSortOrder)">
                  Status
                  <span *ngIf="selectedColumnName == 'Status' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'Status' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('ScheduleStartDate', columnSortOrder)">
                  Planned Start Date
                  <span *ngIf="selectedColumnName == 'ScheduleStartDate' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'ScheduleStartDate' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header" (click)="sortColumn('FirstName', columnSortOrder)">
                  Assignee
                  <span *ngIf="selectedColumnName == 'FirstName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'FirstName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
              </tr>
            </thead>
            <tbody
              *ngFor="
                let wo of workorders
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getWorkorderById(wo.WorkorderId)">
                <td>{{ wo.WorkorderId }}</td>
                <td>{{ wo.Title }}</td>
                <td>{{ wo.WorkorderStatus.Status }}</td>
                <td>{{ wo.ScheduleStartDate | date: 'MM/dd/yyyy' }}</td>
                <td>{{ wo.AssignedTo.FirstName }} {{ wo.AssignedTo.LastName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="pageChanged($event)"
          *ngIf="hasData() && count > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!workorders">
    <form #workorderForm="ngForm" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div>
          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="WorkorderId"> Cleaning Alert #</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ workorder.WorkorderId }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="title"> Cleaning Alert Name</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ workorder.Title }}</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="col-md-6 col-xs-12 form-group">
            <div class="col-lg-6 col-md-6 col-sm-6 p1" *ngIf="isReadOnlyField">
              <label for="priority"> Priority</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ workorder.WorkorderPriority.PriorityLevel }}</p>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 s1" *ngIf="isReadOnlyField">
              <label for="severity"> Severity</label>
              <div>
                <div class="clipping-wrapper">
                  <p class="readOnly clipping">{{ workorder.WorkorderSeverity.SeverityLevel }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="status"><span id="red-circle">&#x25CF;</span> Status</label>
            <div>
              <ngx-atlas-select
                (model)="statusChanged($event)"
                [list]="workorderStatus"
                [idField]="'StatusId'"
                [textField]="'Status'"
                [placeholder]="'Select Status'"
                [allowClear]="true"
                [multiple]="false"
                [disabled]="isBadgeEnable"
                [selectedValue]="workorder.WorkorderStatus.StatusId"
              >
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div>
          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="description"> Description</label>
            <div>
              <textarea
                type="text"
                class="form-control"
                rows="3"
                maxlength="500"
                name="Description"
                placeholder="Description"
                [(ngModel)]="workorder.Description"
                readonly
              ></textarea>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group">
            <label for="comment">Comment</label>
            <div>
              <textarea
                type="text"
                class="form-control"
                rows="3"
                name="Comments"
                placeholder="Comment"
                [(ngModel)]="workorder.WorkorderComment"
                [readonly]="
                  workorder.WorkorderStatus.StatusId == 21 ||
                  workorder.WorkorderStatus.StatusId == 8 ||
                  workorder.WorkorderStatus.StatusId == 22 ||
                  workorder.WorkorderStatus.StatusId == 10 ||
                  workorder.WorkorderStatus.StatusId == 6 ||
                  workorder.WorkorderStatus.StatusId == 5 ||
                  workorder.WorkorderStatus.StatusId == 24 ||
                  workorder.WorkorderStatus.StatusId == 23
                "
              ></textarea>
            </div>
          </div>
        </div>

        <div>
          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField && isAssignedTo">
            <!--!isStatusInprogress-->
            <label for="assignedto"
              ><span
                id="red-circle"
                *ngIf="
                  workorder.WorkorderStatus.StatusId == 19 ||
                  workorder.WorkorderStatus.StatusId == 21 ||
                  workorder.WorkorderStatus.StatusId == 8 ||
                  workorder.WorkorderStatus.StatusId == 22 ||
                  workorder.WorkorderStatus.StatusId == 10 ||
                  workorder.WorkorderStatus.StatusId == 5 ||
                  workorder.WorkorderStatus.StatusId == 6 ||
                  workorder.WorkorderStatus.StatusId == 4
                "
                >&#x25CF;</span
              >
              Assigned To</label
            >
            <div>
              <ngx-atlas-select
                (model)="workorder.AssignedTo.UserId = $event"
                [list]="assignedToUsers"
                [idField]="'UserId'"
                [textField]="'FirstName'"
                [placeholder]="'Select Assigned To'"
                [allowClear]="true"
                [multiple]="false"
                [disabled]="assignedToDisabled"
                [selectedValue]="workorder.AssignedTo.UserId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField && !isAssignedTo">
            <!--!isStatusInprogress-->
            <label for="assignedto"> Assigned To</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">
                  {{ workorder.AssignedTo.FirstName }} {{ workorder.AssignedTo.LastName }}
                </p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="startdate"> Scheduled Start Date</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ workorder.ScheduleStartDate | date: 'MM/dd/yyyy, hh:mm:ss a' }}</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="venue"> Venue</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ workorder.Building.Venue.VenueName }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="building"> Building</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ workorder.Building.BuildingName }}</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="col-md-6 col-xs-12 form-group" *ngIf="isReadOnlyField">
            <label for="location"> Location</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ workorder.LocationName }}</p>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="showCompletionDate">
            <label for="completionDate"><span id="red-circle">&#x25CF;</span> Completion Date</label>
            <div>
              <input
                class="form-control unstyled"
                name="ModifiedOn"
                [owlDateTime]="dt1"
                [owlDateTimeTrigger]="dt1"
                placeholder="Completion Date"
                [(ngModel)]="workorder.ModifiedOn"
                #ScheduleStartDate="ngModel"
                required
              />
              <owl-date-time #dt1 [hour12Timer]="true" [pickerMode]="'dialog'"></owl-date-time>
            </div>
          </div>

          <div class="col-md-6 col-xs-12 form-group" *ngIf="showReadOnlyCompletionDate">
            <label for="completionDate"> Completion Date</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ workorder.ModifiedOn | date: 'MM/dd/yyyy, hh:mm:ss a' }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <input
            type="button"
            value="View Log"
            *ngIf="workorder.WorkorderId"
            class="btn btn-type btn-save"
            (click)="getWorkorderDetails(workorder.WorkorderId)"
          />
          <input
            type="button"
            value="Inspection History"
            *ngIf="workorder.InspectionId && showInspectionHistoryButton"
            class="btn btn-type btn-save"
            (click)="getInspectionDetails()"
          />
          <input
            type="button"
            value="History"
            *ngIf="workorder.WorkorderId && !wo_Status && showHistoryButton"
            class="btn btn-type btn-save"
            (click)="getWorkorderHistory(workorder.WorkorderId)"
            data-toggle="modal"
            data-target="#cleaningAlertModal"
          />
          <input
            type="button"
            value="Cancel"
            class="btn btn-type pull-right btn-cancel"
            (click)="clearFormData(workorderForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="workorder.WorkorderId"
            class="btn btn-type btn-save"
            [disabled]="
              !canAccess('CLEANING_ALERT_MODIFY', 'view') ||
              !workorderForm.form.valid ||
              !workorder.WorkorderStatus.StatusId ||
              isStatusComplete ||
              (!isStatus && workorder.WorkorderStatus.StatusId == 21) ||
              ((workorder.WorkorderStatus.StatusId == 19 ||
                workorder.WorkorderStatus.StatusId == 21 ||
                workorder.WorkorderStatus.StatusId == 8 ||
                workorder.WorkorderStatus.StatusId == 22 ||
                workorder.WorkorderStatus.StatusId == 10 ||
                workorder.WorkorderStatus.StatusId == 5 ||
                workorder.WorkorderStatus.StatusId == 6 ||
                workorder.WorkorderStatus.StatusId == 4) &&
                !workorder.AssignedTo.UserId) ||
              (!isQC_CreationShow && workorder.WorkorderStatus.StatusId == 6) ||
              workorder.WorkorderStatus.StatusId == 3 ||
              workorder.WorkorderStatus.StatusId == 5 ||
              workorder.WorkorderStatus.StatusId == 6 ||
              workorder.WorkorderStatus.StatusId == 8 ||
              workorder.WorkorderStatus.StatusId == 10 ||
              workorder.WorkorderStatus.StatusId == 22 ||
              workorder.WorkorderStatus.StatusId == 24
            "
            (click)="checkIsQCReject(workorderForm, 'no')"
          />
        </div>

        <div *ngIf="inspectionHistory && workorder.Inspection != null">
          <div class="col-md-12">
            <hr />
            <h3>Inspection History</h3>
            <div class="col-md-12 col-sm-12 col-lg-12">
              <div class="box-body table-responsive">
                <table class="table table-hover table-responsive">
                  <thead>
                    <tr>
                      <th>Inspect #</th>
                      <th>Venue Name</th>
                      <th>Location Name</th>
                      <th>Status</th>
                      <th *ngIf="workorder.Inspection.InspectionStatus.InsStatus != 'Open'">Inspector</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ workorder.Inspection.InspectionId }}</td>
                      <td>{{ workorder.Inspection.Venue.VenueName }}</td>
                      <td>{{ workorder.Inspection.Location.LocationName }}</td>
                      <td>{{ workorder.Inspection.InspectionStatus.InsStatus }}</td>
                      <td *ngIf="workorder.Inspection.InspectionStatus.InsStatus != 'Open'">
                        {{ workorder.Inspection.User.FirstName }} {{ workorder.Inspection.User.LastName }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr class="hr-margin" />
              <div class="row">
                <h3>Overall Comment</h3>
                <div
                  *ngIf="
                    workorder.Inspection != null &&
                    (workorder.Inspection.OverallComment == null || workorder.Inspection.OverallComment == '')
                  "
                  class="col-lg-12 col-md-12 col-sm-12"
                >
                  <h4 class="listerror">No overall comment available.</h4>
                </div>

                <div
                  class="col-lg-12 col-md-12 col-sm-12"
                  *ngIf="
                    workorder.Inspection != null &&
                    (workorder.Inspection.OverallComment != null || workorder.Inspection.OverallComment != '')
                  "
                >
                  <h4>
                    <span style="word-break: break-word">{{ workorder.Inspection.OverallComment }}</span>
                  </h4>
                </div>
              </div>

              <hr class="hr-margin" />
              <div class="row">
                <h3>Beacon Activity</h3>
                <div class="col-md-12 col-sm-12 col-lg-12" *ngIf="!hasInspectionBeacons()">
                  <h4 class="listerror">No beacon data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div class="col-md-12 col-sm-12 col-lg-12" id="workorderBeacons" *ngIf="hasInspectionBeacons()">
                  <div class="box-body table-responsive" style="height: auto; overflow-y: auto; max-height: 310px">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>First Time In Range</th>
                          <th>Last Time In Range</th>
                          <th>Battery Level</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let beacon of inspectionDetailsBeacons">
                          <tr *ngIf="beacon.timeElapsed == null">
                            <td style="cursor: auto">{{ beacon.Beacon.BeaconName }}</td>
                            <td style="cursor: auto" *ngIf="beacon.OptIn != null">
                              {{ beacon.OptIn | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                            </td>
                            <td style="cursor: auto" *ngIf="beacon.OptIn == null"></td>
                            <td style="cursor: auto" *ngIf="beacon.OptOut != null">
                              {{ beacon.OptOut | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                            </td>
                            <td style="cursor: auto" *ngIf="beacon.OptOut == null"></td>
                            <td style="cursor: auto" *ngIf="beacon.BatteryLevel != null">{{ beacon.BatteryLevel }}%</td>
                            <td style="cursor: auto" *ngIf="beacon.BatteryLevel == null">-</td>
                          </tr>
                          <tr *ngIf="beacon.timeElapsed != null">
                            <td class="text-right" style="cursor: auto" colspan="3">Time Elapsed</td>
                            <td style="cursor: auto">{{ beacon.timeElapsed }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <hr class="hr-margin" *ngIf="hasInspectionBeacons()" />

              <div class="row" *ngIf="workorder.Inspection != null && workorder.Inspection.InspectionElements != null">
                <h3>Elements & Attachments</h3>
                <div
                  class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive"
                  style="padding: 0 18px 0 18px; height: auto; overflow-y: auto; max-height: 310px"
                >
                  <table
                    class="table table-hover table-responsive"
                    style="border-collapse: inherit !important; background: white; margin-bottom: 6px"
                  >
                    <div class="table-inner">
                      <div class="col-lg-2 col-md-2 col-sm-2 font">Element #</div>
                      <div class="col-lg-4 col-md-4 col-sm-4 font">Element Name</div>
                      <div class="col-lg-3 col-md-3 col-sm-3 font">Score Factor</div>
                      <div class="col-lg-1 col-md-1 col-sm-1 font">Rating</div>
                      <div class="col-lg-2 col-md-2 col-sm-2 font">Attachment</div>
                    </div>
                    <div *ngFor="let element of workorder.Inspection.InspectionElements; let index = index">
                      <div class="col-lg-12 col-md-12 first table-innerbody">
                        <div class="col-lg-2 col-md-2 col-sm-2">{{ element.Element.ElementId }}</div>
                        <div class="col-lg-4 col-md-4 col-sm-4" style="word-break: break-all">
                          {{ element.Element.ElementName }}
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-3" style="word-break: break-all">
                          {{
                            element.InspectionScoreFactor &&
                            (element.InspectionScoreFactor.ScoreFactorName != null ||
                              element.InspectionScoreFactor.ScoreFactorName != '')
                              ? element.InspectionScoreFactor.ScoreFactorName
                              : ''
                          }}
                        </div>
                        <div class="col-lg-1 col-md-1 col-sm-1">{{ element.Element.Rating }}</div>
                        <div class="col-lg-2 col-md-2 col-sm-2">
                          <a *ngIf="element.InspectElementAttachments.length > 0" (click)="showSubItem(index)"
                            ><i class="fa fa-paperclip" aria-hidden="true" style="font-size: 20px; cursor: pointer"></i
                          ></a>
                        </div>
                      </div>

                      <div
                        class="col-lg-12 col-md-12"
                        *ngIf="
                          visibleIndex === index &&
                          element.InspectElementAttachments != null &&
                          element.InspectElementAttachments.length > 0
                        "
                        style="
                          height: auto;
                          border: 1px solid #a3b5b5 !important;
                          overflow-y: auto;
                          max-height: 200px;
                          width: 98%;
                          margin-left: 10px;
                          margin-top: 10px;
                        "
                      >
                        <div class="col-lg-12 col-md-12" *ngFor="let file of element.InspectElementAttachments">
                          <div class="col-lg-2 col-md-2 col-sm-2" id="download">
                            <img
                              *ngIf="
                                file.AttachmentType == 'PNG' ||
                                file.AttachmentType == 'png' ||
                                file.AttachmentType == 'jpg' ||
                                file.AttachmentType == 'JPG' ||
                                file.AttachmentType == 'jpeg' ||
                                file.AttachmentType == 'JPEG' ||
                                file.AttachmentType == 'gif' ||
                                file.AttachmentType == 'GIF' ||
                                file.AttachmentType == 'doc' ||
                                file.AttachmentType == 'DOC'
                              "
                              class="img-responsive cursor-pointer"
                              src="{{ file.AttachmentURL }}"
                              alt="{{ file.AttachmentName }}"
                              style="
                                height: 110px;
                                width: 110px !important;
                                border: 1px solid black;
                                margin: 5px 0px 5px 0;
                              "
                              data-toggle="modal"
                              data-target="#InspectionimageModal"
                              (click)="openInspectionLogImage(file.AttachmentURL)"
                            />
                            <a
                              data-toggle="tooltip"
                              data-html="true"
                              title="View"
                              *ngIf="
                                file.AttachmentType == 'MP4' ||
                                file.AttachmentType == 'mp4' ||
                                file.AttachmentType == 'MOV' ||
                                file.AttachmentType == 'mov' ||
                                file.AttachmentType == 'm4v' ||
                                file.AttachmentType == 'M4V'
                              "
                              (click)="downloadCTImage(file.AttachmentURL)"
                              class="fa fa-download"
                              style="
                                color: black;
                                cursor: pointer;
                                height: 110px;
                                width: 110px;
                                border: 1px solid black;
                                margin: 5px 0px 5px 0;
                              "
                            ></a>
                          </div>
                          <div class="col-lg-10 col-md-10 col-sm-10">
                            <textarea
                              *ngIf="file.Comment != null"
                              type="text"
                              class="form-control outer-border"
                              rows="4"
                              name="Comments"
                              style="margin-top: 7px; margin-bottom: 10px"
                              readonly
                              >{{ file.Comment }}</textarea
                            >
                            <textarea
                              *ngIf="file.Comment == null"
                              type="text"
                              class="form-control outer-border"
                              rows="4"
                              name="Comments"
                              style="margin-top: 7px; margin-bottom: 10px"
                              readonly
                              >{{ OverallComment }}</textarea
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="workorderDetails" *ngIf="isWorkorderDetails">
          <div class="col-lg-12 col-md-12 col-sm-12 form-group">
            <hr />
            <h3>Cleaning Alert Log Details</h3>
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h4>Status Activity</h4>
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="box-body table-responsive">
                  <table class="table table-hover table-responsive">
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Time</th>
                        <th>User</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let status of workorderDetailsStatus">
                        <td style="cursor: auto">{{ status.Status }}</td>
                        <td style="cursor: auto">{{ status.Time | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                        <td style="cursor: auto">{{ status.UserName }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <hr class="hr-margin" />
              </div>

              <div *ngIf="isCAAttachmentUploadAllow">
                <h4>Creator's Comment</h4>
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <h4
                    class="listerror"
                    *ngIf="workorderDetails.CreatorComments == null || workorderDetails.CreatorComments == ''"
                  >
                    {{ OverallComment }}
                  </h4>
                  <h4 *ngIf="workorderDetails.CreatorComments != null || workorderDetails.CreatorComments != ''">
                    <span style="word-break: break-word">{{ workorderDetails.CreatorComments }}</span>
                  </h4>
                  <hr class="hr-margin" />
                </div>
              </div>

              <div *ngIf="isCAAttachmentUploadAllow">
                <h4>Creator's Pictures and Videos</h4>
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!hasCreatorsAttatchment()">
                  <h4 class="listerror">No attachment available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="hasCreatorsAttatchment()">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="caGallery" *ngFor="let attachments of workorderDetails.WorkorderCreatorAttachments">
                      <img
                        *ngIf="
                          (attachments.AttachmentType != null && attachments.AttachmentType == 'PNG') ||
                          attachments.AttachmentType == 'png' ||
                          attachments.AttachmentType == 'jpg' ||
                          attachments.AttachmentType == 'JPG' ||
                          attachments.AttachmentType == 'jpeg' ||
                          attachments.AttachmentType == 'JPEG' ||
                          attachments.AttachmentType == 'gif' ||
                          attachments.AttachmentType == 'GIF' ||
                          attachments.AttachmentType == 'doc' ||
                          attachments.AttachmentType == 'DOC'
                        "
                        class="img-responsive cursor-pointer"
                        src="{{ attachments.AttachmentURL }}"
                        alt="{{ attachments.AttachmentName }}"
                        width="600"
                        height="400"
                        (click)="openCreatorsPhoto(attachments.AttachmentURL)"
                        data-toggle="modal"
                        data-target="#CreatorsAttachmentImg"
                      />
                      <a
                        data-toggle="tooltip"
                        data-html="true"
                        title="View"
                        *ngIf="
                          (attachments.AttachmentType != null && attachments.AttachmentType == 'MP4') ||
                          attachments.AttachmentType == 'mp4' ||
                          attachments.AttachmentType == 'MOV' ||
                          attachments.AttachmentType == 'mov' ||
                          attachments.AttachmentType == 'm4v' ||
                          attachments.AttachmentType == 'M4V'
                        "
                        (click)="downloadCreatorsVideo(attachments.AttachmentURL)"
                        class="fa fa-download"
                      ></a>
                    </div>
                  </div>
                  <hr class="hr-margin" />
                </div>
              </div>

              <div *ngIf="showVenuePreCleaningFeedback">
                <h4>Pre-Cleaning Feedback</h4>
                <div *ngIf="!workorderDetails.InitialSurvey" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No feedback available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="workorderDetails.InitialSurvey">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Feedback</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="cursor: auto">{{ workorderDetails.InitialSurvey }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div *ngIf="workorderDetails.IsBadgeEnable == 0 || showVenueTagBeacon">
                <h4>Beacon Activity</h4>
                <div *ngIf="!hasWorkorderBeacons()" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No beacon data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div id="workorderBeacons" *ngIf="hasWorkorderBeacons()">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>First Time In Range</th>
                          <th>Last Time In Range</th>
                          <th>Battery Level</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let beacon of workorderDetailsBeacons">
                          <tr *ngIf="beacon.TimeElapsed == null">
                            <td style="cursor: auto">{{ beacon.Name }}</td>
                            <td style="cursor: auto" *ngIf="beacon.Optin != null">
                              {{ beacon.Optin | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                            </td>
                            <td style="cursor: auto" *ngIf="beacon.Optin == null"></td>
                            <td style="cursor: auto" *ngIf="beacon.Optout != null">
                              {{ beacon.Optout | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                            </td>
                            <td style="cursor: auto" *ngIf="beacon.Optout == null"></td>
                            <td style="cursor: auto" *ngIf="beacon.BatteryLevel != null">{{ beacon.BatteryLevel }}%</td>
                            <td style="cursor: auto" *ngIf="beacon.BatteryLevel == null">-</td>
                          </tr>
                          <tr *ngIf="beacon.TimeElapsed != null">
                            <td class="text-right" style="cursor: auto" colspan="3">Time Elapsed</td>
                            <td style="cursor: auto">{{ beacon.TimeElapsed }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div *ngIf="workorderDetails.IsBadgeEnable == 1">
                <h4>Badge Activity</h4>
                <div *ngIf="!hasWorkorderBadges()" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No badge data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div id="workorderBeacons" *ngIf="hasWorkorderBadges()">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Badge Id</th>
                          <th>In Range</th>
                          <th>Out Range</th>
                          <th>Time Elapsed (Seconds)</th>
                        </tr>
                      </thead>

                      <tbody *ngFor="let badge of workorderDetailsBadges">
                        <tr>
                          <td>{{ badge.Name }}</td>
                          <td *ngIf="badge.Optin != null">{{ badge.Optin | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                          <td *ngIf="badge.Optin == null"></td>
                          <td *ngIf="badge.Optout != null">{{ badge.Optout | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
                          <td *ngIf="badge.Optout == null"></td>
                          <td *ngIf="badge.BatteryLevel != null">{{ badge.BatteryLevel }}</td>
                          <td *ngIf="badge.BatteryLevel == null">-</td>
                        </tr>
                      </tbody>

                      <tbody>
                        <tr>
                          <td colspan="3" style="text-align: right">Clean Time (HH:MM:SS)</td>
                          <td>{{ totalCleanTime }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div *ngIf="showVenueTagTask">
                <h4>Tasks</h4>
                <div *ngIf="!hasWorkorderTasks()" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No task data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div id="workorderTasks" *ngIf="hasWorkorderTasks()">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Task</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let task of workorderDetailsTasks.TaskDetails">
                          <tr>
                            <td style="cursor: auto">{{ task.ItemName }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div id="workorderTaskComment">
                <h4>Comment</h4>
                <div *ngIf="!isTaskComment" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No comment data.</h4>
                  <hr class="hr-margin" />
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isTaskComment">
                  <h4>
                    <span style="word-break: break-word">{{ workorderDetailsTasks.Comment }}</span>
                  </h4>
                  <hr class="hr-margin" />
                </div>
              </div>

              <div *ngIf="showVenueTagInventory">
                <h4>Inventory</h4>
                <div *ngIf="!hasWorkorderInventories()" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No inventory data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div id="workorderInventory" *ngIf="hasWorkorderInventories()">
                  <div class="box-body table-responsive">
                    <table class="table table-hover table-responsive">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let inventory of workorderDetailsInventories">
                          <td style="cursor: auto">{{ inventory.ItemName }}</td>
                          <td style="cursor: auto">{{ inventory.Quantity }}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr class="hr-margin" />
                  </div>
                </div>
              </div>

              <div *ngIf="!isQCLogShow" style="margin-top: -20px">
                <h3>Quality Check Details</h3>

                <div *ngIf="isQCTaskComment">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <h4 *ngIf="workorderDetailsTasks != null">
                      Quality Check Comment:&nbsp;<span style="word-break: break-word">{{
                        qcDetails.QCTaskComment.QcComment
                      }}</span>
                    </h4>
                    <hr class="hr-margin" />
                  </div>
                </div>

                <div *ngIf="!isQCTaskComment" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Comment available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="isQCFeedback">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <h4 *ngIf="workorderDetailsTasks != null">
                      Quality Check Feedback:&nbsp;<span>{{ qcDetails.PreQCFeedback }}</span>
                    </h4>
                    <hr class="hr-margin" />
                  </div>
                </div>

                <div *ngIf="!isQCFeedback" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Feedback available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div>
                  <h4>Beacon Activity</h4>
                  <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!hasQCBeacons()">
                    <h4 class="listerror">No beacon data.</h4>
                    <hr class="hr-margin" />
                  </div>

                  <div id="workorderBeacons" *ngIf="hasQCBeacons()">
                    <div class="box-body table-responsive">
                      <table class="table table-hover table-responsive">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>First Time In Range</th>
                            <th>Last Time In Range</th>
                            <th>Battery Level</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container *ngFor="let beacon of qcDetailsBeacons">
                            <tr *ngIf="beacon.timeElapsed == null">
                              <td style="cursor: auto">{{ beacon.Beacon.BeaconName }}</td>
                              <td style="cursor: auto" *ngIf="beacon.OptIn != null">
                                {{ beacon.OptIn | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                              </td>
                              <td style="cursor: auto" *ngIf="beacon.OptIn == null"></td>
                              <td style="cursor: auto" *ngIf="beacon.OptOut != null">
                                {{ beacon.OptOut | date: 'MM/dd/yyyy, hh:mm:ss a' }}
                              </td>
                              <td style="cursor: auto" *ngIf="beacon.OptOut == null"></td>
                              <td style="cursor: auto" *ngIf="beacon.BatteryLevel != null">
                                {{ beacon.BatteryLevel }}%
                              </td>
                              <td style="cursor: auto" *ngIf="beacon.BatteryLevel == null">-</td>
                            </tr>
                            <tr *ngIf="beacon.timeElapsed != null">
                              <td class="text-right" style="cursor: auto" colspan="3">Time Elapsed</td>
                              <td style="cursor: auto">{{ beacon.timeElapsed }}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                      <hr class="hr-margin" />
                    </div>
                  </div>
                </div>

                <div *ngIf="showVenueTagTask && !hasQcTaskFeedback()" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Task data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="showVenueTagTask && hasQcTaskFeedback()">
                  <h4>Tasks</h4>
                  <div id="workorderTasks">
                    <div class="box-body table-responsive">
                      <table class="table table-hover table-responsive">
                        <thead>
                          <tr>
                            <th>Task</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let feedback of qcDetails.QCTaskFeedbacks">
                            <td style="cursor: auto">{{ feedback.TaskName }}</td>
                            <td style="cursor: auto">
                              <span *ngIf="feedback.QcInput == 'S'"><i class="fa fa-check-circle"></i></span>
                              <span *ngIf="feedback.QcInput == 'U'"><i class="fa fa-times-circle"></i></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <hr class="hr-margin" />
                    </div>
                  </div>
                </div>

                <div *ngIf="showVenueTagInventory && !hasQcInventoryRefills()" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Inventory data.</h4>
                  <hr class="hr-margin" />
                </div>

                <div *ngIf="showVenueTagInventory && hasQcInventoryRefills()">
                  <h4>Inventory</h4>
                  <div id="workorderTasks">
                    <div class="box-body table-responsive">
                      <table class="table table-hover table-responsive">
                        <thead>
                          <tr>
                            <th>Inventory</th>
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let refill of qcDetails.QCInventoryRefills">
                            <td style="cursor: auto">{{ refill.InventoryName }}</td>
                            <td style="cursor: auto">{{ refill.Quantity }}</td>
                          </tr>
                        </tbody>
                      </table>
                      <hr class="hr-margin" />
                    </div>
                  </div>
                </div>

                <h4 *ngIf="hasworkorderQCImages()">Quality Check Attachments</h4>
                <div *ngIf="!hasworkorderQCImages()" class="col-lg-12 col-md-12 col-sm-12">
                  <h4 class="listerror">No Quality Check Attachments available.</h4>
                  <hr class="hr-margin" />
                </div>

                <div class="row" *ngIf="workorderQCImages != null && workorderQCImages.length > 0">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="caGallery" *ngFor="let attachments of workorderQCImages">
                      <img
                        *ngIf="
                          (attachments.ImageType != null && attachments.ImageType == 'PNG') ||
                          attachments.ImageType == 'png' ||
                          attachments.ImageType == 'jpg' ||
                          attachments.ImageType == 'JPG' ||
                          attachments.ImageType == 'jpeg' ||
                          attachments.ImageType == 'JPEG' ||
                          attachments.ImageType == 'gif' ||
                          attachments.ImageType == 'GIF' ||
                          attachments.ImageType == 'doc' ||
                          attachments.ImageType == 'DOC'
                        "
                        class="img-responsive cursor-pointer"
                        src="{{ attachments.AttachmentURL }}"
                        alt="{{ attachments.ImageName }}"
                        width="600"
                        height="400"
                        (click)="openQCAttachment(attachments.AttachmentURL)"
                        data-toggle="modal"
                        data-target="#imageModal"
                      />
                      <a
                        data-toggle="tooltip"
                        data-html="true"
                        title="View"
                        *ngIf="
                          (attachments.ImageType != null && attachments.ImageType == 'MP4') ||
                          attachments.ImageType == 'mp4' ||
                          attachments.ImageType == 'MOV' ||
                          attachments.ImageType == 'mov' ||
                          attachments.ImageType == 'm4v' ||
                          attachments.ImageType == 'M4V'
                        "
                        (click)="downloadWOImage(attachments.AttachmentURL)"
                        class="fa fa-download"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal"
          id="cleaningAlertRejectQCModal"
          [ngStyle]="cleaningAlertRejectQCModal ? { display: 'block' } : { display: 'none' }"
        >
          <div class="modal-dialog modal-md">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" (click)="closecleaningAlertRejectQCModal()">
                  &times;
                </button>
                <h3 class="modal-title">Quality Control Check</h3>
              </div>
              <div class="modal-body">Are you sure you want to Accept/reject this cleaning alert Quality Check?</div>
              <div class="modal-footer">
                <input
                  type="button"
                  value="With Cleaning Alert"
                  class="btn btn-type btn-save"
                  [disabled]="isReqComplete"
                  (click)="updateWorkorder(workorderForm, 'yes')"
                />
                <input
                  type="button"
                  value="Without Cleaning Alert"
                  class="btn btn-type btn-cancel"
                  [disabled]="isReqComplete"
                  (click)="updateWorkorder(workorderForm, 'no')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" id="cleaningAlertModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Cleaning Alert History</h3>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>

        <div class="modal-body" style="text-align: left !important">
          <div class="row">
            <div
              class="col-lg-12 col-md-12 form-group"
              *ngFor="let workorderHistory of workorderHistoryDetails; let i = index"
            >
              <div class="panel-group" id="accordion">
                <div class="panel panel-default">
                  <div
                    class="panel-heading"
                    id="caHistoryheading{{ i }}"
                    style="height: auto; display: inline-flex; width: 100%"
                  >
                    <h4 class="panel-title">
                      <a
                        id="woHistory{{ workorderHistory.CleaningAlertDetails.Title }}"
                        class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        data-target="#CleaningAlertDetails{{ i }}"
                        href="#CleaningAlertDetails{{ i }}"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span style="font-weight: bold; float: left; word-break: break-word"
                          >Cleaning Alert # {{ workorderHistory.CleaningAlertDetails.WorkorderId }} -
                        </span>
                        <span style="float: left; padding-left: 5px; word-break: break-word">{{
                          workorderHistory.CleaningAlertDetails.Title
                        }}</span>
                        <span style="float: right; padding: 0 8px 0 8px; padding-left: 5px; word-break: break-word"
                          >Completion Date:
                          {{
                            workorderHistory.CleaningAlertDetails.WorkorderCompletionDate
                              | date: 'MM/dd/yyyy, hh:mm:ss a'
                          }}</span
                        >
                      </a>
                    </h4>
                  </div>

                  <div id="CleaningAlertDetails{{ i }}" class="panel-collapse collapse">
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="col-md-6 history-box">
                            <span class="history-header">Cleaning Alert #: </span>
                            <span class="history-text">{{ workorderHistory.CleaningAlertDetails.WorkorderId }}</span>
                          </div>

                          <div class="col-md-6 history-box">
                            <span class="history-header">Cleaning Alert Name: </span>
                            <span class="history-text">{{ workorderHistory.CleaningAlertDetails.Title }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="col-md-12 history-box">
                            <span class="history-header">CA Performed By: </span>
                            <span class="history-text"
                              >{{ workorderHistory.CleaningAlertDetails.AssignedTo.FirstName }}
                              {{ workorderHistory.CleaningAlertDetails.AssignedTo.LastName }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <hr style="margin: 5px !important; border-top: 1px solid #3d5c70" />

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails == null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail == null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment == null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.Comment == null
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Comment available.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.Comment != null
                        "
                      >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <h4>
                            CA Comment:&nbsp;<span style="word-break: break-word">{{
                              workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.Comment
                            }}</span>
                          </h4>
                          <hr class="hr-margin" />
                        </div>
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.InitialSurvey == null
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Feedback available.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div>
                        <div
                          class="col-lg-12 col-md-12 col-sm-12"
                          *ngIf="
                            workorderHistory.CleaningAlertDetails != null &&
                            workorderHistory.CleaningAlertDetails.InitialSurvey != null
                          "
                        >
                          <h4>
                            CA Feedback:&nbsp;<span>{{ workorderHistory.CleaningAlertDetails.InitialSurvey }}</span>
                          </h4>
                          <hr class="hr-margin" />
                        </div>
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.TaskDetails ==
                            null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.TaskDetails
                            .length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Task data.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.TaskDetails !=
                            null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.WorkorderTaskComment.TaskDetails
                            .length > 0
                        "
                      >
                        <h4>Tasks</h4>
                        <div id="workorderTasks">
                          <div class="box-body table-responsive">
                            <table class="table table-hover table-responsive">
                              <thead>
                                <tr>
                                  <th style="text-align: unset">Task Name</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let task of workorderHistory.CleaningAlertDetails.WorkorderDetail
                                      .WorkorderTaskComment.TaskDetails
                                  "
                                >
                                  <td>{{ task.Task.TaskName }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <hr class="hr-margin" style="margin-top: 10px !important" />
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="
                          (workorderHistory.CleaningAlertDetails != null &&
                            workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory == null) ||
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory.length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Inventory data.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.CleaningAlertDetails != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory != null &&
                          workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory.length > 0
                        "
                      >
                        <h4>Inventory</h4>
                        <div id="workorderTasks">
                          <div class="box-body table-responsive">
                            <table class="table table-hover">
                              <thead>
                                <tr>
                                  <th>Inventory Name</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let inventory of workorderHistory.CleaningAlertDetails.WorkorderDetail.Inventory
                                  "
                                >
                                  <td>{{ inventory.ItemName }}</td>
                                  <td>{{ inventory.Quantity }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <hr class="hr-margin" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="panel-group" id="accordion" style="margin-top: 15px">
                <div class="panel panel-default">
                  <div
                    class="panel-heading"
                    id="caHistoryheading{{ i }}"
                    style="height: auto; display: inline-flex; width: 100%"
                  >
                    <h4 class="panel-title">
                      <a
                        id="woHistory{{ workorderHistory.QCDetails.Title }}"
                        class="collapsed"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        data-target="#QCDetails{{ i }}"
                        href="#QCDetails{{ i }}"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <span style="font-weight: bold; float: left; word-break: break-word"
                          >Quality Check # {{ workorderHistory.QCDetails.QcId }} -
                        </span>
                        <span style="float: left; word-break: break-word">{{ workorderHistory.QCDetails.Title }}</span>
                        <span style="float: right; padding: 0 8px 0 8px; word-break: break-word"
                          >Completion Date:
                          {{ workorderHistory.QCDetails.QCCompletionDate | date: 'MM/dd/yyyy, hh:mm:ss a' }}</span
                        >
                      </a>
                    </h4>
                  </div>
                  <div id="QCDetails{{ i }}" class="panel-collapse collapse">
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="col-md-6 history-box">
                            <span class="history-header">Quality Check #: </span>
                            <span class="history-text">{{ workorderHistory.QCDetails.QcId }}</span>
                          </div>

                          <div class="col-md-6 history-box">
                            <span class="history-header">Quality Check Name: </span>
                            <span class="history-text">{{ workorderHistory.QCDetails.Title }}</span>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <div class="col-md-12 history-box">
                            <span class="history-header">Quality Check Performed By: </span>
                            <span class="history-text"
                              >{{ workorderHistory.QCDetails.QCAssignedTo.FirstName }}
                              {{ workorderHistory.QCDetails.QCAssignedTo.LastName }}</span
                            >
                          </div>
                        </div>
                      </div>

                      <div
                        *ngIf="
                          (workorderHistory.QCDetails != null && workorderHistory.QCDetails.QCTaskComment == null) ||
                          workorderHistory.QCDetails.QCTaskComment.length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Comment available.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="
                          workorderHistory.QCDetails != null &&
                          workorderHistory.QCDetails.QCTaskComment != null &&
                          workorderHistory.QCDetails.QCTaskComment.QcComment != null
                        "
                      >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <h4>
                            Quality Check Comment:&nbsp;<span style="word-break: break-word">{{
                              workorderHistory.QCDetails.QCTaskComment.QcComment
                            }}</span>
                          </h4>
                          <hr class="hr-margin" />
                        </div>
                      </div>

                      <div
                        *ngIf="
                          (workorderHistory.QCDetails != null && workorderHistory.QCDetails.PreQCFeedback == null) ||
                          workorderHistory.QCDetails.PreQCFeedback.length <= 0
                        "
                        class="col-lg-12 col-md-12 col-sm-12"
                      >
                        <h4 class="listerror">No Feedback available.</h4>
                        <hr class="hr-margin" />
                      </div>

                      <div
                        *ngIf="workorderHistory.QCDetails != null && workorderHistory.QCDetails.PreQCFeedback != null"
                      >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <h4>
                            Quality Check Feedback:&nbsp;<span>{{ workorderHistory.QCDetails.PreQCFeedback }}</span>
                          </h4>
                          <hr class="hr-margin" />
                        </div>
                      </div>

                      <div>
                        <div
                          *ngIf="
                            (workorderHistory.QCDetails != null &&
                              workorderHistory.QCDetails.QCTaskFeedbacks == null) ||
                            workorderHistory.QCDetails.QCTaskFeedbacks <= 0
                          "
                          class="col-lg-12 col-md-12 col-sm-12"
                        >
                          <h4 class="listerror">No Task data.</h4>
                          <hr class="hr-margin" />
                        </div>

                        <div
                          id="workorderTasks"
                          *ngIf="
                            workorderHistory.QCDetails != null &&
                            workorderHistory.QCDetails.QCTaskFeedbacks != null &&
                            workorderHistory.QCDetails.QCTaskFeedbacks.length > 0
                          "
                        >
                          <h4>Tasks</h4>
                          <div class="box-body table-responsive">
                            <table class="table table-hover table-responsive">
                              <thead>
                                <tr>
                                  <th>Task</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let feedback of workorderHistory.QCDetails.QCTaskFeedbacks">
                                  <td>{{ feedback.TaskName }}</td>
                                  <!--<td>{{feedback.QcInput}}</td>-->
                                  <td>
                                    <span *ngIf="feedback.QcInput == 'S'"><i class="fa fa-check-circle"></i></span>
                                    <span *ngIf="feedback.QcInput == 'U'"><i class="fa fa-times-circle"></i></span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <hr class="hr-margin" />
                          </div>
                        </div>
                      </div>

                      <div>
                        <div
                          *ngIf="
                            (workorderHistory.QCDetails != null &&
                              workorderHistory.QCDetails.QCInventoryRefills == null) ||
                            workorderHistory.QCDetails.QCInventoryRefills.length <= 0
                          "
                          class="col-lg-12 col-md-12 col-sm-12"
                        >
                          <h4 class="listerror">No Inventory data.</h4>
                          <hr class="hr-margin" />
                        </div>

                        <div
                          id="workorderTasks"
                          *ngIf="
                            workorderHistory.QCDetails != null &&
                            workorderHistory.QCDetails.QCInventoryRefills != null &&
                            workorderHistory.QCDetails.QCInventoryRefills.length > 0
                          "
                        >
                          <h4>Top-up Inventory</h4>
                          <div class="box-body table-responsive">
                            <table class="table table-hover table-responsive">
                              <thead>
                                <tr>
                                  <th>Inventory</th>
                                  <th>Quantity</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let refill of workorderHistory.QCDetails.QCInventoryRefills">
                                  <td>{{ refill.InventoryName }}</td>
                                  <td>{{ refill.Quantity }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <hr class="hr-margin" />
                          </div>
                        </div>
                      </div>

                      <div
                        class="row"
                        *ngIf="
                          workorderHistory != null &&
                          workorderHistory.QCDetails != null &&
                          workorderHistory.QCDetails.QCImages != null &&
                          workorderHistory.QCDetails.QCImages.length > 0
                        "
                      >
                        <span class="history-header" style="margin-left: 30px">Quality Check Attachments:</span>
                        <div class="col-lg-12 col-md-12 col-sm-12">
                          <div
                            class="col-lg-3 col-md-3 col-sm-3"
                            style="margin-top: 10px"
                            *ngFor="let attachments of workorderHistory.QCDetails.QCImages"
                          >
                            <div
                              style="border: 1px solid #d2d6de; padding: 4px"
                              *ngIf="
                                (attachments.ImageType != null && attachments.ImageType == 'PNG') ||
                                attachments.ImageType == 'png' ||
                                attachments.ImageType == 'jpg' ||
                                attachments.ImageType == 'JPG' ||
                                attachments.ImageType == 'jpeg' ||
                                attachments.ImageType == 'JPEG' ||
                                attachments.ImageType == 'gif' ||
                                attachments.ImageType == 'GIF' ||
                                attachments.ImageType == 'doc' ||
                                attachments.ImageType == 'DOC'
                              "
                            >
                              <img
                                class="img-responsive cursor-pointer"
                                src="{{ attachments.AttachmentURL }}"
                                alt="{{ attachments.ImageName }}"
                                style="height: 125px; width: 100%"
                                (click)="openQCAttachment(attachments.AttachmentURL)"
                                data-toggle="modal"
                                data-target="#imageModal"
                              />
                            </div>

                            <div
                              style="border: 1px solid #d2d6de; text-align: center; height: 135px"
                              *ngIf="
                                (attachments.ImageType != null && attachments.ImageType == 'MP4') ||
                                attachments.ImageType == 'mp4' ||
                                attachments.ImageType == 'MOV' ||
                                attachments.ImageType == 'mov' ||
                                attachments.ImageType == 'm4v' ||
                                attachments.ImageType == 'M4V'
                              "
                            >
                              <a
                                class="fa fa-download"
                                style="color: black; cursor: pointer; position: relative; top: calc(50% - 10px)"
                                data-toggle="tooltip"
                                data-html="true"
                                title="View"
                                (click)="downloadWOImage(attachments.AttachmentURL)"
                              ></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer"></div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="imageModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close close-button" data-dismiss="modal" style="color: black">&times;</button>
          <img class="img-responsive" src="{{ qcAttachmentImg }}" style="max-height: 424px; margin: auto" />
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal signup-content"
    id="reAssignModal"
    [ngClass]="{ show: reAssignModal }"
    [ngStyle]="reAssignModal ? { display: 'block' } : { display: 'none' }"
  >
    <form #workorderForm="ngForm">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Reassign Cleaning Alert</h3>
            <button type="button" class="close" (click)="closeReassignModal()" style="margin-top: -26px">
              &times;
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="box" style="box-shadow: none; border: none">
                  <div class="box-body">
                    <span>{{ reAssignErrorMsg }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
              <button
                class="btn btn-save btn-footersave"
                [disabled]="!workorderForm.form.valid"
                (click)="updateWorkorder(workorderForm, 'no')"
              >
                RE-ASSIGN
              </button>
              <button class="btn btn-type btn-cancel" (click)="closeReassignModal()">CANCEL</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div class="modal fade" id="InspectionimageModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close close-button" data-dismiss="modal" style="color: black; margin-top: -10px">
            &times;
          </button>
          <img class="img-responsive" src="{{ InspectionImg }}" style="max-height: 424px; margin: auto" />
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="CreatorsAttachmentImg" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body">
          <button type="button" class="close close-button" data-dismiss="modal" style="color: black">&times;</button>
          <img class="img-responsive" src="{{ CreatorsAttachmentImg }}" style="max-height: 424px; margin: auto" />
        </div>
      </div>
    </div>
  </div>
</section>
