import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { ZoneRoutes } from './zone.routes';
import { ZoneComponent } from './components/zone.component';
import { BuildingModule } from '../../venuemanagement/building/building.module';

@NgModule({
  imports: [CommonModule, ZoneRoutes, FormsModule, NgxPaginationModule, HomeModule],

  declarations: [ZoneComponent],
})
export class ZoneModule extends GenericEntity {
  public ZoneId: number;
  public ZoneCPId: string;
  public ZoneName: string;
  public Building: BuildingModule;
}

export class NearByZone extends GenericEntity {
  public relNearByZoneId: number;
  public zoneId: number;
  public zoneName: string;
  public nearByZoneId: number;
  public nearByZoneName: string;
}
