import { Component, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
  DownlineTreeviewItem,
  OrderDownlineTreeviewEventParser,
  TreeviewComponent,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewItem,
} from 'ngx-treeview';
import { AuthState } from 'src/app/store/auth.state';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { ServiceProviderModule } from '../../../serviceprovider/serviceprovider.module';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { ChildPermissions, ParentPermissions, UserRoleModule } from '../../role/role.module';
import { User } from '../../user/user.model';
import { UserRoleTemplateModule } from '../userroletemplate.module';
import { UserRoleTemplateService } from './userroletemplate.http.service';

@Injectable({
  providedIn: 'root',
})
export class config extends TreeviewConfig {
  hasAllCheckBox = false;
  hasFilter = true;
  hasCollapseExpand = false;
  maxHeight = 500;
}

@Component({
  selector: 'user-role-template-component',
  templateUrl: 'userroletemplate.component.html',
  styleUrls: ['./userroletemplate.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    UserRoleTemplateService,
    UtilitiesService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: config },
  ],
})
export class UserRoleTemplateComponent implements OnInit {
  currentUser: User;
  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;
  public items: TreeviewItem[];
  public config: {} = {};
  public isAllFeatureConfigurationChecked = false;
  public isCheckBoxVisible = false;

  public userRoleTemplates: UserRoleTemplateModule[] = [];
  public userRoleTemplate: UserRoleTemplateModule = new UserRoleTemplateModule();

  public serviceProviders: ServiceProviderModule[] = [];
  public corporations: CorporationModule[] = [];
  public venues: VenueModule[] = [];

  public page = 1;
  public size = 10;
  public count = 0;
  public pageSizes: any[] = [];
  public searchString = '';
  public isReqComplete = false;

  public listviewtypes: any[];
  public viewType: any = null;

  public columnName: string;
  public sortOrder: string;
  public columnSortOrder = 'ASC';
  public selectedColumnName: string;
  public pageState = [{ pageName: 'roleTemplateName', page: this.page, size: this.size, viewType: this.viewType }];
  public isSysAdmin = false;

  constructor(
    private userRoleTemplateService: UserRoleTemplateService,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.userRoleTemplate = new UserRoleTemplateModule();
    const permission: ParentPermissions[] = [];
    this.userRoleTemplate.ParentPermissionsList = permission;

    this.config = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      maxHeight: 10,
    };
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  public canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.viewType = this.pageState[0].viewType;
      this.getAllUserRoleTemplates(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllUserRoleTemplates(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.searchString = '';
    this.userRoleTemplates = null;
    this.count = 0;
    this.userRoleTemplate = new UserRoleTemplateModule();
    const permission: ParentPermissions[] = [];
    this.userRoleTemplate.ParentPermissionsList = permission;
    this.isCheckBoxVisible = false;
    this.GetAllServiceProviders(0);
    setTimeout(() => {
      const element = document.getElementById('RoleTemplateName');
      element.focus();
    }, 1000);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.userRoleTemplates = null;
    this.count = 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUserRoleTemplates(this.searchString, this.page, this.size, this.viewType);
  }

  public clearFormData(userRoleForm: NgForm) {
    this.userRoleTemplate = new UserRoleTemplateModule();
    const permission: ParentPermissions[] = [];
    this.userRoleTemplate.ParentPermissionsList = permission;
    this.searchString = '';
    this.isSysAdmin = false;
    this.isCheckBoxVisible = false;
    this.isAllFeatureConfigurationChecked = false;
    userRoleForm.reset();
    this.items = [];
    this.config = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      maxHeight: 10,
    };
    this.getAllUserRoleTemplates('', this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllUserRoleTemplates('', this.page, this.size, this.viewType);
  }

  public userRoleTemplateSearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUserRoleTemplates(searchString, 1, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUserRoleTemplates(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public RolePageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllUserRoleTemplates(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllUserRoleTemplates(this.searchString, this.page, this.size, this.viewType);
  }

  public addUserRoleTemplate(userRoleTemplateForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (userRoleTemplateForm.status === 'INVALID' && userRoleTemplateForm.disabled === false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'User Role Template',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.userRoleTemplate.RoleTemplateName = this.utilitiesService.removeInnerSpaces(
      this.userRoleTemplate.RoleTemplateName,
    );
    if (this.userRoleTemplate.RoleTemplateName === null || this.userRoleTemplate.RoleTemplateName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    let permissionPayload: ParentPermissions[] = [];
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let parentPermission = new ParentPermissions();
          parentPermission.ParentPermissionId = it.value;
          parentPermission.PAccess = 1;
          parentPermission.ChildPermissionsList = [];
          if (it.children != null) {
            it.children.forEach(function (chield) {
              if (chield.checked || chield.checked == undefined) {
                let childrenPermission = new ChildPermissions();
                childrenPermission.ChildPermissionId = chield.value;
                childrenPermission.PAccess = 1;

                parentPermission.ChildPermissionsList.push(childrenPermission);
              }
            });
          }
          permissionPayload.push(parentPermission);
        }
      });

      if (permissionPayload.length == 0) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal('User Role Template', 'Please select atleast one permission.');
        return;
      }
    }
    this.userRoleTemplate.ParentPermissionsList = permissionPayload;
    this.userRoleTemplate.AddedBy = this.currentUser.UserId;

    this.userRoleTemplateService.Add(this.utilitiesService.stripScript(this.userRoleTemplate)).subscribe(
      (data) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('User Role Template', data.Message);
        this.clearFormData(userRoleTemplateForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'userRoleTemplateService addUserRoleTemplate Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public updateUserRoleTemplate(userRoleTemplateForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (userRoleTemplateForm.status === 'INVALID' && userRoleTemplateForm.disabled === false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'User Role Template',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.userRoleTemplate.RoleTemplateName = this.utilitiesService.removeInnerSpaces(
      this.userRoleTemplate.RoleTemplateName,
    );
    if (this.userRoleTemplate.RoleTemplateName === null || this.userRoleTemplate.RoleTemplateName === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    let permissionPayload: ParentPermissions[] = [];
    // console.log(permissionPayload);
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let parentPermission = new ParentPermissions();
          parentPermission.ParentPermissionId = it.value;
          parentPermission.PAccess = 1;
          parentPermission.ChildPermissionsList = [];
          if (it.children != null) {
            it.children.forEach(function (chield) {
              if (chield.checked || chield.checked == undefined) {
                let childrenPermission = new ChildPermissions();
                childrenPermission.ChildPermissionId = chield.value;
                childrenPermission.PAccess = 1;

                parentPermission.ChildPermissionsList.push(childrenPermission);
              }
            });
          }
          permissionPayload.push(parentPermission);
        }
      });

      if (permissionPayload.length == 0) {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.utilitiesService.smmodal('User Role Template', 'Please select atleast one permission.');
        return;
      }
    }

    this.userRoleTemplate.ParentPermissionsList = permissionPayload;
    this.userRoleTemplate.IsActive = this.userRoleTemplate.IsActive ? 1 : 0;
    this.userRoleTemplate.ModifiedBy = this.currentUser.UserId;

    this.userRoleTemplateService.Update(this.utilitiesService.stripScript(this.userRoleTemplate)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('User Role Template', data.Message);
        this.clearFormData(userRoleTemplateForm);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'userRoleTemplateService updateUserRoleTemplate Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getAllUserRoleTemplates(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.userRoleTemplateService.GetUserroleList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.userRoleTemplates = data;
        if (
          this.userRoleTemplates != null &&
          this.userRoleTemplates.length < 0 &&
          this.userRoleTemplates[0].Count / 10 < this.page
        ) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'userRoleTemplateService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.userRoleTemplates[0] ? this.userRoleTemplates[0].Count : 0),
    );
  }

  public hasData(): boolean {
    return this.userRoleTemplates !== null && this.userRoleTemplates.length > 0;
  }

  public getUserRoleTemplateById(userRoleTemplateId: number, serviceProviderId: number) {
    if (this.canAccess('USER_ROLE_PERMISSION_TEMPLATE_VIEW', 'view')) {
      this.loaderService.display(true);
      const CProle = new UserRoleTemplateModule();
      CProle.ServiceProviderCPId = this.authState.AESEncryptText(serviceProviderId);
      CProle.CorporationCPId = this.authState.AESEncryptText(this.authState.getStoredContextCorporationId());
      CProle.VenueCPId = this.authState.AESEncryptText(this.authState.getStoredContextVenueId());
      CProle.UserRoleTemplateCPId = this.authState.AESEncryptText(userRoleTemplateId);

      this.userRoleTemplateService.GetSingle(CProle).subscribe(
        (data) => {
          this.userRoleTemplate = data;
          this.loaderService.display(false);
          if (this.userRoleTemplate != null) {
            if (
              this.userRoleTemplate.ServiceProviderId == null &&
              this.userRoleTemplate.CorporationId == null &&
              this.userRoleTemplate.VenueId == null
            ) {
              this.isSysAdmin = true;
            } else {
              this.isSysAdmin = false;
            }
            if (
              this.userRoleTemplate.ParentPermissionsList != null &&
              this.userRoleTemplate.ParentPermissionsList.length > 0
            ) {
              this.config = {
                hasAllCheckBox: false,
                hasFilter: true,
                hasCollapseExpand: false,
                maxHeight: 500,
              };

              this.items = [];
              let that = this;
              this.isCheckBoxVisible = true;
              this.userRoleTemplate.ParentPermissionsList.forEach(function (ParentPermission) {
                let childrens = [];
                if (ParentPermission.ChildPermissionsList != null && ParentPermission.ChildPermissionsList.length > 0) {
                  ParentPermission.ChildPermissionsList.forEach(function (item) {
                    item.PAccess = item.PAccess ? true : false;
                    childrens.push({
                      text:
                        item.PermissionDisplayName +
                        ' (Description: ' +
                        item.PermissionDescription +
                        ')' +
                        ' (Type: ' +
                        item.UserType +
                        ')',
                      checked: item.PAccess,
                      value: item.ChildPermissionId,
                      collapsed: true,
                    });
                  });
                }
                that.items.push(
                  new TreeviewItem({
                    text:
                      ParentPermission.PermissionDisplayName +
                      ' (Description: ' +
                      ParentPermission.PermissionDescription +
                      ')' +
                      ' (Type: ' +
                      ParentPermission.UserType +
                      ')',
                    value: ParentPermission.ParentPermissionId,
                    checked: ParentPermission.PAccess ? true : false,
                    children: childrens,
                    collapsed: true,
                  }),
                );
              });

              if (this.items && this.items.length > 0) {
                let itemObj = this.items.filter((tree) => tree.checked == false || tree.checked == undefined);
                if (itemObj && itemObj.length > 0) {
                  this.isAllFeatureConfigurationChecked = false;
                } else {
                  this.isAllFeatureConfigurationChecked = true;
                }
              }
            }
            this.GetAllServiceProviders(this.userRoleTemplate.UserRoleTemplateId);
          }
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'userRoleTemplateService GetSingle Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal(
        'User Role Template',
        'You do not have permission to view user role template details.',
      );
    }
  }

  public GetAllServiceProviders(userRoleId: number) {
    this.loaderService.display(true);
    const CPSP = new UserRoleModule();
    CPSP.UserRoleCPId = this.authState.AESEncryptText(userRoleId);

    this.userRoleTemplateService.GetServiceProviderListForDropdown(CPSP).subscribe(
      (data) => {
        this.serviceProviders = data;
        this.loaderService.display(false);

        if (this.userRoleTemplate.UserRoleTemplateId == null) {
          const obj = this.serviceProviders.filter(
            (v) => v.ServiceProviderId == this.authState.getStoredContextServiceProviderId(),
          );
          if (obj && obj[0]) {
            this.userRoleTemplate.ServiceProviderId = obj[0].ServiceProviderId;
          }

          this.getAllUserRolePermissions(0, this.authState.getStoredContextServiceProviderId(), 0, 0);
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'serviceProviderService GetServiceProviderListForDropdown Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getAllUserRolePermissions(
    userRoleId: number,
    serviceProviderId: number,
    corporationId: number,
    venueId: number,
  ) {
    this.loaderService.display(true);
    const CPVenue = new UserRoleTemplateModule();
    CPVenue.UserRoleTemplateId = this.authState.AESEncryptText(userRoleId);
    CPVenue.ServiceProviderCPId = this.authState.AESEncryptText(serviceProviderId);
    CPVenue.CorporationCPId = this.authState.AESEncryptText(corporationId);
    CPVenue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.userRoleTemplateService.GetRolePermissions(CPVenue, -1, -1).subscribe(
      (data) => {
        if (data.ParentPermissionsList != null && data.ParentPermissionsList.length > 0) {
          this.config = {
            hasAllCheckBox: false,
            hasFilter: true,
            hasCollapseExpand: false,
            maxHeight: 500,
          };

          this.items = [];
          let that = this;
          data.ParentPermissionsList.forEach(function (ParentPermission) {
            let childrens = [];
            that.isCheckBoxVisible = true;
            if (ParentPermission.ChildPermissionsList != null && ParentPermission.ChildPermissionsList.length > 0) {
              ParentPermission.ChildPermissionsList.forEach(function (item) {
                item.PAccess = item.PAccess ? true : false;
                childrens.push({
                  text:
                    item.PermissionDisplayName +
                    ' (Description: ' +
                    item.PermissionDescription +
                    ')' +
                    ' (Type: ' +
                    item.UserType +
                    ')',
                  checked: item.PAccess,
                  value: item.ChildPermissionId,
                  collapsed: true,
                });
              });
            }
            that.items.push(
              new TreeviewItem({
                text:
                  ParentPermission.PermissionDisplayName +
                  ' (Description: ' +
                  ParentPermission.PermissionDescription +
                  ')' +
                  ' (Type: ' +
                  ParentPermission.UserType +
                  ')',
                value: ParentPermission.ParentPermissionId,
                checked: false,
                children: childrens,
                collapsed: true,
              }),
            );
          });
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'userRoleTemplateService GetRolePermissions Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  // permissionHasSelected() {
  //     if (this.userRole.UserRolePermissions != null && this.userRole.UserRolePermissions.length > 0) {
  //         for (let i = 0; i < this.userRole.UserRolePermissions.length; i++) {
  //             if (this.userRole.UserRolePermissions[i].PAccess > 0) {
  //                 return true;
  //             }
  //         }
  //     } else {
  //         return false;
  //     }
  // }

  public onSelectedChange(_downlineItems: DownlineTreeviewItem[]) {
    if (this.treeviewComponent && this.treeviewComponent.items.length > 0) {
      let item = this.treeviewComponent.items.filter((tree) => tree.checked == false || tree.checked == undefined);
      if (item && item.length > 0) {
        this.isAllFeatureConfigurationChecked = false;
      } else {
        this.isAllFeatureConfigurationChecked = true;
      }
    }
  }

  public onAllCheckedChange(event: any) {
    let that = this;
    this.treeviewComponent.items.forEach(function (it) {
      it.checked = event.checked;
      that.changeChildrenSelection(it, event.checked);
    });
  }

  public changeChildrenSelection(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }
}
