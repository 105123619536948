import { Configuration } from '../../../app.constants';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { CityModule } from '../city.module';

@Injectable({
  providedIn: 'root',
})
export class CityService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (addcity: CityModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/city';
    return this.http.post<ResponseMessage>(_url, addcity);
  };

  public Update = (updateCity: CityModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/city';
    return this.http.put<ResponseMessage>(_url, updateCity);
  };

  public GetCitiesList = (body: string, page: number, size: number): Observable<CityModule[]> => {
    const _url: string = this.configuration.Server + 'admin/all/cities/' + page + '/' + size;
    return this.http.post<CityModule[]>(_url, body);
  };

  public GetCitiesDropDown = (body: string, page: number, size: number): Observable<CityModule[]> => {
    const _url: string = this.configuration.Server + 'admin/cities/' + page + '/' + size;
    return this.http.post<CityModule[]>(_url, body);
  };

  public GetSingle = (body: CityModule): Observable<CityModule> => {
    const _url: string = this.configuration.Server + 'admin/city/details';
    return this.http.post<CityModule>(_url, body);
  };
}
