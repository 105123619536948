<section class="content-header">
  <div class="row">
    <div class="col-lg-12">
      <div class="col-lg-3 col-md-8 col-sm-12 form-heading" *ngIf="locationTypes && !isImportLocationTypeScreen">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading">LOCATION TYPES</span>
      </div>

      <div class="col-lg-6 col-md-8 col-sm-12 form-heading" *ngIf="!locationTypes">
        <i class="fa fa-building heading-icon"></i>
        <span class="list-heading" *ngIf="!locationTypes && !locationType.VenueLocationTypeId">ADD LOCATION TYPE</span>
        <span class="list-heading" *ngIf="!locationTypes && locationType.VenueLocationTypeId">EDIT LOCATION TYPE</span>
      </div>

      <div class="col-lg-9 col-md-12 col-sm-12" *ngIf="!isImportLocationTypeScreen">
        <div
          id="page-size"
          class="col-lg-1 col-sm-3 col-md-4 col-xs-12 pull-right list-heading pageSize"
          *ngIf="locationTypes"
        >
          <ngx-atlas-select
            (model)="locationTypePageSizeChange($event)"
            [list]="pageSizes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select Size'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="size"
          >
          </ngx-atlas-select>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          style="margin: 0px 0px 0px 15px"
          *ngIf="canAccess('IMPORT_LOCATION_TYPES', 'view') && !isLocationTypeForm"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="goToImportLocationType()">
            Import Location Types
          </button>
        </div>

        <div
          class="col-lg-2 col-md-4 col-sm-3 col-xs-12 input-group pull-right add-section"
          *ngIf="locationTypes && !locationType.VenueLocationTypeId && canAccess('LOCATION_TYPE_ADD', 'view')"
        >
          <button type="submit" class="btn btn-add col-lg-12 col-md-12 col-xs-12" (click)="setAddForm()">
            Add Location Type
          </button>
        </div>

        <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pull-right list-heading" *ngIf="locationTypes">
          <ngx-atlas-select
            (model)="listviewtype($event)"
            [list]="listviewtypes"
            [idField]="'id'"
            [textField]="'name'"
            [placeholder]="'Select List'"
            [allowClear]="true"
            [multiple]="false"
            [required]="true"
            [selectedValue]="viewType"
          >
          </ngx-atlas-select>
        </div>

        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12 input-group pull-right p-search" *ngIf="locationTypes">
          <form>
            <input
              type="text"
              name="searchString"
              class="form-control search-radius searchBoxRadius"
              placeholder="Search"
              [(ngModel)]="searchString"
              autocomplete="off"
            />
            <div class="input-group-btn">
              <div id="clear-btn">
                <button type="button" name="clear" class="btn" (click)="clearSearch()" *ngIf="searchString">
                  <i class="fa fa-close"></i>
                </button>
              </div>
              <div id="search-btn">
                <button type="submit" name="search" class="btn" (click)="locationTypeSearch(searchString)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isImportLocationTypeScreen">
        <div class="col-lg-2 col-md-4 col-sm-3 col-xs-12 add-section" style="">
          <button
            type="submit"
            class="btn btn-add col-lg-12 col-md-12 col-xs-12"
            style="width: 65%"
            (click)="backToLocationTypeList()"
          >
            <i class="fa fa-chevron-left"></i> Back
          </button>
        </div>

        <div class="col-lg-4 col-md-8 col-sm-12 form-heading">
          <i class="fa fa-building heading-icon"></i>
          <span class="list-heading">IMPORT LOCATION TYPES</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="content col-lg-12 col-md-12 col-sm-12">
  <div class="row" *ngIf="locationTypes && !isImportLocationTypeScreen">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div>
        <div *ngIf="!hasData()" class="col-lg-12 col-md-12 col-sm-12">
          <h3 class="list-view-error">Sorry no data found!</h3>
        </div>

        <div class="box-body table-responsive" *ngIf="hasData()">
          <table class="table table-hover table-responsive">
            <thead>
              <tr>
                <th class="table-sort-header" (click)="sortColumn('VenueLocationTypeName', columnSortOrder)">
                  Location Type
                  <span *ngIf="selectedColumnName == 'VenueLocationTypeName' && columnSortOrder == 'DESC'"
                    ><i class="fa fa-sort-up"></i></span
                  ><span *ngIf="selectedColumnName == 'VenueLocationTypeName' && columnSortOrder == 'ASC'"
                    ><i class="fa fa-sort-down"></i
                  ></span>
                </th>
                <th class="table-sort-header">Venue</th>
                <th
                  class="table-sort-header"
                  (click)="sortColumn('IsActive', columnSortOrder)"
                  style="border-radius: 0 5px 0 0 !important"
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody
              *ngFor="
                let lt of locationTypes
                  | paginate: { filter: search, itemsPerPage: size, currentPage: page, totalItems: count }
              "
            >
              <tr (click)="getLocationTypeById(lt.VenueLocationTypeId)">
                <td>{{ lt.VenueLocationTypeName }}</td>
                <td>{{ lt.VenueName }}</td>
                <td *ngIf="lt.IsActive">
                  <span
                    ><i class="fa fa-check-square" data-toggle="tooltip" data-placement="top" title="Active"></i
                  ></span>
                </td>
                <td *ngIf="!lt.IsActive">
                  <span
                    ><i class="fa fa-window-close" data-toggle="tooltip" data-placement="top" title="Inactive"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls
            class="pagination pull-right"
            (pageChange)="pageChanged($event)"
            *ngIf="hasData() && count > 10"
          ></pagination-controls>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!locationTypes && !isImportLocationTypeScreen">
    <form #locationTypeForm="ngForm" name="locationType" role="form" class="form-horizontal">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div>
          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="Venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div>
              <div class="clipping-wrapper">
                <p class="readOnly clipping">{{ locationType.VenueName }}</p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-sm-6 form-group">
            <label for="locationTypeName"><span id="red-circle">&#x25CF;</span> Location Type</label>
            <input
              id="locationTypeName"
              type="text"
              class="form-control"
              name="locationTypeName"
              placeholder="Location Type"
              [(ngModel)]="locationType.VenueLocationTypeName"
              required
              #locationTypeName="ngModel"
              maxlength="120"
              autocomplete="off"
              [pattern]="utilitiesService.alphanumericRegex"
            />
            <div
              *ngIf="locationTypeName.errors && (locationTypeName.dirty || locationTypeName.touched)"
              class="alert alert-danger"
            >
              <div [hidden]="!locationTypeName.errors.required" class="text-danger">Location type is required.</div>
              <div [hidden]="!locationTypeName.errors.pattern" class="text-danger">
                {{ utilitiesService.alphanumericRegexMessage }}
              </div>
            </div>
          </div>

          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            *ngIf="isLocationElementShow || isLocationInventoryShow || isLocationTaskShow || isLocationAreaShow"
          >
            <mat-tab-group
              mat-align-tabs="left"
              [(selectedIndex)]="selectedTab"
              (selectedIndexChange)="TabChange($event)"
              animationDuration="1000ms"
              style="margin-top: 20px; margin-bottom: 20px"
            >
              <mat-tab label="Task" *ngIf="isLocationTaskShow">
                <div *ngIf="taskInstructionsList.length > 0">
                  <div class="col-sm-12 col-md-12 col-lg-12 table-top">
                    <div class="pull-right" style="margin: 0px 65px 0px 0px">
                      <label for="SelectTasks">Select/Unselect All &nbsp;</label>
                      <mat-checkbox
                        id="SelectTasks"
                        name="SelectTasks"
                        [(ngModel)]="isAllTaskSelected"
                        (change)="selectAllTasks(isAllTaskSelected)"
                      ></mat-checkbox>
                    </div>
                  </div>

                  <div
                    class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive"
                    style="max-height: calc(100vh - 123px)"
                  >
                    <table class="table table-hover table-responsive customtbl">
                      <thead>
                        <tr>
                          <th class="sequence">Sequence</th>
                          <th>Task</th>
                          <th style="text-align: center">Select Existing Task</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let taskl of taskInstructionsList">
                          <tr>
                            <td class="srno form-group" style="width: 15% !important">
                              <input
                                type="number"
                                (keypress)="keyPress($event)"
                                class="form-control"
                                placeholder="Sequence"
                                [(ngModel)]="taskl.Sequence"
                                id="taskOrder_{{ taskl.VenueTaskId }}"
                                [ngModelOptions]="{ standalone: true }"
                                #taskOrder="ngModel"
                                pattern="[0-9]+"
                                min="1"
                                (input)="
                                  selectedTask(taskl.VenueTaskId, taskl.VenueTaskName, taskl.Sequence, taskl.IsActive)
                                "
                              />
                            </td>
                            <td>{{ taskl.VenueTaskName }}</td>
                            <td style="width: 16% !important; text-align: center; vertical-align: middle">
                              <mat-checkbox
                                id="Task_{{ taskl.VenueTaskId }}"
                                name="Task_{{ taskl.VenueTaskId }}"
                                [(ngModel)]="taskl.IsActive"
                                (change)="
                                  selectedTask(
                                    taskl.VenueLocationTypeId,
                                    taskl.VenueTaskId,
                                    taskl.VenueTaskName,
                                    taskl.Sequence,
                                    taskl.IsActive
                                  )
                                "
                              ></mat-checkbox>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="taskInstructions.length > 0">
                  <div
                    class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top"
                    style="max-height: calc(100vh - 123px)"
                  >
                    <table class="table table-hover table-responsive customtbl">
                      <thead>
                        <tr>
                          <th class="sequence" style="width: 15% !important">
                            <span id="red-circle">&#x25CF;</span> Sequence
                          </th>
                          <th><span id="red-circle">&#x25CF;</span> Task</th>
                          <th style="text-align: center; width: 16% !important">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let task of taskInstructions">
                          <tr *ngIf="task.IsActive && task.VenueLocationTypeMaster == 0">
                            <td class="srno form-group" style="width: 15% !important">
                              <input
                                type="number"
                                (keypress)="keyPress($event)"
                                class="form-control"
                                placeholder="Sequence"
                                [(ngModel)]="task.Sequence"
                                id="StepOrder_{{ task.VenueTaskRowID }}"
                                [ngModelOptions]="{ standalone: true }"
                                #StepOrder="ngModel"
                                pattern="[0-9]+"
                                min="1"
                                required
                              />
                              <div
                                *ngIf="StepOrder.errors && (StepOrder.dirty || StepOrder.touched)"
                                class="alert alert-danger"
                              >
                                <div [hidden]="!StepOrder.errors.required" class="text-danger">
                                  Sequence is required.
                                </div>
                                <div [hidden]="!StepOrder.errors.pattern" class="text-danger">
                                  Sequence accepts only numeric.
                                </div>
                              </div>
                            </td>
                            <td class="form-group">
                              <textarea
                                type="text"
                                class="form-control"
                                placeholder="Task"
                                [(ngModel)]="task.VenueTaskName"
                                id="VenueTaskName_{{ task.VenueTaskRowID }}"
                                #VenueTaskName="ngModel"
                                [ngModelOptions]="{ standalone: true }"
                                maxlength="500"
                                required
                              ></textarea>
                              <div
                                *ngIf="VenueTaskName.errors && (VenueTaskName.dirty || VenueTaskName.touched)"
                                class="alert alert-danger"
                              >
                                <div [hidden]="!VenueTaskName.errors.required" class="text-danger">
                                  Task name is required.
                                </div>
                              </div>
                            </td>
                            <td class="table-center" style="width: 16% !important">
                              <button class="btn btn-purple" (click)="removeTask(task.VenueTaskRowID)">
                                <i class="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px; padding-right: 0">
                  <button type="submit" class="btn btn-type pull-right btn-cancel" (click)="addTask()">
                    Add New Task
                  </button>
                </div>
              </mat-tab>

              <mat-tab label="Inventory" *ngIf="isLocationInventoryShow">
                <div *ngIf="inventoryInstructionsList.length > 0">
                  <div class="col-sm-12 col-md-12 col-lg-12 table-top">
                    <div class="pull-right" style="margin: 0px 65px 0px 0px">
                      <label for="SelectInventories">Select/Unselect All &nbsp;</label>
                      <mat-checkbox
                        id="SelectInventories"
                        name="SelectInventories"
                        [(ngModel)]="isAllInventoriesSelected"
                        (change)="selectAllInventories(isAllInventoriesSelected)"
                      ></mat-checkbox>
                    </div>
                  </div>

                  <div
                    class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive"
                    style="max-height: calc(100vh - 123px)"
                  >
                    <table class="table table-hover table-responsive customtbl">
                      <thead>
                        <tr>
                          <th class="sequence">Sequence</th>
                          <th>Inventory</th>
                          <th style="text-align: center">Select Existing Inventory</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let inventoryl of inventoryInstructionsList">
                          <tr>
                            <td class="srno form-group" style="width: 15% !important">
                              <input
                                type="number"
                                (keypress)="keyPress($event)"
                                class="form-control"
                                placeholder="Sequence"
                                [(ngModel)]="inventoryl.Sequence"
                                id="inventoryOrder_{{ inventoryl.VenueInventoryId }}"
                                [ngModelOptions]="{ standalone: true }"
                                #taskOrder="ngModel"
                                pattern="[0-9]+"
                                min="1"
                                (input)="
                                  selectedVenueInventory(
                                    inventoryl.VenueInventoryId,
                                    inventoryl.VenueInventoryName,
                                    inventoryl.Sequence,
                                    inventoryl.IsActive
                                  )
                                "
                              />
                            </td>
                            <td>{{ inventoryl.VenueInventoryName }}</td>

                            <td style="width: 16% !important; text-align: center; vertical-align: middle">
                              <mat-checkbox
                                id="Inventory_{{ inventoryl.VenueInventoryId }}"
                                name="Inventory_{{ inventoryl.VenueInventoryId }}"
                                [(ngModel)]="inventoryl.IsActive"
                                (change)="
                                  selectedVenueInventory(
                                    inventoryl.VenueLocationTypeId,
                                    inventoryl.VenueInventoryId,
                                    inventoryl.VenueInventoryName,
                                    inventoryl.Sequence,
                                    inventoryl.IsActive
                                  )
                                "
                              ></mat-checkbox>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="inventoryInstructions.length > 0">
                  <div
                    class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top"
                    style="max-height: calc(100vh - 123px)"
                  >
                    <table class="table table-hover table-responsive customtbl">
                      <thead>
                        <tr>
                          <th class="sequence" style="width: 15% !important">
                            <span id="red-circle">&#x25CF;</span> Sequence
                          </th>
                          <th><span id="red-circle">&#x25CF;</span> Inventory</th>
                          <th style="text-align: center; width: 16% !important">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let inventory of inventoryInstructions">
                          <tr *ngIf="inventory.IsActive && inventory.VenueLocationTypeMaster == 0">
                            <td class="srno form-group" style="width: 15% !important">
                              <input
                                type="number"
                                (keypress)="keyPress($event)"
                                class="form-control"
                                placeholder="Sequence"
                                [(ngModel)]="inventory.Sequence"
                                id="StepOrder_{{ inventory.VenueInventoryRowID }}"
                                [ngModelOptions]="{ standalone: true }"
                                #StepOrder="ngModel"
                                pattern="[0-9]+"
                                min="1"
                                required
                              />
                              <div
                                *ngIf="StepOrder.errors && (StepOrder.dirty || StepOrder.touched)"
                                class="alert alert-danger"
                              >
                                <div [hidden]="!StepOrder.errors.required" class="text-danger">
                                  Sequence is required.
                                </div>
                                <div [hidden]="!StepOrder.errors.pattern" class="text-danger">
                                  Sequence accepts only numeric.
                                </div>
                              </div>
                            </td>
                            <td class="form-group">
                              <textarea
                                type="text"
                                class="form-control"
                                placeholder="Inventory"
                                [(ngModel)]="inventory.VenueInventoryName"
                                id="VenueInventoryName_{{ inventory.VenueInventoryRowID }}"
                                #VenueInventoryName="ngModel"
                                [ngModelOptions]="{ standalone: true }"
                                maxlength="500"
                                required
                              ></textarea>
                              <div
                                *ngIf="
                                  VenueInventoryName.errors && (VenueInventoryName.dirty || VenueInventoryName.touched)
                                "
                                class="alert alert-danger"
                              >
                                <div [hidden]="!VenueInventoryName.errors.required" class="text-danger">
                                  Inventory name is required.
                                </div>
                              </div>
                            </td>
                            <td class="table-center" style="width: 16% !important">
                              <button
                                class="btn btn-purple"
                                (click)="removeVenueInventory(inventory.VenueInventoryRowID)"
                              >
                                <i class="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px; padding-right: 0">
                  <button type="submit" class="btn btn-type pull-right btn-cancel" (click)="addInventory()">
                    Add New Inventory
                  </button>
                </div>
              </mat-tab>

              <mat-tab label="Element" *ngIf="isLocationElementShow">
                <div *ngIf="elementInstructionsList.length > 0">
                  <div class="col-sm-12 col-md-12 col-lg-12 table-top">
                    <div class="pull-right" style="margin: 0px 65px 0px 0px">
                      <label for="SelectElements">Select/Unselect All &nbsp;</label>
                      <mat-checkbox
                        id="SelectElements"
                        name="SelectElements"
                        [(ngModel)]="isAllElementsSelected"
                        (change)="selectAllElements(isAllElementsSelected)"
                      ></mat-checkbox>
                    </div>
                  </div>

                  <div
                    class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top"
                    style="max-height: calc(100vh - 123px)"
                  >
                    <table class="table table-hover table-responsive customtbl">
                      <thead>
                        <tr>
                          <th class="sequence">Sequence</th>
                          <th>Element</th>
                          <th style="text-align: center">Select Existing Element</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let elementl of elementInstructionsList">
                          <tr>
                            <td class="srno form-group" style="width: 15% !important">
                              <input
                                type="number"
                                (keypress)="keyPress($event)"
                                class="form-control"
                                placeholder="Sequence"
                                [(ngModel)]="elementl.Sequence"
                                id="elementOrder_{{ elementl.VenueElementId }}"
                                [ngModelOptions]="{ standalone: true }"
                                #taskOrder="ngModel"
                                pattern="[0-9]+"
                                min="1"
                                (input)="
                                  selectedVenueElement(
                                    elementl.VenueElementId,
                                    elementl.VenueElementName,
                                    elementl.Sequence,
                                    elementl.IsActive
                                  )
                                "
                              />
                            </td>
                            <td>{{ elementl.VenueElementName }}</td>
                            <td style="width: 17% !important; text-align: center; vertical-align: middle">
                              <mat-checkbox
                                id="Element_{{ elementl.VenueElementId }}"
                                name="Element_{{ elementl.VenueElementId }}"
                                [(ngModel)]="elementl.IsActive"
                                (change)="
                                  selectedVenueElement(
                                    elementl.VenueLocationTypeId,
                                    elementl.VenueElementId,
                                    elementl.VenueElementName,
                                    elementl.Sequence,
                                    elementl.IsActive
                                  )
                                "
                              ></mat-checkbox>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="elementInstructions.length > 0">
                  <div
                    class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top"
                    style="max-height: calc(100vh - 123px)"
                  >
                    <table class="table table-hover table-responsive customtbl">
                      <thead>
                        <tr>
                          <th class="sequence" style="width: 15% !important">
                            <span id="red-circle">&#x25CF;</span> Sequence
                          </th>
                          <th><span id="red-circle">&#x25CF;</span> Element</th>
                          <th style="text-align: center; width: 16% !important">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let element of elementInstructions">
                          <tr *ngIf="element.IsActive && element.VenueLocationTypeMaster == 0">
                            <td class="srno form-group" style="width: 15% !important">
                              <input
                                type="number"
                                (keypress)="keyPress($event)"
                                class="form-control"
                                placeholder="Sequence"
                                [(ngModel)]="element.Sequence"
                                id="StepOrder_{{ element.VenueElementRowID }}"
                                [ngModelOptions]="{ standalone: true }"
                                #StepOrder="ngModel"
                                pattern="[0-9]+"
                                min="1"
                                required
                              />
                              <div
                                *ngIf="StepOrder.errors && (StepOrder.dirty || StepOrder.touched)"
                                class="alert alert-danger"
                              >
                                <div [hidden]="!StepOrder.errors.required" class="text-danger">
                                  Sequence is required.
                                </div>
                                <div [hidden]="!StepOrder.errors.pattern" class="text-danger">
                                  Sequence accepts only numeric.
                                </div>
                              </div>
                            </td>
                            <td class="form-group">
                              <textarea
                                type="text"
                                class="form-control"
                                placeholder="Element"
                                [(ngModel)]="element.VenueElementName"
                                id="VenueElementName_{{ element.VenueElementRowID }}"
                                #VenueElementName="ngModel"
                                [ngModelOptions]="{ standalone: true }"
                                maxlength="500"
                                required
                              ></textarea>
                              <div
                                *ngIf="VenueElementName.errors && (VenueElementName.dirty || VenueElementName.touched)"
                                class="alert alert-danger"
                              >
                                <div [hidden]="!VenueElementName.errors.required" class="text-danger">
                                  Element name is required.
                                </div>
                              </div>
                            </td>
                            <td class="table-center" style="width: 16% !important">
                              <button class="btn btn-purple" (click)="removeElement(element.VenueElementRowID)">
                                <i class="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px; padding-right: 0">
                  <button type="submit" class="btn btn-type pull-right btn-cancel" (click)="addElement()">
                    Add New Element
                  </button>
                </div>
              </mat-tab>

              <mat-tab label="Area" *ngIf="isLocationAreaShow">
                <div *ngIf="areaInstructionsList.length > 0">
                  <div class="col-sm-12 col-md-12 col-lg-12 table-top">
                    <div class="pull-right" style="margin: 0px 65px 0px 0px">
                      <label for="SelectAreas">Select/Unselect All &nbsp;</label>
                      <mat-checkbox
                        id="SelectAreas"
                        name="SelectAreas"
                        [(ngModel)]="isAllAreasSelected"
                        (change)="selectAllAreas(isAllAreasSelected)"
                      ></mat-checkbox>
                    </div>
                  </div>

                  <div
                    class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top"
                    style="max-height: calc(100vh - 123px)"
                  >
                    <table class="table table-hover table-responsive customtbl">
                      <thead>
                        <tr>
                          <th class="sequence">Sequence</th>
                          <th>Area</th>
                          <th style="text-align: center">Select Existing Area</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let area of areaInstructionsList">
                          <tr>
                            <td class="srno form-group" style="width: 15% !important">
                              <input
                                type="number"
                                (keypress)="keyPress($event)"
                                class="form-control"
                                placeholder="Sequence"
                                [(ngModel)]="area.Sequence"
                                id="areaOrder_{{ area.AreaId }}"
                                [ngModelOptions]="{ standalone: true }"
                                #taskOrder="ngModel"
                                pattern="[0-9]+"
                                min="1"
                                (input)="
                                  selectedVenueArea(
                                    area.VenueLocationTypeId,
                                    area.AreaId,
                                    area.AreaName,
                                    area.Sequence,
                                    area.IsActive
                                  )
                                "
                              />
                            </td>
                            <td>{{ area.AreaName }}</td>
                            <td style="width: 17% !important; text-align: center; vertical-align: middle">
                              <mat-checkbox
                                id="Area_{{ area.AreaId }}"
                                name="Area_{{ area.AreaId }}"
                                [(ngModel)]="area.IsActive"
                                (change)="
                                  selectedVenueArea(
                                    area.VenueLocationTypeId,
                                    area.AreaId,
                                    area.AreaName,
                                    area.Sequence,
                                    area.IsActive
                                  )
                                "
                              ></mat-checkbox>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div *ngIf="areaInstructions.length > 0">
                  <div
                    class="col-sm-12 col-md-12 col-lg-12 box-body table-responsive table-top"
                    style="max-height: calc(100vh - 123px)"
                  >
                    <table class="table table-hover table-responsive customtbl">
                      <thead>
                        <tr>
                          <th class="sequence" style="width: 15% !important">Sequence</th>
                          <th><span id="red-circle">&#x25CF;</span> Area</th>
                          <th style="text-align: center; width: 16% !important">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let area of areaInstructions">
                          <tr *ngIf="area.IsActive && area.VenueLocationTypeMaster == 0">
                            <td class="srno form-group" style="width: 15% !important">
                              <input
                                type="number"
                                (keypress)="keyPress($event)"
                                class="form-control"
                                placeholder="Sequence"
                                [(ngModel)]="area.Sequence"
                                id="StepOrder_{{ area.AreaRowID }}"
                                [ngModelOptions]="{ standalone: true }"
                                #StepOrder="ngModel"
                                pattern="[0-9]+"
                                min="1"
                              />
                              <div
                                *ngIf="StepOrder.errors && (StepOrder.dirty || StepOrder.touched)"
                                class="alert alert-danger"
                              >
                                <div [hidden]="!StepOrder.errors.pattern" class="text-danger">
                                  Sequence accepts only numeric.
                                </div>
                              </div>
                            </td>
                            <td class="form-group">
                              <textarea
                                type="text"
                                class="form-control"
                                placeholder="Area"
                                [(ngModel)]="area.AreaName"
                                id="AreaName_{{ area.AreaRowID }}"
                                #AreaName="ngModel"
                                [ngModelOptions]="{ standalone: true }"
                                maxlength="500"
                                required
                              ></textarea>
                              <div
                                *ngIf="AreaName.errors && (AreaName.dirty || AreaName.touched)"
                                class="alert alert-danger"
                              >
                                <div [hidden]="!AreaName.errors.required" class="text-danger">
                                  Area name is required.
                                </div>
                              </div>
                            </td>
                            <td class="table-center" style="width: 16% !important">
                              <button class="btn btn-purple" (click)="removeArea(area.AreaRowID)">
                                <i class="fa fa-times"></i>
                              </button>
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="margin-top: 20px; padding-right: 0">
                  <button type="submit" class="btn btn-type pull-right btn-cancel" (click)="addArea()">
                    Add New Area
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>

          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group" *ngIf="locationType.VenueLocationTypeId">
              <div>
                <div>
                  <mat-checkbox [(ngModel)]="locationType.IsActive" name="isActive">Is Active</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <input
            type="button"
            value="Cancel"
            class="btn btn-type pull-right btn-cancel"
            (click)="clearFormData(locationTypeForm)"
          />
          <input
            type="button"
            value="Save"
            *ngIf="!locationType.VenueLocationTypeId"
            class="btn btn-type btn-save"
            (click)="addLocationType(locationTypeForm)"
            [disabled]="!locationTypeForm.form.valid || !locationType.VenueId || isReqComplete"
          />
          <input
            type="button"
            value="Save"
            *ngIf="locationType.VenueLocationTypeId"
            class="btn btn-type btn-save"
            (click)="updateLocationType(locationTypeForm)"
            [disabled]="
              !locationTypeForm.form.valid ||
              !locationType.VenueId ||
              isReqComplete ||
              !canAccess('LOCATION_TYPE_EDIT', 'view')
            "
          />
        </div>
      </div>
    </form>
  </div>

  <div class="row" *ngIf="isImportLocationTypeScreen">
    <div>
      <form #importLocationTypeForm="ngForm" name="locationType" role="form" class="form-horizontal">
        <div>
          <div class="col-lg-6 col-md-6 col-xs-12 form-group">
            <label for="Corporation"><span id="red-circle">&#x25CF;</span> Corporation</label>
            <div>
              <ngx-atlas-select
                (model)="corporationChange($event)"
                [list]="importLocationTypeCorporations"
                [idField]="'CorporationId'"
                [textField]="'CorporationName'"
                [placeholder]="'Select Corporation'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [disabled]="isCorporationDisabled"
                [selectedValue]="selectedCorporationId"
              >
              </ngx-atlas-select>
            </div>
          </div>

          <div class="col-lg-6 col-md-6 col-xs-12 form-group">
            <label for="Venue"><span id="red-circle">&#x25CF;</span> Venue</label>
            <div>
              <ngx-atlas-select
                (model)="venueChange($event)"
                [list]="importLocationTypeVenues"
                [idField]="'VenueId'"
                [textField]="'VenueName'"
                [placeholder]="'Select Venue'"
                [allowClear]="true"
                [multiple]="false"
                [required]="true"
                [disabled]="isVenueDisabled"
                [selectedValue]="selectedVenueId"
              >
              </ngx-atlas-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12 tboday-scroll">
          <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!hasImportLocationTypesData() && selectedVenueId">
            <h3 class="listerror">Sorry no data found!</h3>
          </div>

          <div class="box-body table-responsive" *ngIf="hasImportLocationTypesData()">
            <table class="table table-hover table-responsive">
              <thead>
                <tr>
                  <th style="border-radius: 5px 5px 0 0 !important">Location Types</th>
                  <!--<th style="width: 15%;">Is Imported?</th>
                                    <th style="width: 15%;">Active/Inactive</th>-->
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td style="background-color: #d5dde6">
                    <mat-checkbox
                      [(ngModel)]="selectAllImportLocationTypes"
                      name="selectAllLocation"
                      (change)="selectedAllLocationTypes(selectAllImportLocationTypes)"
                    ></mat-checkbox>
                    <span style="font-weight: bold; word-break: break-word"> Select All</span>
                  </td>
                </tr>

                <tr *ngFor="let locationTypes of importLocationTypes">
                  <td style="display: inline-flex">
                    <mat-checkbox
                      [(ngModel)]="locationTypes.IsSelected"
                      name="Location_{{ locationTypes.VenueLocationTypeId }}"
                      (change)="
                        selectedImportLocationType(
                          locationTypes.VenueLocationTypeId,
                          locationTypes.VenueLocationTypeName,
                          locationTypes.IsSelected
                        )
                      "
                    ></mat-checkbox
                    >&nbsp;
                    <span style="font-weight: bold; word-break: break-word">{{
                      locationTypes.VenueLocationTypeName
                    }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12" style="margin-top: 15px">
          <input
            type="button"
            value="Import"
            class="btn btn-type btn-save"
            (click)="openImportConfirmationModal(importLocationTypeForm)"
            [disabled]="
              !importLocationTypeForm.form.valid ||
              !selectedCorporationId ||
              !selectedVenueId ||
              storeImportLocationTypes.length === 0
            "
          />
        </div>
      </form>
    </div>

    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div style="margin-top: 10px; margin-bottom: 10px">
          <span style="font-size: 20px; font-weight: bold; color: #333">IMPORT LOGS</span>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!hasImportLogData()">
        <h3 class="list-view-error">Sorry no data found!</h3>
      </div>

      <div class="box-body table-responsive" *ngIf="hasImportLogData()">
        <table class="table table-hover table-responsive">
          <thead>
            <tr>
              <th class="table-sort-header" style="cursor: auto">Import Date</th>
              <th class="table-sort-header" style="cursor: auto">From Venue</th>
              <th class="table-sort-header" style="cursor: auto">Activity</th>
              <th class="table-sort-header" style="cursor: auto">Import Status</th>
              <th class="table-sort-header" style="cursor: auto">Imported By</th>
              <th class="table-sort-header" style="cursor: auto">Download Log</th>
              <th class="table-sort-header" style="border-radius: 0 5px 0 0 !important; cursor: auto">
                <a
                  (click)="getImportLogData(importLogPage, true)"
                  class="fa fa-refresh"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Refresh"
                ></a>
              </th>
            </tr>
          </thead>

          <tbody
            *ngFor="
              let importLog of importLogsData
                | paginate
                  : { filter: search, itemsPerPage: size, currentPage: importLogPage, totalItems: importLogCount }
            "
          >
            <tr>
              <td>{{ importLog.ModifiedOn | date: 'MM/dd/yyyy, hh:mm:ss a' }}</td>
              <td>{{ importLog.VenueName }}</td>
              <td>{{ importLog.TransactionMessage }}</td>
              <td>{{ importLog.TransactionStatus }}</td>
              <td>{{ importLog.UserName }}</td>
              <td>
                <a
                  (click)="
                    downloadImportLocationTypeFile(
                      importLog.SourceVenueId,
                      importLog.DestinationVenueId,
                      importLog.ImportLocationTypeLogId
                    )
                  "
                  class="fa fa-download"
                  style="color: black; cursor: pointer"
                  download
                ></a>
              </td>
              <td style="width: 35px"></td>
            </tr>
          </tbody>
        </table>
        <pagination-controls
          class="pagination pull-right"
          (pageChange)="importLogDataPageChanged($event)"
          *ngIf="hasImportLogData() && importLogCount > 10"
        ></pagination-controls>
      </div>
    </div>
  </div>

  <div
    class="modal"
    id="importConfirmationModal"
    [ngStyle]="importConfirmationModal ? { display: 'block' } : { display: 'none' }"
  >
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">Import Location Type</h3>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            (click)="closeImportConfirmationModal()"
            style="margin-top: -27px"
          >
            &times;
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h4>Proceed to importing location types? This action cannot be undone.</h4>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-lg-12 col-md-12 col-xs-12 btn-footer">
            <button class="btn btn-type btn-save" [disabled]="isImportReqComplete" (click)="saveImportLocationTypes()">
              Yes
            </button>
            <button class="btn btn-type btn-cancel" data-dismiss="modal" (click)="closeImportConfirmationModal()">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
