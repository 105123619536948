import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SubscriptionModule } from '../subscription.module';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (addSubscription: SubscriptionModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/subscription';
    return this.http.post<ResponseMessage>(_url, addSubscription);
  };

  public Update = (updateSubscription: SubscriptionModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/subscription';
    return this.http.put<ResponseMessage>(_url, updateSubscription);
  };

  public GetSubscriptionList = (body: string, page: number, size: number): Observable<SubscriptionModule[]> => {
    const _url: string = this.configuration.Server + 'admin/all/subscriptions/' + page + '/' + size;
    return this.http.post<SubscriptionModule[]>(_url, body);
  };

  public GetSubscriptionDropdown = (body: string, page: number, size: number): Observable<SubscriptionModule[]> => {
    const _url: string = this.configuration.Server + 'admin/Subscriptions/' + page + '/' + size;
    return this.http.post<SubscriptionModule[]>(_url, body);
  };

  public GetSingle = (body: SubscriptionModule): Observable<SubscriptionModule> => {
    const _url: string = this.configuration.Server + 'admin/subscription/details';
    return this.http.post<SubscriptionModule>(_url, body);
  };
}
