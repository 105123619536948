import { Configuration } from '../../../app.constants';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';

import { CountryModule } from '../country.module';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetCountryList = (body: string, page: number, size: number): Observable<CountryModule[]> => {
    const _url: string = this.configuration.Server + 'admin/all/countries/' + page + '/' + size;
    return this.http.post<CountryModule[]>(_url, body);
  };

  public GetCountryDropdown = (body: string, page: number, size: number): Observable<CountryModule[]> => {
    const _url: string = this.configuration.Server + 'admin/countries/' + page + '/' + size;
    return this.http.post<CountryModule[]>(_url, body);
  };

  public GetSingle = (body: CountryModule): Observable<CountryModule> => {
    const _url: string = this.configuration.Server + 'admin/country/details';
    return this.http.post<CountryModule>(_url, body);
  };

  public Add = (addCountry: CountryModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/country';
    return this.http.post<ResponseMessage>(_url, addCountry);
  };

  public Update = (updateCountry: CountryModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/country';
    return this.http.put<ResponseMessage>(_url, updateCountry);
  };
}
