import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';

import { Module } from '../../../serviceprovider/serviceprovider.module';
import { AreaModule } from '../../../venuemanagement/area/area.module';
import {
  LocationElementModule,
  LocationInventoryModule,
  LocationModule,
  LocationTaskModule,
  VenueLocationTouchlessFeedbackReason,
} from '../../location/location.module';
import { LocationTypeModule } from '../../locationtype/locationtype.module';
import { ImportLocationTemplateLog, LocationTemplateImport, LocationTemplateImportValidationResponse, LocationTemplateModule } from '../locationtemplate.module';

@Injectable({
  providedIn: 'root',
})
export class LocationTemplateService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetLocationTemplateList = (body: string, page: number, size: number): Observable<LocationTemplateModule[]> => {
    const _url: string = this.configuration.Server + 'venue/all/location/templates/' + page + '/' + size;
    return this.http.post<LocationTemplateModule[]>(_url, body);
  };

  public GetLocationTemplateDropDown = (
    body: string,
    page: number,
    size: number,
  ): Observable<LocationTemplateModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/templates/' + page + '/' + size;
    return this.http.post<LocationTemplateModule[]>(_url, body);
  };

  public GetSingle = (body: LocationTemplateModule): Observable<LocationTemplateModule> => {
    const _url: string = this.configuration.Server + 'venue/location/template/details';
    return this.http.post<LocationTemplateModule>(_url, body);
  };

  public Add = (body: LocationTemplateModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/template';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: LocationTemplateModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/venue/location/template';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetAllTasksByVenue = (body: LocationModule): Observable<LocationTaskModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/template/tasks';
    return this.http.post<LocationTaskModule[]>(_url, body);
  };

  public GetAllInventoriesByVenue = (body: LocationModule): Observable<LocationInventoryModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/template/inventories';
    return this.http.post<LocationInventoryModule[]>(_url, body);
  };

  public GetAllElementsByVenue = (body: LocationModule): Observable<LocationElementModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/template/elements';
    return this.http.post<LocationElementModule[]>(_url, body);
  };

  public GetFeatureConfigurations = (body: LocationModule): Observable<Module[]> => {
    const _url: string = this.configuration.Server + 'venue/location/template/feature/configurations';
    return this.http.post<Module[]>(_url, body);
  };

  public Delete = (body: LocationTemplateModule): Observable<LocationTemplateModule> => {
    const _url: string = this.configuration.Server + 'venue/location/template/delete';
    return this.http.post<LocationTemplateModule>(_url, body);
  };

  public ApplyTemplate = (body: LocationTemplateModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/apply/existing/template/locations';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public GetAllReasonsByVenue = (body: LocationModule): Observable<VenueLocationTouchlessFeedbackReason[]> => {
    const _url: string = this.configuration.Server + 'venue/location/template/feedback/reasons';
    return this.http.post<VenueLocationTouchlessFeedbackReason[]>(_url, body);
  };

  public GetAllAreasByVenue = (body: LocationTypeModule): Observable<AreaModule[]> => {
    const _url: string = this.configuration.Server + 'venue/location/type/areas';
    return this.http.post<AreaModule[]>(_url, body);
  };

   public GetAllImportLocationTemplates = (body: ImportLocationTemplateLog): Observable<LocationTemplateImport[]> => {
    const _url: string = this.configuration.Server + 'venue/import/venue/location/templates';
    return this.http.post<LocationTemplateImport[]>(_url, body);
  };

  public saveImportLocationTemplates = (body: any): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/import/location/template';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public validateSaveImportLocationTemplates = (body: any): Observable<LocationTemplateImportValidationResponse> => {
    const _url: string = this.configuration.Server + 'venue/validate/import/location/template';
    return this.http.post<LocationTemplateImportValidationResponse>(_url, body);
  };

  public GetAllImportedLocationTypes = (body: any, page: number, size: number): Observable<ImportLocationTemplateLog[]> => {
    const _url: string = this.configuration.Server + 'venue/import/location/template/log/' + page + '/' + size;
    return this.http.post<ImportLocationTemplateLog[]>(_url, body);
  };

  public downloadImportLogFile(body: any): Observable<any> {
    const url: string = this.configuration.Server + 'venue/download/import/location/template/log';
    return new Observable((obs) => {
      const oReq = new XMLHttpRequest();
      oReq.open('POST', url, true);
      oReq.setRequestHeader('content-type', 'application/json');
      oReq.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);
      oReq.responseType = 'arraybuffer';

      oReq.onload = function () {
        const arrayBuffer = oReq.response;
        const byteArray = new Uint8Array(arrayBuffer);
        obs.next(byteArray);
      };
      oReq.send(JSON.stringify(body));
    });
  }
  
}
