import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';
import { GenericEntity } from '../../shared/genericentity/genericentity';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { VenueElementConfigurationComponent } from './components/venueelementconfiguration.component';
import { VenueElementConfigurationRoutes } from './venueelementconfiguration.routes';

@NgModule({
  imports: [
    CommonModule,
    VenueElementConfigurationRoutes,
    FormsModule,

    NgxPaginationModule,
    NgMultiSelectDropDownModule,
    HomeModule,
  ],

  declarations: [VenueElementConfigurationComponent],
})
export class VenueElement extends GenericEntity {
  public ElementId: number;
  public VenueElementId: number;
  public VenueElementName: string;
  public Sequence: number;
  public ImageType: string;
  public ImageTypeName: string;
  public IsMasterElement: number;
  public MasterElementId: number;
  public VenueLocationTypeId: number;
  public VenueLocationTypeName: string;
  public VenueElementRowID: number;
  public VenueLocationTypeMaster: number;
  public isAllowToEdit: any;

  public IsImageRemoved: any;
  public ImageBase64String: string;
  public showElementImage: string;
  public VenueElementImageTypeName: string;
  public VenueElementImageType: string;
  public AttachmentURL: string;
}
