<div class="overlay">
  <div class="dialog">
    <h1 class="dialog-header">Reset Password</h1>
    <form #resetForm="ngForm" (ngSubmit)="onSubmit(resetForm)">
      <div class="form-group">
        <label for="password">New Password </label>
        <div class="input-group">
          <input
            type="password"
            id="password"
            name="password"
            class="form-control"
            ngModel
            required
            minlength="4"
            maxlength="49"
            pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
            #password="ngModel"
            [type]="passwordFieldType"
          />
          <span class="input-group-addon" (click)="togglePasswordVisibility()"
            ><i [ngClass]="showHidePassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i
          ></span>
        </div>
        <div *ngIf="password.invalid && (password.dirty || password.touched)" class="error-message">
          <div *ngIf="password.errors?.required">Password is required.</div>
          <div *ngIf="password.errors?.minlength">Password must be at least 4 characters long.</div>
          <div *ngIf="password.errors?.maxlength">Password cannot be more than 49 characters long.</div>
          <div *ngIf="password.errors?.pattern">
            Password must contain at least one capital letter, small letter and one number.
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm Password</label>
        <div class="input-group">
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            class="form-control"
            ngModel
            required
            pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$"
            #confirmPassword="ngModel"
            [type]="confirmPasswordFieldType"
          />
          <span class="input-group-addon" (click)="toggleConfirmPasswordVisibility()"
            ><i [ngClass]="showHidePassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i
          ></span>
        </div>
      </div>
      <div *ngIf="passwordsDoNotMatch && (password.dirty || confirmPassword.dirty)" class="error-message">
        Passwords do not match
      </div>
      <div class="actions">
        <button class="btn btn-type1 pull-right btn-cancel" type="button" (click)="onCancel(resetForm)">Cancel</button>
        <button class="btn btn-type btn-save" type="submit" [disabled]="resetForm.invalid">Save</button>
      </div>
    </form>
  </div>
</div>
