import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from '../../../app.constants';
import { InspectionModule } from '../inspectionlog.module';

@Injectable({
  providedIn: 'root',
})
export class InspectionLogService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetAll = (body: string, page: number, size: number): Observable<InspectionModule[]> => {
    const _url: string = this.configuration.Server + 'inspection/inspections/' + page + '/' + size;
    return this.http.post<InspectionModule[]>(_url, body);
  };

  public GetSingle = (body: InspectionModule): Observable<InspectionModule> => {
    const _url: string = this.configuration.Server + 'inspection/log/details';
    return this.http.post<InspectionModule>(_url, body);
  };
}
