import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { UserRoleRoutes } from './role.routes';
import { UserRoleComponent } from './components/role.component';
import { PermissionModule } from '../accessgrouppermission/accessgrouppermission.module';
import {
  TreeviewModule,
  TreeviewConfig,
  TreeviewI18nDefault,
  TreeviewI18n,
  DefaultTreeviewEventParser,
  TreeviewEventParser,
} from 'ngx-treeview';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, UserRoleRoutes, HomeModule, TreeviewModule],
  providers: [
    TreeviewConfig,
    // MatColorPickerComponent,
    { provide: TreeviewI18n, useClass: TreeviewI18nDefault },
    { provide: TreeviewEventParser, useClass: DefaultTreeviewEventParser },
  ],
  declarations: [UserRoleComponent],
})
export class UserRoleModule extends GenericEntity {
  public UserRoleId: number;
  public UserRoleCPId: string;
  public RoleName: string;
  public RoleCode: string;
  public ParentRoleId: number;
  public ParentRoleName: string;
  public UserRolePermissions: RelUserRolePermissions[];
  public ParentPermissionsList: ParentPermissions[];
}

export class RelUserRolePermissions extends GenericEntity {
  public RelUserRolePermissionId: any;
  public Permission: PermissionModule;
  public PAccess: any;
  public UserRoleId: number;
  public UserRoleCPId: string;
  public UserRoleName: string;
  public ParentPermissionsList: ParentPermissions[];
}

export class ParentPermissions extends GenericEntity {
  public ParentPermissionId: number;
  public PermissionName: string;
  public PermissionDisplayName: string;
  public ParentId: number;
  public ParentPName: string;
  public ParentPDisplayName: string;
  public PermissionTypeCode: string;
  public PermissionTypeName: string;
  public PermissionSubTypeCode: string;
  public PermissionSubTypeName: string;
  public PermissionSequence: number;
  public PermissionDescription: string;
  public PAccess: number;
  public ChildPermissionsList: ChildPermissions[];
  public UserTypeId: number;
  public UserType: string;
}

export class ChildPermissions extends GenericEntity {
  public ChildPermissionId: number;
  public PermissionName: string;
  public PermissionDisplayName: string;
  public ParentId: number;
  public ParentPName: string;
  public ParentPDisplayName: string;
  public PermissionTypeCode: string;
  public PermissionTypeName: string;
  public PermissionSubTypeCode: string;
  public PermissionSubTypeName: string;
  public PermissionSequence: number;
  public PermissionDescription: string;
  public PAccess: any;
  public UserTypeId: number;
  public UserType: string;
}
