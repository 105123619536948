import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Configuration } from '../app.constants';
import { HomeModule } from '../home/home.module';
import { GenericEntity } from '../shared/genericentity/genericentity';
import { VenueModule } from '../venuemanagement/venue/venue.module';
import { LoginComponent } from './components/login.component';
import { LoginService } from './components/login.http.service';
import { LoginRoutes } from './login.routes';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, LoginRoutes, HomeModule],
  declarations: [LoginComponent],
})
export class LoginModule {
  static forRoot(): ModuleWithProviders<LoginModule> {
    return {
      ngModule: LoginModule,
      providers: [Configuration, LoginService],
    };
  }
}

export class Login {
  public username: string;
  public password: string;
  public grant_type: string;
  public ip_address: string;
  public user_id: number;
  public rememberMe: boolean;
}

export class UserTokenAuthentication extends GenericEntity {
  public UserTokenAuthenticationId: number;
  public Venue: VenueModule;
  public Token: string;
}
