import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { AuthState } from 'src/app/store/auth.state';
import { Configuration } from '../../app.constants';
import { UtilitiesService } from '../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../shared/directives/loader.service';
// import { User } from '../../usermanagement/user/user.model';
import { ImportDataModule, ImportTypes } from '../importdata.module';
import { ImportDataService } from './importdata.http.service';

@Component({
  selector: 'import-data',
  templateUrl: 'importdata.component.html',
  styleUrls: ['./importdata.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ImportDataService, UtilitiesService],
})
export class ImportDataComponent implements OnInit, OnDestroy {
  // currentUser: User;
  myQuerySub: Subscription;
  @ViewChild('ImportTransactionFile') fileInputVariable: any;

  public transactions: ImportDataModule[] = [];
  public data: ImportDataModule = new ImportDataModule();

  file: File = null;
  page = 1;
  size = 10;
  count = 0;
  searchString = '';
  public pageSizes: any[] = [];

  isFileSelected = true;
  isReqComplete = false;
  multiFiles: any[] = [];
  download: string;

  activeNavigationUrls: any;
  activeNavigationName: string;
  public pageState = [{ pageName: 'importdataForm', page: undefined, size: undefined }];

  constructor(
    private importDataService: ImportDataService,
    private loaderService: LoaderService,
    private utilitiesService: UtilitiesService,
    public authState: AuthState,
    private configuration: Configuration,
  ) {
    // this.currentUser = this.authState.getCurrentUser();
  }

  ngOnInit() {
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;

    this.getPageState();
    this.interval();
    this.activeNavigationUrls = document.getElementsByClassName('treeview');

    for (let i = 0; i < this.activeNavigationUrls.length; i++) {
      this.activeNavigationName = this.utilitiesService.removeInnerSpaces(this.activeNavigationUrls[i].textContent);

      if (this.activeNavigationName.toLowerCase() != 'import data') {
        this.activeNavigationUrls[i].classList.remove('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'none';
        this.activeNavigationUrls[i].classList.remove('active');
      }
      if (this.activeNavigationName.toLowerCase() == 'import data') {
        this.activeNavigationUrls[i].classList.add('menu-open');
        this.activeNavigationUrls[i].lastElementChild.style.display = 'block';
        this.activeNavigationUrls[i].classList.add('active');
      }
    }
  }

  public canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public async interval() {
    this.myQuerySub = interval(1000 * 60).subscribe(() => {
      this.getAllData('', this.page, this.size);
    });
  }

  ngOnDestroy() {
    if (this.myQuerySub) {
      this.myQuerySub.unsubscribe();
    }
  }

  public pageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllData(this.searchString, this.page, this.size);
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.getAllData(this.searchString, this.page, this.size);
    } else {
      this.getAllData(this.searchString, this.page, this.size);
    }
  }

  public getAllData(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 1, ImportType: 'USER' };

    this.importDataService.GetAllData(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.transactions = data;
        if (this.transactions.length < 0 && this.transactions[0].Count / 10 < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log('userRoleService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
        this.loaderService.display(false);
      },
      () => (this.count = this.transactions[0] ? this.transactions[0].Count : 0),
    );
  }

  public fileChange(event: any) {
    this.data.ImportTransactionFiles = [];
    for (let i = 0; i < event.target.files.length; i++) {
      const filename = event.target.files[i].name;
      let name = filename.split('');
      for (let n = 0; n < name.length; n++) {
        if (
          name[n] == '<' ||
          name[n] == '>' ||
          name[n] == '%' ||
          name[n] == ':' ||
          name[n] == '?' ||
          name[n] == '/' ||
          name[n] == '*' ||
          name[n] == '"' ||
          name[n] == '|' ||
          name[n] == '#' ||
          name[n] == '{' ||
          name[n] == '}' ||
          name[n] == '&' ||
          name[n] == '='
        ) {
          this.utilitiesService.smmodal(
            'Import Data',
            'A file name cannot contain any of the further characters: / : * ? " < > | # { } % & =',
          );
          event.target.value = '';
          return;
        }
      }

      const ext = filename.split('.')[1];
      if (ext == 'xls' || ext == 'xlsx' || ext == 'xlsm') {
        const index = i + 1;
        this.multiFiles.push(event.target.files[i]);
        // const filename = event.target.files[i].name;
        this.data.ImportTransactionFiles.push({ SerialNumber: index, fileName: filename });
        if (this.multiFiles.length > 0) {
          this.isFileSelected = false;
        }
      } else {
        this.utilitiesService.smmodal('Import Data', 'Please upload file of type .xls, .xlsx, .xlsm.');
        event.target.value = '';
      }
    }
  }

  public fileReset() {
    this.multiFiles = [];
    this.file = null;
    this.isFileSelected = true;
    if (this.fileInputVariable != undefined) {
      this.fileInputVariable.nativeElement.value = '';
    }
    this.data.ImportTransactionFiles = [];
  }

  public AddFile() {
    if (this.data != null) {
      if (this.multiFiles) {
        let that = this;
        this.importDataService
          .ImportTransactionFiles(this.multiFiles, 'workorder/import')
          .then(function (_response) {
            // console.log(_response);
            that.loaderService.display(false);
            that.utilitiesService.smmodal('Import Data', 'Data Imported successfully!');
            that.getAllData('', 1, that.size);
            that.multiFiles = [];
            if (that.fileInputVariable != undefined) {
              that.fileInputVariable.nativeElement.value = '';
            }
            that.isFileSelected = true;
          })
          .catch((err) => {
            console.log(err);
            that.loaderService.display(false);

            that.getAllData('', 1, that.size);
            that.multiFiles = [];
            if (that.fileInputVariable != undefined) {
              that.fileInputVariable.nativeElement.value = '';
            }
            that.isFileSelected = true;
          });
      }
    }
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.authState.storePageState(this.pageState);
    this.getAllData(this.searchString, this.page, this.size);
  }

  // Show message when data not available in list view
  public hasData(): boolean {
    return this.transactions != null && this.transactions.length > 0;
  }

  public downloadTransactionFiles(transactionId: number) {
    if (transactionId) {
      const obj = this.transactions.filter((tran) => tran.Id == transactionId);
      if (obj[0] && obj[0].LogFiles && obj[0].LogFiles.length > 0) {
        for (let i = 0; i < obj[0].LogFiles.length; i++) {
          if (obj[0].LogFiles[i].fileName != null) {
            this.download =
              this.configuration.Server + '/importfiles/' + obj[0].Identifier + '/logs/' + obj[0].LogFiles[i].fileName;
            const fileName = obj[0].Identifier;

            const a = document.createElement('a');
            a.href = this.download;
            a.target = '_blank';
            a.download = fileName;
            a.click();
          }
        }
      }
    }
  }

  //// To get excel details
  public exportToExcel() {
    this.loaderService.display(true);

    this.importDataService.DownloadData(ImportTypes.USER).subscribe(
      (result) => {
        // console.log(result);
        this.loaderService.display(false);
        const date = new Date();
        let month = date.getMonth() + 1;
        const timestamp =
          date.getFullYear().toString() +
          month.toString() +
          date.getDate().toString() +
          date.getHours().toString() +
          date.getMinutes().toString() +
          date.getMilliseconds().toString();
        const filename = timestamp.toString() + '.xlsx';
        this.downloadFile(result, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', filename);
      },
      (error) => {
        console.log(
          'importDataService DownloadData Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public downloadFile(blob: any, filetype: string, filename: string) {
    const binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: filetype })); // <-- work with blob directly

    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
  }
}
