import { Component, Injectable, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import DOMPurify from 'dompurify';
import {
  DownlineTreeviewItem,
  OrderDownlineTreeviewEventParser,
  TreeviewComponent,
  TreeviewConfig,
  TreeviewEventParser,
  TreeviewItem,
} from 'ngx-treeview';
import { Subscription, interval, timer } from 'rxjs';
import { ImportDataService } from 'src/app/importdata/components/importdata.http.service';
import { ImportDataModule, ImportTypes } from 'src/app/importdata/importdata.module';
import { AuthState } from 'src/app/store/auth.state';
import { Configuration } from '../../../app.constants';
import {
  LocationSensor,
  SensorLite,
  SensorModule,
  SensorTypeLite,
} from '../../../corporationmanagement/sensor/sensor.module';
import { SensorTypeService } from '../../../corporationmanagement/sensortype/components/sensortype.http.service';
import { SensorTypeModule } from '../../../corporationmanagement/sensortype/sensortype.module';
import { RestroomOverviewService } from '../../../restroomoverview/components/restroomoverview.http.service';
import {
  FeatureConfiguration,
  Module,
  ModuleFeature,
  ModuleFeatureType,
} from '../../../serviceprovider/serviceprovider.module';
import { CompressImageService } from '../../../shared/components/utilityservices/compressimageservice';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { GenericEntity } from '../../../shared/genericentity/genericentity';
import { VenueTouchlessFeedbackConfig } from '../../../touchlessfeedbackconfiguration/touchless-feedback-config/touchless-feedback-config.module';
import { User } from '../../../usermanagement/user/user.model';
import { AreaModule } from '../../../venuemanagement/area/area.module';
import { BuildingModule } from '../../../venuemanagement/building/building.module';
import { GatewayModule } from '../../../venuemanagement/gateway/getway.module';
import { LevelModule } from '../../../venuemanagement/level/level.module';
import { VenueService } from '../../../venuemanagement/venue/components/venue.http.service';
import { InspectionScoreFactor, VenueModule } from '../../../venuemanagement/venue/venue.module';
import { BeaconModule } from '../../beacon/beacon.module';
import { BeaconService } from '../../beacon/components/beacon.http.service';
import { LocationTemplateService } from '../../locationtemplate/components/locationtemplate.http.service';
import { LocationTemplateModule } from '../../locationtemplate/locationtemplate.module';
import { LocationTypeService } from '../../locationtype/components/locationtype.http.service';
import { LocationTypeModule } from '../../locationtype/locationtype.module';
import { ShiftService } from '../../shift/components/shift.http.service';
import { ZoneService } from '../../zone/components/zone.http.service';
import { ZoneModule } from '../../zone/zone.module';
import {
  LocationBeacon,
  LocationElementModule,
  LocationGateway,
  LocationGenderType,
  LocationInventoryModule,
  LocationModule,
  LocationSensorDetails,
  LocationTaskModule,
  VenueLocationTouchlessFeedbackReason,
  WorkorderCreatorAttachments,
} from '../location.module';
import { LocationService } from './location.http.service';

@Injectable({
  providedIn: 'root',
})
export class config extends TreeviewConfig {
  hasAllCheckBox = true;
  hasFilter = false;
  hasCollapseExpand = true;
  maxHeight = 400;
}

@Component({
  selector: 'location.component',
  templateUrl: 'location.component.html',
  styleUrls: ['./location.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    LocationService,
    UtilitiesService,
    ZoneService,
    BeaconService,
    VenueService,
    ShiftService,
    LocationTypeService,
    LocationTemplateService,
    SensorTypeService,
    RestroomOverviewService,
    ImportDataService,
    { provide: TreeviewEventParser, useClass: OrderDownlineTreeviewEventParser },
    { provide: TreeviewConfig, useClass: config },
  ],
})
export class LocationComponent implements OnInit {
  myQuerySub: Subscription;
  currentUser: User;

  @ViewChild('geojsonFileInput') geojsonFileInputVariable: any;
  @ViewChild('svgFileInput') svgFileInputVariable: any;
  @ViewChild('treeviewComponentTemplate', { static: true }) treeviewComponentTemplate: TreeviewComponent;
  @ViewChild('caFileInput', { static: true }) caFileInputVariable: any;
  @ViewChild(TreeviewComponent) treeviewComponent: TreeviewComponent;
  @ViewChild('ImportTransactionFile') fileInputVariable: any;

  public locationTypes: LocationTypeModule[] = [];
  public venue: VenueModule = new VenueModule();
  public venues: VenueModule[] = [];
  public buildings: BuildingModule[] = [];
  public levels: LevelModule[] = [];
  public zones: ZoneModule[] = [];

  public locationBeacons: LocationBeacon[] = [];

  public listbeacons: LocationModule[] = [];

  public locations: LocationModule[] = [];
  public location: LocationModule = new LocationModule();

  public zonesTemplate: ZoneModule[] = [];

  public levelsTemplate: LevelModule[] = [];

  public locationGenderTypes: LocationGenderType[] = [];
  public locationTemplates: LocationTemplateModule[] = [];
  public locationTemplate: LocationTemplateModule = new LocationTemplateModule();
  public locationTemplateModalObj: LocationTemplateModule = new LocationTemplateModule();

  public sensorTypes: SensorTypeModule[] = [];
  public locationSensors: LocationSensor[] = [];
  public filteredSensors: LocationSensor[] = [];

  public locationSensorDetails: LocationSensorDetails[] = [];
  public locationSensorDetailsDisplay: LocationSensorDetails[] = [];
  public NextRestroomDirections = [
    { id: 'Up', name: 'Up' },
    { id: 'Down', name: 'Down' },
    { id: 'Left', name: 'Left' },
    { id: 'Right', name: 'Right' },
    { id: 'Left_Right', name: 'Left and Right' },
    { id: 'UP_Down', name: 'UP and Down' },
  ];
  public Modules: Module[] = [];
  public feedbackIdentifiers: VenueTouchlessFeedbackConfig[] = [];
  public areas: AreaModule[] = [];
  public scoreFactors: InspectionScoreFactor[] = [];

  public page = 1;
  public size = 10;
  public count = 0;

  public logpage = 1;
  public logsize = 10;
  public logcount = 0;

  public pageSizes: any[] = [];
  public searchString = '';
  public buildingDisabled = true;
  public levelDisabled = true;
  public zoneDisabled = true;
  public levelDisabledTemplate = false;
  public zoneDisabledTemplate = false;
  public isReqComplete = false;
  public pageSize = 10;
  public pageNumbers: any[];
  public venueName: string;
  public buildingName: string;
  public restRoom: string;
  public venueId: number;
  public locationId: number;
  public isLocationBeacons = false;
  public isLocationSensors = false;
  public listviewtypes: any[];
  public LocationStatusList: any[];
  public viewType: any = null;
  public columnName: string;
  public sortOrder: string;
  public columnSortOrder = 'ASC';
  public selectedColumnName: string;
  public selectedSensorType: any;
  public SensorThreshold: any;
  public SensorWarnPercentage: any;
  public file: File = null;
  public multiFiles: any[] = [];
  public svgFile: File = null;
  public IsSVGTable = false;
  public isAdd = false;
  public isSensorTypeSelected = false;
  public isGuestFeedback = false;
  public isPeopleCounterRestroom = false;
  public isAirQualityDetection = false;
  public isVendingMachine = false;
  public isThresholdWarnShow = false;

  public sId: any;
  public venueBind: number;
  public selectedSVGFile: string;
  public svgModal = false;
  public isBadgeShow = 0;

  public isLocationGateways = false;
  public isLocationTasks = false;
  public isLocationInventories = false;
  public isLocationElements = false;
  public isLocationTasksTemplate = false;
  public isLocationInventoriesTemplate = false;
  public isLocationElementsTemplate = false;
  public totalElementWeightage = 0;
  public totalTemplateElementWeightage = 0;
  public isFeedbackReason = false;
  public isFeedbackReasonTemplate = false;
  public isAllFeatureConfigurationChecked = false;
  public isAllFeatureConfigurationCheckedTemplate = false;
  public isCheckBoxVisible = false;
  public isCheckBoxVisibleTemplate = false;
  public enableLocationStatus = false;
  public pageState = [{ pageName: 'locationForm', page: this.page, size: this.size, viewType: this.viewType }];

  public isLocationTaskShow = false;
  public isLocationInventoryShow = false;
  public isLocationElementShow = false;
  public isLocationBeaconShow = false;
  public isLocationGetwayShow = false;
  public isLocationAreaShow = false;
  public retriveModules: any[] = [];

  public items: TreeviewItem[];
  public itemsTemplate: TreeviewItem[];
  public config: {} = {};
  public editsignagePageUrl = '';
  public isCleaningAlert = false;
  public fileExtension: any;
  public isStatus = false;
  public templateModal = false;
  public scrollTop: any;
  public editTouchlessFeedbackPageUrl = '';
  public editCleaningAlertRequestPageUrl = '';
  public isFeedbackReasonshow = false;
  public isFeedbackReasonshowTemplate = false;
  public isAllTaskChecked = false;
  public isAllInventoryChecked = false;
  public isAllElementChecked = false;
  public isAllTemplateTaskChecked = false;
  public isAllTemplateInventoryChecked = false;
  public isAllTemplateElementChecked = false;

  public caAttachments: WorkorderCreatorAttachments[] = [];
  public attachmentRowId: number;
  public attachmentIndex = -1;
  public caAttachmentImgSrc: any = null;
  public caAttachmentFile: any;
  public caAttachmentFileType: string;
  public caAttachFile = null;
  public caAttachmentPopUp = false;
  public isCAAttachmentUploadAllow = false;
  public DefaultImage = 'assets/no-image.jpg';
  public CreatorComments: any;
  public caCompressImage = null;
  public feedbackIdentifierdisabled = false;
  public isGuestFeedbackSelected = false;
  public data: any;
  public isFileSelected = true;
  public impExpDataPopUp = false;
  public exelFiles: any[] = [];
  public transactions: ImportDataModule[] = [];
  public download: string;

  constructor(
    public authState: AuthState,
    //public authState: AuthState,
    public utilitiesService: UtilitiesService,
    private configuration: Configuration,
    private locationService: LocationService,
    private sensorTypeService: SensorTypeService,
    private venueService: VenueService,
    private shiftService: ShiftService,
    private locationTypeService: LocationTypeService,
    private locationTemplateService: LocationTemplateService,
    private restroomOverviewService: RestroomOverviewService,
    private compressImage: CompressImageService,
    private loaderService: LoaderService,
    private importDataService: ImportDataService,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.location.Zone = new ZoneModule();
    this.location.Building = new BuildingModule();
    this.location.Building.Venue = new VenueModule();
    this.location.Building.Level = new LevelModule();

    this.locationTemplateModalObj = new LocationTemplateModule();
    this.locationTemplateModalObj.Zone = new ZoneModule();
    this.locationTemplateModalObj.Building = new BuildingModule();
    this.locationTemplateModalObj.Building.Venue = new VenueModule();
    this.locationTemplateModalObj.Building.Level = new LevelModule();

    this.config = {
      hasAllCheckBox: false,
      hasFilter: false,
      hasCollapseExpand: false,
      decoupleChildFromParent: false,
      maxHeight: 500,
    };
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;

    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.LocationStatusList = this.utilitiesService.LocationStatusList;
    this.feedbackIdentifiers = [];
    this.getModulesData();
    this.getAllLocationGenderTypes('', -1, -1);
    this.getAllLocationTypes();
    this.getAllLocationTemplates();
    this.getAllScoreFactors();
    this.getAllFeedbackConfigurationIdentifiers();
    this.getAllSensorTypes();
    this.GetModuleList(this.authState.getStoredContextVenueId(), 0, 0);

    this.venueBind = this.authState.getStoredContextVenueId();
    if (this.venueBind != null) {
      this.getVenueById(this.venueBind, false);
    }
    this.getPageState();
  }

  public getModulesData() {
    this.isLocationTaskShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_TASK');
    this.isLocationInventoryShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_INVENTORIES');
    if (
      this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_BEACON') ||
      this.utilitiesService.checkVenueFeatureConfiguration('INS_VENUE_TAG_BEACON')
    ) {
      this.isLocationBeaconShow = true;
    }
    this.isLocationElementShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_ELEMENT');
    this.isCleaningAlert = this.utilitiesService.checkVenueFeatureConfiguration('CA_CREATION_MANUAL_FROM_WEB');
    this.isCAAttachmentUploadAllow = this.utilitiesService.checkVenueFeatureConfiguration('ALLOW_ATTACHMENTS_FROM_WEB');
    this.isLocationAreaShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_AREA');
  }

  canAccess(feature: string, access: string) {
    return this.authState.canAccess(feature, access);
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.viewType = this.pageState[0].viewType;
      this.getAllLocations(this.searchString, this.page, this.size, this.viewType, '');
    } else {
      this.getAllLocations(this.searchString, this.page, this.size, this.viewType, '');
    }
  }

  public pageChange(p: number) {
    this.page = p;
    this.getAllBeaconsByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
  }

  public LocationPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType, '');
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType, '');
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType == null) {
      this.viewType = 1;
    }
    this.pageState[0].viewType = this.viewType;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.authState.storePageState(this.pageState);
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType, '');
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName != this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order == 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType, '');
  }

  public setAddForm() {
    this.searchString = '';
    this.locations = null;
    this.count = 0;
    // this.checkCount = 0;
    this.isAdd = true;
    this.location = new LocationModule();
    this.location.Zone = new ZoneModule();
    this.location.Building = new BuildingModule();
    this.location.Building.Venue = new VenueModule();
    this.location.Building.Level = new LevelModule();
    this.IsSVGTable = false;
    this.selectedSVGFile = null;
    this.isStatus = false;
    this.getVenueById(this.venueBind, true);
    setTimeout(() => {
      const element = document.getElementById('locationName');
      element.focus();
    }, 1000);
    this.location.LocationStatus = this.LocationStatusList[0].id;
    this.GetModuleList(this.authState.getStoredContextVenueId(), 0, 0);
    window.scrollTo(0, 0);
    this.feedbackIdentifierdisabled = false;
  }

  public setUpdateForm() {
    this.searchString = '';
    this.locations = null;
    this.count = 0;
    this.isCheckBoxVisible = false;
    this.getAllVenues('', -1, -1);
  }

  public hasData(): boolean {
    return this.locations ? this.locations != null && this.locations.length > 0 : false;
  }

  public hasVenueBeaconsData(): boolean {
    return this.location.LocationBeacons != null && this.location.LocationBeacons.length > 0;
  }

  public hasVenueSensorsData(): boolean {
    return this.location.LocationSensors
      ? this.location.LocationSensors != null && this.location.LocationSensors.length > 0
      : false;
  }

  public clearFormData(locationForm: NgForm) {
    locationForm.reset();
    this.searchString = '';
    this.buildings = [];
    this.zones = [];
    this.zoneDisabled = true;
    this.location.LocationBeacons = [];
    this.isLocationBeacons = false;
    this.isLocationSensors = false;
    // this.curPage = 1;
    // this.curPageOfSensor = 1;
    this.levels = [];
    this.levelDisabled = true;
    this.filteredSensors = [];
    this.locationSensorDetailsDisplay = [];
    this.SensorThreshold = null;
    this.SensorWarnPercentage = null;
    this.multiFiles = [];
    this.isSensorTypeSelected = false;
    this.isAdd = false;

    this.isVendingMachine = false;
    // this.isPeopleCounterRestroom = false;
    this.isAirQualityDetection = false;
    this.isGuestFeedback = false;
    this.isThresholdWarnShow = false;
    this.svgFile = null;
    this.selectedSVGFile = null;
    this.IsSVGTable = false;
    this.isLocationGateways = false;
    this.isBadgeShow = 0;
    this.isFeedbackReasonshow = false;

    this.items = [];
    this.Modules = [];
    this.isCheckBoxVisible = false;
    this.editsignagePageUrl = '';
    this.location = new LocationModule();
    this.location.Building = new BuildingModule();
    this.location.Building.Venue = new VenueModule();
    this.location.Building.Level = new LevelModule();
    this.location.Zone = new ZoneModule();
    this.editTouchlessFeedbackPageUrl = '';
    this.editCleaningAlertRequestPageUrl = '';
    this.totalElementWeightage = 0;
    this.selectedSensorType = null;
    this.isGuestFeedbackSelected = false;
    window.scrollTo(0, 0);
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType, '');
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllLocations(this.searchString, this.page, this.size, this.viewType, '');
  }

  public locationsSearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllLocations(searchString, 1, this.size, this.viewType, '');
  }

  public addLocation(locationForm: NgForm, saveType: string) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (locationForm.status == 'INVALID' && locationForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Location', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    const that = this;
    const payload: LocationBeacon[] = [];
    const payloadSensor: LocationSensor[] = [];
    const gatewayPayload: LocationGateway[] = [];

    const taskPayload: LocationTaskModule[] = [];
    const inventoryPayload: LocationInventoryModule[] = [];
    const feedbackPayload: VenueLocationTouchlessFeedbackReason[] = [];
    const elementPayload: LocationElementModule[] = [];
    const _locationDetails: LocationModule = new LocationModule();

    _locationDetails.LocationName = this.utilitiesService.removeInnerSpaces(this.location.LocationName);
    if (_locationDetails.LocationName == null || _locationDetails.LocationName == '') {
      this.location.LocationName = _locationDetails.LocationName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _locationDetails.Longitude = this.location.Longitude;
    _locationDetails.Latitude = this.location.Latitude;
    _locationDetails.Altitude = this.location.Altitude;
    _locationDetails.AddedBy = this.currentUser.UserId;
    if (this.isStatus == true) {
      _locationDetails.LocationType = this.location.LocationType;
    } else {
      _locationDetails.LocationType = 'B';
    }
    _locationDetails.LevelId = this.location.Building.Level.LevelId;
    _locationDetails.NextRestroomDistance = this.location.NextRestroomDistance;
    _locationDetails.NextRestroomDirection = this.location.NextRestroomDirection;
    _locationDetails.LocationStatus = this.location.LocationStatus;

    for (let i = 0; i < this.location.LocationBeacons.length; i++) {
      const _locationBeacons: LocationBeacon = new LocationBeacon();
      _locationBeacons.Beacon = new BeaconModule();
      _locationBeacons.Beacon.BeaconId = this.location.LocationBeacons[i].Beacon.BeaconId;
      _locationBeacons.IsActive = this.location.LocationBeacons[i].IsActive ? 1 : 0;
      payload.push(_locationBeacons);
    }

    for (let i = 0; i < this.location.LocationGateways.length; i++) {
      const _locationGateways: LocationGateway = new LocationGateway();
      _locationGateways.Gateway = new GatewayModule();
      _locationGateways.Gateway.GatewayId = this.location.LocationGateways[i].Gateway.GatewayId;
      _locationGateways.IsActive = this.location.LocationGateways[i].IsActive ? 1 : 0;
      gatewayPayload.push(_locationGateways);
    }

    for (let i = 0; i < this.location.LocationTasks.length; i++) {
      const _locationTasks: LocationTaskModule = new LocationTaskModule();
      this.location.LocationTasks[i].IsActive = this.location.LocationTasks[i].IsActive ? 1 : 0;

      if (this.location.LocationTasks[i].IsActive == 1) {
        _locationTasks.TaskId = this.location.LocationTasks[i].TaskId;
        _locationTasks.IsActive = this.location.LocationTasks[i].IsActive ? 1 : 0;
        _locationTasks.VenueTaskId = this.location.LocationTasks[i].VenueTaskId;
        taskPayload.push(_locationTasks);
      }
    }

    for (let i = 0; i < this.location.LocationInventories.length; i++) {
      const _locationInventories: LocationInventoryModule = new LocationInventoryModule();
      this.location.LocationInventories[i].IsActive = this.location.LocationInventories[i].IsActive ? 1 : 0;

      if (this.location.LocationInventories[i].IsActive == 1) {
        _locationInventories.InventoryId = this.location.LocationInventories[i].InventoryId;
        _locationInventories.IsActive = this.location.LocationInventories[i].IsActive ? 1 : 0;
        _locationInventories.VenueInventoryId = this.location.LocationInventories[i].VenueInventoryId;
        inventoryPayload.push(_locationInventories);
      }
    }
    _locationDetails.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
    for (let i = 0; i < this.location.LocationFeedbackReasons.length; i++) {
      const _feedback: VenueLocationTouchlessFeedbackReason = new VenueLocationTouchlessFeedbackReason();
      this.location.LocationFeedbackReasons[i].IsActive = this.location.LocationFeedbackReasons[i].IsActive ? 1 : 0;

      if (this.location.LocationFeedbackReasons[i].IsActive == 1) {
        _feedback.VenueFeedbackReasonId = this.location.LocationFeedbackReasons[i].VenueFeedbackReasonId;
        _feedback.IsActive = this.location.LocationFeedbackReasons[i].IsActive ? 1 : 0;
        _feedback.VenueLocationFeedbackReasonId =
          this.location.LocationFeedbackReasons[i].VenueLocationFeedbackReasonId;
        _feedback.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
        feedbackPayload.push(_feedback);
      }
    }

    if (
      this.location.LocationElements != null &&
      this.location.LocationElements.length > 0 &&
      this.isLocationElements &&
      this.isLocationElementShow
    ) {
      let isElementChecked = false;
      this.totalElementWeightage = 0;
      this.location.LocationElements.forEach(function (element) {
        if (element.IsActive == true) {
          that.totalElementWeightage += Number(element.ElementWeightage);
          isElementChecked = true;
        }
      });

      if (isElementChecked && (this.totalElementWeightage > 100 || this.totalElementWeightage < 100)) {
        this.utilitiesService.smmodal('Location', 'Total weight of elements should equal 100%.');
        this.isReqComplete = false;
        this.loaderService.display(false);

        let elementScroll = document.getElementById('elementConfig');
        if (elementScroll) {
          elementScroll.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        return;
      }
    }

    for (let i = 0; i < this.location.LocationElements.length; i++) {
      const _locationElements: LocationElementModule = new LocationElementModule();
      this.location.LocationElements[i].IsActive = this.location.LocationElements[i].IsActive ? 1 : 0;

      if (this.location.LocationElements[i].IsActive == 1) {
        _locationElements.ElementId = this.location.LocationElements[i].ElementId;
        _locationElements.IsActive = this.location.LocationElements[i].IsActive ? 1 : 0;
        _locationElements.VenueElementId = this.location.LocationElements[i].VenueElementId;
        _locationElements.ElementWeightage = this.location.LocationElements[i].ElementWeightage;

        if (this.location.LocationElements[i].Area != null && this.location.LocationElements[i].Area.AreaId > 0) {
          const _locationAreaElements: AreaModule = new AreaModule();
          _locationElements.Areas = [];
          _locationAreaElements.AreaId = this.location.LocationElements[i].Area.AreaId;
          _locationAreaElements.AreaName = this.location.LocationElements[i].Area.AreaName;
          _locationAreaElements.IsActive = 1;
          _locationElements.Areas.push(_locationAreaElements);
        }

        if (
          this.location.LocationElements[i].InspectionScoreFactor != null &&
          this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId > 0
        ) {
          _locationElements.InspectionScoreFactor = new InspectionScoreFactor();
          _locationElements.InspectionScoreFactor.InspectionScoreFactorId =
            this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId;
          _locationElements.InspectionScoreFactor.ScoreFactorName =
            this.location.LocationElements[i].InspectionScoreFactor.ScoreFactorName;
          _locationElements.InspectionScoreFactor.IsActive = 1;
        } else {
          if (this.isLocationElementShow) {
            this.loaderService.display(false);
            this.isReqComplete = false;
            this.utilitiesService.smmodal('Location', 'Please select score factor.');
            return;
          }
        }
        elementPayload.push(_locationElements);
      }
    }

    if (this.location.LocationSensors != null && this.location.LocationSensors.length > 0) {
      for (let i = 0; i < this.location.LocationSensors.length; i++) {
        if (this.location.LocationSensors[i].IsActive == 1) {
          const _locationSensors: LocationSensor = new LocationSensor();
          _locationSensors.Sensor = new SensorLite();
          _locationSensors.Sensor.SensorType = new SensorTypeLite();
          const _location: LocationModule = new LocationModule();
          _locationSensors.Sensor = new SensorLite();

          _locationSensors.LocationSensorId = this.location.LocationSensors[i].LocationSensorId;

          _locationSensors.Sensor.SensorId = this.location.LocationSensors[i].Sensor.SensorId;
          _locationSensors.Sensor.SensorType = new SensorTypeLite();
          _locationSensors.Sensor.SensorType.SensorTypeCode =
            this.location.LocationSensors[i].Sensor.SensorType.SensorTypeCode;
          _locationSensors.IsActive = this.location.LocationSensors[i].IsActive ? 1 : 0;
          _location.LocationId = that.location.LocationId;
          // _locationSensors.Location = _location;
          _locationSensors.AddedBy = that.currentUser.UserId;
          _locationSensors.Threshold = this.location.LocationSensors[i].Threshold;
          _locationSensors.WarnPercentage = this.location.LocationSensors[i].WarnPercentage;

          payloadSensor.push(_locationSensors);
        }
      }
    }

    _locationDetails.LocationTasks = taskPayload;
    _locationDetails.LocationInventories = inventoryPayload;
    _locationDetails.LocationFeedbackReasons = feedbackPayload;
    _locationDetails.LocationElements = elementPayload;

    _locationDetails.LocationGateways = gatewayPayload;
    _locationDetails.LocationBeacons = payload;
    _locationDetails.LocationSensors = payloadSensor;

    _locationDetails.Zone = new ZoneModule();
    _locationDetails.Zone.ZoneId = this.location.Zone.ZoneId;

    _locationDetails.Building = new BuildingModule();
    _locationDetails.Building.Venue = new VenueModule();
    _locationDetails.Building.BuildingId = this.location.Building.BuildingId;
    _locationDetails.Building.Venue.VenueId = this.location.Building.Venue.VenueId;
    _locationDetails.AllowGender = this.location.AllowGender ? 1 : 0;

    let modulePayload: Module[] = [];
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let module = new Module();
          module.ModuleId = it.value;
          module.ModuleFeatures = [];

          it.children.forEach(function (mf) {
            if (mf.checked || mf.checked == undefined) {
              let mFeature = new ModuleFeature();
              mFeature.ModuleFeatureId = mf.value;
              mFeature.ModuleFeatureTypes = [];

              mf.children.forEach(function (mft) {
                if (mft.checked || mft.checked == undefined) {
                  let mFeatureType = new ModuleFeatureType();
                  mFeatureType.FeatureTypeCode = mft.value;
                  mFeatureType.FeatureConfigurations = [];

                  mft.children.forEach(function (fc) {
                    if (fc.checked || fc.checked == undefined) {
                      let fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId = fc.value;
                      fConfig.IsActive = 1;

                      mFeatureType.FeatureConfigurations.push(fConfig);
                    }
                  });
                  mFeature.ModuleFeatureTypes.push(mFeatureType);
                }
              });
              module.ModuleFeatures.push(mFeature);
            }
          });
          modulePayload.push(module);
        }
      });
    }
    _locationDetails.Modules = modulePayload;
    _locationDetails.VenueLocationTypeId = this.location.VenueLocationTypeId;
    _locationDetails.CapacityCount = this.location.CapacityCount;
    _locationDetails.AvailabilityPercentage = this.location.AvailabilityPercentage;
    // console.log(JSON.stringify(_locationDetails));

    this.locationService.Add(this.utilitiesService.stripScript(_locationDetails)).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null) {
          if (this.multiFiles != null && this.multiFiles.length > 0) {
            this.locationService.VenueFiles(this.multiFiles, data.msg);
          }

          if (this.svgFile) {
            this.locationService.SvgFile(this.svgFile, data.msg);
          }
        }

        if (saveType == 'single') {
          this.page = 1;
          this.clearFormData(locationForm);
          // form.reset();
          this.isReqComplete = false;
          this.utilitiesService.smmodal('Location', data.Message);
        } else if (saveType == 'multiple') {
          this.location.LocationName = '';
          for (let i = 0; i < this.location.LocationBeacons.length; i++) {
            this.location.LocationBeacons[i].IsActive = 0;
          }
          this.locationSensorDetailsDisplay = [];
          this.selectedSensorType = null;
          this.filteredSensors = [];
          this.isSensorTypeSelected = false;
          this.SensorThreshold = null;
          this.SensorWarnPercentage = null;
          this.isSensorTypeSelected = true;
          this.fileReset('geojson');
          this.fileReset('svg');
          this.sensorTypes = null;
          this.location.LocationSensors = [];
          // this.getAllSensorTypes();
          if (this.location.Building.Venue.VenueId) {
            if (this.location.LocationId) {
              this.getAllSensorsByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
            } else {
              this.getAllSensorsByVenue(this.location.Building.Venue.VenueId, 0);
            }
            this.getAllBeaconsByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
          }
          this.isReqComplete = false;
          this.utilitiesService.smmodal('Location', data.Message);
        }
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'locationService addLocation Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public updateLocation(locationForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (locationForm.status == 'INVALID' && locationForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal('Location', 'You cannot proceed ahead until all the fields are filled properly.');
      return;
    }

    const that = this;
    const payload: LocationBeacon[] = [];
    const payloadSensor: LocationSensor[] = [];
    const gatewayPayload: LocationGateway[] = [];
    const taskPayload: LocationTaskModule[] = [];
    const inventoryPayload: LocationInventoryModule[] = [];
    const feedbackPayload: VenueLocationTouchlessFeedbackReason[] = [];
    const elementPayload: LocationElementModule[] = [];
    const _locationDetails: LocationModule = new LocationModule();

    _locationDetails.LocationId = this.location.LocationId;
    _locationDetails.LocationName = this.utilitiesService.removeInnerSpaces(this.location.LocationName);
    if (_locationDetails.LocationName == null || _locationDetails.LocationName == '') {
      this.location.LocationName = _locationDetails.LocationName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _locationDetails.Longitude = this.location.Longitude;
    _locationDetails.Latitude = this.location.Latitude;
    _locationDetails.Altitude = this.location.Altitude;
    _locationDetails.ModifiedBy = this.currentUser.UserId;
    if (this.isStatus == true) {
      _locationDetails.LocationType = this.location.LocationType;
    } else {
      _locationDetails.LocationType = 'B';
    }
    _locationDetails.LevelId = this.location.Building.Level.LevelId;
    _locationDetails.NextRestroomDistance = this.location.NextRestroomDistance;
    _locationDetails.NextRestroomDirection = this.location.NextRestroomDirection;
    _locationDetails.LocationStatus = this.location.LocationStatus;
    _locationDetails.Building = new BuildingModule();
    _locationDetails.Building.Venue = new VenueModule();
    _locationDetails.Building.Venue.EnableLocationStatus = this.location.Building.Venue.EnableLocationStatus ? 1 : 0;

    for (let i = 0; i < this.location.LocationBeacons.length; i++) {
      const _locationBeacons: LocationBeacon = new LocationBeacon();
      _locationBeacons.Beacon = new BeaconModule();

      _locationBeacons.LocationBeaconId = this.location.LocationBeacons[i].LocationBeaconId;
      _locationBeacons.Beacon.BeaconId = this.location.LocationBeacons[i].Beacon.BeaconId;
      _locationBeacons.IsActive = this.location.LocationBeacons[i].IsActive ? 1 : 0;
      _locationBeacons.LocationId = that.location.LocationId;
      _locationBeacons.AddedBy = that.currentUser.UserId;

      payload.push(_locationBeacons);
    }

    for (let i = 0; i < this.location.LocationSensors.length; i++) {
      if (this.location.LocationSensors[i].IsActive == 1) {
        const _locationSensors: LocationSensor = new LocationSensor();
        _locationSensors.Sensor = new SensorLite();
        _locationSensors.Sensor.SensorType = new SensorTypeLite();
        // const _location: LocationModule = new LocationModule();

        _locationSensors.LocationSensorId = this.location.LocationSensors[i].LocationSensorId;
        _locationSensors.Sensor.SensorId = this.location.LocationSensors[i].Sensor.SensorId;
        _locationSensors.Sensor.SensorType.SensorTypeCode =
          this.location.LocationSensors[i].Sensor.SensorType.SensorTypeCode;
        _locationSensors.IsActive = this.location.LocationSensors[i].IsActive ? 1 : 0;
        _locationSensors.LocationId = that.location.LocationId;
        _locationSensors.ModifiedBy = that.currentUser.UserId;
        _locationSensors.Threshold = this.location.LocationSensors[i].Threshold;
        _locationSensors.WarnPercentage = this.location.LocationSensors[i].WarnPercentage;

        payloadSensor.push(_locationSensors);
      }
    }

    for (let i = 0; i < this.location.LocationGateways.length; i++) {
      const _locationGateways: LocationGateway = new LocationGateway();
      _locationGateways.Gateway = new GatewayModule();

      _locationGateways.LocationGatewayId = this.location.LocationGateways[i].LocationGatewayId;
      _locationGateways.Gateway.GatewayId = this.location.LocationGateways[i].Gateway.GatewayId;
      _locationGateways.IsActive = this.location.LocationGateways[i].IsActive ? 1 : 0;
      _locationGateways.LocationId = that.location.LocationId;
      _locationGateways.AddedBy = that.currentUser.UserId;

      gatewayPayload.push(_locationGateways);
    }

    for (let i = 0; i < this.location.LocationTasks.length; i++) {
      const _locationTasks: LocationTaskModule = new LocationTaskModule();
      this.location.LocationTasks[i].IsActive = this.location.LocationTasks[i].IsActive ? 1 : 0;

      if (this.location.LocationTasks[i].IsActive == 1) {
        _locationTasks.TaskId = this.location.LocationTasks[i].TaskId;
        _locationTasks.IsActive = this.location.LocationTasks[i].IsActive ? 1 : 0;
        _locationTasks.VenueTaskId = this.location.LocationTasks[i].VenueTaskId;
        taskPayload.push(_locationTasks);
      }
    }

    for (let i = 0; i < this.location.LocationInventories.length; i++) {
      const _locationInventories: LocationInventoryModule = new LocationInventoryModule();
      this.location.LocationInventories[i].IsActive = this.location.LocationInventories[i].IsActive ? 1 : 0;

      if (this.location.LocationInventories[i].IsActive == 1) {
        _locationInventories.InventoryId = this.location.LocationInventories[i].InventoryId;
        _locationInventories.IsActive = this.location.LocationInventories[i].IsActive ? 1 : 0;
        _locationInventories.VenueInventoryId = this.location.LocationInventories[i].VenueInventoryId;
        inventoryPayload.push(_locationInventories);
      }
    }

    _locationDetails.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
    for (let i = 0; i < this.location.LocationFeedbackReasons.length; i++) {
      const _feedback: VenueLocationTouchlessFeedbackReason = new VenueLocationTouchlessFeedbackReason();
      this.location.LocationFeedbackReasons[i].IsActive = this.location.LocationFeedbackReasons[i].IsActive ? 1 : 0;

      if (this.location.LocationFeedbackReasons[i].IsActive == 1) {
        _feedback.VenueFeedbackReasonId = this.location.LocationFeedbackReasons[i].VenueFeedbackReasonId;
        _feedback.IsActive = this.location.LocationFeedbackReasons[i].IsActive ? 1 : 0;
        _feedback.VenueLocationFeedbackReasonId =
          this.location.LocationFeedbackReasons[i].VenueLocationFeedbackReasonId;
        _feedback.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
        feedbackPayload.push(_feedback);
      }
    }

    if (
      this.location.LocationElements != null &&
      this.location.LocationElements.length > 0 &&
      this.isLocationElements &&
      this.isLocationElementShow
    ) {
      let isElementChecked = false;
      this.totalElementWeightage = 0;
      this.location.LocationElements.forEach(function (element) {
        if (element.IsActive == true) {
          that.totalElementWeightage += Number(element.ElementWeightage);
          isElementChecked = true;
        }
      });

      if (isElementChecked && (this.totalElementWeightage > 100 || this.totalElementWeightage < 100)) {
        this.utilitiesService.smmodal('Location', 'Total weight of elements should equal 100%.');
        this.isReqComplete = false;
        this.loaderService.display(false);

        let elementScroll = document.getElementById('elementConfig');
        if (elementScroll) {
          elementScroll.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        }
        return;
      }
    }

    for (let i = 0; i < this.location.LocationElements.length; i++) {
      const _locationElements: LocationElementModule = new LocationElementModule();
      this.location.LocationElements[i].IsActive = this.location.LocationElements[i].IsActive ? 1 : 0;

      if (this.location.LocationElements[i].IsActive == 1) {
        _locationElements.ElementId = this.location.LocationElements[i].ElementId;
        _locationElements.IsActive = this.location.LocationElements[i].IsActive ? 1 : 0;
        _locationElements.VenueElementId = this.location.LocationElements[i].VenueElementId;
        _locationElements.ElementWeightage = this.location.LocationElements[i].ElementWeightage;

        if (this.location.LocationElements[i].Area != null && this.location.LocationElements[i].Area.AreaId > 0) {
          const _locationAreaElements: AreaModule = new AreaModule();
          _locationElements.Areas = [];
          _locationAreaElements.AreaId = this.location.LocationElements[i].Area.AreaId;
          _locationAreaElements.AreaName = this.location.LocationElements[i].Area.AreaName;
          _locationAreaElements.IsActive = 1;
          _locationElements.Areas.push(_locationAreaElements);
        }

        if (
          this.location.LocationElements[i].InspectionScoreFactor != null &&
          this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId > 0
        ) {
          _locationElements.InspectionScoreFactor = new InspectionScoreFactor();
          _locationElements.InspectionScoreFactor.InspectionScoreFactorId =
            this.location.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId;
          _locationElements.InspectionScoreFactor.ScoreFactorName =
            this.location.LocationElements[i].InspectionScoreFactor.ScoreFactorName;
          _locationElements.InspectionScoreFactor.IsActive = 1;
        } else {
          if (this.isLocationElementShow) {
            this.loaderService.display(false);
            this.isReqComplete = false;
            this.utilitiesService.smmodal('Location', 'Please select score factor.');
            return;
          }
        }
        elementPayload.push(_locationElements);
      }
    }

    _locationDetails.LocationTasks = taskPayload;
    _locationDetails.LocationInventories = inventoryPayload;
    _locationDetails.LocationElements = elementPayload;
    _locationDetails.LocationFeedbackReasons = feedbackPayload;

    _locationDetails.LocationGateways = gatewayPayload;
    _locationDetails.LocationBeacons = payload;
    _locationDetails.LocationSensors = payloadSensor;

    _locationDetails.Zone = new ZoneModule();
    _locationDetails.Zone.ZoneId = this.location.Zone.ZoneId;

    _locationDetails.Building = new BuildingModule();
    _locationDetails.Building.Venue = new VenueModule();
    _locationDetails.Building.BuildingId = this.location.Building.BuildingId;
    _locationDetails.Building.Venue.VenueId = this.location.Building.Venue.VenueId;
    _locationDetails.IsActive = this.location.IsActive ? 1 : 0;
    _locationDetails.AllowGender = this.location.AllowGender ? 1 : 0;

    if (this.location.LocationId) {
      if (this.multiFiles != null && this.multiFiles.length > 0) {
        this.locationService.VenueFiles(this.multiFiles, this.location.LocationId);
      }

      if (this.svgFile) {
        this.locationService.SvgFile(this.svgFile, this.location.LocationId);
      }
    }

    let modulePayload: Module[] = [];
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let module = new Module();
          module.ModuleId = it.value;
          module.ModuleFeatures = [];

          it.children.forEach(function (mf) {
            if (mf.checked || mf.checked == undefined) {
              let mFeature = new ModuleFeature();
              mFeature.ModuleFeatureId = mf.value;
              mFeature.ModuleFeatureTypes = [];

              mf.children.forEach(function (mft) {
                if (mft.checked || mft.checked == undefined) {
                  let mFeatureType = new ModuleFeatureType();
                  mFeatureType.FeatureTypeCode = mft.value;
                  mFeatureType.FeatureConfigurations = [];

                  mft.children.forEach(function (fc) {
                    if (fc.checked || fc.checked == undefined) {
                      let fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId = fc.value;
                      fConfig.IsActive = 1;

                      mFeatureType.FeatureConfigurations.push(fConfig);
                    }
                  });
                  mFeature.ModuleFeatureTypes.push(mFeatureType);
                }
              });
              module.ModuleFeatures.push(mFeature);
            }
          });
          modulePayload.push(module);
        }
      });
    }
    _locationDetails.Modules = modulePayload;
    _locationDetails.VenueLocationTypeId = this.location.VenueLocationTypeId;
    _locationDetails.CapacityCount = this.location.CapacityCount;
    _locationDetails.AvailabilityPercentage = this.location.AvailabilityPercentage;
    // console.log(JSON.stringify(_locationDetails));

    this.locationService.Update(this.utilitiesService.stripScript(_locationDetails)).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.clearFormData(locationForm);
        this.utilitiesService.smmodal('Location', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'locationService updateLocation Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllLocations(
    searchTerm: string,
    pageIndex: number,
    pageSize: number,
    isActive: any,
    isExportLocation: string,
  ) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.locationService.GetLocationList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.loaderService.display(false);
        if (data != null && data.length > 0 && isExportLocation === 'Export Location') {
          this.exportLocationToExcel();
        } else if ((data == null || data.length === 0) && isExportLocation === 'Export Location') {
          this.utilitiesService.smmodal('Location', 'There are no Locations defined for this venue.');
        } else {
          this.locations = data;
          if (this.locations.length < 0 && this.locations[0].Count / 10 < this.page) {
            this.page = 1;
          }

          if (this.locations != null && this.locations.length > 0) {
            this.locations.forEach(function (location) {
              if (location.LocationFeatureConfigurations != null) {
                location.LocationFeatureConfigurations.forEach(function (featureConfig) {
                  if (
                    featureConfig.FeatureConfigurationCode != null &&
                    featureConfig.FeatureConfigurationCode == 'ENABLE_CLEANING_ALERTS'
                  ) {
                    location.isCA = true;
                  }
                });
              }
            });
          }
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService getAllLocations Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
      () => (this.count = this.locations[0] ? this.locations[0].Count : 0),
    );
  }

  public getLocationById(locationId: number) {
    if (this.canAccess('LOCATION_VIEW_MY_RECORDS', 'view') || this.canAccess('LOCATION_VIEW_ALL_RECORDS', 'view')) {
      this.isAdd = false;
      this.loaderService.display(true);
      const CPlocation = new LocationModule();
      CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);

      this.locationService.GetSingle(CPlocation).subscribe(
        (data) => {
          this.location = data;
          this.loaderService.display(false);
          this.feedbackIdentifierdisabled = false;
          if (this.location.Building.Venue.VenueId) {
            this.getAllBuildingsByVenue(this.location.Building.Venue.VenueId);
            this.GetModuleList(this.location.Building.Venue.VenueId, this.location.LocationId, 0);
            if (this.location.Building.BuildingId) {
              this.getAllZonesByBuilding(this.location.Building.BuildingId, 0);
              this.getAllLevelsByBuilding(this.location.Building.BuildingId, 0);
            }

            this.getAllBeaconsByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
            this.getAllSensorsByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
            this.getAllGatewaysByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
            this.getAllFeedbackReasonsByVenue(
              this.location.Building.Venue.VenueId,
              this.location.LocationId,
              this.location.TouchlessFeedbackIdentifier,
            );
            if (this.location.VenueLocationTypeId) {
              if (this.isLocationAreaShow) {
                this.getAllAreas(this.location.VenueLocationTypeId);
              }
              this.getAllTasksByVenue(
                this.location.Building.Venue.VenueId,
                this.location.LocationId,
                this.location.VenueLocationTypeId,
                0,
              );
              this.getAllInventoriesByVenue(
                this.location.Building.Venue.VenueId,
                this.location.LocationId,
                this.location.VenueLocationTypeId,
                0,
              );
              this.getAllElementsByVenue(
                this.location.Building.Venue.VenueId,
                this.location.LocationId,
                this.location.VenueLocationTypeId,
                0,
              );
            }
          }

          const mapFiles = [];
          if (this.location.LocationMapFiles != null && this.location.LocationMapFiles.length > 0) {
            for (let i = 0; i < this.location.LocationMapFiles.length; i++) {
              const index = i + 1;
              const filename = this.location.LocationMapFiles[i].fileName;

              mapFiles.push({ serialNumber: index, fileName: filename });
            }
            this.location.LocationMapFiles = mapFiles;
          }

          if (this.location.LocationStatus == null) {
            this.location.LocationStatus = 'Open';
          }

          if (this.location.LocationSVGMap) {
            this.IsSVGTable = true;
          } else {
            this.IsSVGTable = false;
          }

          if (this.location.LocationGateways && this.location.LocationGateways.length > 0) {
            this.isLocationGateways = true;
          }

          const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
          if (obj[0]) {
            this.isBadgeShow = obj[0].IsBadgeEnable;
          }
          this.location.AllowGender = this.location.AllowGender ? true : false;

          if (this.location.AllowGender == true) {
            this.isStatus = true;
          } else {
            this.isStatus = false;
          }

          this.editsignagePageUrl = this.utilitiesService.signageUrl(
            this.location.LocationId,
            this.location.Building.Venue.VenueName,
          );

          this.editTouchlessFeedbackPageUrl = this.utilitiesService.TouchlessFeedbackPageUrl(
            this.location.LocationId,
            this.location.Building.Venue.VenueName,
          );

          this.editCleaningAlertRequestPageUrl = this.utilitiesService.CleaningAlertRequestPageUrl(
            this.location.LocationId,
            this.location.Building.Venue.VenueName,
          );

          window.scrollTo(0, 0);
          if (this.venueBind) {
            this.getVenueById(this.venueBind, false);
          }
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService getLocationById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
        () => this.setUpdateForm(),
      );
    } else {
      this.utilitiesService.smmodal('Location', 'You do not have permission to view location details.');
    }
  }

  public getAllVenues(searchTerm: string, pageIndex: number, pageSize: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.venueService.GetVenueDropdown(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.venues = data;
        this.loaderService.display(false);

        this.isBadgeShow = 0;

        const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
        if (obj[0]) {
          this.isBadgeShow = obj[0].IsBadgeEnable;
        }

        if (!this.locations && !this.location.LocationId) {
          if (obj[0]) {
            this.location.Building.Venue.VenueId = obj[0].VenueId;
            this.location.Building.Venue.VenueName = obj[0].VenueName;
            this.getAllBuildingsByVenue(this.location.Building.Venue.VenueId);
            this.getAllBeaconsByVenue(this.location.Building.Venue.VenueId, 0);
            this.getAllSensorsByVenue(this.location.Building.Venue.VenueId, 0);
            this.getAllGatewaysByVenue(this.location.Building.Venue.VenueId, 0);
            this.getAllFeedbackReasonsByVenue(
              this.location.Building.Venue.VenueId,
              0,
              this.location.TouchlessFeedbackIdentifier,
            );
            if (this.location.VenueLocationTypeId != null) {
              if (this.isLocationAreaShow) {
                this.getAllAreas(this.location.VenueLocationTypeId);
              }
              this.getAllTasksByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId, 0);
              this.getAllInventoriesByVenue(
                this.location.Building.Venue.VenueId,
                0,
                this.location.VenueLocationTypeId,
                0,
              );
              this.getAllElementsByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId, 0);
            }
          }
        }

        this.SensorThreshold = this.venues[0].Threshold;
        this.SensorWarnPercentage = this.venues[0].WarnPercentage;
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'venueService getAllVenues Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public changeVenue($event: number) {
    this.location.Building.Venue.VenueId = $event;
    this.isBadgeShow = 0;
    const obj = this.venues.filter((v) => v.VenueId == this.venueBind);
    if (obj && obj[0]) {
      this.isBadgeShow = obj[0].IsBadgeEnable;
    }

    if (this.location.Building.Venue.VenueId) {
      this.items = [];
      this.Modules = [];
      this.GetModuleList(this.location.Building.Venue.VenueId, 0, 0);

      this.buildings = [];
      this.location.Building.BuildingId = null;
      this.getAllBuildingsByVenue(this.location.Building.Venue.VenueId);

      if (!this.location.LocationId) {
        this.getAllBeaconsByVenue(this.location.Building.Venue.VenueId, 0);
        this.getAllSensorsByVenue(this.location.Building.Venue.VenueId, 0);
        this.getAllGatewaysByVenue(this.location.Building.Venue.VenueId, 0);
        this.getAllFeedbackReasonsByVenue(
          this.location.Building.Venue.VenueId,
          0,
          this.location.TouchlessFeedbackIdentifier,
        );

        if (this.location.VenueLocationTypeId != null) {
          if (this.isLocationAreaShow) {
            this.getAllAreas(this.location.VenueLocationTypeId);
          }
          this.getAllTasksByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId, 0);
          this.getAllInventoriesByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId, 0);
          this.getAllElementsByVenue(this.location.Building.Venue.VenueId, 0, this.location.VenueLocationTypeId, 0);
        }
      } else {
        this.getAllBeaconsByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
        this.getAllSensorsByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
        this.getAllGatewaysByVenue(this.location.Building.Venue.VenueId, this.location.LocationId);
        this.getAllFeedbackReasonsByVenue(
          this.location.Building.Venue.VenueId,
          this.location.LocationId,
          this.location.TouchlessFeedbackIdentifier,
        );
      }
      if (this.location.VenueLocationTypeId != null && this.location.LocationId) {
        if (this.isLocationAreaShow) {
          this.getAllAreas(this.location.VenueLocationTypeId);
        }
        this.getAllTasksByVenue(
          this.location.Building.Venue.VenueId,
          this.location.LocationId,
          this.location.VenueLocationTypeId,
          0,
        );
        this.getAllInventoriesByVenue(
          this.location.Building.Venue.VenueId,
          this.location.LocationId,
          this.location.VenueLocationTypeId,
          0,
        );
        this.getAllElementsByVenue(
          this.location.Building.Venue.VenueId,
          this.location.LocationId,
          this.location.VenueLocationTypeId,
          0,
        );
      } else {
        this.location.LocationTasks = null;
        this.location.LocationInventories = null;
        this.location.LocationElements = null;
        this.isLocationTasks = false;
        this.isLocationInventories = false;
        this.isLocationElements = false;
      }
    } else {
      this.buildings = [];
      this.buildingDisabled = true;
      this.location.Building.BuildingId = null;

      this.zones = [];
      this.zoneDisabled = true;
      this.location.Zone.ZoneId = null;

      this.levels = [];
      this.levelDisabled = true;
      this.location.Building.Level.LevelId = null;

      this.location.LocationBeacons = [];
      this.location.LocationSensors = [];
      this.isLocationBeacons = false;
      this.location.LocationGateways = [];
      this.isLocationGateways = false;

      this.location.LocationTasks = null;
      this.location.LocationInventories = null;
      this.location.LocationElements = null;
      this.isLocationTasks = false;
      this.isLocationInventories = false;
      this.isLocationElements = false;
    }
  }

  public getAllBuildingsByVenue(venueId: any) {
    if (venueId) {
      this.loaderService.display(true);
      const CPvenue = new VenueModule();
      CPvenue.VenueCPId = this.authState.AESEncryptText(venueId);

      this.locationService.GetBuildingDropdown(CPvenue).subscribe(
        (data) => {
          this.buildings = data;
          this.buildingDisabled = false;
          this.loaderService.display(false);
        },
        (error) =>
          console.log(
            'locationService GetAllBuildings Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          ),
      );
    } else {
      this.buildings = [];
      this.location.Building.BuildingId = null;
      this.buildingDisabled = true;
      this.loaderService.display(false);
    }
  }

  public changeBuilding($event: number, type: number) {
    if (type == 0) {
      this.location.Building.BuildingId = $event;
      if (this.location.Building.BuildingId) {
        this.zones = [];
        this.location.Zone.ZoneId = null;

        this.getAllZonesByBuilding(this.location.Building.BuildingId, type);

        this.levels = [];
        this.location.Building.Level.LevelId = null;
        this.getAllLevelsByBuilding(this.location.Building.BuildingId, type);
      } else {
        this.zones = [];
        this.zoneDisabled = true;
        this.location.Zone.ZoneId = null;

        this.levels = [];
        this.levelDisabled = true;
        this.location.Building.Level.LevelId = null;

        this.location.LocationBeacons = [];
        this.location.LocationSensors = [];
      }
    } else if (type == 1) {
      this.locationTemplateModalObj.Building.BuildingId = $event;
      if (this.locationTemplateModalObj.Building.BuildingId) {
        this.zonesTemplate = [];
        this.locationTemplateModalObj.Zone.ZoneId = null;

        this.getAllZonesByBuilding(this.locationTemplateModalObj.Building.BuildingId, type);

        this.levelsTemplate = [];
        this.locationTemplateModalObj.Building.Level.LevelId = null;
        this.getAllLevelsByBuilding(this.locationTemplateModalObj.Building.BuildingId, type);
      } else {
        this.zonesTemplate = [];
        this.zoneDisabledTemplate = true;
        this.locationTemplateModalObj.Zone.ZoneId = null;

        this.levelsTemplate = [];
        this.levelDisabledTemplate = true;
        this.locationTemplateModalObj.Building.Level.LevelId = null;
      }
    }
  }

  public getAllZonesByBuilding(buildingId: any, type: number) {
    if (buildingId) {
      this.loaderService.display(true);
      const CPbuilding = new BuildingModule();
      CPbuilding.BuildingCPId = this.authState.AESEncryptText(buildingId);

      this.shiftService.GetZoneDropdown(CPbuilding).subscribe(
        (data) => {
          if (type == 0) {
            this.zones = data;
            this.zoneDisabled = false;
          } else {
            this.zonesTemplate = data;
            this.zoneDisabledTemplate = false;
          }
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            ' locationComponent shiftService GetAllZones Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    } else {
      if (type == 0) {
        this.zones = [];
        this.location.Zone.ZoneId = null;
      } else {
        this.zonesTemplate = [];
        this.locationTemplateModalObj.Zone.ZoneId = null;
      }
      this.loaderService.display(false);
    }
  }

  public changeZone($event: number, type: number) {
    if (type == 0) {
      this.location.Zone.ZoneId = $event;
    } else {
      this.locationTemplateModalObj.Zone.ZoneId = $event;
    }
  }

  public getAllLevelsByBuilding(buildingId: number, type: number) {
    if (buildingId) {
      this.loaderService.display(true);
      const CPbuilding = new BuildingModule();
      CPbuilding.BuildingCPId = this.authState.AESEncryptText(buildingId);

      this.locationService.GetLevelDropdown(CPbuilding).subscribe(
        (data) => {
          if (type == 0) {
            this.levels = data;
            this.levelDisabled = false;
          } else {
            this.levelsTemplate = data;
            this.levelDisabledTemplate = false;
          }

          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetLevelDropdown Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    } else {
      this.buildings = [];
      this.location.Building.BuildingId = null;
      this.loaderService.display(false);
    }
  }

  public getAllBeaconsByVenue(venueId: number, locationId: number) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.locationService.GetAllBeaconsByVenue(CPlocation).subscribe(
      (data) => {
        this.location.LocationBeacons = data;
        // this.curPage = 1;
        if (this.location.LocationBeacons.length > 0) {
          this.isLocationBeacons = true;
        } else {
          this.isLocationBeacons = false;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllBeaconsByZone Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getAllSensorsByVenue(venueId: number, locationId: number) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
    CPlocation.Venue = new VenueModule();
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);

    this.locationService.GetAllSensorsByVenue(CPlocation).subscribe(
      (data) => {
        this.loaderService.display(false);
        this.locationSensors = data;
        this.location.LocationSensors = data;
        // this.curPageOfSensor = 1;
        if (this.locationSensors.length > 0) {
          this.isLocationSensors = true;
        } else {
          this.isLocationSensors = false;
        }

        if (!this.isAdd) {
          if (this.locationSensorDetailsDisplay.length == 0) {
            this.locationSensors.forEach((ls) => {
              if (ls.IsActive == 1) {
                const record = new LocationSensorDetails();
                record.SensorType = new SensorTypeModule();

                const sensorData = new SensorModule();
                sensorData.SensorType = new SensorTypeModule();

                const existingRecord = this.locationSensorDetailsDisplay.filter(
                  (lsd) => lsd.SensorType.SensorTypeId == ls.Sensor.SensorType.SensorTypeId,
                );

                if (existingRecord != null && existingRecord.length == 0) {
                  record.locationSensorId = ls.LocationSensorId;
                  record.SensorType.SensorTypeId = ls.Sensor.SensorType.SensorTypeId;
                  record.SensorType.SensorTypeName = ls.Sensor.SensorType.SensorTypeName;
                  record.SensorType.SensorTypeCode = ls.Sensor.SensorType.SensorTypeCode;
                  record.Threshold = ls.Threshold;
                  record.WarnPercentage = ls.WarnPercentage;
                  sensorData.SensorId = ls.Sensor.SensorId;
                  sensorData.SensorName = ls.Sensor.SensorName;
                  sensorData.IsActive = 1;
                  sensorData.Threshold = ls.Threshold == null ? 0 : ls.Threshold;
                  sensorData.WarnPercentage = ls.WarnPercentage == null ? 0 : ls.WarnPercentage;
                  record.Sensors.push(sensorData);
                  this.locationSensorDetailsDisplay.push(record);
                } else if (existingRecord[0].SensorType.SensorTypeId == ls.Sensor.SensorType.SensorTypeId) {
                  sensorData.SensorId = ls.Sensor.SensorId;
                  sensorData.SensorName = ls.Sensor.SensorName;
                  sensorData.SensorType.SensorTypeCode = ls.Sensor.SensorType.SensorTypeCode;
                  sensorData.IsActive = 1;
                  sensorData.Threshold = ls.Threshold == null ? 0 : ls.Threshold;
                  sensorData.WarnPercentage = ls.WarnPercentage == null ? 0 : ls.WarnPercentage;
                  existingRecord[0].Sensors.push(sensorData);
                }
              }
            });

            if (this.locationSensorDetailsDisplay != null && this.locationSensorDetailsDisplay.length > 0) {
              let SensorDetailsDisplayObj = this.locationSensorDetailsDisplay.filter(
                (a) => a.SensorType.SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS',
              );
              if (SensorDetailsDisplayObj != null && SensorDetailsDisplayObj.length > 0) {
                this.feedbackIdentifierdisabled = true;
              } else {
                this.feedbackIdentifierdisabled = false;
              }
            }
          }
        }
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllSensorsByVenue Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getAllLocationGenderTypes(searchTerm: string, pageIndex: number, pageSize: number) {
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 0 };

    this.locationService.GetLocationTypes(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.locationGenderTypes = data;
      },
      (error) => {
        console.log(
          'locationService getAllLocationTypes Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  /// here restRoom means location name
  public createWorkorder(
    venueName: string,
    buildingName: string,
    restRoom: string,
    locationId: number,
    venueId: number,
    isSkip: any,
  ) {
    this.venueName = venueName;
    this.buildingName = buildingName;
    this.restRoom = restRoom;
    this.locationId = locationId;
    this.venueId = venueId;
    if (this.isCAAttachmentUploadAllow && isSkip) {
      this.cleaningAlertStatus(locationId);
    } else {
      this.loaderService.display(true);
      let isAttachmentAvilable = false;
      this.isReqComplete = true;
      if (isSkip) {
        this.CreatorComments = '';
        this.caAttachments = [];
      }

      if (this.caAttachments != null && this.caAttachments.length > 0) {
        isAttachmentAvilable = true;
      } else {
        isAttachmentAvilable = false;
      }

      this.locationService
        .CreateWorkorder(
          this.venueName,
          this.buildingName,
          this.restRoom,
          this.locationId,
          this.venueId,
          this.CreatorComments,
          isAttachmentAvilable,
        )
        .subscribe(
          (data) => {
            this.isReqComplete = false;
            this.loaderService.display(false);
            this.utilitiesService.smmodal('Location', 'Cleaning Alert #' + data.id + ' created successfully!');

            if (this.caAttachments != null && this.caAttachments.length > 0 && !isSkip) {
              let that = this;
              this.loaderService.display(true);

              this.restroomOverviewService
                .CleaningAlertAttatchments(this.caAttachments, data.id)
                .then(function (_response) {
                  // console.log(_response);
                  that.loaderService.display(false);
                })
                .catch((err) => {
                  console.log(err);
                  that.loaderService.display(false);
                });
              this.closeCAAttachmentPopUp();
            } else {
              this.closeCAAttachmentPopUp();
            }
          },
          (error) => {
            this.isReqComplete = false;
            this.loaderService.display(false);
            console.log(
              'locationService CreateWorkorder Call Failed. Status:' +
              error.status +
              ' Status Text:' +
              error.statusText,
            );
          },
        );
    }
  }

  public getAllSensorTypes() {
    const searchfilter = { SearchQuery: '', GenericSearch: 0, SortColumn: 'SensorTypeName', SortOrder: 'ASC' };

    this.sensorTypeService.GetSensorTypesDropDown(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.sensorTypes = data;
      },
      (error) => {
        console.log(
          'sensorTypeService GetAllSensorTypes Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getVenueSensors($event: any) {
    this.selectedSensorType = $event;
    this.SensorThreshold = null;
    this.SensorWarnPercentage = null;
    this.isSensorTypeSelected = true;
    if ($event != null) {
      this.filteredSensors = this.locationSensors.filter((l) => l.Sensor.SensorType.SensorTypeId == $event);
      this.filteredSensors.forEach((fs) => {
        fs.Sensor.IsActive = fs.IsActive;
        if (fs.Sensor.IsActive == 1) {
          this.sId = fs.Sensor.SensorId;
        }
      });

      const isActiveResult = this.filteredSensors.filter((fs) => fs.IsActive == 1);
      if (isActiveResult != null && isActiveResult.length > 0) {
        this.SensorThreshold = isActiveResult[0].Threshold;
        this.SensorWarnPercentage = isActiveResult[0].WarnPercentage;
      } else {
        if (this.filteredSensors && this.filteredSensors.length > 0) {
          this.SensorThreshold = this.filteredSensors[0].Threshold;
          this.SensorWarnPercentage = this.filteredSensors[0].WarnPercentage;
        }
      }
      const sensorObj = this.sensorTypes.filter((st) => st.SensorTypeId == $event);
      if (sensorObj && sensorObj[0]) {
        if (
          sensorObj[0].SensorTypeCode == 'AIR_QUALITY_DETECTION' ||
          sensorObj[0].SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS' ||
          sensorObj[0].SensorTypeCode == 'VENDING_MACHINE'
        ) {
          this.isAirQualityDetection = true;
          this.isGuestFeedback = true;
          this.isVendingMachine = true;
        } else {
          this.isAirQualityDetection = false;
          this.isGuestFeedback = false;
          this.isVendingMachine = false;
        }

        if (
          sensorObj[0].SensorTypeCode == 'STALL_OCCUPANCY' ||
          sensorObj[0].SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS'
        ) {
          this.isThresholdWarnShow = false;
          this.SensorThreshold = 0;
          this.SensorWarnPercentage = 0;
        } else {
          this.isThresholdWarnShow = true;
        }
      }

      let sensorTypeObj = this.sensorTypes.filter((l) => l.SensorTypeId == $event);
      if (
        this.location.TouchlessFeedbackIdentifier != null &&
        sensorTypeObj &&
        sensorTypeObj.length > 0 &&
        sensorTypeObj[0].SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS'
      ) {
        this.isGuestFeedbackSelected = true;
        this.filteredSensors = [];
        // need to show message below Sensor Type drop down
      } else {
        this.isGuestFeedbackSelected = false;
        if (this.filteredSensors != null && this.filteredSensors.length > 0) {
          for (let s = 0; s < this.filteredSensors.length; s++) {
            if (
              this.filteredSensors[s].Sensor.SensorType.SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS' &&
              this.filteredSensors[s].Sensor.SensorType.IsActive == 1
            ) {
              this.feedbackIdentifierdisabled = true;
            }
          }
        } else {
          if (this.locationSensorDetailsDisplay != null && this.locationSensorDetailsDisplay.length > 0) {
            let SensorDetailsDisplayObj = this.locationSensorDetailsDisplay.filter(
              (a) => a.SensorType.SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS',
            );
            if (
              SensorDetailsDisplayObj != null &&
              SensorDetailsDisplayObj.length > 0 &&
              this.locationSensorDetailsDisplay[0].Sensors != null &&
              this.locationSensorDetailsDisplay[0].Sensors.length > 0
            ) {
              this.feedbackIdentifierdisabled = true;
            } else {
              this.feedbackIdentifierdisabled = false;
            }
          } else {
            this.feedbackIdentifierdisabled = false;
          }
        }
      }
    } else {
      if (this.filteredSensors != null && this.filteredSensors.length > 0) {
        for (let s = 0; s < this.filteredSensors.length; s++) {
          if (
            this.filteredSensors[s].Sensor.SensorType.SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS' &&
            this.filteredSensors[s].Sensor.SensorType.IsActive == 1
          ) {
            this.feedbackIdentifierdisabled = true;
          }
        }
      } else {
        let isGuestFeedbackSurveySelected = false;
        for (let s = 0; s < this.locationSensorDetailsDisplay.length; s++) {
          if (
            this.locationSensorDetailsDisplay[s].SensorType.SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS' &&
            this.locationSensorDetailsDisplay[s].Sensors != null &&
            this.locationSensorDetailsDisplay[s].Sensors.length > 0
          ) {
            isGuestFeedbackSurveySelected = true;
            this.feedbackIdentifierdisabled = true;
          }
        }
        if (!isGuestFeedbackSurveySelected) {
          this.feedbackIdentifierdisabled = false;
          this.isGuestFeedbackSelected = false;
        }
      }

      this.selectedSensorType = null;
      this.filteredSensors = [];
      this.isSensorTypeSelected = false;
    }

    if (this.sId) {
      this.myQuerySub = timer(100).subscribe(() => {
        this.sId = document.getElementById(this.sId);
        if (this.sId) {
          this.sId.checked = true;
        }
      });
    }
  }

  public sensorChecked($event: any) {
    if ($event != null) {
      const sensor = this.filteredSensors.filter((fs) => fs.Sensor.SensorId == $event);
      const sensorType = this.sensorTypes.filter((st) => st.SensorTypeId == sensor[0].Sensor.SensorType.SensorTypeId);
      let isActiveValue = 0;

      if (this.locationSensorDetailsDisplay != null) {
        const existingRecord = this.locationSensorDetailsDisplay.filter(
          (lsd) => lsd.SensorType.SensorTypeId == sensorType[0].SensorTypeId,
        );

        if (this.isAirQualityDetection || this.isGuestFeedback || this.isVendingMachine) {
          if (
            existingRecord != null &&
            existingRecord.length > 0 &&
            existingRecord[0].Sensors != null &&
            existingRecord[0].Sensors.length > 0
          ) {
            const existingSensorTypeSensor = existingRecord[0].Sensors.filter((lsd) => lsd.SensorId == $event);
            if (existingSensorTypeSensor != null && existingSensorTypeSensor.length > 0) {
              isActiveValue = 0;
            } else {
              isActiveValue = 1;
            }
          } else {
            isActiveValue = 1;
          }

          let index = -1;
          for (let i = 0; i < this.filteredSensors.length; i++) {
            if (
              existingRecord &&
              existingRecord[0] &&
              existingRecord[0].Sensors &&
              existingRecord[0].Sensors.length > 0
            ) {
              for (let j = 0; j < existingRecord.length; j++) {
                if (
                  existingRecord[j].SensorType.SensorTypeId == this.filteredSensors[i].Sensor.SensorType.SensorTypeId
                ) {
                  existingRecord[j].Sensors[j].IsActive = 0;
                  index = i;
                  existingRecord[j].Sensors.splice(index, 1);
                  i = i - 1;
                }
              }
            }
          }

          const allPeopleCounterSensors = this.location.LocationSensors.filter(
            (lsd) => lsd.Sensor.SensorType.SensorTypeId == sensorType[0].SensorTypeId,
          );

          this.location.LocationSensors.forEach((locSens) => {
            allPeopleCounterSensors.forEach((apc) => {
              if (apc.Sensor.SensorId == locSens.Sensor.SensorId) {
                locSens.IsActive = 0;
              }
            });
          });

          this.filteredSensors.forEach((locSens) => {
            allPeopleCounterSensors.forEach((apc) => {
              if (apc.Sensor.SensorId == locSens.Sensor.SensorId) {
                locSens.Sensor.IsActive = false;
              }
            });
          });
        }
        // else {
        const sensorData = new SensorModule();
        sensorData.SensorType = new SensorTypeModule();

        let existingSensorIndex = -1;
        if (existingRecord != null && existingRecord.length > 0) {
          const existingSensorRecord = existingRecord[0].Sensors.filter((s) => s.SensorId == sensor[0].Sensor.SensorId);

          if (existingSensorRecord != null && existingSensorRecord.length > 0) {
            for (let i = 0; i < existingRecord[0].Sensors.length; i++) {
              if (existingRecord[0].Sensors[i].SensorId == sensor[0].Sensor.SensorId) {
                existingRecord[0].Sensors[i].IsActive = 0;
                existingSensorIndex = i;
                existingRecord[0].Sensors.splice(existingSensorIndex, 1);
                i = i - 1;
                isActiveValue = 0;
              }
            }
          } else {
            existingRecord[0].locationSensorId = sensor[0].LocationSensorId;
            sensorData.SensorId = sensor[0].Sensor.SensorId;
            sensorData.SensorName = sensor[0].Sensor.SensorName;
            sensorData.SensorType.SensorTypeCode = sensorType[0].SensorTypeCode;
            if (!this.isAirQualityDetection && !this.isGuestFeedback && !this.isVendingMachine) {
              sensorData.IsActive = 1;
              isActiveValue = 1;
            } else {
              sensorData.IsActive = isActiveValue;
            }
            sensorData.Threshold = this.SensorThreshold == 'undefined' ? 0 : this.SensorThreshold;
            sensorData.WarnPercentage = this.SensorWarnPercentage == 'undefined' ? 0 : this.SensorWarnPercentage;
            if (isActiveValue == 1) {
              existingRecord[0].Sensors.push(sensorData);
            }
          }
        } else {
          const record = new LocationSensorDetails();
          record.SensorType = new SensorTypeModule();

          record.SensorType.SensorTypeId = sensorType[0].SensorTypeId;
          record.SensorType.SensorTypeName = sensorType[0].SensorTypeName;
          record.SensorType.SensorTypeCode = sensorType[0].SensorTypeCode;
          record.Threshold = this.SensorThreshold;
          record.WarnPercentage = this.SensorWarnPercentage;

          record.locationSensorId = sensor[0].LocationSensorId;
          sensorData.SensorId = sensor[0].Sensor.SensorId;
          sensorData.SensorName = sensor[0].Sensor.SensorName;
          sensorData.SensorType.SensorTypeCode = sensorType[0].SensorTypeCode;
          sensorData.IsActive = 1;
          sensorData.Threshold = this.SensorThreshold == 'undefined' ? 0 : this.SensorThreshold;
          sensorData.WarnPercentage = this.SensorWarnPercentage == 'undefined' ? 0 : this.SensorWarnPercentage;

          record.Sensors.push(sensorData);
          this.locationSensorDetailsDisplay.push(record);
          isActiveValue = 1;
        }
      }

      this.location.LocationSensors.forEach((ls) => {
        if (ls.Sensor.SensorId == $event) {
          ls.IsActive = isActiveValue;
          ls.Threshold = this.SensorThreshold;
          ls.WarnPercentage = this.SensorWarnPercentage;
        }
      });
    }

    if (this.locationSensorDetailsDisplay != null && this.locationSensorDetailsDisplay.length > 0) {
      let isGFSelected = false;
      for (let index = 0; index < this.locationSensorDetailsDisplay.length; index++) {
        if (
          this.locationSensorDetailsDisplay[index].Sensors != null &&
          this.locationSensorDetailsDisplay[index].Sensors.length > 0
        ) {
          for (let sensor = 0; sensor < this.locationSensorDetailsDisplay[index].Sensors.length; sensor++) {
            if (
              this.locationSensorDetailsDisplay[index].Sensors[sensor].SensorType.SensorTypeCode ==
              'GUEST_FEEDBACK_SURVEYS'
            ) {
              isGFSelected = true;
              this.feedbackIdentifierdisabled = true;
              this.location.TouchlessFeedbackIdentifier = null;
              this.location.LocationFeedbackReasons = [];
            }
          }
        }
      }

      if (!isGFSelected) {
        this.feedbackIdentifierdisabled = false;
      }
    }
  }

  //// Location Geojson & SVG file upload function
  fileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal('Location', this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        return;
      }

      const filename = event.target.files[0].name;
      this.fileExtension = filename.split('.')[1].toLowerCase();
      const ext = filename.split('.')[1];
      if (ext == 'svg') {
        if (event.target.files && event.target.files[0]) {
          const fileList: FileList = event.target.files;
          if (fileList.length > 0) {
            this.selectedSVGFile = event.target.files[0].name;
            this.svgFile = fileList[0];
            const reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            reader.readAsBinaryString(this.svgFile);
          }
        }
      } else {
        this.utilitiesService.smmodal('Location', 'Please upload image of type .svg.');
        event.target.value = '';
      }
    }
  }

  checkMaliciousTagInGeojson(_readerEvt: any) {
    const binaryString = _readerEvt.target.result;
    if (this.utilitiesService.maliciousTags && this.utilitiesService.maliciousTags.length > 0) {
      let isMaliciousTagsFound = false;
      for (let tag = 0; tag < this.utilitiesService.maliciousTags.length; tag++) {
        if (binaryString.search(this.utilitiesService.maliciousTags[tag].name) >= 1) {
          isMaliciousTagsFound = true;
          break;
        }
      }

      if (isMaliciousTagsFound) {
        this.utilitiesService.smmodal('Location', 'Your file contains malicious code, thus please upload valid file.');
        this.multiFiles = [];
        this.location.LocationMapFiles = [];
        this.geojsonFileInputVariable.nativeElement.value = '';
        return;
      }
    }
  }

  _handleReaderLoaded(_readerEvt: any) {
    const binaryString = _readerEvt.target.result;
    if (binaryString && this.fileExtension == 'svg') {
      if (
        binaryString.search('script') == -1 &&
        binaryString.search('function') == -1 &&
        binaryString.search('javascript') == -1 &&
        binaryString.search('alert') == -1 &&
        binaryString.search('onMouseOver') == -1
      ) {
        const testSVG = DOMPurify.sanitize(binaryString);
        const fileName = this.svgFile.name;
        const newFile = new File([testSVG], fileName, { type: 'image/svg+xml', lastModified: new Date().getTime() });
        this.svgFile = newFile;
      } else {
        this.svgFileInputVariable.nativeElement.value = '';
        this.svgFile = null;
        this.selectedSVGFile = null;
        this.utilitiesService.smmodal(
          'Location',
          'Your SVG file contains malicious code, thus please upload valid SVG file.',
        );
        return;
      }
    }
  }

  fileReset(uploadType: string) {
    if (uploadType === 'geojson') {
      this.multiFiles = [];
      this.file = null;

      if (this.geojsonFileInputVariable != undefined) {
        this.geojsonFileInputVariable.nativeElement.value = '';
      }
      this.location.LocationMapFiles = [];
    } else if (uploadType === 'svg') {
      this.svgFile = null;
      this.selectedSVGFile = null;

      if (this.svgFileInputVariable != undefined) {
        this.svgFileInputVariable.nativeElement.value = '';
      }
    } else if (uploadType === 'exel') {
      this.exelFiles = [];
      this.isFileSelected = true;
      if (this.fileInputVariable != undefined) {
        this.fileInputVariable.nativeElement.value = '';
      }
      this.data = [];
    }
  }

  removeSVG() {
    this.IsSVGTable = false;
    this.svgModal = true;
  }

  removeSvgFile() {
    if (this.location.LocationId) {
      this.isReqComplete = true;
      const locationModal = new LocationModule();
      locationModal.LocationId = this.location.LocationId;
      locationModal.LocationSVGMap = this.location.LocationSVGMap;
      this.locationService.RemoveSVG(locationModal).subscribe(
        () => {
          this.closeSVGModal();
          this.selectedSVGFile = null;
          this.isReqComplete = false;
          this.fileReset('svg');
          this.utilitiesService.smmodal('Location', 'SVG deleted successfully!');
        },
        (error) => {
          this.isReqComplete = false;
          console.log(
            'locationService RemoveSVG Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    }
  }

  public closeSVGModal() {
    this.svgModal = false;
  }

  public sensorThresholdChange() {
    const threshold = this.SensorThreshold;
    if (this.locationSensorDetailsDisplay.length > 0) {
      // this.isRequired = true;
      this.locationSensorDetailsDisplay.forEach((lsDetails) => {
        if (lsDetails.SensorType.SensorTypeId == this.selectedSensorType) {
          lsDetails.Sensors.forEach((sens) => {
            sens.Threshold = threshold;
            this.location.LocationSensors.forEach((ls) => {
              if (ls.Sensor.SensorId == sens.SensorId) {
                ls.Sensor.Threshold = threshold;
              }
            });
          });
        }
      });

      this.location.LocationSensors.forEach((ls) => {
        if (ls.Sensor.SensorType.SensorTypeId == this.selectedSensorType) {
          ls.Threshold = threshold;
        }
      });
    }
  }

  public sensorWarnPercentageChange() {
    const warnPercentage = this.SensorWarnPercentage;
    if (this.locationSensorDetailsDisplay.length > 0) {
      // this.isRequired = true;
      this.locationSensorDetailsDisplay.forEach((lsDetails) => {
        if (lsDetails.SensorType.SensorTypeId == this.selectedSensorType) {
          lsDetails.Sensors.forEach((sens) => {
            sens.WarnPercentage = warnPercentage;
            this.location.LocationSensors.forEach((ls) => {
              if (ls.Sensor.SensorId == sens.SensorId) {
                ls.Sensor.WarnPercentage = warnPercentage;
              }
            });
          });
        }
      });

      this.location.LocationSensors.forEach((ls) => {
        if (ls.Sensor.SensorType.SensorTypeId == this.selectedSensorType) {
          ls.WarnPercentage = warnPercentage;
        }
      });
    }
  }
  public getAllGatewaysByVenue(venueId: number, locationId: number) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
    CPlocation.VenueCPId = this.authState.AESEncryptText(venueId);

    this.locationService.GetAllGatewaysByVenue(CPlocation).subscribe(
      (data) => {
        this.location.LocationGateways = data;
        // this.gatewayCurPage = 1;
        if (this.location.LocationGateways.length > 0) {
          this.isLocationGateways = true;
        } else {
          this.isLocationGateways = false;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllGatewaysByVenue Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public hasVenueGatewaysData(): boolean {
    return this.location.LocationGateways != null && this.location.LocationGateways.length > 0;
  }

  public getAllTasksByVenue(venueId: number, locationId: number, LocationTypeId: number, type: number) {
    this.loaderService.display(true);
    let CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);
    CPlocation.VenueLocationTypeId = LocationTypeId;
    if (type == 0) {
      CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
      this.locationService.GetAllTasksByVenue(CPlocation).subscribe(
        (data) => {
          this.location.LocationTasks = data;
          if (this.location.LocationTasks.length > 0) {
            this.isLocationTasks = true;
          } else {
            this.isLocationTasks = false;
          }
          this.ChangeTask();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllTasksByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    } else if (type == 1) {
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(locationId);
      this.locationTemplateService.GetAllTasksByVenue(CPlocation).subscribe(
        (data) => {
          this.location.LocationTasks = data;
          if (this.location.LocationTasks.length > 0) {
            this.isLocationTasks = true;
          } else {
            this.isLocationTasks = false;
          }
          this.ChangeTask();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllTasksByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    } else if (type == 2) {
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(locationId);
      this.locationTemplateService.GetAllTasksByVenue(CPlocation).subscribe(
        (data) => {
          this.locationTemplateModalObj.LocationTasks = data;
          if (this.locationTemplateModalObj.LocationTasks.length > 0) {
            this.isLocationTasksTemplate = true;
          } else {
            this.isLocationTasksTemplate = false;
          }
          this.ChangeTaskTemplate();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllTasksByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    }
  }

  public hasVenueTasksData(): boolean {
    return this.location.LocationTasks != null && this.location.LocationTasks.length > 0;
  }

  public getAllInventoriesByVenue(venueId: number, locationId: number, LocationTypeId: number, type: number) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.VenueLocationTypeId = LocationTypeId;
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);
    if (type == 0) {
      CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
      this.locationService.GetAllInventoriesByVenue(CPlocation).subscribe(
        (data) => {
          this.location.LocationInventories = data;
          if (this.location.LocationInventories.length > 0) {
            this.isLocationInventories = true;
          } else {
            this.isLocationInventories = false;
          }
          this.ChangeInventory();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllInventoriesByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    } else if (type == 1) {
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(locationId);
      this.locationTemplateService.GetAllInventoriesByVenue(CPlocation).subscribe(
        (data) => {
          this.location.LocationInventories = data;
          if (this.location.LocationInventories.length > 0) {
            this.isLocationInventories = true;
          } else {
            this.isLocationInventories = false;
          }
          this.ChangeInventory();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllInventoriesByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    } else if (type == 2) {
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(locationId);
      this.locationTemplateService.GetAllInventoriesByVenue(CPlocation).subscribe(
        (data) => {
          this.locationTemplateModalObj.LocationInventories = data;
          if (this.locationTemplateModalObj.LocationInventories.length > 0) {
            this.isLocationInventoriesTemplate = true;
          } else {
            this.isLocationInventoriesTemplate = false;
          }
          this.ChangeInventoryTemplate();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllInventoriesByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    }
  }

  public hasVenueInventoriesData(): boolean {
    return this.location.LocationInventories != null && this.location.LocationInventories.length > 0;
  }

  public getAllFeedbackReasonsByVenue(venueId: number, locationId: number, TouchlessFeedbackIdentifier: any) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.VenueCPId = this.authState.AESEncryptText(venueId);
    CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
    CPlocation.TouchlessFeedbackIdentifier = TouchlessFeedbackIdentifier;

    this.locationService.GetAllFeedbackReasonsByVenue(CPlocation).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.location.LocationFeedbackReasons = data;
          this.isFeedbackReason = true;
        } else {
          this.location.LocationFeedbackReasons = [];
          this.isFeedbackReason = false;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationService GetAllFeedbackReasonsByVenue Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public hasFeedbackReasonsData(): boolean {
    return this.location.LocationFeedbackReasons != null && this.location.LocationFeedbackReasons.length > 0;
  }

  public hasFeedbackData(): boolean {
    return this.feedbackIdentifiers != null && this.feedbackIdentifiers.length > 0;
  }

  public getAllElementsByVenue(venueId: number, locationId: number, LocationTypeId: number, type: number) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.VenueLocationTypeId = LocationTypeId;
    CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);

    let that = this;
    if (type == 0) {
      CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
      this.locationService.GetAllElementsByVenue(CPlocation).subscribe(
        (data) => {
          this.location.LocationElements = data;
          if (this.location.LocationElements.length > 0) {
            this.totalElementWeightage = 0;
            this.location.LocationElements.forEach(function (element) {
              element.Area = new AreaModule();
              // If condition used only in update location element
              if (that.location.LocationId && element.Areas && element.Areas.length > 0) {
                element.Areas.forEach(function (area) {
                  element.Area.AreaId = area.AreaId;
                  element.Area.AreaName = area.AreaName;
                  element.Area.IsActive = area.IsActive;
                });
              }

              element.IsActive = element.IsActive ? true : false;
              if (element.ElementWeightage) {
                that.totalElementWeightage += Number(element.ElementWeightage);
              }
            });
            this.isLocationElements = true;
          } else {
            this.isLocationElements = false;
          }
          this.ChangeIElement();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllElementsByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    } else if (type == 1) {
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(locationId);
      this.locationTemplateService.GetAllElementsByVenue(CPlocation).subscribe(
        (data) => {
          this.location.LocationElements = data;
          if (this.location.LocationElements.length > 0) {
            this.totalElementWeightage = 0;
            this.location.LocationElements.forEach(function (element) {
              element.Area = new AreaModule();
              // If condition used only in update location element
              if (
                that.location.LocationId ||
                (that.location.LocationTemplateId && element.Areas && element.Areas.length > 0)
              ) {
                element.Areas.forEach(function (area) {
                  element.Area.AreaId = area.AreaId;
                  element.Area.AreaName = area.AreaName;
                  element.Area.IsActive = area.IsActive;
                });
              }

              element.IsActive = element.IsActive ? true : false;
              if (element.ElementWeightage) {
                that.totalElementWeightage += Number(element.ElementWeightage);
              }
            });
            this.isLocationElements = true;
          } else {
            this.isLocationElements = false;
          }
          this.ChangeIElement();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllElementsByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    } else if (type == 2) {
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(locationId);
      this.locationTemplateService.GetAllElementsByVenue(CPlocation).subscribe(
        (data) => {
          this.locationTemplateModalObj.LocationElements = data;
          if (this.locationTemplateModalObj.LocationElements.length > 0) {
            this.totalTemplateElementWeightage = 0;
            this.locationTemplateModalObj.LocationElements.forEach(function (element) {
              element.Area = new AreaModule();
              // If condition used only in update location element
              if (that.location.LocationId && element.Areas && element.Areas.length > 0) {
                element.Areas.forEach(function (area) {
                  element.Area.AreaId = area.AreaId;
                  element.Area.AreaName = area.AreaName;
                  element.Area.IsActive = area.IsActive;
                });
              }

              element.IsActive = element.IsActive ? true : false;
              if (element.ElementWeightage) {
                that.totalTemplateElementWeightage += Number(element.ElementWeightage);
              }
            });
            this.isLocationElementsTemplate = true;
          } else {
            this.isLocationElementsTemplate = false;
          }
          this.ChangeIElementTemplate();
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);
          console.log(
            'locationService GetAllElementsByVenue Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    }
  }

  public hasVenueElementsData(): boolean {
    return this.location.LocationElements != null && this.location.LocationElements.length > 0;
  }

  public checkLocationElementIsChecked(type: string, isChecked: any, elementId: number) {
    let that = this;
    if (type == 'locationElement') {
      if (isChecked == false) {
        this.location.LocationElements.forEach(function (element) {
          if (element.VenueElementId == elementId) {
            element.ElementWeightage = null;
            if (element.Area) {
              element.Area.AreaId = null;
              element.Area.AreaName = '';
              element.Area.IsActive = 0;
            }

            element.InspectionScoreFactor.InspectionScoreFactorId = null;
            element.InspectionScoreFactor.IsActive = 0;
          }
        });
      }

      this.totalElementWeightage = 0;
      this.location.LocationElements.forEach(function (element) {
        if (element.IsActive == true) {
          that.totalElementWeightage += Number(element.ElementWeightage);
        }
      });
      if (this.location.LocationElements && this.location.LocationElements.length > 0) {
        let item = this.location.LocationElements.filter(
          (inventory) => inventory.IsActive == false || inventory.IsActive == 0,
        );
        if (item && item.length > 0) {
          this.isAllElementChecked = false;
        } else {
          this.isAllElementChecked = true;
        }
      }
    }

    if (type == 'locationTemplateElement') {
      if (isChecked == false) {
        this.locationTemplateModalObj.LocationElements.forEach(function (element) {
          if (element.VenueElementId == elementId) {
            element.ElementWeightage = null;
            if (element.Area) {
              element.Area.AreaId = null;
              element.Area.AreaName = '';
              element.Area.IsActive = 0;
            }

            element.InspectionScoreFactor.InspectionScoreFactorId = null;
            element.InspectionScoreFactor.IsActive = 0;
          }
        });
      }

      this.totalTemplateElementWeightage = 0;
      this.locationTemplateModalObj.LocationElements.forEach(function (element) {
        if (element.IsActive == true) {
          that.totalTemplateElementWeightage += Number(element.ElementWeightage);
        }
      });
    }
  }

  //// Feature Configurations
  public GetModuleList(venueId: number, locationId: number, type: number) {
    this.isCheckBoxVisible = false;
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);

    if (type == 0) {
      CPlocation.LocationCPId = this.authState.AESEncryptText(locationId);
      this.locationService.GetFeatureConfigurations(CPlocation).subscribe(
        (data) => {
          this.isCheckBoxVisible = false;

          if (data != null && data.length > 0) {
            this.isCheckBoxVisible = true;
            let that = this;
            this.Modules = [];

            // add module
            if (this.venue.VenueId == null) {
              this.Modules = data;
              if (this.Modules != null && this.Modules.length > 0) {
                this.items = [];

                this.Modules.forEach(function (item) {
                  let childrens = [];
                  item.ModuleFeatures.forEach(function (moduleFeature) {
                    let ModuleFeatureChildrens = [];
                    childrens.push({
                      text: moduleFeature.ModuleFeatureName,
                      value: moduleFeature.ModuleFeatureId,
                      children: ModuleFeatureChildrens,
                      collapsed: true,
                    });

                    moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
                      let ModuleFeatureTypesChildrens = [];
                      ModuleFeatureChildrens.push({
                        text: moduleFeatureType.FeatureTypeName,
                        value: moduleFeatureType.FeatureTypeCode,
                        children: ModuleFeatureTypesChildrens,
                        collapsed: true,
                      });

                      moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                        featureConfig.IsActive = featureConfig.IsActive ? true : false;
                        ModuleFeatureTypesChildrens.push({
                          text: featureConfig.FeatureConfigurationName,
                          checked: featureConfig.IsActive,
                          value: featureConfig.FeatureConfigurationId,
                          collapsed: true,
                        });
                      });
                    });
                  });
                  that.items.push(
                    new TreeviewItem({
                      text: item.ModuleName,
                      value: item.ModuleId,
                      checked: false,
                      children: childrens,
                      collapsed: true,
                    }),
                  );
                });
              }
            }

            // update module
            if (this.venue.VenueId != null) {
              if (this.venue.Modules != null && this.venue.Modules.length > 0) {
                this.items = [];

                this.venue.Modules.forEach(function (item) {
                  let childrens = [];
                  item.ModuleFeatures.forEach(function (moduleFeature) {
                    let ModuleFeatureChildrens = [];
                    childrens.push({
                      text: moduleFeature.ModuleFeatureName,
                      value: moduleFeature.ModuleFeatureId,
                      children: ModuleFeatureChildrens,
                      collapsed: true,
                    });

                    moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
                      let ModuleFeatureTypesChildrens = [];
                      ModuleFeatureChildrens.push({
                        text: moduleFeatureType.FeatureTypeCode,
                        value: moduleFeatureType.FeatureTypeCode,
                        children: ModuleFeatureTypesChildrens,
                        collapsed: true,
                      });

                      moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                        featureConfig.IsActive = featureConfig.IsActive ? true : false;
                        ModuleFeatureTypesChildrens.push({
                          text: featureConfig.FeatureConfigurationName,
                          checked: featureConfig.IsActive,
                          value: featureConfig.FeatureConfigurationId,
                          collapsed: true,
                        });
                      });
                    });
                  });
                  that.items.push(
                    new TreeviewItem({
                      text: item.ModuleName,
                      value: item.ModuleId,
                      checked: false,
                      children: childrens,
                      collapsed: true,
                    }),
                  );
                });
              }
            }
          }
        },
        (error) => {
          console.log(
            'SubscriptionService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
          );
        },
      );
    } else if (type == 1) {
      CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(locationId);
      this.locationTemplateService.GetFeatureConfigurations(CPlocation).subscribe(
        (data) => {
          this.isCheckBoxVisible = false;

          if (data != null && data.length > 0) {
            this.isCheckBoxVisible = true;
            let that = this;
            this.Modules = [];
            this.Modules = data;
            if (this.Modules != null && this.Modules.length > 0) {
              this.items = [];

              this.Modules.forEach(function (item) {
                let childrens = [];
                item.ModuleFeatures.forEach(function (moduleFeature) {
                  let ModuleFeatureChildrens = [];
                  childrens.push({
                    text: moduleFeature.ModuleFeatureName,
                    value: moduleFeature.ModuleFeatureId,
                    children: ModuleFeatureChildrens,
                    collapsed: true,
                  });

                  moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
                    let ModuleFeatureTypesChildrens = [];
                    ModuleFeatureChildrens.push({
                      text: moduleFeatureType.FeatureTypeName,
                      value: moduleFeatureType.FeatureTypeCode,
                      children: ModuleFeatureTypesChildrens,
                      collapsed: true,
                    });

                    moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                      featureConfig.IsActive = featureConfig.IsActive ? true : false;
                      ModuleFeatureTypesChildrens.push({
                        text: featureConfig.FeatureConfigurationName,
                        checked: featureConfig.IsActive,
                        value: featureConfig.FeatureConfigurationId,
                        collapsed: true,
                      });
                    });
                  });
                });
                that.items.push(
                  new TreeviewItem({
                    text: item.ModuleName,
                    value: item.ModuleId,
                    checked: false,
                    children: childrens,
                    collapsed: true,
                  }),
                );
              });
            }
          }
        },
        (error) => {
          console.log(
            'locationTemplateService GetFeatureConfigurations Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
        },
      );
    }
  }

  public onSelectedChange(_downlineItems: DownlineTreeviewItem[]) {
    this.isAllFeatureConfigurationChecked = false;

    if (this.treeviewComponent && this.treeviewComponent.items != null && this.treeviewComponent.items != undefined) {
      let item = this.treeviewComponent.items.filter((tree) => tree.checked == false || tree.checked == undefined);
      if (item && item.length > 0) {
        this.isAllFeatureConfigurationChecked = false;
      } else {
        this.isAllFeatureConfigurationChecked = true;
      }
    }
  }

  public onAllCheckedChange(event: any) {
    let that = this;
    this.treeviewComponent.items.forEach(function (it) {
      it.checked = event.checked;
      that.changeChildrenSelection(it, event.checked);
    });
  }

  public changeChildrenSelection(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }

  public getSignagePage(locationId: number, venueName: string) {
    let signagePageUrl = '';
    signagePageUrl = this.utilitiesService.signageUrl(locationId, venueName);
    window.open(signagePageUrl, '_blank');
  }

  public getTouchlessFeedbackPage(locationId: number) {
    let signagePageUrl = this.configuration.TouchlessFeedbackAppBaseURL + locationId;
    window.open(signagePageUrl, '_blank');
  }

  public getCleaningAlertRequestPage(locationId: number) {
    let signagePageUrl = this.configuration.cleaningAlertRequestURL + locationId;
    window.open(signagePageUrl, '_blank');
  }

  public getVenueById(venueId: number, isLocationAdd: boolean) {
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.authState.AESEncryptText(venueId);
    this.venueService.GetSingle(CPvenue).subscribe(
      (data) => {
        if (data != null) {
          if (data.EnableLocationStatus == 1) {
            this.enableLocationStatus = true;
          }

          if (data.EnableTouchlessFeedback == 1 && data.EnableTouchlessFeedbackReason == 1) {
            this.isFeedbackReasonshow = true;
            this.isFeedbackReasonshowTemplate = true;
          } else {
            this.isFeedbackReasonshow = false;
            this.isFeedbackReasonshowTemplate = false;
          }

          if (isLocationAdd) {
            this.getAllVenues('', -1, -1);
          }
        }
      },
      (error) => {
        console.log(
          'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public getAllLocationTypes() {
    const searchfilter = { SearchQuery: '', GenericSearch: 0, IsActive: '1', SortColumn: null, SortOrder: null };

    this.locationTypeService.GetAll(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.locationTypes = data;
      },
      (error) => {
        console.log(
          'locationTypeService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public changeLocationType($event, type: number) {
    if ($event != null) {
      if (type == 0) {
        this.location.VenueLocationTypeId = $event;
      } else if (type == 2) {
        this.locationTemplateModalObj.VenueLocationTypeId = $event;
      }
      if ((this.location.LocationId && type == 0) || (this.locationTemplateModalObj.LocationId && type == 2)) {
        if (this.isLocationAreaShow) {
          this.getAllAreas(this.location.VenueLocationTypeId);
        }
        this.getAllTasksByVenue(this.location.Building.Venue.VenueId, this.location.LocationId, $event, type);
        this.getAllInventoriesByVenue(this.location.Building.Venue.VenueId, this.location.LocationId, $event, type);
        this.getAllElementsByVenue(this.location.Building.Venue.VenueId, this.location.LocationId, $event, type);
      } else {
        if (this.isLocationAreaShow) {
          this.getAllAreas(this.location.VenueLocationTypeId);
        }
        this.getAllTasksByVenue(this.location.Building.Venue.VenueId, 0, $event, type);
        this.getAllInventoriesByVenue(this.location.Building.Venue.VenueId, 0, $event, type);
        this.getAllElementsByVenue(this.location.Building.Venue.VenueId, 0, $event, type);
      }
    } else {
      if (type == 0) {
        this.location.VenueLocationTypeId = null;
        this.location.LocationTasks = null;
        this.location.LocationInventories = null;
        this.location.LocationElements = null;
        this.isLocationTasks = false;
        this.isLocationInventories = false;
        this.isLocationElements = false;
      } else if (type == 2) {
        this.locationTemplateModalObj.VenueLocationTypeId = null;
        this.locationTemplateModalObj.LocationTasks = null;
        this.locationTemplateModalObj.LocationInventories = null;
        this.locationTemplateModalObj.LocationElements = null;
        this.isLocationTasksTemplate = false;
        this.isLocationInventoriesTemplate = false;
        this.isLocationElementsTemplate = false;
        this.isFeedbackReasonTemplate = false;
      }
    }
  }

  // For Reflection Of Location Template Dropdown to Location Form -----------
  public getAllLocationTemplates() {
    const searchfilter = {
      SearchQuery: '',
      GenericSearch: 0,
      IsActive: '1',
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.locationTemplateService.GetLocationTemplateDropDown(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        this.locationTemplates = data;
      },
      (error) => {
        console.log(
          'locationTemplateService GetLocationTemplateDropDown Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  changeLocationTemplate($event: number) {
    this.location.LocationTemplateId = null;
    this.location.LocationTemplateId = $event;
    if ($event != null) {
      this.getLocationTemplateById($event);
    }
  }

  public getLocationTemplateById(LocationTemplateId: number) {
    this.isAdd = false;
    this.loaderService.display(true);
    const CPlocation = new LocationTemplateModule();
    CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(LocationTemplateId);

    this.locationTemplateService.GetSingle(CPlocation).subscribe(
      (data) => {
        this.locationTemplate = data;
        this.loaderService.display(false);
        this.location.TouchlessFeedbackIdentifier = this.locationTemplate.TouchlessFeedbackIdentifier;
        if (this.locationTemplate.Building.Venue.VenueId) {
          this.getAllBuildingsByVenue(this.locationTemplate.Building.Venue.VenueId);
          if (this.locationTemplate.LocationTemplateId) {
            this.GetModuleList(
              this.locationTemplate.Building.Venue.VenueId,
              this.locationTemplate.LocationTemplateId,
              1,
            );
            this.getAllReasonsByVenue(
              this.locationTemplate.Building.Venue.VenueId,
              this.locationTemplate.LocationTemplateId,
              this.locationTemplate.TouchlessFeedbackIdentifier,
              false,
            );
          } else {
            this.GetModuleList(this.locationTemplate.Building.Venue.VenueId, 0, 1);
            this.getAllReasonsByVenue(
              this.locationTemplate.Building.Venue.VenueId,
              0,
              this.locationTemplate.TouchlessFeedbackIdentifier,
              false,
            );
          }
          if (this.locationTemplate.Building.BuildingId) {
            this.getAllLevelsByBuilding(this.locationTemplate.Building.BuildingId, 0);
            this.getAllZonesByBuilding(this.locationTemplate.Building.BuildingId, 0);
          }
          if (this.locationTemplate.VenueLocationTypeId && this.locationTemplate.LocationTemplateId) {
            if (this.isLocationAreaShow) {
              this.getAllAreas(this.locationTemplate.VenueLocationTypeId);
            }
            this.getAllTasksByVenue(
              this.locationTemplate.Building.Venue.VenueId,
              this.locationTemplate.LocationTemplateId,
              this.locationTemplate.VenueLocationTypeId,
              1,
            );
            this.getAllInventoriesByVenue(
              this.locationTemplate.Building.Venue.VenueId,
              this.locationTemplate.LocationTemplateId,
              this.locationTemplate.VenueLocationTypeId,
              1,
            );
            this.getAllElementsByVenue(
              this.locationTemplate.Building.Venue.VenueId,
              this.locationTemplate.LocationTemplateId,
              this.locationTemplate.VenueLocationTypeId,
              1,
            );
          } else {
            if (this.isLocationAreaShow) {
              this.getAllAreas(this.location.VenueLocationTypeId);
            }
            this.getAllTasksByVenue(
              this.locationTemplate.Building.Venue.VenueId,
              0,
              this.locationTemplate.VenueLocationTypeId,
              1,
            );
            this.getAllInventoriesByVenue(
              this.locationTemplate.Building.Venue.VenueId,
              0,
              this.locationTemplate.VenueLocationTypeId,
              1,
            );
            this.getAllElementsByVenue(
              this.locationTemplate.Building.Venue.VenueId,
              0,
              this.locationTemplate.VenueLocationTypeId,
              1,
            );
          }
        }

        this.location.VenueLocationTypeId = this.locationTemplate.VenueLocationTypeId;
        if (
          this.locationTemplate.Building.BuildingId != null &&
          this.locationTemplate.Building.BuildingId != undefined &&
          this.locationTemplate.Building.BuildingId != 0
        ) {
          this.location.Building.BuildingId = this.locationTemplate.Building.BuildingId;
        }
        if (
          this.locationTemplate.Building.Level.LevelId != null &&
          this.locationTemplate.Building.Level.LevelId != undefined &&
          this.locationTemplate.Building.Level.LevelId != 0
        ) {
          this.location.Building.Level.LevelId = this.locationTemplate.Building.Level.LevelId;
        }
        if (
          this.locationTemplate.Zone.ZoneId != null &&
          this.locationTemplate.Zone.ZoneId != undefined &&
          this.locationTemplate.Zone.ZoneId != 0
        ) {
          this.location.Zone.ZoneId = this.locationTemplate.Zone.ZoneId;
        }
        this.location.Building.Venue.VenueId = this.locationTemplate.Building.Venue.VenueId;
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTemplateService GetSingle Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  // For Allow Gender Dropdown ------------------
  public changeAllowGender(event: any) {
    this.location.AllowGender = event.checked;
    this.location.LocationType = null;
    if (this.location.AllowGender == true) {
      this.isStatus = true;
    } else {
      this.isStatus = false;
    }
  }

  // For Location Template Modal Popup ----------------
  public openLocationTemplate() {
    this.loaderService.display(true);
    this.locationTemplateModalObj = new LocationTemplateModule();
    this.locationTemplateModalObj.Zone = new ZoneModule();
    this.locationTemplateModalObj.Building = new BuildingModule();
    this.locationTemplateModalObj.Building.Venue = new VenueModule();
    this.locationTemplateModalObj.Building.Level = new LevelModule();

    this.locationTemplateModalObj.LocationTasks = [];
    this.locationTemplateModalObj.LocationInventories = [];
    this.locationTemplateModalObj.LocationElements = [];
    this.locationTemplateModalObj.LocationFeedbackReasons = [];
    this.templateModal = true;

    if (this.levels == undefined && this.levels == null && this.levels.length <= 0) {
      this.zoneDisabledTemplate = true;
    }
    this.zonesTemplate = this.zones;
    if (this.levels == undefined && this.levels == null && this.levels.length <= 0) {
      this.levelDisabledTemplate = true;
    }
    this.levelsTemplate = this.levels;

    this.locationTemplateModalObj.VenueLocationTypeId = this.location.VenueLocationTypeId;
    this.locationTemplateModalObj.Building.Level.LevelId = this.location.Building.Level.LevelId;
    this.locationTemplateModalObj.Zone.ZoneId = this.location.Zone.ZoneId;
    this.locationTemplateModalObj.Building.BuildingId = this.location.Building.BuildingId;
    this.locationTemplateModalObj.Building.Venue.VenueId = this.location.Building.Venue.VenueId;
    this.locationTemplateModalObj.Building.Venue.VenueName = this.location.Building.Venue.VenueName;

    let that = this;
    if (this.location.LocationTasks != null && this.location.LocationTasks.length > 0) {
      this.location.LocationTasks.forEach(function (ltask) {
        const task = new LocationTaskModule();
        task.TaskId = ltask.TaskId;
        task.VenueTaskId = ltask.VenueTaskId;
        task.LocationTaskName = ltask.LocationTaskName;
        task.IsActive = ltask.IsActive;
        that.locationTemplateModalObj.LocationTasks.push(task);
      });
    }

    // this.taskCurPageTemplate = 1;
    if (
      this.locationTemplateModalObj.LocationTasks != undefined &&
      this.locationTemplateModalObj.LocationTasks.length > 0
    ) {
      this.isLocationTasksTemplate = true;
      this.ChangeTaskTemplate();
    } else {
      this.isLocationTasksTemplate = false;
    }
    // this.totalTasksTemplate = this.locationTemplateModalObj.LocationTasks.length;
    // this.taskNumberOfPagesTemplate = Math.ceil(this.totalTasksTemplate / this.taskPageSizeTemplate);
    if (this.location.LocationInventories != null && this.location.LocationInventories.length > 0) {
      this.location.LocationInventories.forEach(function (linventory) {
        const inventory = new LocationInventoryModule();
        inventory.InventoryId = linventory.InventoryId;
        inventory.VenueInventoryId = linventory.VenueInventoryId;
        inventory.InventoryName = linventory.InventoryName;
        inventory.IsActive = linventory.IsActive;
        that.locationTemplateModalObj.LocationInventories.push(inventory);
      });
    }

    // this.inventoryCurPageTemplate = 1;
    if (
      this.locationTemplateModalObj.LocationInventories != undefined &&
      this.locationTemplateModalObj.LocationInventories.length > 0
    ) {
      this.isLocationInventoriesTemplate = true;
      this.ChangeInventoryTemplate();
    } else {
      this.isLocationInventoriesTemplate = false;
    }
    if (this.location.LocationElements != null && this.location.LocationElements.length > 0) {
      this.location.LocationElements.forEach(function (lelement) {
        const element = new LocationElementModule();
        element.ElementId = lelement.ElementId;
        element.VenueElementId = lelement.VenueElementId;
        element.ElementName = lelement.ElementName;
        element.IsActive = lelement.IsActive;
        element.ElementWeightage = lelement.ElementWeightage;

        element.InspectionScoreFactor = new InspectionScoreFactor();
        element.InspectionScoreFactor.InspectionScoreFactorId = lelement.InspectionScoreFactor.InspectionScoreFactorId;
        element.InspectionScoreFactor.ScoreFactorName = lelement.InspectionScoreFactor.ScoreFactorName;
        element.InspectionScoreFactor.IsActive = lelement.InspectionScoreFactor.IsActive;

        element.Area = new AreaModule();
        //// If condition used only in update location template
        element.Area.AreaId = lelement.Area.AreaId;
        element.Area.AreaName = lelement.Area.AreaName;
        element.Area.IsActive = lelement.Area.IsActive;
        that.locationTemplateModalObj.LocationElements.push(element);
      });
    }

    if (
      this.locationTemplateModalObj.LocationElements != undefined &&
      this.locationTemplateModalObj.LocationElements.length > 0
    ) {
      this.isLocationElementsTemplate = true;
      this.ChangeIElementTemplate();
      this.totalTemplateElementWeightage = 0;
      this.locationTemplateModalObj.LocationElements.forEach(function (element) {
        element.IsActive = element.IsActive ? true : false;
        if (element.ElementWeightage) {
          that.totalTemplateElementWeightage += Number(element.ElementWeightage);
        }
      });
    } else {
      this.isLocationElementsTemplate = false;
    }
    that.locationTemplateModalObj.TouchlessFeedbackIdentifier = this.location.TouchlessFeedbackIdentifier;
    if (this.location.LocationFeedbackReasons != null && this.location.LocationFeedbackReasons.length > 0) {
      this.location.LocationFeedbackReasons.forEach(function (lreason) {
        const reason = new VenueLocationTouchlessFeedbackReason();
        reason.VenueFeedbackReasonId = lreason.VenueFeedbackReasonId;
        reason.VenueLocationFeedbackReasonId = lreason.VenueLocationFeedbackReasonId;
        reason.TouchlessFeedbackIdentifier = lreason.TouchlessFeedbackIdentifier;
        reason.Reason = lreason.Reason;
        reason.ResponseType = lreason.ResponseType;
        reason.IsActive = lreason.IsActive;
        that.locationTemplateModalObj.LocationFeedbackReasons.push(reason);
      });
    }

    // this.feedbackReasonCurPageTemplate = 1;
    if (
      this.locationTemplateModalObj.LocationFeedbackReasons != undefined &&
      this.locationTemplateModalObj.LocationFeedbackReasons.length > 0
    ) {
      this.isFeedbackReasonTemplate = true;
    } else {
      this.isFeedbackReasonTemplate = false;
    }
    this.isCheckBoxVisibleTemplate = false;
    let moduleObj: Module[] = [];
    if (this.treeviewComponent.items != null) {
      this.treeviewComponent.items.forEach(function (it) {
        let module = new Module();
        module.ModuleId = it.value;
        module.ModuleName = it.text;
        module.ModuleFeatures = [];

        it.children.forEach(function (mf) {
          let mFeature = new ModuleFeature();
          mFeature.ModuleFeatureId = mf.value;
          mFeature.ModuleFeatureName = mf.text;
          mFeature.ModuleFeatureTypes = [];

          mf.children.forEach(function (mft) {
            let mFeatureType = new ModuleFeatureType();
            mFeatureType.FeatureTypeCode = mft.value;
            mFeatureType.FeatureTypeName = mft.text;
            mFeatureType.FeatureConfigurations = [];

            mft.children.forEach(function (fc) {
              if (fc.checked || fc.checked == undefined) {
                let fConfig = new FeatureConfiguration();
                fConfig.FeatureConfigurationId = fc.value;
                fConfig.FeatureConfigurationName = fc.text;
                fConfig.IsActive = 1;

                mFeatureType.FeatureConfigurations.push(fConfig);
              } else {
                let fConfig = new FeatureConfiguration();
                fConfig.FeatureConfigurationId = fc.value;
                fConfig.FeatureConfigurationName = fc.text;
                fConfig.IsActive = 0;

                mFeatureType.FeatureConfigurations.push(fConfig);
              }
            });
            mFeature.ModuleFeatureTypes.push(mFeatureType);
            // }
          });
          module.ModuleFeatures.push(mFeature);
          // }
        });
        moduleObj.push(module);
        // }
      });

      if (moduleObj != null && moduleObj.length > 0) {
        this.itemsTemplate = [];
        that.isCheckBoxVisibleTemplate = true;
        moduleObj.forEach(function (item) {
          let childrens = [];
          item.ModuleFeatures.forEach(function (moduleFeature) {
            let ModuleFeatureChildrens = [];
            childrens.push({
              text: moduleFeature.ModuleFeatureName,
              value: moduleFeature.ModuleFeatureId,
              children: ModuleFeatureChildrens,
              collapsed: true,
            });

            moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
              let ModuleFeatureTypesChildrens = [];
              ModuleFeatureChildrens.push({
                text: moduleFeatureType.FeatureTypeName,
                value: moduleFeatureType.FeatureTypeCode,
                children: ModuleFeatureTypesChildrens,
                collapsed: true,
              });

              moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                featureConfig.IsActive = featureConfig.IsActive ? true : false;
                ModuleFeatureTypesChildrens.push({
                  text: featureConfig.FeatureConfigurationName,
                  checked: featureConfig.IsActive,
                  value: featureConfig.FeatureConfigurationId,
                  collapsed: true,
                });
              });
            });
          });
          that.itemsTemplate.push(
            new TreeviewItem({
              text: item.ModuleName,
              value: item.ModuleId,
              checked: false,
              children: childrens,
              collapsed: true,
            }),
          );
        });
      } else {
        this.treeviewComponent.items.forEach(function (it) {
          let module = new Module();
          module.ModuleId = it.value;
          module.ModuleName = it.text;
          module.ModuleFeatures = [];

          it.children.forEach(function (mf) {
            let mFeature = new ModuleFeature();
            mFeature.ModuleFeatureId = mf.value;
            mFeature.ModuleFeatureName = mf.text;
            mFeature.ModuleFeatureTypes = [];

            mf.children.forEach(function (mft) {
              let mFeatureType = new ModuleFeatureType();
              mFeatureType.FeatureTypeCode = mft.value;
              mFeatureType.FeatureTypeName = mft.text;
              mFeatureType.FeatureConfigurations = [];

              mft.children.forEach(function (fc) {
                let fConfig = new FeatureConfiguration();
                fConfig.FeatureConfigurationId = fc.value;
                fConfig.FeatureConfigurationName = fc.text;
                fConfig.IsActive = 0;

                mFeatureType.FeatureConfigurations.push(fConfig);
              });
              mFeature.ModuleFeatureTypes.push(mFeatureType);
            });
            module.ModuleFeatures.push(mFeature);
          });
          moduleObj.push(module);
        });

        this.itemsTemplate = [];
        that.isCheckBoxVisibleTemplate = true;
        moduleObj.forEach(function (item) {
          let childrens = [];
          item.ModuleFeatures.forEach(function (moduleFeature) {
            let ModuleFeatureChildrens = [];
            childrens.push({
              text: moduleFeature.ModuleFeatureName,
              value: moduleFeature.ModuleFeatureId,
              children: ModuleFeatureChildrens,
              collapsed: true,
            });

            moduleFeature.ModuleFeatureTypes.forEach(function (moduleFeatureType) {
              let ModuleFeatureTypesChildrens = [];
              ModuleFeatureChildrens.push({
                text: moduleFeatureType.FeatureTypeName,
                value: moduleFeatureType.FeatureTypeCode,
                children: ModuleFeatureTypesChildrens,
                collapsed: true,
              });

              moduleFeatureType.FeatureConfigurations.forEach(function (featureConfig) {
                featureConfig.IsActive = featureConfig.IsActive ? true : false;
                ModuleFeatureTypesChildrens.push({
                  text: featureConfig.FeatureConfigurationName,
                  checked: featureConfig.IsActive,
                  value: featureConfig.FeatureConfigurationId,
                  collapsed: true,
                });
              });
            });
          });
          that.itemsTemplate.push(
            new TreeviewItem({
              text: item.ModuleName,
              value: item.ModuleId,
              checked: false,
              children: childrens,
              collapsed: true,
            }),
          );
        });
      }
    }
    setTimeout(() => {
      let el = document.getElementById('scrolltop');
      el.scrollTop = el.scrollHeight;
      el.scrollTop = 0;
    }, 500);
    this.loaderService.display(false);
  }

  closeTemplateModal() {
    this.totalTemplateElementWeightage = 0;
    this.templateModal = false;
    this.locationTemplateModalObj = new LocationTemplateModule();
    this.locationTemplateModalObj.Zone = new ZoneModule();
    this.locationTemplateModalObj.Building = new BuildingModule();
    this.locationTemplateModalObj.Building.Venue = new VenueModule();
    this.locationTemplateModalObj.Building.Level = new LevelModule();
    this.locationTemplateModalObj.LocationTasks = [];
    this.locationTemplateModalObj.LocationInventories = [];
    this.locationTemplateModalObj.LocationElements = [];
    this.locationTemplateModalObj.LocationFeedbackReasons = [];
    this.isCheckBoxVisibleTemplate = false;
  }

  public hasVenueTasksDataTemplate(): boolean {
    return (
      this.locationTemplateModalObj.LocationTasks != null && this.locationTemplateModalObj.LocationTasks.length > 0
    );
  }

  public hasVenueInventoriesDataTemplate(): boolean {
    return (
      this.locationTemplateModalObj.LocationInventories != null &&
      this.locationTemplateModalObj.LocationInventories.length > 0
    );
  }

  public hasVenueElementsDataTemplate(): boolean {
    return (
      this.locationTemplateModalObj.LocationElements != null &&
      this.locationTemplateModalObj.LocationElements.length > 0
    );
  }

  public hasLocationFeedbackReasonsDataTemplate(): boolean {
    return (
      this.locationTemplateModalObj.LocationFeedbackReasons != null &&
      this.locationTemplateModalObj.LocationFeedbackReasons.length > 0
    );
  }

  public addTemplateLocation(locationForm: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (locationForm.status == 'INVALID' && locationForm.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Location Template',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }
    const taskPayload: LocationTaskModule[] = [];
    const inventoryPayload: LocationInventoryModule[] = [];
    const elementPayload: LocationElementModule[] = [];
    const locationReasonPayload: VenueLocationTouchlessFeedbackReason[] = [];
    const _locationDetails: LocationTemplateModule = new LocationTemplateModule();

    _locationDetails.LocationTemplateName = this.utilitiesService.removeInnerSpaces(
      this.locationTemplateModalObj.LocationTemplateName,
    );
    if (_locationDetails.LocationTemplateName == null || _locationDetails.LocationTemplateName == '') {
      this.locationTemplateModalObj.LocationTemplateName = _locationDetails.LocationTemplateName;
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    _locationDetails.AddedBy = this.currentUser.UserId;
    _locationDetails.LocationType = this.locationTemplateModalObj.LocationType;
    _locationDetails.LevelId = this.locationTemplateModalObj.Building.Level.LevelId;
    _locationDetails.NextRestroomDistance = this.locationTemplateModalObj.NextRestroomDistance;
    _locationDetails.NextRestroomDirection = this.locationTemplateModalObj.NextRestroomDirection;
    _locationDetails.LocationStatus = this.locationTemplateModalObj.LocationStatus;

    if (this.locationTemplateModalObj.LocationTasks != null) {
      for (let i = 0; i < this.locationTemplateModalObj.LocationTasks.length; i++) {
        const _locationTasks: LocationTaskModule = new LocationTaskModule();
        this.locationTemplateModalObj.LocationTasks[i].IsActive = this.locationTemplateModalObj.LocationTasks[i]
          .IsActive
          ? 1
          : 0;

        if (this.locationTemplateModalObj.LocationTasks[i].IsActive == 1) {
          _locationTasks.TaskId = this.locationTemplateModalObj.LocationTasks[i].TaskId;
          _locationTasks.IsActive = this.locationTemplateModalObj.LocationTasks[i].IsActive ? 1 : 0;
          _locationTasks.VenueTaskId = this.locationTemplateModalObj.LocationTasks[i].VenueTaskId;
          taskPayload.push(_locationTasks);
        }
      }
    }

    if (this.locationTemplateModalObj.LocationInventories != null) {
      for (let i = 0; i < this.locationTemplateModalObj.LocationInventories.length; i++) {
        const _locationInventories: LocationInventoryModule = new LocationInventoryModule();
        this.locationTemplateModalObj.LocationInventories[i].IsActive = this.locationTemplateModalObj
          .LocationInventories[i].IsActive
          ? 1
          : 0;

        if (this.locationTemplateModalObj.LocationInventories[i].IsActive == 1) {
          _locationInventories.InventoryId = this.locationTemplateModalObj.LocationInventories[i].InventoryId;
          _locationInventories.IsActive = this.locationTemplateModalObj.LocationInventories[i].IsActive ? 1 : 0;
          _locationInventories.VenueInventoryId = this.locationTemplateModalObj.LocationInventories[i].VenueInventoryId;
          inventoryPayload.push(_locationInventories);
        }
      }
    }

    if (
      this.locationTemplateModalObj.LocationElements != null &&
      this.locationTemplateModalObj.LocationElements.length > 0 &&
      this.isLocationElements &&
      this.isLocationElementShow
    ) {
      let that = this;
      let isElementChecked = false;
      this.totalTemplateElementWeightage = 0;
      this.locationTemplateModalObj.LocationElements.forEach(function (element) {
        element.Areas = [];
        if (element.IsActive == true) {
          if (element.Area != null && element.Area.AreaId > 0) {
            const areaObj: AreaModule = new AreaModule();
            areaObj.AreaId = element.Area.AreaId;
            areaObj.AreaName = element.Area.AreaName;
            areaObj.IsActive = 1;
            element.Areas.push(areaObj);
          }

          that.totalTemplateElementWeightage += Number(element.ElementWeightage);
          isElementChecked = true;
        }
      });

      if (isElementChecked && (this.totalTemplateElementWeightage > 100 || this.totalTemplateElementWeightage < 100)) {
        this.utilitiesService.smmodal('Location Template', 'Total weight of elements should equal 100%.');
        this.isReqComplete = false;
        this.loaderService.display(false);
        return;
      }

      for (let i = 0; i < this.locationTemplateModalObj.LocationElements.length; i++) {
        const _locationElements: LocationElementModule = new LocationElementModule();
        this.locationTemplateModalObj.LocationElements[i].IsActive = this.locationTemplateModalObj.LocationElements[i]
          .IsActive
          ? 1
          : 0;

        if (this.locationTemplateModalObj.LocationElements[i].IsActive == 1) {
          _locationElements.ElementId = this.locationTemplateModalObj.LocationElements[i].ElementId;
          _locationElements.IsActive = this.locationTemplateModalObj.LocationElements[i].IsActive ? 1 : 0;
          _locationElements.VenueElementId = this.locationTemplateModalObj.LocationElements[i].VenueElementId;
          _locationElements.ElementWeightage = this.locationTemplateModalObj.LocationElements[i].ElementWeightage;
          _locationElements.Areas = [];
          _locationElements.Areas = this.locationTemplateModalObj.LocationElements[i].Areas;

          if (
            this.locationTemplateModalObj.LocationElements[i].InspectionScoreFactor != null &&
            this.locationTemplateModalObj.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId > 0
          ) {
            _locationElements.InspectionScoreFactor = new InspectionScoreFactor();
            _locationElements.InspectionScoreFactor.InspectionScoreFactorId =
              this.locationTemplateModalObj.LocationElements[i].InspectionScoreFactor.InspectionScoreFactorId;
            _locationElements.InspectionScoreFactor.ScoreFactorName =
              this.locationTemplateModalObj.LocationElements[i].InspectionScoreFactor.ScoreFactorName;
            _locationElements.InspectionScoreFactor.IsActive = 1;
          } else {
            if (this.isLocationElementShow) {
              this.isReqComplete = false;
              this.loaderService.display(false);
              this.utilitiesService.smmodal('Location Template', 'Please select score factor.');
              return;
            }
          }
          elementPayload.push(_locationElements);
        }
      }
    }

    if (this.locationTemplateModalObj.LocationFeedbackReasons != null) {
      for (let i = 0; i < this.locationTemplateModalObj.LocationFeedbackReasons.length; i++) {
        const _locationFeedbackReason: VenueLocationTouchlessFeedbackReason =
          new VenueLocationTouchlessFeedbackReason();
        this.locationTemplateModalObj.LocationFeedbackReasons[i].IsActive = this.locationTemplateModalObj
          .LocationFeedbackReasons[i].IsActive
          ? 1
          : 0;

        if (this.locationTemplateModalObj.LocationFeedbackReasons[i].IsActive == 1) {
          _locationFeedbackReason.VenueFeedbackReasonId =
            this.locationTemplateModalObj.LocationFeedbackReasons[i].VenueFeedbackReasonId;
          _locationFeedbackReason.TouchlessFeedbackIdentifier =
            this.locationTemplateModalObj.TouchlessFeedbackIdentifier;
          _locationFeedbackReason.IsActive = this.locationTemplateModalObj.LocationFeedbackReasons[i].IsActive ? 1 : 0;
          _locationFeedbackReason.VenueLocationFeedbackReasonId =
            this.locationTemplateModalObj.LocationFeedbackReasons[i].VenueLocationFeedbackReasonId;
          locationReasonPayload.push(_locationFeedbackReason);
        }
      }
    }

    _locationDetails.TouchlessFeedbackIdentifier = this.locationTemplateModalObj.TouchlessFeedbackIdentifier;
    _locationDetails.LocationTasks = taskPayload;
    _locationDetails.LocationInventories = inventoryPayload;
    _locationDetails.LocationElements = elementPayload;
    _locationDetails.LocationFeedbackReasons = locationReasonPayload;

    _locationDetails.Zone = new ZoneModule();
    _locationDetails.Zone.ZoneId = this.locationTemplateModalObj.Zone.ZoneId;

    _locationDetails.Building = new BuildingModule();
    _locationDetails.Building.Venue = new VenueModule();
    _locationDetails.Building.BuildingId = this.locationTemplateModalObj.Building.BuildingId;
    _locationDetails.VenueId = this.locationTemplateModalObj.Building.Venue.VenueId;
    _locationDetails.Building.Venue.VenueId = this.locationTemplateModalObj.Building.Venue.VenueId;

    let modulePayload: Module[] = [];
    if (this.treeviewComponentTemplate.items != null) {
      this.treeviewComponentTemplate.items.forEach(function (it) {
        if (it.checked || it.checked == undefined) {
          let module = new Module();
          module.ModuleId = it.value;
          module.ModuleFeatures = [];

          it.children.forEach(function (mf) {
            if (mf.checked || mf.checked == undefined) {
              let mFeature = new ModuleFeature();
              mFeature.ModuleFeatureId = mf.value;
              mFeature.ModuleFeatureTypes = [];

              mf.children.forEach(function (mft) {
                if (mft.checked || mft.checked == undefined) {
                  let mFeatureType = new ModuleFeatureType();
                  mFeatureType.FeatureTypeCode = mft.value;
                  mFeatureType.FeatureConfigurations = [];

                  mft.children.forEach(function (fc) {
                    if (fc.checked || fc.checked == undefined) {
                      let fConfig = new FeatureConfiguration();
                      fConfig.FeatureConfigurationId = fc.value;
                      fConfig.IsActive = 1;

                      mFeatureType.FeatureConfigurations.push(fConfig);
                    }
                  });
                  mFeature.ModuleFeatureTypes.push(mFeatureType);
                }
              });
              module.ModuleFeatures.push(mFeature);
            }
          });
          modulePayload.push(module);
        }
      });
    }
    _locationDetails.Modules = modulePayload;
    _locationDetails.VenueLocationTypeId = this.locationTemplateModalObj.VenueLocationTypeId;

    this.locationTemplateService.Add(this.utilitiesService.stripScript(_locationDetails)).subscribe(
      (data) => {
        this.page = 1;
        this.loaderService.display(false);
        this.isReqComplete = false;
        this.closeTemplateModal();
        this.utilitiesService.smmodal('Location Template', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'locationTemplateService addTemplateLocation Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public onSelectedChangeTemplate(_downlineItems: DownlineTreeviewItem[]) {
    this.isAllFeatureConfigurationCheckedTemplate = false;

    if (this.treeviewComponentTemplate && this.treeviewComponentTemplate.items.length > 0) {
      let item = this.treeviewComponentTemplate.items.filter(
        (tree) => tree.checked == false || tree.checked == undefined,
      );
      if (item && item.length > 0) {
        this.isAllFeatureConfigurationCheckedTemplate = false;
      } else {
        this.isAllFeatureConfigurationCheckedTemplate = true;
      }
    }
  }

  public onAllCheckedChangeTemplate(event: any) {
    let that = this;
    this.treeviewComponentTemplate.items.forEach(function (it) {
      it.checked = event.checked;
      that.changeChildrenSelectionTemplate(it, event.checked);
    });
  }

  public changeChildrenSelectionTemplate(obj: any, isChecked: boolean) {
    let that = this;
    if (obj.children) {
      obj.children.forEach(function (fc) {
        fc.checked = isChecked;
        that.changeChildrenSelection(fc, isChecked);
      });
    }
  }

  public getAllReasonsByVenue(
    venueId: number,
    LocationTemplateId: number,
    TouchlessFeedbackIdentifier: any,
    flag: boolean,
  ) {
    this.loaderService.display(true);
    const CPlocation = new LocationModule();
    CPlocation.Venue = new VenueModule();
    CPlocation.LocationTemplateCPId = this.authState.AESEncryptText(LocationTemplateId);
    CPlocation.Venue.VenueCPId = this.authState.AESEncryptText(venueId);
    CPlocation.TouchlessFeedbackIdentifier = TouchlessFeedbackIdentifier;

    this.locationTemplateService.GetAllReasonsByVenue(CPlocation).subscribe(
      (data) => {
        if (flag == false) {
          this.location.LocationFeedbackReasons = data;
        }

        this.locationTemplateModalObj.LocationFeedbackReasons = data;
        // this.inventoryCurPageTemplate = 1;
        if (this.location.LocationFeedbackReasons && this.location.LocationFeedbackReasons.length > 0) {
          this.isFeedbackReason = true;
        } else {
          this.isFeedbackReason = false;
        }
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'locationTemplateService GetAllReasonsByVenue Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getAllFeedbackConfigurationIdentifiers() {
    const searchfilter = { SearchQuery: '', GenericSearch: 0, IsActive: '1' };

    this.locationService.GetAllFeedbackConfigurationIdentifiers(JSON.stringify(searchfilter)).subscribe(
      (data) => {
        if (data != null && data.length > 0) {
          this.feedbackIdentifiers = data;
          this.feedbackIdentifierdisabled = false;
        } else {
          this.feedbackIdentifiers = [];
          this.feedbackIdentifierdisabled = true;
        }
      },
      (error) => {
        console.log(
          'locationService GetAllFeedbackConfigurationIdentifiers Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  ChangeFeedbackIdentifier($event) {
    this.location.TouchlessFeedbackIdentifier = $event;
    this.getAllFeedbackReasonsByVenue(
      this.location.Building.Venue.VenueId,
      this.location.LocationId,
      this.location.TouchlessFeedbackIdentifier,
    );

    if (
      this.selectedSensorType != null &&
      this.sensorTypes != null &&
      this.sensorTypes.length > 0 &&
      this.filteredSensors != null &&
      this.filteredSensors.length > 0
    ) {
      let sensorTypeObj = this.sensorTypes.filter(
        (fs) => fs.SensorTypeCode == 'GUEST_FEEDBACK_SURVEYS' && fs.SensorTypeId == this.selectedSensorType,
      );
      if (sensorTypeObj && sensorTypeObj.length > 0) {
        this.selectedSensorType = null;
        this.filteredSensors = [];
      }
    }
  }

  ChangeFeedbackIdentifierTemplate($event) {
    this.locationTemplateModalObj.TouchlessFeedbackIdentifier = $event;
    this.getAllReasonsByVenue(
      this.locationTemplateModalObj.Building.Venue.VenueId,
      this.locationTemplateModalObj.LocationId,
      this.locationTemplateModalObj.TouchlessFeedbackIdentifier,
      true,
    );
  }

  ChangeSelectAllTask() {
    if (this.location.LocationTasks && this.location.LocationTasks.length > 0) {
      if (this.isAllTaskChecked == false) {
        this.location.LocationTasks.forEach(function (t) {
          t.IsActive = false;
        });
      } else {
        this.location.LocationTasks.forEach(function (t) {
          t.IsActive = true;
        });
      }
    }
  }

  ChangeTask() {
    if (this.location.LocationTasks && this.location.LocationTasks.length > 0) {
      let item = this.location.LocationTasks.filter((task) => task.IsActive == false || task.IsActive == 0);
      if (item && item.length > 0) {
        this.isAllTaskChecked = false;
      } else {
        this.isAllTaskChecked = true;
      }
    }
  }

  ChangeSelectAllInventory() {
    if (this.location.LocationInventories && this.location.LocationInventories.length > 0) {
      if (this.isAllInventoryChecked == false) {
        this.location.LocationInventories.forEach(function (i) {
          i.IsActive = false;
        });
      } else {
        this.location.LocationInventories.forEach(function (i) {
          i.IsActive = true;
        });
      }
    }
  }

  ChangeInventory() {
    if (this.location.LocationInventories && this.location.LocationInventories.length > 0) {
      let item = this.location.LocationInventories.filter(
        (inventory) => inventory.IsActive == false || inventory.IsActive == 0,
      );
      if (item && item.length > 0) {
        this.isAllInventoryChecked = false;
      } else {
        this.isAllInventoryChecked = true;
      }
    }
  }

  ChangeSelectAllElement() {
    if (this.location.LocationElements && this.location.LocationElements.length > 0) {
      if (this.isAllElementChecked == false) {
        this.location.LocationElements.forEach(function (e) {
          e.IsActive = false;
          e.ElementWeightage = null;
          e.Area.AreaId = null;
          e.Area.AreaName = '';
          e.Area.IsActive = 0;

          e.InspectionScoreFactor.InspectionScoreFactorId = null;
          e.InspectionScoreFactor.ScoreFactorName = '';
          e.InspectionScoreFactor.IsActive = 0;
        });
        this.totalElementWeightage = null;
      } else {
        this.location.LocationElements.forEach(function (e) {
          e.IsActive = true;
        });
      }
    }
  }

  ChangeIElement() {
    if (this.location.LocationElements && this.location.LocationElements.length > 0) {
      let item = this.location.LocationElements.filter((element) => element.IsActive == false || element.IsActive == 0);
      if (item && item.length > 0) {
        this.isAllElementChecked = false;
      } else {
        this.isAllElementChecked = true;
      }
    }
  }

  ChangeSelectAllTaskTemplate() {
    if (this.locationTemplateModalObj.LocationTasks && this.locationTemplateModalObj.LocationTasks.length > 0) {
      if (this.isAllTemplateTaskChecked == false) {
        this.locationTemplateModalObj.LocationTasks.forEach(function (t) {
          t.IsActive = false;
        });
      } else {
        this.locationTemplateModalObj.LocationTasks.forEach(function (t) {
          t.IsActive = true;
        });
      }
    }
  }

  ChangeTaskTemplate() {
    if (this.locationTemplateModalObj.LocationTasks && this.locationTemplateModalObj.LocationTasks.length > 0) {
      let item = this.locationTemplateModalObj.LocationTasks.filter(
        (task) => task.IsActive == false || task.IsActive == 0,
      );
      if (item && item.length > 0) {
        this.isAllTemplateTaskChecked = false;
      } else {
        this.isAllTemplateTaskChecked = true;
      }
    }
  }

  ChangeSelectAllInventoryTemplate() {
    if (
      this.locationTemplateModalObj.LocationInventories &&
      this.locationTemplateModalObj.LocationInventories.length > 0
    ) {
      if (this.isAllTemplateInventoryChecked == false) {
        this.locationTemplateModalObj.LocationInventories.forEach(function (i) {
          i.IsActive = false;
        });
      } else {
        this.locationTemplateModalObj.LocationInventories.forEach(function (i) {
          i.IsActive = true;
        });
      }
    }
  }

  ChangeInventoryTemplate() {
    if (
      this.locationTemplateModalObj.LocationInventories &&
      this.locationTemplateModalObj.LocationInventories.length > 0
    ) {
      let item = this.locationTemplateModalObj.LocationInventories.filter(
        (inventory) => inventory.IsActive == false || inventory.IsActive == 0,
      );
      if (item && item.length > 0) {
        this.isAllTemplateInventoryChecked = false;
      } else {
        this.isAllTemplateInventoryChecked = true;
      }
    }
  }

  ChangeSelectAllElementTemplate() {
    if (this.locationTemplateModalObj.LocationElements && this.locationTemplateModalObj.LocationElements.length > 0) {
      if (this.isAllTemplateElementChecked == false) {
        this.locationTemplateModalObj.LocationElements.forEach(function (e) {
          e.IsActive = false;
          e.ElementWeightage = null;
          e.Area.AreaId = null;
          e.Area.AreaName = '';
          e.Area.IsActive = 0;

          e.InspectionScoreFactor.InspectionScoreFactorId = null;
          e.InspectionScoreFactor.ScoreFactorName = '';
          e.InspectionScoreFactor.IsActive = 0;
        });
        this.totalTemplateElementWeightage = null;
      } else {
        this.locationTemplateModalObj.LocationElements.forEach(function (e) {
          e.IsActive = true;
        });
      }
    }
  }

  ChangeIElementTemplate() {
    if (this.locationTemplateModalObj.LocationElements && this.locationTemplateModalObj.LocationElements.length > 0) {
      let item = this.locationTemplateModalObj.LocationElements.filter(
        (element) => element.IsActive == false || element.IsActive == 0,
      );
      if (item && item.length > 0) {
        this.isAllTemplateElementChecked = false;
      } else {
        this.isAllTemplateElementChecked = true;
      }
    }
  }

  public getAllAreas(venueLocationTypeId: number) {
    this.loaderService.display(true);
    const payload = new LocationTypeModule();
    payload.VenueLocationTypeCPId = this.authState.AESEncryptText(venueLocationTypeId);

    this.locationTemplateService.GetAllAreasByVenue(payload).subscribe(
      (data) => {
        this.areas = data;
        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log('areaService getAllAreas Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
      },
    );
  }

  public getAllScoreFactors() {
    const payload = new GenericEntity();
    //payload.VenueId = this.authState.getStoredContextVenueId();
    payload.ServiceProviderId = this.authState.getStoredContextServiceProviderId();

    this.locationService.GetAllScoreFactorsByVenue(payload).subscribe(
      (data) => {
        this.scoreFactors = data;
      },
      (error) => {
        console.log(
          'locationService GetAllScoreFactorsByVenue Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  //// Cleaning alert attachment code (start) ------------------------------------
  public openCAAttachmentPopUp() {
    this.caAttachments = [];
    this.CreatorComments = '';
    this.caAttachmentPopUp = true;
  }

  public closeCAAttachmentPopUp() {
    this.caAttachmentPopUp = false;
    this.caAttachments = [];
    this.CreatorComments = '';
  }

  public removeAttachment(rowId: number) {
    this.loaderService.display(true);
    if (this.caAttachments != null && this.caAttachments.length > 0) {
      for (let i = 0; i < this.caAttachments.length; i++) {
        if (this.caAttachments[i].rowid == rowId) {
          this.caAttachments[i].IsActive = 0;
          this.attachmentIndex = i;
          if (this.caAttachments[i].IsActive == 0) {
            this.caAttachments.splice(this.attachmentIndex, 1);
            i = i - 1;
          }
        }
      }

      this.caAttachments.forEach(function (item, index) {
        item.rowid = null;
        item.rowid = index + 1;
      });
    }
    this.loaderService.display(false);
  }

  public caFileChange(event: any) {
    if (event != null && event && event.target && event.target.files && event.target.files.length > 0) {
      this.loaderService.display(true);
      this.caAttachmentImgSrc = null;
      this.caAttachmentFile = null;
      this.caAttachmentFileType = '';
      this.caCompressImage = null;

      let checkAttachments = this.caAttachments.length;
      if (checkAttachments != null && checkAttachments >= 5) {
        this.utilitiesService.mdmodal(
          'Cleaning Alert Attachment',
          'You have reached the maximum limit to upload attachments.',
        );
        event.target.value = '';
        this.loaderService.display(false);
        return;
      }

      let uploadedFileName = this.utilitiesService.checkUploadedFileName(event.target.files[0].name.split(''));
      if (uploadedFileName) {
        this.utilitiesService.smmodal('Cleaning Alert Attachment', this.utilitiesService.fileUploadErrorMessage);
        event.target.value = '';
        this.loaderService.display(false);
        return;
      }

      const extention = event.target.files[0].name.split('.');
      if (extention != null && extention.length > 0) {
        const ext = extention[extention.length - 1].toLowerCase();
        this.caAttachmentFileType = ext;
        if (ext == 'jpg' || ext == 'jpeg' || ext == 'png' || ext == 'mp4' || ext == 'm4v' || ext == 'mov') {
          if (event.target.files && event.target.files[0]) {
            if (ext === 'jpg' || ext === 'jpeg' || ext === 'png') {
              const filesize = event.target.files[0].size / Math.pow(1024, 2);
              if (filesize > this.utilitiesService.fileUploadSize) {
                this.utilitiesService.mdmodal(
                  'Cleaning Alert Attachment',
                  'Picture should not be more than ' + this.utilitiesService.fileUploadSize + ' MB.',
                );
                event.target.value = '';
                this.loaderService.display(false);
                return;
              }
              const fileList: FileList = event.target.files;
              if (fileList.length > 0) {
                this.file = fileList[0];
                const reader = new FileReader();
                reader.onload = this.onFileRead.bind(this, event.target.files[0], 'image');
                reader.readAsBinaryString(this.file);
              }
            } else if (ext === 'mp4' || ext === 'm4v' || ext === 'mov') {
              const videoFileList: FileList = event.target.files;
              const filesize = event.target.files[0].size / Math.pow(1024, 2);

              if (filesize > 50) {
                this.utilitiesService.mdmodal('Cleaning Alert Attachment', 'Video should not be more than 50 MB.');
                event.target.value = '';
                this.loaderService.display(false);
                return;
              }

              this.loaderService.display(false);
              this.caAttachFile = videoFileList[0];
              const reader = new FileReader();
              reader.onload = this.onFileRead.bind(this, videoFileList[0], 'video');
              reader.readAsBinaryString(this.caAttachFile);
              this.caAttachmentFile = event.target.files[0];
            }
          }
        } else {
          this.utilitiesService.mdmodal(
            'Cleaning Alert Attachment',
            'Please upload file of type .jpg, .jpeg, .png, .mp4, .m4v, .mov.',
          );
          event.target.value = '';
        }
      } else {
        this.loaderService.display(false);
      }
    }
  }

  onFileRead(attachmentFile: any, attachmentType: string, readerEvt: any) {
    if (attachmentType === 'image') {
      this.compressImg(attachmentFile);
    } else if (attachmentType === 'video') {
      this.caAttachFile = attachmentFile;
      const reader = new FileReader();
      reader.onload = this._caHandleReaderLoaded.bind(this);
      reader.readAsBinaryString(this.caAttachFile);
      this.caAttachmentFile = attachmentFile;
    }
  }

  async compressImg(img: any): Promise<void> {
    await this.compressImageMethod(img);
  }

  public compressImageMethod(img: any) {
    this.compressImage.compress(img).subscribe(
      (response) => {
        this.loaderService.display(false);
        this.caCompressImage = response;

        const imageFileList: FileList = this.caCompressImage;
        this.loaderService.display(false);
        this.caAttachFile = imageFileList;
        const reader = new FileReader();
        reader.onload = this._caHandleReaderLoaded.bind(this);
        reader.readAsBinaryString(this.caAttachFile);
        this.caAttachmentFile = this.caAttachFile;
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'Image compression service Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
      },
    );
  }

  public _caHandleReaderLoaded(readerEvt: any) {
    this.loaderService.display(true);
    const binaryString = readerEvt.target.result;
    this.caAttachmentImgSrc = 'data:image/jpeg;base64,' + btoa(binaryString);

    this.attachmentRowId = this.caAttachments.length;
    this.attachmentRowId = this.attachmentRowId + 1;

    const attach = new WorkorderCreatorAttachments();
    attach.rowid = this.attachmentRowId;
    attach.attachmentImage = this.caAttachmentImgSrc;
    attach.attachmentImageFile = this.caAttachmentFile;
    attach.attachmentImageType = this.caAttachmentFileType;
    attach.IsActive = 1;
    this.caAttachments.push(attach);

    this.caFileInputVariable.nativeElement.value = '';
    this.caAttachFile = null;
    this.loaderService.display(false);
  }

  public cleaningAlertStatus(locationid: any) {
    this.loaderService.display(true);
    const payload = { LocationId: locationid };

    this.restroomOverviewService.CleaningAlertStatus(payload).subscribe(
      () => {
        this.openCAAttachmentPopUp();
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'restroomOverviewService CleaningAlertStatus Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }
  //// Cleaning alert attachment code (end) --------------------------------------

  public checkExportLocation(exportString: string) {
    this.getAllLocations('', 1, 10, null, exportString);
  }

  public exportLocationToExcel() {
    this.loaderService.display(true);

    this.locationService.DownloadLocationDetails().subscribe(
      (result) => {
        this.loaderService.display(false);
        if (result) {
          const date = new Date();
          let month = date.getMonth() + 1;
          const timestamp =
            date.getFullYear().toString() +
            month.toString() +
            date.getDate().toString() +
            date.getHours().toString() +
            date.getMinutes().toString() +
            date.getMilliseconds().toString();
          const filename = 'TRAXExportLocations_' + timestamp.toString() + '.xlsx';
          this.downloadFile(result, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', filename);
        }
      },
      (error) => {
        console.log(
          'locationService DownloadLocationDetails Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
        this.loaderService.display(false);
        // this.impExpDataPopUp = false;
      },
    );
  }

  public downloadFile(blob: any, filetype: string, filename: string) {
    const binaryData = [];
    binaryData.push(blob);
    const url = window.URL.createObjectURL(new Blob(binaryData, { type: filetype })); // <-- work with blob directly

    /// create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);

    /// create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
  }

  keyPressCheck($event: any) {
    if (
      $event.key == 'e' ||
      $event.key == 'E' ||
      $event.key == '-' ||
      $event.key == '.' ||
      ($event.target.value === '' && $event.key == '0')
    ) {
      $event.preventDefault();
      return false;
    }

    if ($event.target.value.length > 3) {
      $event.preventDefault();
      return false;
    }
  }

  public openImpExpDataPopUp() {
    this.impExpDataPopUp = true;
    this.getAllData('', this.logpage, this.logsize);
    this.interval();
  }

  public async interval() {
    this.myQuerySub = interval(1000 * 60).subscribe(() => {
      this.getAllData('', this.logpage, this.logsize);
    });
  }

  public closeImpExpDataPopUp() {
    this.impExpDataPopUp = false;
    this.fileReset('exel');
    if (this.myQuerySub) {
      this.myQuerySub.unsubscribe();
    }
  }

  public downloadTemplate() {
    this.loaderService.display(true);

    this.importDataService.DownloadData(ImportTypes.LOCATION).subscribe(
      (result) => {
        this.loaderService.display(false);
        const filename =
          'LocationImport_' + this.authState.getStoredVenueName().replace(/\s/g, '').toString() + '_Template' + '.xlsx';
        this.downloadFile(result, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', filename);
      },
      (error) => {
        console.log(
          'importDataService DownloadData Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public locationFileChange(event: any) {
    this.data = [];
    for (let i = 0; i < event.target.files.length; i++) {
      const filename = event.target.files[i].name;
      let name = filename.split('');
      for (let n = 0; n < name.length; n++) {
        if (
          name[n] == '<' ||
          name[n] == '>' ||
          name[n] == '%' ||
          name[n] == ':' ||
          name[n] == '?' ||
          name[n] == '/' ||
          name[n] == '*' ||
          name[n] == '"' ||
          name[n] == '|' ||
          name[n] == '#' ||
          name[n] == '{' ||
          name[n] == '}' ||
          name[n] == '&' ||
          name[n] == '='
        ) {
          this.utilitiesService.smmodal(
            'Import Data',
            'A file name cannot contain any of the further characters: / : * ? " < > | # { } % & =',
          );
          event.target.value = '';
          return;
        }
      }

      const ext = filename.split('.')[1];
      if (ext == 'xls' || ext == 'xlsx' || ext == 'xlsm') {
        const index = i + 1;
        this.exelFiles.push(event.target.files[i]);
        const filename = event.target.files[i].name;
        this.data.push({ SerialNumber: index, fileName: filename });
        if (this.exelFiles.length > 0) {
          this.isFileSelected = false;
        }
      } else {
        this.utilitiesService.smmodal('Import Data', 'Please upload file of type .xls, .xlsx, .xlsm.');
        event.target.value = '';
      }
    }
  }

  public AddFile() {
    if (this.data != null) {
      if (this.exelFiles) {
        let that = this;
        this.importDataService
          .ImportBulkFiles(this.exelFiles, ImportTypes.LOCATION)
          .then(function (_response) {
            that.loaderService.display(false);
            that.utilitiesService.smmodal('Import Data', 'Data Imported successfully!');
            this.getAllData('', this.logpage, this.logsize);
            that.exelFiles = [];
            if (that.fileInputVariable != undefined) {
              that.fileInputVariable.nativeElement.value = '';
            }
            that.isFileSelected = true;
          })
          .catch((err) => {
            console.log(err);
            that.loaderService.display(false);
            this.getAllData('', this.logpage, this.logsize);
            that.exelFiles = [];
            if (that.fileInputVariable != undefined) {
              that.fileInputVariable.nativeElement.value = '';
            }
            that.isFileSelected = true;
          });
      }
    }
  }

  public hasLogData(): boolean {
    return this.transactions ? this.transactions != null && this.transactions.length > 0 : false;
  }

  public getAllData(searchTerm: string, Index: number, Size: number) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: searchTerm ? searchTerm.trim() : '', GenericSearch: 1, ImportType: 'LOCATION' };

    this.importDataService.GetAllData(JSON.stringify(searchfilter), Index, Size).subscribe(
      (data) => {
        this.transactions = data;
        if (this.transactions.length < 0 && this.transactions[0].Count / 10 < this.logpage) {
          this.logpage = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log('userRoleService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText);
        this.loaderService.display(false);
      },
      () => (this.logcount = this.transactions[0] ? this.transactions[0].Count : 0),
    );
  }

  public downloadTransactionFiles(transactionId: number) {
    if (transactionId) {
      const obj = this.transactions.filter((tran) => tran.Id == transactionId);
      if (obj[0] && obj[0].LogFiles && obj[0].LogFiles.length > 0) {
        for (let i = 0; i < obj[0].LogFiles.length; i++) {
          if (obj[0].LogFiles[i].fileName != null) {
            this.download =
              this.configuration.Server + '/importfiles/' + obj[0].Identifier + '/logs/' + obj[0].LogFiles[i].fileName;
            const fileName = obj[0].Identifier;

            const a = document.createElement('a');
            a.href = this.download;
            a.target = '_blank';
            a.download = fileName;
            a.click();
          }
        }
      }
    }
  }
  public logPageChanged(p: number) {
    this.logpage = p;
    this.getAllData('', this.logpage, this.logsize);
  }
}
