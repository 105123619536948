import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';

import { SensorModule } from '../sensor.module';

@Injectable({
  providedIn: 'root',
})
export class SensorService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (body: SensorModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'corporation/sensor';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: SensorModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'corporation/sensor';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetSensorList = (body: string, page: number, size: number): Observable<SensorModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/all/sensors/' + page + '/' + size;
    return this.http.post<SensorModule[]>(_url, body);
  };

  public GetSensorDropDown = (body: string, page: number, size: number): Observable<SensorModule[]> => {
    const _url: string = this.configuration.Server + 'corporation/sensors/' + page + '/' + size;
    return this.http.post<SensorModule[]>(_url, body);
  };

  public GetSingle = (body: SensorModule): Observable<SensorModule> => {
    const _url: string = this.configuration.Server + 'corporation/sensor/details';
    return this.http.post<SensorModule>(_url, body);
  };

  public GetAirQualitySingle = (body: SensorModule): Observable<SensorModule> => {
    const _url: string = this.configuration.Server + 'corporation/airquality/sensor/details';
    return this.http.post<SensorModule>(_url, body);
  };

  public RemoveSensorImage = (sensorId: number): Observable<ResponseMessage> => {
    const toUpdate = JSON.stringify({});
    const _url: string = this.configuration.Server + 'corporation/remove/sensor/' + sensorId + '/image';
    return this.http.put<ResponseMessage>(_url, toUpdate);
  };

  SensorImage(filedata: File, id: number): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'corporation/sensor/' + id + '/image';

      xhr.open('PUT', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }
}
