import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { ElementModule } from '../element.module';

@Injectable({
  providedIn: 'root',
})
export class ElementService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetElementList = (body: string, page: number, size: number): Observable<ElementModule[]> => {
    const _url: string = this.configuration.Server + 'venue/elements/' + page + '/' + size;
    return this.http.post<ElementModule[]>(_url, body);
  };

  public GetSingle = (body: ElementModule): Observable<ElementModule> => {
    const _url: string = this.configuration.Server + 'venue/element/details';
    return this.http.post<ElementModule>(_url, body);
  };

  public Add = (body: ElementModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/element';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: ElementModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/element';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public Delete = (body: ElementModule): Observable<ElementModule> => {
    const _url: string = this.configuration.Server + 'venue/delete/element';
    return this.http.put<ElementModule>(_url, body);
  };

  public RemoveElementImage = (body: ElementModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/element/icon/remove';
    return this.http.put<ResponseMessage>(_url, body);
  };

  addElementImage(filedata: File, elementId: number): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'venue/element/' + elementId + '/icon';

      xhr.open('PUT', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }
}
