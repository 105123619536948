import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';

import { UserRoleModule } from '../role.module';
import { ServiceProviderModule } from '../../../serviceprovider/serviceprovider.module';
import { CorporationModule } from '../../../corporationmanagement/corporation/corporation.module';
import { VenueModule } from '../../../venuemanagement/venue/venue.module';
import { UserRoleTemplateModule } from '../../userroletemplate/userroletemplate.module';

@Injectable({
  providedIn: 'root',
})
export class UserRoleService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (addUserRole: UserRoleModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/user/role';
    return this.http.post<ResponseMessage>(_url, addUserRole);
  };

  public Update = (updateUserRrole: UserRoleModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'admin/user/role';
    return this.http.put<ResponseMessage>(_url, updateUserRrole);
  };

  public GetUserroleList = (body: string, page: number, size: number): Observable<UserRoleModule[]> => {
    const _url: string = this.configuration.Server + 'admin/all/user/roles/' + page + '/' + size;
    return this.http.post<UserRoleModule[]>(_url, body);
  };

  public GetUserroleDropdown = (body: string, page: number, size: number): Observable<UserRoleModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/role/' + page + '/' + size;
    return this.http.post<UserRoleModule[]>(_url, body);
  };

  public GetSingle = (body: UserRoleModule): Observable<UserRoleModule> => {
    const _url: string = this.configuration.Server + 'admin/user/role/details';
    return this.http.post<UserRoleModule>(_url, body);
  };

  public GetServiceProviderListForDropdown = (body: UserRoleModule): Observable<ServiceProviderModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/role/serviceproviders';
    return this.http.post<ServiceProviderModule[]>(_url, body);
  };

  public GetCorporationListForDropdown = (body: UserRoleModule): Observable<CorporationModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/role/corporations';
    return this.http.post<CorporationModule[]>(_url, body);
  };

  public GetVenueDropdown = (body: UserRoleModule): Observable<VenueModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/role/venues';
    return this.http.post<VenueModule[]>(_url, body);
  };

  public GetRolePermissions = (body: UserRoleModule, page: number, size: number): Observable<UserRoleModule> => {
    const _url: string = this.configuration.Server + 'admin/user/role/permissions/' + page + '/' + size;
    return this.http.post<UserRoleModule>(_url, body);
  };

  public GetAllUserRoleTemplates = (body: string, page: number, size: number): Observable<UserRoleTemplateModule[]> => {
    const _url: string = this.configuration.Server + 'admin/user/roles/templates/' + page + '/' + size;
    return this.http.post<UserRoleTemplateModule[]>(_url, body);
  };
}
