import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxPaginationModule } from 'ngx-pagination';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { HomeModule } from '../../home/home.module';

import { AreaComponent } from './components/area.component';
import { AreaRoutes } from './area.routes';

@NgModule({
  imports: [CommonModule, AreaRoutes, FormsModule, NgxPaginationModule, HomeModule],

  declarations: [AreaComponent],
})
export class AreaModule extends GenericEntity {
  public AreaId: number;
  public AreaCPId: number;
  public AreaName: string;
  public Sequence: number;
  public RelLocationTemplateElementAreaId: any;
}
