import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthState } from 'src/app/store/auth.state';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { User } from '../../user/user.model';
import { SubscriptionModule } from '../subscription.module';
import { SubscriptionService } from './subscription.http.service';

@Component({
  selector: 'subscription-component',
  templateUrl: 'subscription.component.html',
  providers: [SubscriptionService, UtilitiesService],
})
export class SubscriptionComponent implements OnInit {
  currentUser: User;

  public subscriptions: SubscriptionModule[] = [];
  public subscription: SubscriptionModule = new SubscriptionModule();

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  public pageState = [{ pageName: 'subscriptionForm', page: this.page, size: this.size, viewType: this.viewType }];

  constructor(
    private subscriptionService: SubscriptionService,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.viewType = this.pageState[0].viewType;
      this.getAllSubscriptions(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllSubscriptions(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public setAddForm() {
    this.searchString = '';
    this.subscriptions = null;
    this.count = 0;
    this.subscription = new SubscriptionModule();

    setTimeout(() => {
      const element = document.getElementById('SubscriptionType');
      element.focus();
    }, 1000);
  }

  public setUpdateForm() {
    this.subscriptions = null;
    this.count = 0;
    this.searchString = '';
  }

  public clearFormData(subscriptionForm: NgForm) {
    this.searchString = '';
    subscriptionForm.reset();
    this.getAllSubscriptions('', this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllSubscriptions('', this.page, this.size, this.viewType);
  }

  public subscriptionsSearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSubscriptions(searchString, 1, this.size, this.viewType);
  }

  public hasData(): boolean {
    return this.subscriptions != null && this.subscriptions.length > 0;
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSubscriptions(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public SubscriptionPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSubscriptions(this.searchString, this.page, this.size, this.viewType);
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSubscriptions(this.searchString, this.page, this.size, this.viewType);
  }

  public addSubscription(subscriptionform: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (subscriptionform.status == 'INVALID' && subscriptionform.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Subscription',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.subscription.SubscriptionType = this.utilitiesService.removeInnerSpaces(this.subscription.SubscriptionType);
    if (this.subscription.SubscriptionType === null || this.subscription.SubscriptionType === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    this.subscription.AddedBy = this.currentUser.UserId;
    this.subscriptionService.Add(this.utilitiesService.stripScript(this.subscription)).subscribe(
      (data) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Subscription', data.Message);
        this.clearFormData(subscriptionform);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'SubscriptionService addSubscription Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public updateSubscription(subscriptionform: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (subscriptionform.status == 'INVALID' && subscriptionform.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Subscription',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.subscription.SubscriptionType = this.utilitiesService.removeInnerSpaces(this.subscription.SubscriptionType);

    if (this.subscription.SubscriptionType === null || this.subscription.SubscriptionType === '') {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    this.subscription.ModifiedBy = this.currentUser.UserId;
    this.subscription.IsActive = this.subscription.IsActive ? 1 : 0;

    this.subscriptionService.Update(this.utilitiesService.stripScript(this.subscription)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Subscription', data.Message);
        this.clearFormData(subscriptionform);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'SubscriptionService updateSubscription Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getAllSubscriptions(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.subscriptionService.GetSubscriptionList(JSON.stringify(searchfilter), pageIndex, pageSize).subscribe(
      (data) => {
        this.subscriptions = data;
        if (this.subscriptions.length < 0 && this.subscriptions[0].Count / 10 < this.page) {
          this.page = 1;
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'SubscriptionService GetAll Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
      () => (this.count = this.subscriptions[0] ? this.subscriptions[0].Count : 0),
    );
  }

  public getSubscriptionById(subscriptionId: number) {
    this.loaderService.display(true);
    const CPsubscription = new SubscriptionModule();
    CPsubscription.SubscriptionCPId = this.authState.AESEncryptText(subscriptionId);

    this.subscriptionService.GetSingle(CPsubscription).subscribe(
      (data) => {
        this.subscription = data;
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'SubscriptionService GetSingle Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
      },
      () => this.setUpdateForm(),
    );
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    // console.log('Column Name: ' + columnName + ', Sort Order: ' + this.sortOrder);
    // console.log('Column Name: ' + columnName + ', Column Sort Order: ' + this.columnSortOrder);
    this.getAllSubscriptions(this.searchString, this.page, this.size, this.viewType);
  }
}
