<div id="maincontent" class="hold-transition sidebar-collapse">
  <div>
    <app-navigation *ngIf="Islogin"></app-navigation>

    <!--<div [ngClass]="{'content-wrapper': Islogin}">-->
    <div [ngClass]="Islogin ? 'content-wrapper' : 'main-content'">
      <router-outlet>
        <span *ngIf="showLoader" class="preloader"></span>
      </router-outlet>
    </div>
  </div>
</div>
