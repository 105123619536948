import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },

  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'callback',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'restroom-overview',
    loadChildren: () => import('./restroomoverview/restroomoverview.module').then((m) => m.RestroomOverviewModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'overview-dashboard',
    loadChildren: () => import('./overviewdashboard/overviewdashboard.module').then((m) => m.OverviewDashboardModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'predictive-analytics',
    loadChildren: () =>
      import('./restroomprediction/restroomprediction.module').then((m) => m.RestroomPredictionModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'service-provider',
    loadChildren: () => import('./serviceprovider/serviceprovider.module').then((m) => m.ServiceProviderModule),
    canActivate: [AuthGuard],
  },

  //// User Management --------------------
  {
    path: 'user',
    loadChildren: () => import('./usermanagement/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'profile-menu',
    loadChildren: () => import('./usermanagement/user/user.module').then((m) => m.UserModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'user-role-permission-template',
    loadChildren: () =>
      import('./usermanagement/userroletemplate/userroletemplate.module').then((m) => m.UserRoleTemplateModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'role',
    loadChildren: () => import('./usermanagement/role/role.module').then((m) => m.UserRoleModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'device-registration',
    loadChildren: () =>
      import('./usermanagement/deviceregistration/deviceregistration.module').then((m) => m.DeviceRegistrationModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'subscription',
    loadChildren: () => import('./usermanagement/subscription/subscription.module').then((m) => m.SubscriptionModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'userlocationassignment',
    loadChildren: () =>
      import('./usermanagement/userlocationassignment/userlocationassignment.module').then(
        (m) => m.UserLocationAssignmentModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'share-user',
    loadChildren: () => import('./usermanagement/shareuser/shareuser.module').then((m) => m.ShareUserModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'acquired-users',
    loadChildren: () => import('./usermanagement/acquireduser/acquireduser.module').then((m) => m.AcquiredUserModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'transfer-ownership',
    loadChildren: () =>
      import('./usermanagement/transferownership/transferownership.module').then((m) => m.TransferOwnershipModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('./salesforcetermsconditions/salesforcetermsconditions.module').then(
        (m) => m.SalesforceTermsAndConditionsModule,
      ),
  },

  //// Staff Scheduling --------------------
  {
    path: 'shift',
    loadChildren: () => import('./staffscheduling/shift/shift.module').then((m) => m.ShiftModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'zone',
    loadChildren: () => import('./staffscheduling/zone/zone.module').then((m) => m.ZoneModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'beacon',
    loadChildren: () => import('./staffscheduling/beacon/beacon.module').then((m) => m.BeaconModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'location',
    loadChildren: () => import('./staffscheduling/location/location.module').then((m) => m.LocationModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'location-template',
    loadChildren: () =>
      import('./staffscheduling/locationtemplate/locationtemplate.module').then((m) => m.LocationTemplateModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'location-type',
    loadChildren: () => import('./staffscheduling/locationtype/locationtype.module').then((m) => m.LocationTypeModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'staff-schedule',
    loadChildren: () =>
      import('./staffscheduling/staffschedule/staffschedule.module').then((m) => m.StaffScheduleModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'shift-break-schedule',
    loadChildren: () =>
      import('./staffscheduling/shiftbreakschedule/shiftbreakschedule.module').then((m) => m.ShiftScheduleModule),
    canActivate: [AuthGuard],
  },

  //// Venue Management --------------------
  {
    path: 'venue',
    loadChildren: () => import('./venuemanagement/venue/venue.module').then((m) => m.VenueModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'building',
    loadChildren: () => import('./venuemanagement/building/building.module').then((m) => m.BuildingModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'area',
    loadChildren: () => import('./venuemanagement/area/area.module').then((m) => m.AreaModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'level-assignment',
    loadChildren: () =>
      import('./venuemanagement/levelassignment/levelassignment.module').then((m) => m.LevelAssignmentModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'country',
    loadChildren: () => import('./venuemanagement/country/country.module').then((m) => m.CountryModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'state',
    loadChildren: () => import('./venuemanagement/state/state.module').then((m) => m.StateModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'city',
    loadChildren: () => import('./venuemanagement/city/city.module').then((m) => m.CityModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'gateway',
    loadChildren: () => import('./venuemanagement/gateway/getway.module').then((m) => m.GatewayModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'badge',
    loadChildren: () => import('./venuemanagement/badge/badge.module').then((m) => m.BadgeModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'gate',
    loadChildren: () => import('./venuemanagement/gate/gate.module').then((m) => m.GateModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'location-gate-group',
    loadChildren: () =>
      import('./venuemanagement/locationgategroup/locationgategroup.module').then((m) => m.LocationGateGroupModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'user-license-request-log',
    loadChildren: () =>
      import('./venuemanagement/userlicenserequestlog/userlicenserequestlog.module').then(
        (m) => m.UserLicenseRequestLogModule,
      ),
    canActivate: [AuthGuard],
  },

  //// Workorder Management --------------------
  {
    path: 'cleaning-alert',
    loadChildren: () => import('./workordermanagement/workorder/workorder.module').then((m) => m.WorkorderModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'quality-check-log',
    loadChildren: () =>
      import('./workordermanagement/qualitychecklog/qualitychecklog.module').then((m) => m.QualityCheckLogModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'task',
    loadChildren: () => import('./staffscheduling/task/task.module').then((m) => m.Task),
    canActivate: [AuthGuard],
  },

  {
    path: 'inventory',
    loadChildren: () => import('./staffscheduling/inventory/inventory.module').then((m) => m.Inventory),
    canActivate: [AuthGuard],
  },

  {
    path: 'element',
    loadChildren: () => import('./staffscheduling/element/element.module').then((m) => m.ElementModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'venue-task-configuration',
    loadChildren: () =>
      import('./staffscheduling/venuetaskconfiguration/venuetaskconfiguration.module').then((m) => m.VenueTask),
    canActivate: [AuthGuard],
  },

  {
    path: 'venue-inventory-configuration',
    loadChildren: () =>
      import('./staffscheduling/venueinventoryconfiguration/venueinventoryconfiguration.module').then(
        (m) => m.VenueInventoryModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'venue-element-configuration',
    loadChildren: () =>
      import('./staffscheduling/venueelementconfiguration/venueelementconfiguration.module').then(
        (m) => m.VenueElement,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'inspection-score-factor-configuration',
    loadChildren: () =>
      import('./staffscheduling/inspectionrating/inspectionrating.module').then((m) => m.InspectionRatingModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'workorder',
    loadChildren: () =>
      import('./workordermanagement/workorder/maintenanceworkorder.module').then((m) => m.MaintenanceWorkorderModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'workorder-type',
    loadChildren: () =>
      import('./workordermanagement/workordertype/workordertype.module').then((m) => m.WorkorderTypeModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'workorder-category',
    loadChildren: () =>
      import('./workordermanagement/workordercategory/workordercategory.module').then((m) => m.WorkorderCategoryModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'closure-template',
    loadChildren: () =>
      import('./workordermanagement/closuretemplate/closuretemplate.module').then((m) => m.ClosureTemplateModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'qc-workorder',
    loadChildren: () => import('./workordermanagement/qcworkorder/qcworkorder.module').then((m) => m.QCWorkOrderModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'qc-cleaning-alert',
    loadChildren: () =>
      import('./workordermanagement/qccleaningalert/qccleaningalert.module').then((m) => m.QCCleaningAlertModule),
    canActivate: [AuthGuard],
  },

  //// Asset Module --------------------
  {
    path: 'asset',
    loadChildren: () => import('./assetmanagement/asset/asset.module').then((m) => m.AssetModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'asset-type',
    loadChildren: () => import('./assetmanagement/assettype/assettype.module').then((m) => m.AssetTypeModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'product',
    loadChildren: () => import('./assetmanagement/product/product.module').then((m) => m.ProductModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'product-model',
    loadChildren: () => import('./assetmanagement/productmodel/productmodel.module').then((m) => m.ProductModelModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'product-manufacturer',
    loadChildren: () =>
      import('./assetmanagement/productmanufacturer/productmanufacturer.module').then(
        (m) => m.ProductManufacturerModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'supplier',
    loadChildren: () => import('./assetmanagement/supplier/supplier.module').then((m) => m.SupplierModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'import-data',
    loadChildren: () => import('./importdata/importdata.module').then((m) => m.ImportDataModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'sensor-type',
    loadChildren: () => import('./corporationmanagement/sensortype/sensortype.module').then((m) => m.SensorTypeModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'sensor',
    loadChildren: () => import('./corporationmanagement/sensor/sensor.module').then((m) => m.SensorModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'sensor-manufacturer',
    loadChildren: () =>
      import('./corporationmanagement/sensormanufacturer/sensormanufacturer.module').then(
        (m) => m.SensorManufacturerModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'stall-occupancy-status',
    loadChildren: () =>
      import('./corporationmanagement/stallocupancystatus/stallocupancystatus.module').then(
        (m) => m.StallOcupancyStatusModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'corporation',
    loadChildren: () =>
      import('./corporationmanagement/corporation/corporation.module').then((m) => m.CorporationModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'alert-template',
    loadChildren: () =>
      import('./corporationmanagement/alerttemplate/alerttemplate.module').then((m) => m.AlertTemplateModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'configure-default-venue',
    loadChildren: () =>
      import('./usermanagement/configuredefaultvenue/configuredefaultvenue.module').then(
        (m) => m.ConfigureDefaultVenueModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'alert-type',
    loadChildren: () => import('./corporationmanagement/alerttype/alerttype.module').then((m) => m.AlertTypeModule),
    canActivate: [AuthGuard],
  },

  //// venue survey configuration --------------------
  {
    path: 'survey-question-configuration',
    loadChildren: () =>
      import('./venuesurveyconfigurations/surveyquestionconfiguration/surveyquestionconfiguration.module').then(
        (m) => m.SurveyQuestionConfigModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'survey-question-response-configuration',
    loadChildren: () =>
      import(
        './venuesurveyconfigurations/surveyquestionresponseconfiguration/surveyquestionresponseconfiguration.module'
      ).then((m) => m.SurveyQuestionResponseConfigModule),
    canActivate: [AuthGuard],
  },

  //// Infor Workorder --------------------
  {
    path: 'infor-workorder',
    loadChildren: () =>
      import('./inforworkordermanagement/inforworkorder/inforworkorder.module').then((m) => m.InforWorkorderModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'inspection-dashboard',
    loadChildren: () =>
      import('./inspectiondashboard/inspectiondashboard.module').then((m) => m.InspectionDashboardModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'statistics',
    loadChildren: () =>
      import('./statisticsdashboard/statisticsdashboard.module').then((m) => m.StatisticsDashboardModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'signage/:id',
    loadChildren: () => import('./signage/signage.module').then((m) => m.SignageModule),
  },

  {
    path: 'signage-atl/:id',
    loadChildren: () => import('./digitaldisplay/signage-atl/signage-atl.module').then((m) => m.SignageATLModule),
  },

  {
    path: 'signage-dfw',
    loadChildren: () => import('./digitaldisplay/signage-dfw/signage-dfw.module').then((m) => m.SignageDFWModule),
  },

  {
    path: 'signage-dfw/:id',
    loadChildren: () => import('./digitaldisplay/signage-dfw/signage-dfw.module').then((m) => m.SignageDFWModule),
  },

  //// Inspection Management --------------------
  {
    path: 'inspection-log',
    loadChildren: () =>
      import('./workordermanagement/inspectionlog/inspectionlog.module').then((m) => m.InspectionModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'inspection',
    loadChildren: () =>
      import('./inspectionmanagement/inspection/inspection.module').then((m) => m.InspectionDetailModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'inspectorlocationassignment',
    loadChildren: () =>
      import('./inspectionmanagement/inspectorlocationassignment/inspectorlocationassignment.module').then(
        (m) => m.InspectorLocationAssignmentModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'location-overview',
    loadChildren: () =>
      import('./inspectionmanagement/locationoverview/locationoverview.module').then((m) => m.LocationOverviewModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'inspection-notification-alert',
    loadChildren: () =>
      import('./inspectionmanagement/inspectionnotification/inspectionnotification.module').then(
        (m) => m.InspectionNotificationModule,
      ),
    canActivate: [AuthGuard],
  },

  //// Project Work --------------------
  {
    path: 'project-work-log',
    loadChildren: () =>
      import('./projectwork/projectworklog/projectworklog.module').then((m) => m.ProjectWorkLogModule),
    canActivate: [AuthGuard],
  },

  //// Reports --------------------
  {
    path: 'throughput-custom-report',
    loadChildren: () =>
      import('./reports/customreports/throughput/throughputcustomreport.module').then(
        (m) => m.ThroughputCustomReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'feedback-custom-report',
    loadChildren: () =>
      import('./reports/customreports/feedback/feedbackcustomreport.module').then((m) => m.FeedbackCustomReportModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'inspection-log-report',
    loadChildren: () =>
      import('./reports/customreports/inspectionlogreport/inspectionlogreport.module').then(
        (m) => m.InspectionLogReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'dispenser-custom-report',
    loadChildren: () =>
      import('./reports/customreports/dispensors/dispensorscustomreport.module').then(
        (m) => m.DispensorsCustomReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'task-custom-report',
    loadChildren: () =>
      import('./reports/customreports/task/taskcustomreport.module').then((m) => m.TaskCustomReportModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'inventory-custom-report',
    loadChildren: () =>
      import('./reports/customreports/inventory/inventorycustomreport.module').then(
        (m) => m.InventoryCustomReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'trash-custom-report',
    loadChildren: () =>
      import('./reports/customreports/trash/trashcustomreport.module').then((m) => m.TrashCustomReportModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'badge-summary',
    loadChildren: () =>
      import('./reports/customreports/badgesummary/badgesummaryreport.module').then((m) => m.BadgeSummaryReportModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'cleaning-alert-custom-report',
    loadChildren: () =>
      import('./reports/customreports/cleaningalert/cleaningalertcustomreport.module').then(
        (m) => m.CleaningAlertCustomReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'user-log-activity-custom-report',
    loadChildren: () =>
      import('./reports/customreports/userloginactivity/userloginactivitycustomreport.module').then(
        (m) => m.UserLoginActivityCustomReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'flush-valve-custom-report',
    loadChildren: () =>
      import('./reports/customreports/zurnsmartplumbing/zurnsmartplumbingcustomreport.module').then(
        (m) => m.ZurnSmartPlumbingCustomReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'equipment-pm-custom-report',
    loadChildren: () =>
      import('./reports/customreports/events/eventscustomreport.module').then((m) => m.EventsCustomReportModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'user-billing-custom-report',
    loadChildren: () =>
      import('./reports/customreports/userbillingreport/userbillingcustomreport.module').then(
        (m) => m.UserBillingcCustomReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'user-attendance-report',
    loadChildren: () =>
      import('./reports/customreports/userattendance/userattendancereport.module').then(
        (m) => m.UserAttendanceReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'project-work-custom-report',
    loadChildren: () =>
      import('./reports/customreports/projectworklogreport/projectworklogreport.module').then(
        (m) => m.ProjectWorkLogReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'calender-schedules-custom-report',
    loadChildren: () =>
      import('./reports/customreports/calendarschedulesreport/calendarschedulesreport.module').then(
        (m) => m.CalendarScheduleReportModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then((m) => m.CalModule),
    canActivate: [AuthGuard],
  },

  // Touchless Feedback Configuration  --------------------
  {
    path: 'feedback-configuration',
    loadChildren: () =>
      import('./touchlessfeedbackconfiguration/touchless-feedback-config/touchless-feedback-config.module').then(
        (m) => m.TouchlessFeedbackConfigModule,
      ),
    canActivate: [AuthGuard],
  },

  {
    path: 'venue-feedback-reason',
    loadChildren: () =>
      import('./touchlessfeedbackconfiguration/touchless-feedback-reason/touchless-feedback-reason.module').then(
        (m) => m.TouchlessFeedbackReasonModule,
      ),
    canActivate: [AuthGuard],
  },
];

export const AppRoutes = RouterModule.forRoot(routes, { useHash: false, onSameUrlNavigation: 'reload' });
