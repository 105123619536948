import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { LocalStorageService } from 'ngx-webstorage';
import { Subject } from 'rxjs';
import { Configuration } from '../app.constants';
import { Login } from '../login/login.module';
import { UtilitiesService } from '../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../shared/directives/loader.service';
import { SharedModule, StateMaintain } from '../shared/shared.module';
import { AccessGroupPermissionService } from '../usermanagement/accessgrouppermission/components/accessgrouppermission.http.service';
import { RelUserRolePermissions } from '../usermanagement/role/role.module';
import { User } from '../usermanagement/user/user.model';
import { VenueService } from '../venuemanagement/venue/components/venue.http.service';
import { VenueModule } from '../venuemanagement/venue/venue.module';

@Injectable({
  providedIn: 'root',
})
export class AuthState {
  public stateMaintains: StateMaintain[] = [];
  public stateMaintain: StateMaintain = new StateMaintain();
  private currentUserVal: User;
  public currentUser: Subject<User> = new Subject<User>();
  private accessPermission: boolean;
  private isAuthenticatedVal: boolean;
  public isAuthenticated: Subject<boolean> = new Subject<boolean>();
  public SharedModule: SharedModule = new SharedModule();
  public userInfoChanged: Subject<any> = new Subject();

  public constructor(
    private router: Router,
    private localSt: LocalStorageService,
    private configuration: Configuration,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    public venueService: VenueService,
    private accessGroupPermissionService: AccessGroupPermissionService,
  ) {
    this.isAuthenticated.subscribe((value) => {
      this.isAuthenticatedVal = value;
    });

    this.currentUser.subscribe((value) => {
      this.currentUserVal = value;
    });
  }

  public setCurrentUser(_user: User) {
    this.currentUser.next(_user);
  }

  public getCurrentUser() {
    return this.currentUserVal;
  }

  public logout() {
    this.isAuthenticated.next(false);
    this.currentUser.next(new User());
    this.configuration.Token = '';
    this.localSt.clear('traxcurrentuser');
    this.localSt.clear('traxtoken');

    this.localSt.clear('traxcontextserviceproviderid');
    this.localSt.clear('serviceproviderkey');

    this.localSt.clear('traxContextCorporationId');
    this.localSt.clear('corporationkey');

    this.localSt.clear('traxContextVenueId');
    this.localSt.clear('traxuservenueid');
    this.localSt.clear('tempvenueid');
    this.localSt.clear('venuekey');

    this.localSt.clear('LocationUserAssignment');
    this.localSt.clear('ShiftStaffSchedule');
    this.localSt.clear('EitherOneOrThese');

    this.localSt.clear('EnableTouchlessFeedback');
    this.localSt.clear('EnableTouchlessFeedbackReason');

    this.localSt.clear('LogoData');
    this.localSt.clear('InspectionDate');
    this.localSt.clear('isAllowToShareUser');
    this.localSt.clear('traxuserzoneid');
    this.localSt.clear('subscriptiontype');
    this.localSt.clear('StateMaintain');
    this.localSt.clear('venueFeaturesConfig');
    this.localSt.clear('spLicenseExpiryDate');
    this.localSt.clear('ipaddress');
    this.localSt.clear('isBadgeEnable');
    this.localSt.clear('signalr');
    this.localSt.clear('gkey');

    this.configuration.isUnauthorizedState = true;
    return false;
  }

  public getModulesData() {
    this.utilitiesService.isAreaMenuShow = this.utilitiesService.checkVenueFeatureConfiguration('VENUE_TAG_AREA');
  }

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // if isAuthenticatedVal=true & isUnauthorizedState=false then go to home else logout
    if (this.isAuthenticatedVal && !this.configuration.isUnauthorizedState) {
      if (state.url.replace('/', '') === 'home') {
        return true;
      }
      return this.canAccess(state.url.split('/')[1], 'view');
    } else {
      this.configuration.isUnauthorizedState = true;

      if (state.url.replace('/', '').indexOf('login') > -1) {
        return true;
      } else {
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    }
  }

  public getUserSession = (): User => {
    if (this.getStoredTraxCurrentUser() && this.getStoredTraxToken()) {
      this.configuration.Token = this.getStoredTraxToken();
      this.configuration.userVenueId = this.getStoredUserVenue();
      this.configuration.contextServiceProviderId = this.getStoredContextServiceProviderId();
      this.configuration.contextCorporationId = this.getStoredContextCorporationId();
      this.configuration.contextVenueId = this.getStoredContextVenueId();

      this.configuration.isUnauthorizedState = false;
      return this.getStoredTraxCurrentUser();
    }
    return new User();
  };

  public canAccess(feature: string, _access: string) {
    this.accessPermission = false;
    if (feature === 'configure-default-venue') {
      return true;
    } else if (feature === 'user-billing-custom-report') {
      return true;
    } else if (
      this.currentUserVal &&
      this.currentUserVal.UserRole.UserRolePermissions &&
      this.currentUserVal.UserRole.UserRolePermissions.length > 0
    ) {
      for (let i = 0; i < this.currentUserVal.UserRole.UserRolePermissions.length; i++) {
        if (this.currentUserVal.UserRole.UserRolePermissions[i].Permission.PermissionName === feature) {
          this.accessPermission = this.currentUserVal.UserRole.UserRolePermissions[i].PAccess > 0;
          return this.accessPermission;
        }
      }

      // let permission = this.currentUserVal.UserRole.UserRolePermissions.filter((a) => a.Permission.PermissionName === 'area');
      // if (permission && permission.length > 0 && permission[0].PAccess === 0 || !this.utilitiesService.isAreaMenuShow) {
      //     this.accessPermission = false;
      //     return this.accessPermission;
      // }

      if (this.currentUserVal.UserApplicationLevel === 'ADMIN') {
        if (
          'country' === feature ||
          'state' === feature ||
          'city' === feature ||
          'task' === feature ||
          'inventory' === feature ||
          'element' === feature ||
          'sensor-type' === feature ||
          'sensor-manufacturer' === feature ||
          'survey-question-configuration' === feature ||
          'survey-question-response-configuration' === feature ||
          'user-billing-custom-report' === feature ||
          'subscription' === feature ||
          'inspection-score-factor-configuration' === feature
        ) {
          return true;
        }
      }
    }
    return false;
  }

  public canAccessFeature(userRolePermissions: RelUserRolePermissions[], feature: string, _access: string) {
    if (userRolePermissions.length > 0) {
      for (let i = 0; i < userRolePermissions.length; i++) {
        if (userRolePermissions[i].Permission.PermissionName === feature) {
          return userRolePermissions[i].PAccess > 0;
        }
      }
    }
    return false;
  }

  public setUserRolePermisisons(userRolePermissions: RelUserRolePermissions[]) {
    this.currentUserVal.UserRole.UserRolePermissions = userRolePermissions;
  }

  public storePageState(pageState) {
    this.stateMaintains = this.localSt.retrieve('StateMaintain');
    if (this.stateMaintains !== null && this.stateMaintains.length > 0) {
      for (let i = 0; i < this.stateMaintains.length; i++) {
        if (this.stateMaintains[i].pageName == pageState[0].pageName) {
          this.stateMaintains[i].pageState = pageState[0].page;
          this.stateMaintains[i].pageSize = pageState[0].size;
          this.stateMaintains[i].statusState = pageState[0].viewType;
        }
      }

      let obj = this.stateMaintains.filter((sm) => sm.pageName === pageState[0].pageName);
      if (obj.length === 0) {
        let custObj: StateMaintain = new StateMaintain();
        custObj.pageName = pageState[0].pageName;
        custObj.pageState = pageState[0].page;
        custObj.pageSize = pageState[0].size;
        custObj.statusState = pageState[0].viewType;
        this.stateMaintains.push(custObj);
      }
    } else {
      this.stateMaintain.pageName = pageState[0].pageName;
      this.stateMaintain.pageState = pageState[0].page;
      this.stateMaintain.pageSize = pageState[0].size;
      this.stateMaintain.statusState = pageState[0].viewType;
      this.stateMaintains = [];
      this.stateMaintains.push(this.stateMaintain);
    }
    this.localSt.store('StateMaintain', this.stateMaintains);
  }

  public retrivePageState(pageState) {
    this.stateMaintains = this.localSt.retrieve('StateMaintain');
    if (this.stateMaintains !== null && this.stateMaintains.length > 0) {
      for (let i = 0; i < this.stateMaintains.length; i++) {
        if (this.stateMaintains[i].pageName === pageState[0].pageName) {
          pageState[0].page = this.stateMaintains[i].pageState;
          pageState[0].size = this.stateMaintains[i].pageSize;
          pageState[0].viewType = this.stateMaintains[i].statusState;
        }
      }
    }
    return pageState;
  }

  public clearPageState() {
    this.localSt.clear('StateMaintain');
    this.localSt.clear('signalr');
    this.localSt.clear('gkey');
  }

  // The set method is use for encrypt the value.
  AESEncryptText(value): any {
    if (value != null) {
      let keys = this.SharedModule.pw1 + this.configuration.pw2;
      let key = CryptoJS.enc.Utf8.parse(keys);
      let iv = CryptoJS.enc.Utf8.parse(keys);
      let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
        keySize: 256,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return encrypted.toString();
    } else {
      return '';
    }
  }

  // The get method is use for decrypt the value.
  AESDecryptText(value): any {
    if (value != null) {
      let keys = this.SharedModule.pw1 + this.configuration.pw2;
      let key = CryptoJS.enc.Utf8.parse(keys);
      let iv = CryptoJS.enc.Utf8.parse(keys);
      let decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 256,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } else {
      return '';
    }
  }

  // store, retrieve and clear 'Service Provider'
  public storedContextServiceProviderId(contextServiceProviderId: any) {
    this.localSt.clear('traxContextServiceProviderId');
    this.localSt.store('traxContextServiceProviderId', contextServiceProviderId);
  }

  public storedServiceProviderName(serviceProviderName: any) {
    this.localSt.clear('serviceProviderKey');
    this.localSt.store('serviceProviderKey', serviceProviderName);
  }

  public getStoredContextServiceProviderId() {
    return this.localSt.retrieve('traxContextServiceProviderId');
  }

  public getStoredServiceProviderName() {
    return this.localSt.retrieve('serviceProviderKey');
  }

  // store, retrieve and clear 'Corporation'
  public storedContextCorporationId(contextCorporationId: any) {
    this.localSt.clear('traxContextCorporationId');
    this.localSt.store('traxContextCorporationId', contextCorporationId);
  }

  public storedCorporationName(corporationName: any) {
    this.localSt.clear('corporationKey');
    this.localSt.store('corporationKey', corporationName);
  }

  public getStoredContextCorporationId() {
    return this.localSt.retrieve('traxcontextcorporationid');
  }

  public getStoredCorporationName() {
    return this.localSt.retrieve('corporationKey');
  }

  // store, retrieve and clear 'Venue'
  public storedContextVenueId(contextVenueId: any) {
    this.localSt.clear('traxContextVenueId');
    this.localSt.store('traxContextVenueId', contextVenueId);
  }

  public storedVenueName(venueName: any) {
    this.localSt.clear('VenueKey');
    this.localSt.store('VenueKey', venueName);
  }

  public getStoredContextVenueId() {
    return this.localSt.retrieve('traxcontextvenueid');
  }

  public getStoredVenueName() {
    return this.localSt.retrieve('VenueKey');
  }

  // store, retrieve and clear 'Temp Venue'
  public storedTempVenueId(tempVenueId: any) {
    this.localSt.clear('tempVenueId');
    this.localSt.store('tempVenueId', tempVenueId);
  }

  public getStoredTempVenueId() {
    return this.localSt.retrieve('tempVenueId');
  }

  // store, retrieve and clear 'User Zone'
  public storeUserZoneId(userZoneId: any) {
    this.localSt.clear('traxuserzoneid');
    return this.localSt.store('traxuserzoneid', userZoneId);
  }

  public getStoredUserZone() {
    return this.localSt.retrieve('traxuserzoneid');
  }

  public clearUserZone() {
    this.localSt.clear('traxuserzoneid');
  }

  // store, retrieve and clear 'User Venue'
  public storeUserVenueId(userVenueId: any) {
    this.localSt.clear('traxuservenueid');
    return this.localSt.store('traxuservenueid', userVenueId);
  }

  public getStoredUserVenue() {
    return this.localSt.retrieve('traxuservenueid');
  }

  public clearUserVenue() {
    this.localSt.clear('traxuservenueid');
  }

  // store, retrieve and clear 'User Email'
  public storeUserEmail(userEmail: any) {
    this.localSt.clear('traxuseremail');
    return this.localSt.store('traxuseremail', userEmail);
  }

  public getStoredUserEmail() {
    return this.localSt.retrieve('traxuseremail');
  }

  public clearUserEmail() {
    this.localSt.clear('traxuseremail');
  }

  // store, retrieve and clear 'Sensor'
  public storeSensor(storeSensor: any) {
    this.localSt.clear('store-sensor');
    this.localSt.store('store-sensor', storeSensor);
  }

  public getStoredStoreSensor() {
    return this.localSt.retrieve('store-sensor');
  }

  // store, retrieve and clear 'Current User'
  public storedTraxCurrentUser(currentUser: any) {
    this.localSt.clear('traxcurrentuser');
    this.localSt.store('traxcurrentuser', currentUser);
  }

  public getStoredTraxCurrentUser() {
    return this.localSt.retrieve('traxcurrentuser');
  }

  public clearTraxCurrentUser() {
    this.localSt.clear('traxcurrentuser');
  }

  // store, retrieve and clear 'Token'
  public storedTraxToken(traxtoken: any) {
    this.localSt.clear('traxtoken');
    this.localSt.store('traxtoken', traxtoken);
  }

  public getStoredTraxToken() {
    return this.localSt.retrieve('traxtoken');
  }

  public clearTraxToken() {
    this.localSt.clear('traxtoken');
  }

  public storedSubscriptionType(subscriptionType: any) {
    this.localSt.clear('subscriptionType');
    this.localSt.store('subscriptionType', subscriptionType);
  }

  public storedShiftStaffSchedule(shiftStaffSchedule: any) {
    this.localSt.clear('ShiftStaffSchedule');
    this.localSt.store('ShiftStaffSchedule', shiftStaffSchedule);
  }

  public getStoredShiftStaffSchedule() {
    return this.localSt.retrieve('ShiftStaffSchedule');
  }

  public storedLocationUserAssignment(locationUserAssignment: any) {
    this.localSt.clear('LocationUserAssignment');
    this.localSt.store('LocationUserAssignment', locationUserAssignment);
  }

  public getStoredLocationUserAssignment() {
    return this.localSt.retrieve('LocationUserAssignment');
  }

  public storedEitherOneOrThese(eitherOneOrThese: any) {
    this.localSt.clear('EitherOneOrThese');
    this.localSt.store('EitherOneOrThese', eitherOneOrThese);
  }

  public getStoredEitherOneOrThese() {
    return this.localSt.retrieve('EitherOneOrThese');
  }

  public storeLogo(logoData: any) {
    this.localSt.clear('LogoData');
    this.localSt.store('LogoData', logoData);
  }

  public getStoredLogo() {
    return this.localSt.retrieve('LogoData');
  }

  // store, retrieve and clear 'Badge'
  public storedBadge(isBadgeEnable: any) {
    this.localSt.clear('isBadgeEnable');
    this.localSt.store('isBadgeEnable', isBadgeEnable);
  }

  public getStoredBadge() {
    return this.localSt.retrieve('isBadgeEnable');
  }

  public storedSignalrURL(signalrurl: any) {
    this.localSt.clear('signalr');
    if (signalrurl != null) {
      this.localSt.store('signalr', this.AESEncryptText(signalrurl));
    }
  }

  public getSignalrURL() {
    return this.localSt.retrieve('signalr');
  }

  public storedGoogleAPIKey(googleKey: any) {
    this.localSt.clear('gkey');
    if (googleKey != null) {
      this.localSt.store('gkey', this.AESEncryptText(googleKey));
    }
  }

  public getGoogleAPIKey() {
    return this.localSt.retrieve('gkey');
  }

  public storedEnableTouchlessFeedback(touchlessFeedback: any) {
    this.localSt.clear('EnableTouchlessFeedback');
    this.localSt.store('EnableTouchlessFeedback', touchlessFeedback);
  }

  public getStoredEnableTouchlessFeedback() {
    return this.localSt.retrieve('EnableTouchlessFeedback');
  }

  public clearEnableTouchlessFeedback() {
    this.localSt.clear('EnableTouchlessFeedback');
  }

  public storedEnableTouchlessFeedbackReason(touchlessFeedbackReason: any) {
    this.localSt.clear('EnableTouchlessFeedbackReason');
    this.localSt.store('EnableTouchlessFeedbackReason', touchlessFeedbackReason);
  }

  public getStoredEnableTouchlessFeedbackReason() {
    return this.localSt.retrieve('EnableTouchlessFeedbackReason');
  }

  public clearEnableTouchlessFeedbackReason() {
    this.localSt.clear('EnableTouchlessFeedbackReason');
  }

  public storedInspectionDate(inspectionDate: any) {
    this.localSt.clear('InspectionDate');
    this.localSt.store('InspectionDate', inspectionDate);
  }

  public getStoredInspectionDate() {
    return this.localSt.retrieve('InspectionDate');
  }

  public clearInspectionDate() {
    this.localSt.clear('InspectionDate');
  }

  // store, retrieve and clear 'Allow to share user flag'
  public storedIsAllowToShareUser(isAllowToShareUser: any) {
    this.localSt.clear('isAllowToShareUser');
    this.localSt.store('isAllowToShareUser', isAllowToShareUser);
  }

  public getStoredIsAllowToShareUser() {
    return this.localSt.retrieve('isAllowToShareUser');
  }

  public clearIsAllowToShareUser() {
    this.localSt.clear('isAllowToShareUser');
  }

  // store, retrieve and clear 'SP License Expiry Date'
  public storedSPLicenseExpiryDate(isAllowToShareUser: any) {
    this.localSt.clear('spLicenseExpiryDate');
    this.localSt.store('spLicenseExpiryDate', isAllowToShareUser);
  }

  public getStoredSPLicenseExpiryDate() {
    return this.localSt.retrieve('spLicenseExpiryDate');
  }

  public clearSPLicenseExpiryDate() {
    this.localSt.clear('spLicenseExpiryDate');
  }

  // store, retrieve and clear 'IP Address'
  public storedIPAddress(ipAddress: any) {
    this.localSt.clear('ipaddress');
    this.localSt.store('ipaddress', ipAddress);
  }

  public getStoredIPAddress() {
    return this.localSt.retrieve('ipaddress');
  }

  //*************************************** */

  public CheckLogin(defaultRoute: string = null): {
    user: User;
    login: Login;
    rolePermissions: RelUserRolePermissions[];
    userInStore: boolean;
  } {
    this.clearInspectionDate();

    this.configuration.userZoneId = this.getStoredUserZone();
    this.configuration.VenueId = this.getStoredUserVenue();

    var loggedInUser = this.getUserSession();
    var loggedInUserObj: Login = new Login();
    var userStored = false;
    var rolePermissions: RelUserRolePermissions[] = [];
    // Retrieve email id if user check the rememer me check box
    if (this.getStoredUserEmail() !== null) {
      userStored = true;
      loggedInUserObj.username = this.getStoredUserEmail();
      loggedInUserObj.rememberMe = true;
    }
    this.userPermissionsData(loggedInUser, rolePermissions, defaultRoute);
    return { user: loggedInUser, login: loggedInUserObj, rolePermissions, userInStore: userStored };
  }

  public userPermissionsData(loggedInUser: User, rolePermissions: RelUserRolePermissions[], defaultRoute: string) {
    if (loggedInUser && loggedInUser.UserId) {
      if (this.getStoredContextVenueId()) {
        this.getVenueById(this.getStoredContextVenueId(), loggedInUser, rolePermissions, defaultRoute);
      } else {
        this.setRolePermissions(loggedInUser, rolePermissions, defaultRoute);
      }
    }
  }

  public getVenueById(
    VenueId: number,
    loggedInUser: User,
    rolePermissions: RelUserRolePermissions[],
    defaultRoute: string,
  ) {
    this.loaderService.display(true);
    const CPvenue = new VenueModule();
    CPvenue.VenueCPId = this.AESEncryptText(VenueId);

    this.venueService.GetSingle(CPvenue).subscribe(
      (data) => {
        if (data != null) {
          if (data.SignalRServiceURL != null && data.SignalRServiceURL != '') {
            this.storedSignalrURL(data.SignalRServiceURL);
          }
          if (data.UserAssignmentRule != null) {
            this.storedShiftStaffSchedule(data.UserAssignmentRule.ShiftStaffSchedule);
            this.storedLocationUserAssignment(data.UserAssignmentRule.LocationUserAssignment);
            this.storedEitherOneOrThese(data.UserAssignmentRule.EitherOneOrThese);
          }
          this.storedBadge(data.IsBadgeEnable);
        }
        this.loaderService.display(false);
        this.setRolePermissions(loggedInUser, rolePermissions, defaultRoute);
      },
      (error) => {
        console.log(
          'venueService GetVenueById Call Failed. Status:' + error.status + ' Status Text:' + error.statusText,
        );
        this.loaderService.display(false);
        this.setRolePermissions(loggedInUser, rolePermissions, defaultRoute);
      },
    );
  }

  private setRolePermissions(loggedInUser: User, rolePermissions: RelUserRolePermissions[], defaultRoute: string) {
    this.loaderService.display(true);
    const searchfilter = { SearchQuery: '', GenericSearch: 0 };

    this.accessGroupPermissionService.GetAllPermissions(JSON.stringify(searchfilter), -1, -1).subscribe(
      (data) => {
        rolePermissions = data;
        this.loaderService.display(false);

        if (this.getStoredTraxToken() !== null && this.getStoredTraxCurrentUser() !== null) {
          if (rolePermissions != null && rolePermissions.length > 0) {
            this.isAuthenticated.next(true);
            this.setCurrentUser(loggedInUser);
            this.setUserRolePermisisons(rolePermissions);

            if (
              this.configuration.contextVenueId == null ||
              this.configuration.contextVenueId == 0 ||
              loggedInUser.IsSharedUser == 1
            ) {
              this.router.navigate(['configure-default-venue']);
            } else if (!!defaultRoute) {
              this.router.navigate([defaultRoute]);
            } else if (this.canAccessFeature(rolePermissions, 'dashboard', 'view')) {
              this.router.navigate(['dashboard']);
            } else if (this.canAccessFeature(rolePermissions, 'restroom-overview', 'view')) {
              this.router.navigate(['restroom-overview']);
            } else if (this.canAccessFeature(rolePermissions, 'profile-menu', 'view')) {
              this.router.navigate(['profile-menu']);
            } else {
              let shift = rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName == 'shift' &&
                  (this.getStoredShiftStaffSchedule() || this.getStoredEitherOneOrThese()),
              );
              if (shift && shift.length > 0) {
                this.router.navigate(['shift']);
                return;
              }

              let shiftBreak = rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName == 'shift-break-schedule' &&
                  (this.getStoredShiftStaffSchedule() || this.getStoredEitherOneOrThese()),
              );
              if (shiftBreak && shiftBreak.length > 0) {
                this.router.navigate(['shift-break-schedule']);
                return;
              }

              let staffSchedule = rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName == 'staff-schedule' &&
                  (this.getStoredShiftStaffSchedule() || this.getStoredEitherOneOrThese()),
              );
              if (staffSchedule && staffSchedule.length > 0) {
                this.router.navigate(['staff-schedule']);
                return;
              }

              let userLocation = rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName == 'userlocationassignment' &&
                  (this.getStoredLocationUserAssignment() || this.getStoredEitherOneOrThese()),
              );
              if (userLocation && userLocation.length > 0) {
                this.router.navigate(['userlocationassignment']);
                return;
              }

              let redirectScreen = rolePermissions.filter(
                (s) =>
                  s.Permission.PermissionName != 'shift' &&
                  s.Permission.PermissionName != 'SHIFT_VIEW' &&
                  s.Permission.PermissionName != 'SHIFT_ADD' &&
                  s.Permission.PermissionName != 'SHIFT_EDIT' &&
                  s.Permission.PermissionName != 'SHIFT_BRK_SCHEDULE_VIEW' &&
                  s.Permission.PermissionName != 'SHIFT_BRK_SCHEDULE_ADD' &&
                  s.Permission.PermissionName != 'shift-break-schedule' &&
                  s.Permission.PermissionName != 'SHIFT_BRK_SCHEDULE_EDIT' &&
                  s.Permission.PermissionName != 'staff-schedule' &&
                  s.Permission.PermissionName != 'STAFF_SCHEDULE_VIEW' &&
                  s.Permission.PermissionName != 'STAFF_SCHEDULE_ADD' &&
                  s.Permission.PermissionName != 'STAFF_SCHEDULE_EDIT' &&
                  s.Permission.PermissionName != 'userlocationassignment' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_VIEW_USERS_RECORDS' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_VIEW_UNASSIGNED_LOCATION' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_EDIT' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_ASSIGN_SELF' &&
                  s.Permission.PermissionName != 'LOCATION_ASGMT_ASSIGN_TO_OTHERS',
              );
              if (redirectScreen && redirectScreen.length > 0) {
                this.router.navigate([redirectScreen[0].Permission.PermissionName]);
              } else {
                this.router.navigate(['configure-default-venue']);
              }
            }
          } else {
            loggedInUser = new User();
            const permissions: RelUserRolePermissions[] = [];
            rolePermissions = permissions;

            this.logout();
            this.router.navigate(['login']);
            this.utilitiesService.smmodal('Sign In', 'You do not have necessary permissions.');
          }
        } else {
          this.logout();
          this.router.navigate(['login']);
        }
      },
      (error) => {
        this.loaderService.display(false);
        if (error.status == 401) {
          this.logout();
        }
      },
    );
  }
}
