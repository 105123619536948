import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';
import { AreaModule } from '../area.module';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetAreaList = (body: string, page: number, size: number): Observable<AreaModule[]> => {
    const _url: string = this.configuration.Server + 'venue/all/areas/' + page + '/' + size;
    return this.http.post<AreaModule[]>(_url, body);
  };

  public GetAreaListForDropDown = (body: string, page: number, size: number): Observable<AreaModule[]> => {
    const _url: string = this.configuration.Server + 'venue/areas/' + page + '/' + size;
    return this.http.post<AreaModule[]>(_url, body);
  };

  public GetSingle = (body: AreaModule): Observable<AreaModule> => {
    const _url: string = this.configuration.Server + 'venue/area/details';
    return this.http.post<AreaModule>(_url, body);
  };

  public Add = (addArea: any): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/area';
    return this.http.post<ResponseMessage>(_url, addArea);
  };

  public Update = (updateArea: any): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'venue/area';
    return this.http.put<ResponseMessage>(_url, updateArea);
  };
}
