import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthState } from 'src/app/store/auth.state';
import { UtilitiesService } from '../../../shared/components/utilityservices/utilityservice';
import { LoaderService } from '../../../shared/directives/loader.service';
import { User } from '../../../usermanagement/user/user.model';
import { CorporationService } from '../../corporation/components/corporation.http.service';
import { SensorManufacturerIntegrationKeyModule, SensorManufacturerModule } from '../sensormanufacturer.module';
import { SensorManufacturerService } from './sensormanufacturer.http.service';

@Component({
  selector: 'sensormanufacturer-component',
  templateUrl: 'sensormanufacturer.component.html',
  providers: [SensorManufacturerService, UtilitiesService, CorporationService],
})
export class SensorManufacturerComponent implements OnInit {
  currentUser: User;

  public sensormanufacturers: SensorManufacturerModule[] = [];
  public sensormanufacturer: SensorManufacturerModule = new SensorManufacturerModule();

  page = 1;
  size = 10;
  count = 0;
  pageSizes: any[] = [];
  searchString = '';
  isReqComplete = false;

  listviewtypes: any[];
  viewType: any = null;

  columnName: string;
  sortOrder: string;
  columnSortOrder = 'ASC';
  selectedColumnName: string;
  public pageState = [
    { pageName: 'sensormanufacturerForm', page: this.page, size: this.size, viewType: this.viewType },
  ];

  public isPublisherKeySectionShow = false;
  public keyRowId: number;
  public integrationKeyIndex = -1;

  constructor(
    private sensorManufacturerService: SensorManufacturerService,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
    private corporationService: CorporationService,
    public authState: AuthState,
  ) {
    this.currentUser = this.authState.getCurrentUser();
    this.sensormanufacturer = new SensorManufacturerModule();
    const integrationKey: SensorManufacturerIntegrationKeyModule[] = [];
    this.sensormanufacturer.SensorManufacturerIntegrationKey = integrationKey;
  }

  ngOnInit() {
    this.listviewtypes = this.utilitiesService.listviewtypes;
    this.viewType = this.listviewtypes[0].id;
    this.pageSizes = this.utilitiesService.pageSizes;
    this.size = this.pageSizes[0].id;
    this.getPageState();
  }

  public getPageState() {
    this.pageState = this.authState.retrivePageState(this.pageState);
    if (this.pageState[0].page != null && this.pageState[0].size != null && this.pageState[0].viewType != null) {
      this.page = this.pageState[0].page;
      this.size = this.pageState[0].size;
      this.viewType = this.pageState[0].viewType;
      this.getAllSensorManufacturers(this.searchString, this.page, this.size, this.viewType);
    } else {
      this.getAllSensorManufacturers(this.searchString, this.page, this.size, this.viewType);
    }
  }

  public addSensorManufacturer(sensormanufacturerform: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (sensormanufacturerform.status == 'INVALID' && sensormanufacturerform.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Sensor Manufacturer',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.sensormanufacturer.SensorManufacturerName = this.utilitiesService.removeInnerSpaces(
      this.sensormanufacturer.SensorManufacturerName,
    );

    if (
      this.sensormanufacturer.SensorManufacturerName === null ||
      this.sensormanufacturer.SensorManufacturerName === ''
    ) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    if (
      this.sensormanufacturer.SensorManufacturerIntegrationKey != null &&
      this.sensormanufacturer.SensorManufacturerIntegrationKey.length > 0
    ) {
      for (let k = 0; k < this.sensormanufacturer.SensorManufacturerIntegrationKey.length; k++) {
        this.sensormanufacturer.SensorManufacturerIntegrationKey[k].IsActive = this.sensormanufacturer
          .SensorManufacturerIntegrationKey[k].IsActive
          ? 1
          : 0;
      }
    }
    this.sensormanufacturer.AddedBy = this.currentUser.UserId;

    this.sensorManufacturerService.Add(this.utilitiesService.stripScript(this.sensormanufacturer)).subscribe(
      (data) => {
        this.page = 1;
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.clearFormData(sensormanufacturerform);
        this.utilitiesService.smmodal('Sensor Manufacturer', data.Message);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'sensorManufacturerService addSensorManufacturer Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public updateSensorManufacturer(sensormanufacturerform: NgForm) {
    this.loaderService.display(true);
    this.isReqComplete = true;

    if (sensormanufacturerform.status == 'INVALID' && sensormanufacturerform.disabled == false) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      this.utilitiesService.smmodal(
        'Sensor Manufacturer',
        'You cannot proceed ahead until all the fields are filled properly.',
      );
      return;
    }

    this.sensormanufacturer.SensorManufacturerName = this.utilitiesService.removeInnerSpaces(
      this.sensormanufacturer.SensorManufacturerName,
    );

    if (
      this.sensormanufacturer.SensorManufacturerName === null ||
      this.sensormanufacturer.SensorManufacturerName === ''
    ) {
      this.loaderService.display(false);
      this.isReqComplete = false;
      return;
    }

    if (
      this.sensormanufacturer.SensorManufacturerIntegrationKey != null &&
      this.sensormanufacturer.SensorManufacturerIntegrationKey.length > 0
    ) {
      for (let k = 0; k < this.sensormanufacturer.SensorManufacturerIntegrationKey.length; k++) {
        this.sensormanufacturer.SensorManufacturerIntegrationKey[k].IsActive = this.sensormanufacturer
          .SensorManufacturerIntegrationKey[k].IsActive
          ? 1
          : 0;
      }
    }
    this.sensormanufacturer.ModifiedBy = this.currentUser.UserId;
    this.sensormanufacturer.IsActive = this.sensormanufacturer.IsActive ? 1 : 0;

    this.sensorManufacturerService.Update(this.utilitiesService.stripScript(this.sensormanufacturer)).subscribe(
      (data) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        this.utilitiesService.smmodal('Sensor Manufacturer', data.Message);
        this.clearFormData(sensormanufacturerform);
      },
      (error) => {
        this.isReqComplete = false;
        this.loaderService.display(false);
        console.log(
          'sensorManufacturerService updateSensorManufacturer Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
    );
  }

  public getAllSensorManufacturers(searchTerm: string, pageIndex: number, pageSize: number, isActive: any) {
    this.loaderService.display(true);
    const searchfilter = {
      SearchQuery: searchTerm ? searchTerm.trim() : '',
      GenericSearch: 1,
      IsActive: this.utilitiesService.listViewType(isActive),
      SortColumn: this.columnName ? this.columnName : null,
      SortOrder: this.sortOrder ? this.sortOrder : null,
    };

    this.sensorManufacturerService
      .GetSensorManufacturerList(JSON.stringify(searchfilter), pageIndex, pageSize)
      .subscribe(
        (data) => {
          this.sensormanufacturers = data;
          if (this.sensormanufacturers.length < 0 && this.sensormanufacturers[0].Count / 10 < this.page) {
            this.page = 1;
          }
          this.loaderService.display(false);
        },
        (error) => {
          console.log(
            'sensorManufacturerService getAllSensorManufacturers Call Failed. Status:' +
            error.status +
            ' Status Text:' +
            error.statusText,
          );
          this.loaderService.display(false);
        },
        () => (this.count = this.sensormanufacturers[0] ? this.sensormanufacturers[0].Count : 0),
      );
  }

  public getSensorManufacturerById(SensorManufacturerId: number) {
    this.loaderService.display(true);
    const CPsensormanufacturer = new SensorManufacturerModule();
    CPsensormanufacturer.SensorManufacturerCPId = this.authState.AESEncryptText(SensorManufacturerId);

    this.sensorManufacturerService.GetSingle(CPsensormanufacturer).subscribe(
      (data) => {
        this.sensormanufacturer = data;

        if (
          this.sensormanufacturer.SensorManufacturerIntegrationKey != null &&
          this.sensormanufacturer.SensorManufacturerIntegrationKey.length > 0
        ) {
          this.isPublisherKeySectionShow = true;
          for (let key = 0; key < this.sensormanufacturer.SensorManufacturerIntegrationKey.length; key++) {
            this.sensormanufacturer.SensorManufacturerIntegrationKey[key].IntegrationKeyRowId = key + 1;
            if (this.sensormanufacturer.SensorManufacturerIntegrationKey[key].IsDelete == null) {
              this.sensormanufacturer.SensorManufacturerIntegrationKey[key].IsDelete = 0;
            }
          }
        } else {
          this.isPublisherKeySectionShow = false;
        }

        this.loaderService.display(false);
      },
      (error) => {
        this.loaderService.display(false);
        console.log(
          'sensorManufacturerService getSensorManufacturerById Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
      },
      () => this.setUpdateForm(),
    );
  }

  public pageChanged(p: number) {
    this.page = p;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensorManufacturers(this.searchString, this.page, this.size, this.viewType);
  }

  /// this funcation used to get result as per page size change
  public SensorManufacturerPageSizeChange($event: any) {
    this.size = $event;
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensorManufacturers(this.searchString, this.page, this.size, this.viewType);
  }

  public setUpdateForm() {
    this.searchString = '';
    this.sensormanufacturers = null;
    this.count = 0;
  }

  public setAddForm() {
    this.sensormanufacturer = new SensorManufacturerModule();
    const integrationKey: SensorManufacturerIntegrationKeyModule[] = [];
    this.sensormanufacturer.SensorManufacturerIntegrationKey = integrationKey;
    this.searchString = '';
    this.sensormanufacturers = null;
    this.count = 0;

    setTimeout(() => {
      const element = document.getElementById('SensormanufacturerName');
      element.focus();
    }, 1000);
  }

  public clearFormData(sensormanufacturerform: NgForm) {
    this.sensormanufacturer = new SensorManufacturerModule();
    const integrationKey: SensorManufacturerIntegrationKeyModule[] = [];
    this.sensormanufacturer.SensorManufacturerIntegrationKey = integrationKey;
    sensormanufacturerform.reset();
    this.searchString = '';
    this.getAllSensorManufacturers('', this.page, this.size, this.viewType);
  }

  public clearSearch() {
    this.searchString = '';
    this.getAllSensorManufacturers('', this.page, this.size, this.viewType);
  }

  public sensorManufacturersSearch(searchString: string) {
    this.page = 1;
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensorManufacturers(searchString, 1, this.size, this.viewType);
  }

  // Show message when data not available in list view
  public hasData(): boolean {
    return this.sensormanufacturers !== null && this.sensormanufacturers.length > 0;
  }

  public listviewtype($event: any) {
    this.viewType = $event;
    this.page = 1;
    if (this.viewType === null) {
      this.viewType = 1;
    }
    this.pageState[0].page = this.page;
    this.pageState[0].size = this.size;
    this.pageState[0].viewType = this.viewType;
    this.authState.storePageState(this.pageState);
    this.getAllSensorManufacturers(this.searchString, this.page, this.size, this.viewType);
  }

  public sortColumn(columnName: string, order: string) {
    this.columnName = columnName;
    if (this.selectedColumnName !== this.columnName) {
      order = 'ASC';
      this.page = 1;
    }
    this.sortOrder = order;
    this.columnSortOrder = order === 'DESC' ? 'ASC' : 'DESC';
    this.selectedColumnName = columnName;
    this.getAllSensorManufacturers(this.searchString, this.page, this.size, this.viewType);
  }

  public generateIntegrationKey() {
    this.loaderService.display(true);
    this.corporationService.GetCorporationIntegrationKey().subscribe(
      (data) => {
        if (data != null) {
          this.addIntegrtionKey(data);
        }
        this.loaderService.display(false);
      },
      (error) => {
        console.log(
          'corporationService GetCorporationIntegrationKey Call Failed. Status:' +
          error.status +
          ' Status Text:' +
          error.statusText,
        );
        this.loaderService.display(false);
      },
    );
  }

  public addIntegrtionKey(integrationKeyValue: any) {
    this.isPublisherKeySectionShow = true;
    this.keyRowId = this.sensormanufacturer.SensorManufacturerIntegrationKey.length;
    this.keyRowId = this.keyRowId + 1;

    const newIntegrationKey = new SensorManufacturerIntegrationKeyModule();
    if (integrationKeyValue != null || integrationKeyValue != '') {
      newIntegrationKey.IntegrationKeyValue = integrationKeyValue;
    } else {
      newIntegrationKey.IntegrationKeyValue = '';
    }

    newIntegrationKey.IntegrationKeyRowId = this.keyRowId;
    newIntegrationKey.IntegrationKeyId = null;
    newIntegrationKey.IntegrationKeyName = '';
    newIntegrationKey.MessageTypes = '';
    newIntegrationKey.IsActive = 1;
    newIntegrationKey.IsDelete = 0; // IsDelete = 0 means active record
    this.sensormanufacturer.SensorManufacturerIntegrationKey.push(newIntegrationKey);
  }

  public removeIntegrationKey(keyRowId: number) {
    for (let i = 0; i < this.sensormanufacturer.SensorManufacturerIntegrationKey.length; i++) {
      if (this.sensormanufacturer.SensorManufacturerIntegrationKey[i].IntegrationKeyRowId === keyRowId) {
        if (this.sensormanufacturer.SensorManufacturerIntegrationKey[i].IntegrationKeyId == null) {
          this.integrationKeyIndex = i;
          this.sensormanufacturer.SensorManufacturerIntegrationKey.splice(this.integrationKeyIndex, 1);
          i = i - 1;
        } else {
          this.sensormanufacturer.SensorManufacturerIntegrationKey[i].IsDelete = 1;
        }
      }
    }

    let keyObj = this.sensormanufacturer.SensorManufacturerIntegrationKey.filter((k) => k.IsDelete === 0);
    if (keyObj != null && keyObj.length === 0) {
      this.isPublisherKeySectionShow = false;
    }
  }
}
