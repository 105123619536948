import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UtilitiesService } from 'src/app/shared/components/utilityservices/utilityservice';
import { LoaderService } from 'src/app/shared/directives/loader.service';
import { AuthState } from 'src/app/store/auth.state';
import { UserService } from '../components/user.http.service';
import { User } from '../user.model';

@Component({
  selector: 'app-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.css'],
})
export class PasswordResetDialogComponent {
  passwordsDoNotMatch = false;
  passwordFieldType: string = 'password'; // For toggling visibility of password
  confirmPasswordFieldType: string = 'password'; // For toggling visibility of confirmPassword

  modalMessage = 'Password updated successfully!';
  modalTitle = 'User';
  showHidePassword: boolean = false;
  constructor(
    public authState: AuthState,
    private userservice: UserService,
    private utilitiesService: UtilitiesService,
    private loaderService: LoaderService,
  ) {}
  @Input() userData: User;
  @Output() close = new EventEmitter<string>();

  onCancel(form: NgForm): void {
    this.passwordsDoNotMatch = false;
    form.resetForm();
    this.close.emit();
  }

  onSubmit(form: NgForm): void {
    const { password, confirmPassword } = form.value;

    this.passwordsDoNotMatch = false;
    if (form.invalid) {
      return;
    }

    // Check if the passwords match
    if (password !== confirmPassword) {
      this.passwordsDoNotMatch = true;
    } else {
      this.loaderService.display(true);
      this.passwordsDoNotMatch = false;
      let newPassword = form.value.password;
      let encryptedPassword = this.authState.AESEncryptText(newPassword);
      this.userData.Password = encryptedPassword;

      this.userservice.Update(this.utilitiesService.stripScript(this.userData)).subscribe(
        (_data) => {
          this.utilitiesService.smmodal(this.modalTitle, this.modalMessage);
          this.loaderService.display(false);
        },
        (error) => {
          this.loaderService.display(false);

          this.utilitiesService.smmodal(error.status, error.statusText);
        },
      );

      form.resetForm();
      this.close.emit();
    }
  }

  togglePasswordVisibility(): void {
    this.showHidePassword = !this.showHidePassword;
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  toggleConfirmPasswordVisibility(): void {
    this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
  }
}
