import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
//////////////////
import 'jquery/src/jquery';
////
import 'bootstrap/dist/js/bootstrap';

//import 'font-awesome/css/font-awesome.css';

// import '../images/aroraatlas.ico';

// Admin LTE Theme
//import 'atlas-theme/dist/css/AdminLTE.css';
//import 'atlas-theme/dist/css/skins/_all-skins.css';
import 'atlas-theme/dist/js/adminApp';
import 'atlas-theme/dist/js/app';

//import '@angular/material/prebuilt-themes/deeppurple-amber.css';

// Pagination
import 'ngx-pagination/dist/ngx-pagination';

import 'crypto-js/crypto-js.js';

// Treeview
// import 'ngx-treeview/src/index';

// Lodash
import 'lodash/lodash';

// File uploader
import 'ng2-file-upload';
////////////////////

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
