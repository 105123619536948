import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Configuration } from '../../../app.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  UserLicenseRequestLogModule,
  SalesforceUserLicenseDetails,
  SProviderSalesforceLicenseRequestModule,
  ServiceProviderActiveUserLicensesModule,
} from '../userlicenserequestlog.module';
import { ResponseMessage } from '../../../shared/genericentity/genericentity';

@Injectable({
  providedIn: 'root',
})
export class UserLicenseRequestLogService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public GetUserLicenseRequestList = (
    body: string,
    page: number,
    size: number,
  ): Observable<SProviderSalesforceLicenseRequestModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/salesforce/license/request/' + page + '/' + size;
    return this.http.post<SProviderSalesforceLicenseRequestModule[]>(_url, body);
  };

  public GetSingle = (body: SalesforceUserLicenseDetails): Observable<SalesforceUserLicenseDetails[]> => {
    const _url: string = this.configuration.Server + 'admin/salesforce/user/licenses/request/log';
    return this.http.post<SalesforceUserLicenseDetails[]>(_url, body);
  };

  public CreateLicenceRequest = (body: UserLicenseRequestLogModule): Observable<ResponseMessage> => {
    // const _url: string = this.configuration.Server + 'admin/salesforce/user/licenses/request/log';
    const _url: string = this.configuration.Server + 'serviceprovider/salesforce/license/request';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public licenseRequest = (body: string): Observable<ServiceProviderActiveUserLicensesModule> => {
    const _url: string = this.configuration.Server + 'admin/serviceprovider/active/user/license/details';
    return this.http.post<ServiceProviderActiveUserLicensesModule>(_url, body);
  };

  public createLicenseRequest = (body: string): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'serviceprovider/salesforce/license/request';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public getInternalExternalUsers = (body: string): Observable<ServiceProviderActiveUserLicensesModule> => {
    const _url: string = this.configuration.Server + 'serviceprovider/internal/external/active/users';
    return this.http.post<ServiceProviderActiveUserLicensesModule>(_url, body);
  };

  public DeactivateUsers = (body: string): Observable<ServiceProviderActiveUserLicensesModule> => {
    const _url: string = this.configuration.Server + 'serviceprovider/deactivate/users';
    return this.http.post<ServiceProviderActiveUserLicensesModule>(_url, body);
  };
}
