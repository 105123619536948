import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HomeModule } from '../../../home/home.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { NavigationComponent } from './navigation.component';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [CommonModule, FormsModule, NgxPaginationModule, HomeModule, RouterModule],
  declarations: [NavigationComponent],
  exports: [NavigationComponent],
})
export class NavigationModule {}
