import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { CorporationModule } from '../../corporationmanagement/corporation/corporation.module';
import { HomeModule } from '../../home/home.module';
import { LocationModule } from '../../staffscheduling/location/location.module';
import { ZoneModule } from '../../staffscheduling/zone/zone.module';
import { User } from '../../usermanagement/user/user.model';
import { BuildingModule } from '../../venuemanagement/building/building.module';
import { LevelModule } from '../../venuemanagement/level/level.module';
import { VenueModule } from '../../venuemanagement/venue/venue.module';
import { InspectorLocationAssignmentComponent } from './components/inspectorlocationassignment.component';
import { InspectorLocationAssignmentRoutes } from './inspectorlocationassignment.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxPaginationModule,
    InspectorLocationAssignmentRoutes,
    HomeModule,
    NgMultiSelectDropDownModule,
  ],

  declarations: [InspectorLocationAssignmentComponent],
})
export class InspectorLocationAssignmentModule {
  public InspectorId: number;
  public Corporations: InspectorAssignmentCorporations[];
  public Corporation: InspectorAssignmentCorporations;
  public Venues: VenModule[];
  public Venue: VenModule;
  public User: User;
  public IsActive: any;
}

export class LocModule {
  public LocationId: number;
  public LocationName: string;
  public IsActive: any;
}

export class VenModule {
  public VenueId: number;
  public VenueName: string;
  public IsActive: any;
  public Location: LocModule;
  public Locations: LocModule[];
}

export class InspectorAssignmentCorporations {
  public CorporationId: number;
  public CorporationName: string;
  public Venue: VenModule;
  public InspectorAssignmentVenuesList: VenModule[];
}

export class FilterInputs {
  public ServiceProviderCPId: string;
  public CorporationCPId: string;
  public VenueCPId: string;
  public UserId: number;
  public ContextUserId: number;
  public UserCPId: string;
  public Corporations: CorporationModule[];
  public Venues: VenueModule[];
  public Buildings: BuildingModule[];
  public Zones: ZoneModule[];
  public Levels: LevelModule[];
  public Locations: LocationModule[];
  public CorporationIds: string;
  public VenueIds: string;
  public BuildingIds: string;
  public ZoneIds: string;
  public FilterIdentifier: string;
  public ContextVenueId: number;
  public ContextServiceProviderId: number;
  public ContextCorporationId: number;

  public Page: number;
  public Size: number;
  public Count: number;
  public SearchQuery: string;
  public GenericSearch: number;
  public SortColumn: string;
  public SortOrder: string;
  public IsActive: number;
  public InspectionDate: any;
  public InspectionId: number;
  public ViewMyInspection: number;
  public ScheduleTypeId: number;
  public VenueId: number;
  public CorporationId: number;
  public ScreenCode: string;
}

export class InspectionLocationAssignment {
  public Corporations: CorporationModule[];
  public Venues: VenueModule[];
  public Buildings: BuildingModule[];
  public Zones: ZoneModule[];
  public Locations: LocationModule[];
  public Levels: LevelModule[];
  public InspectorAssignmentCorporations: InspectorAssignmentCorporations[];
}
