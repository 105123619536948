import { Pipe, PipeTransform } from '@angular/core';
import { AuthState } from 'src/app/store/auth.state';

/**
 * This pipe is used to conditionally show/hide/disable the element based on user permission.
 */

@Pipe({
  name: 'checkUserPermission'
})
export class UserPermissionPipe implements PipeTransform {

  constructor(private readonly authState: AuthState) { }

  transform(permission: string): boolean {
    const currentUser = this.authState.getCurrentUser();
    return currentUser.UserApplicationLevel === permission;
  }
}
