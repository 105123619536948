import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeModule } from '../../home/home.module';
import { GenericEntity } from '../../shared/genericentity/genericentity';
import { User } from '../../usermanagement/user/user.model';
import { SensorManufacturerComponent } from './components/sensormanufacturer.component';
import { SensorManufacturerRoutes } from './sensormanufacturer.routes';

@NgModule({
  imports: [CommonModule, SensorManufacturerRoutes, FormsModule, NgxPaginationModule, HomeModule],

  declarations: [SensorManufacturerComponent],
})
export class SensorManufacturerModule extends GenericEntity {
  public SensorManufacturerId: number;
  public SensorManufacturerCPId: string;
  public SensorManufacturerName: string;
  public SensorManufacturerCode: string;
  public User: User;
  public SensorManufacturerIntegrationKey: SensorManufacturerIntegrationKeyModule[];
}

export class SensorManufacturerIntegrationKeyModule extends GenericEntity {
  public IntegrationKeyId: number;
  public IntegrationKeyName: string;
  public IntegrationKeyValue: string;
  public IsDelete: number;
  public SensorManufacturerId: number;
  public MessageTypes: string;
  public IntegrationKeyRowId: number;
}
