import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../../app.constants';
import { ResponseMessage } from '../../shared/genericentity/genericentity';

import { Module, ServiceProviderModule } from '../serviceprovider.module';

@Injectable({
  providedIn: 'root',
})
export class ServiceProviderService {
  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private configuration: Configuration,
  ) {}

  public Add = (body: ServiceProviderModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'serviceprovider/serviceprovider';
    return this.http.post<ResponseMessage>(_url, body);
  };

  public Update = (body: ServiceProviderModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'serviceprovider/serviceprovider';
    return this.http.put<ResponseMessage>(_url, body);
  };

  public GetServiceProviderList = (body: string, page: number, size: number): Observable<ServiceProviderModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/all/serviceproviders/' + page + '/' + size;
    return this.http.post<ServiceProviderModule[]>(_url, body);
  };

  public GetServiceProviderListForDropdown = (
    body: string,
    page: number,
    size: number,
  ): Observable<ServiceProviderModule[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/serviceproviders/' + page + '/' + size;
    return this.http.post<ServiceProviderModule[]>(_url, body);
  };

  public GetSingle = (body: ServiceProviderModule): Observable<ServiceProviderModule> => {
    const _url: string = this.configuration.Server + 'serviceprovider/details';
    return this.http.post<ServiceProviderModule>(_url, body);
  };

  public GetModueleList = (): Observable<Module[]> => {
    const _url: string = this.configuration.Server + 'serviceprovider/module/features';
    return this.http.get<Module[]>(_url);
  };

  AddServiceProviderLogo(filedata: File, serviceProviderId: number): Promise<ResponseMessage> {
    return new Promise((resolve, reject) => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(<ResponseMessage>JSON.parse(xhr.response));
          } else {
            reject(xhr.response);
          }
        }
      };
      const _url: string = this.configuration.Server + 'serviceprovider/logo/serviceprovider/' + serviceProviderId;

      xhr.open('PUT', _url, true);
      xhr.setRequestHeader('Authorization', 'bearer ' + this.configuration.Token);

      const formData = new FormData();
      formData.append('file', filedata, filedata.name);
      xhr.send(formData);
    });
  }

  public RemoveServiceProviderLogo = (serviceProvider: ServiceProviderModule): Observable<ResponseMessage> => {
    const _url: string = this.configuration.Server + 'serviceprovider/logo/remove';
    return this.http.put<ResponseMessage>(_url, serviceProvider);
  };

  public GetMetaData = (url: string): Observable<any> => {
    const _url: string = this.configuration.AuthServer + 'sso/metadata-details';
    const body = { url: url };
    return this.http.post<any>(_url, body);
  };
}
